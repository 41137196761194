export const iconPresets = {
  goToDetails: { icon: "external-link-square-alt" },
  delete: { icon: "trash-alt", color: "error" },
  terminate: { icon: "power off" },
  edit: { icon: "edit", color: "primary" },
  add: { icon: "plus" },
  copy: { icon: "copy" },
  paste: { icon: "paste" },
  showMore: { icon: "ellipsis-h" },
  search: { icon: "search" },
  filters: { icon: "sliders-h" },
  clear: { icon: "times" },
  revertChanges: { icon: "undo" },
  paginationPrev: { icon: "angle-left" },
  paginationNext: { icon: "angle-right" },
  paginationFirst: { icon: "angle-double-left" },
  paginationLast: { icon: "angle-double-right" },
  // TODO: to chyba powinny być presety pasywnych ikon a nie przycisków
  success: { icon: "check", color: "success", title: "" },
  error: { icon: "times", color: "error", title: "" },
  warning: { icon: "exclamation triangle", color: "warning", title: "" },
};
