import React from "react";
import PropTypes from "prop-types";

const HeaderCell = props => {
  let style = {
    position: "relative",
    cursor:
      props.hasOwnProperty("onClick") || props.hasOwnProperty("onDoubleClick")
        ? "pointer"
        : "inherit",
    textAlign: props.align,
    ...props.style
  };

  return (
    <td style={style} {...props}>
      {props.children}
    </td>
  );
};

HeaderCell.propTypes = {
  align: PropTypes.oneOf(["left", "center", "right"]),
  children: PropTypes.node,
};

HeaderCell.defaultProps = {
  align: "center"
};

export default HeaderCell;
