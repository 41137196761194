import React, { Component } from "react";
import PropTypes from "prop-types";
import Ink from "react-ink";
import cx from "classnames";

import { Link } from "react-router-dom";

import { Icon } from "components/shared";

// 'kafelki' wyświetlane na stronie głównej i przy wybieraniu opcji logowania

class Tile extends Component {
  componentDidMount() {
    this.handleFocus();
  }

  componentDidUpdate(prevProps) {
    if (this.props.focus !== prevProps.focus) {
      this.handleFocus();
    }
  }

  handleFocus() {
    if (this.props.focus) {
      this.divRef.focus();
    }
  }

  render() {
    const props = this.props;
    const deniedClass = props.denied ? `tile--denied` : ""; // klasa dodawana do elementów z atrybutem 'denied'
    const depthClass = props.denied ? "" : `z-depth-1`;
    const twoTilesClass = props.twoTiles ? "tile--two-tiles" : ""; // klasa wyświetlająca dwa kafelki zamiast domyślnych trzech na większych szerokościach ekranów
    const invertedClass = props.inverted ? "tile__content--inverted" : "";
    const narrowClass = props.narrow ? "tile--narrow" : "";
    const wideClass = props.wide ? "tile--wide" : "";
    const animateClass = props.animate === false ? "" : "tile---animate";

    const containerClassNames = cx(
      "tile",
      { "z-depth-1": depthClass },
      { "tile--denied": deniedClass },
      { "tile--narrow": narrowClass },
      { "tile--wide": wideClass },
      { "tile--two-tiles": twoTilesClass },
      { "tile--animate": animateClass },
      props.className
    );

    const contentClassNames = cx("tile__content", {
      "tile__content--inverted": invertedClass
    });

    const ripple = props.ripple && !props.denied ? <Ink /> : null;

    let content = (
      <div className={contentClassNames}>
        <Icon className="tile__icon" name={props.icon} />
        <p className="tile__title">{props.text}</p>
        <p className="tile__description">{props.description}</p>
        {ripple}
      </div>
    );
    
    if (props.link) {
      if (props.target && props.target !== "_self") {
        // FIXME: hak, bo sso-auth.html nie może się zamknąć, bo <Link> otwiera okno skryptowo
        //        i wtedy window.close jest najwyraźniej zabronione
        //        warto by to rozwiązać globalnie
        content = <a href={props.link} target={props.target} rel="opener">
          {content}
        </a>;
      }
      else {
        // wyświetlanie jako routerowy Link, jeśli podany został parametr 'link'
        content = <Link to={props.link} target={props.target}>
          {content}
        </Link>;
      }
    }

    return (
      <div
        className={containerClassNames}
        tabIndex={0}
        ref={el => (this.divRef = el)}
        onClick={props.clicked}
        style={props.style}
      >
        {content}
      </div>
    );
  }
}

export default Tile;

Tile.defaultProps = {
  link: "",
  denied: false,
  description: "",
  ripple: true,
  inverted: false,
  twoTiles: false,
  animate: true
};

Tile.propTypes = {
  link: PropTypes.string,
  denied: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  description: PropTypes.string,
  target: PropTypes.string,
  clicked: PropTypes.func,
  ripple: PropTypes.bool,
  inverted: PropTypes.bool,
  twoTiles: PropTypes.bool,
  animate: PropTypes.bool
};
