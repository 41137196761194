import {useEffect, useRef} from "react";

import {emptyArray} from "../constants";

import {RefSetter} from "./util";

/**
 * Hook monitorujący pozycję i rozmiar elementu.
 * @param callback callback wywoływany gdy pozycja lub rozmiar elementu się zmieni
 * @return         funkcja do przekazania jako ref do JSX by złapać monitorowany element
 */
export function usePositionObserver(active: boolean, callback: (rect: DOMRectReadOnly, element: HTMLElement) => void): RefSetter<HTMLElement> {
  type State = {
    active: boolean
    element: HTMLElement | null
    callback: typeof callback
    onMount: () => () => void
    onRef: RefSetter<HTMLElement>
    interval: ReturnType<typeof setInterval> | null
    rect: DOMRectReadOnly | null
    update: () => void
    listen: () => void
    unlisten: () => void
  }

  const ref = useRef(null as any as State);

  if (ref.current === null) {
    let queued = false;
    let timer: ReturnType<typeof setTimeout> | null = null;

    function update () {
      queued = false;
      if (state.element === null) return;

      const rect = state.element.getBoundingClientRect(), old = state.rect;
      if (old && rect.left === old.left && rect.top === old.top && rect.width === old.width && rect.height === old.height)
        return;

      state.rect = rect;
      state.callback(rect, state.element);

      //scheduleReactUpdate(state.callback, rect);
    }

    function requestUpdate() {
      if (timer) {
        clearTimeout(timer);
        timer = null;
      }

      if (queued === false)
        requestAnimationFrame(update);

      queued = true
    }

    function onMutations(mutations: MutationRecord[]) {
      if (timer || queued)
        return;

      let ignore = true;
      for (let i = 0; i < mutations.length; i++) {
        const mut = mutations[i];
        // ignorujemy ustawianie atrybutu przez usePositionSync
        if (mut.type !== "attributes" || mut.target !== state.element
          // @ts-ignore
          || !POSITION_ATTRS[mut.attributeName]) {
          ignore = false;
          break;
        }
      }

      if (!ignore)
        timer = setTimeout(requestUpdate, 200);
    }

    const observer = new MutationObserver(onMutations);

    const state = ref.current = {
      active,
      element: null,
      callback,
      interval: null,
      rect: null,

      onRef: (ref: HTMLElement | null) => {
        const old = state.element;
        
        state.element = ref;
        
        if (ref === null)
          state.unlisten();
        else if (old === null && state.active)
          state.listen();
      },

      onMount: () => () => {
        if (state.element !== null)
          state.unlisten();
        state.element = null;
      },

      listen() {
        //console.log("listen")
        document.addEventListener("scroll", requestUpdate, CAPTURE_EVENT_OPTS);
        document.addEventListener("hover", requestUpdate, CAPTURE_EVENT_OPTS);
        window.addEventListener("resize", requestUpdate);
        window.addEventListener("orientationchange", requestUpdate);
        // obserwujemy bezpośrednio zmiany w offsetParencie
        state.element && observer.observe(state.element.offsetParent || state.element, MUTATION_OBSERVER_OPTS);
        // jeśli zmiana na zewnątrz przesunie lub zmieni rozmiar elementu, to wykryjemy to po sekundzie
        this.interval = setInterval(requestUpdate, 1000);
        
        requestUpdate();
      },

      unlisten() {
        //console.log("unlisten")
        clearInterval(this.interval as any);
        observer.disconnect();
        window.removeEventListener("resize", requestUpdate);
        window.removeEventListener("orientationchange", requestUpdate);
        document.removeEventListener("scroll", requestUpdate, CAPTURE_EVENT_OPTS);
        document.removeEventListener("hover", requestUpdate, CAPTURE_EVENT_OPTS);
      },
    } as State;
  }
  else {
    const state = ref.current;
    
    state.callback = callback;
    
    if (active && !state.active) {
      state.active = active;
      if (state.element)
        state.listen();
    }
    else if (!active && state.active) {
      state.active = active;
      if (state.element)
        state.unlisten();
    }
  }
  
  useEffect(ref.current.onMount, emptyArray);
  
  return ref.current.onRef;
}

const MUTATION_OBSERVER_OPTS = Object.freeze({
  subtree: true,
  childList: true,
  characterData: true,
  attributes: true,
});

const CAPTURE_EVENT_OPTS = Object.freeze({
  capture: true,
});

const POSITION_ATTRS = { "horz-pos": 1, "vert-pos": 1 }
