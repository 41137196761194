import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import isEqual from "lodash.isequal";

import { Icon } from "components/shared";
import { pages } from "utils/pages";
import { Dictionary } from "utils/types";
import { emptyArray, emptyObject } from "../constants";
import {
  finishImmediateBatchUpdates,
  scheduleImmediateReactUpdate,
  startImmediateBatchUpdates
} from "./batch";

/** Typ okruszka deklarowanego. */
export type Breadcrumb = {
  /** Etykieta wyświetlana w pasku okruszków. */
  label?: React.ReactNode
  
  /** Tytuł strony do ustawienia gdy to ostatni okruszek. */
  title?: string
  
  /** Część ścieżki (bez żadnych ukośników). */
  part?: string
  
  /** Pełna ścieżka */
  path?: string
  
  /** Querystring, (ale można zamiast tutaj zawrzeć też w path) */
  query?: string
  
  /** Dodatkowe dane okruszka. (opcj. lokalny stan aplikacji) */
  bcExtra?: Dictionary
}

/** Typ okruszka przetworzonego do wyświetlenia. */
export type DisplayBreadcrumb = {
  label: React.ReactNode
  
  title: string
  
  url: string
  
  bcExtra: Dictionary
}

type BreadcrumbsChangedSignal = (breadcrumbs: readonly DisplayBreadcrumb[]) => void

/**
 * API do rejestracji okruszków, w postaci kontekstu.
 * Obiekt kontekstu nigdy nie zmienia się sam, powiadomienia o zmianach propagowane są ręcznie.
 */
interface BreadcrumbsContext {
  /** Aktualna Lista okruszków. */
  breadcrumbs: readonly Breadcrumb[]
  
  /** Rejestr callbacków komponentów zainteresowanych zmianami listy okruszków, mutowalny. */
  readonly listeners: Set<BreadcrumbsChangedSignal>
  
  /** Rejestr bezpośrednich potomków tego kontekstu, mutowalny. */
  readonly subcontexts: BreadcrumbsContext[]
  
  /** Aktualizacja listy okruszków dla komponentów odczytujących. */
  update(): void
  
  /** Rejestracja nowego potomka. */
  registerSC(sub: BreadcrumbsContext): void
  
  /** Zapomnienie odmontowanego potomka. */
  unregisterSC(sub: BreadcrumbsContext): void
  
  /** Najwyższy kontekst w hierarchii */
  top: BreadcrumbsContext
}

const BreadcrumbsContext = React.createContext({} as unknown as BreadcrumbsContext);

/** Hook do odczytu breadcrumbów z usługi */
export function useDisplayBreadcrumbs() {
  type State = {
    onMount: () => () => void
  }
  const context = useContext(BreadcrumbsContext).top;
  const stateRef = useRef(null as unknown as State);
  const [breadcrumbs, setBreadcrumbs] = useState<readonly DisplayBreadcrumb[]>(emptyArray);
  
  if (!stateRef.current) {
    stateRef.current = {
      onMount: () => {
        context.listeners.add(setBreadcrumbs);
        if (context.breadcrumbs.length > 0)
          setBreadcrumbs(processBreadcrumbsForDisplay(context.breadcrumbs));
        return () => context.listeners.delete(setBreadcrumbs);
      }
    }
  }
  
  useEffect(stateRef.current.onMount, emptyArray);
  
  return breadcrumbs;
}

function processBreadcrumbsForDisplay(breadcrumbs: readonly Breadcrumb[]): readonly DisplayBreadcrumb[] {
  let path = "/"; // budujemy pełną ścieżkę z komponentów
  let title = ""; // i kaskadujemy też tytuły, interesuje nas ostatni ustawiony
  let lastCrumb: DisplayBreadcrumb | null = null;
  const result = breadcrumbs.map(crumb => {
    let query = crumb.query;
    if (crumb.path) {
      path = crumb.path;
      const q = path.indexOf("?")
      if (q >= 0) {
        query = query || path.substring(q);
        path = path.substring(0, q);
      }
    }
    else if (crumb.part) {
      if (!path.endsWith("/"))
        path += "/";
      path += encodeURIComponent(crumb.part);
    }
    else if (query && lastCrumb && !crumb.label) {
      // doklejamy do poprzedniego
      lastCrumb.url = path + query;
      lastCrumb.bcExtra = { ...lastCrumb.bcExtra, ...crumb.bcExtra };
      return emptyDisplayBreadcrumb; // to się odfiltruje
    }
    
    title = crumb.title || title;
    
    lastCrumb = {
      label: crumb.label || "",
      title,
      url: path + (query || ""),
      bcExtra: crumb.bcExtra || emptyObject
    };
    
    return lastCrumb;
  }).filter(crumb => crumb.label);
  return result;
}

const emptyDisplayBreadcrumb: DisplayBreadcrumb = { label: "", title: "", url: "", bcExtra: emptyObject }

/**
 * Serwis z contextem odpowiadający za zarządzanie okruszkami.
 * Nigdy tego komponentu nie budzimy zmianami stanu.
 */
export function BreadcrumbsService(props: { children: React.ReactNode }) {
  // Tutaj znajduje się interfejs do obsługi okruszków propagowany przez context.
  const apiRef = useRef(null as unknown as BreadcrumbsContext);
  
  if (!apiRef.current) {
    const top: BreadcrumbsContext & { _timer: ReturnType<typeof setTimeout> | null, _update: () => void } = {
      breadcrumbs: emptyArray,
      listeners: new Set<BreadcrumbsChangedSignal>(),
      subcontexts: [],
      _timer: null,
      top: null as any,
      update() {
        // opóźniamy troszkę aktualizację by złapać wiele sąsiadujących zmian
        if (!top._timer)
          top._timer = setTimeout(top._update, 50);
      },
      _update: () => {
        top._timer = null;
        
        // budujemy aktualne okruszki nawigując w dół po drzewie potomków
        // bierzemy zawsze pierwszego potomka, bo on był pierwszy zamontowany
        // powinno to być kompatybilne z concurrent mode
        const result = [] as Breadcrumb[];
        let subs = top.subcontexts;
        while (subs.length > 0) {
          const sub = subs[0];
          result.push(...sub.breadcrumbs);
          subs = sub.subcontexts;
        }
        
        if (isEqual(top.breadcrumbs, result)) return;
        
        top.breadcrumbs = result;
        const display = processBreadcrumbsForDisplay(result);
        
        // budzimy komponenty monitorujące listę okruszków
        startImmediateBatchUpdates();
        try {
          for (let listener of top.listeners)
            scheduleImmediateReactUpdate(listener, display);
        }
        finally {
          finishImmediateBatchUpdates();
        }
      },
      registerSC(sub: BreadcrumbsContext) {
        // tutaj jest this.subcontexts a nie top, żeby działało na potomkach
        this.subcontexts.push(sub);
        this.update();
      },
      unregisterSC(sub: BreadcrumbsContext) {
        this.subcontexts.splice(this.subcontexts.indexOf(sub), 1);
        this.update();
      },
    };
    top.top = top;
    apiRef.current = top;
  }
  
  return (
    <BreadcrumbsContext.Provider value={apiRef.current}>
      <WithPageJs>
        {props.children}
      </WithPageJs>
    </BreadcrumbsContext.Provider>
  );
}

/** Część wspólna logiki komponentów dostarczających breadcrumby */
function useWithBreadcrumbs(): BreadcrumbsContext {
  type API = BreadcrumbsContext & { onMount: () => () => void };
  
  const parent = useContext(BreadcrumbsContext);
  const apiRef = useRef(null as unknown as API);
  
  if (!apiRef.current) {
    const self = {
      __proto__: parent, // dziedziczymy update(), [un]registerSC()
      breadcrumbs: emptyArray,
      top: parent.top || parent,
      subcontexts: [],
      onMount: () => {
        parent.registerSC(self);
        return () => parent.unregisterSC(self);
      }
    } as any as API; // TS jeszcze nie wspiera __proto__
    apiRef.current = self;
  }
  
  const self = apiRef.current;
  useEffect(self.onMount, emptyArray);
  
  return self;
}

export function WithBreadcrumbs(props: { breadcrumbs: readonly Breadcrumb[], children: React.ReactNode }) {
  const self = useWithBreadcrumbs();
  
  useEffect(() => {
    self.breadcrumbs = props.breadcrumbs;
    self.update();
  }, [props.breadcrumbs]);
  
  // TODO: opt?
  return <BreadcrumbsContext.Provider value={self} children={props.children} />;
}

export function WithBreadcrumb(props: Breadcrumb & { children: React.ReactNode }) {
  const self = useWithBreadcrumbs();
  
  useEffect(() => {
    self.breadcrumbs = [{ label: props.label, title: props.title, part: props.part, path: props.path, query: props.query, bcExtra: props.bcExtra }];
    self.update();
  }, [props.label, props.title, props.part, props.path, props.query, props.bcExtra]);
  
  // TODO: opt?
  return <BreadcrumbsContext.Provider value={self} children={props.children} />;
}

function WithPageJs(props: { children: React.ReactNode }) {
  const self = useWithBreadcrumbs();
  const location = useLocation();
  
  useEffect(() => {
    self.breadcrumbs = parseLocation(location);
    self.update();
  }, [location]);
  
  return <BreadcrumbsContext.Provider value={self} children={props.children} />;
}

/** Mapuje stronę do breadcrumbów. */
function parseLocation(location: ReturnType<typeof useLocation>): Breadcrumb[] {
  let breadcrumbs = [ROOT_CRUMB];
  let currentPage = pages;
  
  const pathname = location.pathname.slice(1);
  const pathParts = pathname.split("/");
  
  for (let i = 0; i < pathParts.length; i++) {
    const part = pathParts[i];
    if (part === "") continue;
    
    const page = findPageByKey(part, currentPage);
    if (!page || page.terminateBreadcrumb) 
      return breadcrumbs;
    
    if (page?.children) {
      // do następnej iteracji wchodzimy poziom głębiej jeśli jest dostępny
      currentPage = page.children;
    }
    
    if (page.displayInBreadcrumb !== false) {
      // Tworzymy nowy breadcrumb jeśli strona życzy sobie być wyświetlana w liście.
      const label = page.displayName;
      
      if (page.nextStepRequired) {
        breadcrumbs.push({ part });
        
        // Doklejamy do ścieżki kolejny krok, który jest wymagany.
        breadcrumbs.push({
          label,
          title: label,
          part: pathParts[++i],
        });
      }
      else {
        breadcrumbs.push({
          label, part,
          title: label,
        });
      }
    }
    else {
      if (breadcrumbs.length > 1) {
        // doklejamy ten komponent do poprzedniego okruszka
        const last = breadcrumbs[breadcrumbs.length - 1];
        breadcrumbs.push({
          label: last.label,
          title: last.title,
          part,
        });
        last.label = undefined;
        last.title = undefined;
      }
      else {
        breadcrumbs.push({ part });
      }
    }
    
    if (!page.children)
      break;
  }
  
  return breadcrumbs;
};

/** Ikona domku prowadząca na stronę startową */
const ROOT_CRUMB: Breadcrumb = Object.freeze({ label: <Icon name="home" style={{ marginRight: 0 }} />, path: "/" });

/**
 * Wyszukuje stronę z podanym kluczem.
 * @example
 * const adminPage = findPageByKey("admin", pages);
 * const toolsPage = findPageByKey("tools", adminPage.children);
 */
const findPageByKey = (urlPart: string, nestedPages: Dictionary): any => {
  // FIXME: tego nie da się wydajniej napisać?
  const pageKey = Object.keys(nestedPages)
    .find((key) =>
      nestedPages[key].path.replace("/", "") === urlPart );
  
  return nestedPages[pageKey as any];
}
