import { Dictionary } from "./types";

export const doNothing = Object.freeze(function(): void {}) as (...v: any[]) => void;

export const newObject = Object.freeze(() => ({} as Dictionary));

export const newArray = Object.freeze(() => [] as any[]);

export const emptyObject = Object.freeze({});

export const emptyArray = Object.freeze([]) as (readonly []);

export const emptySet = Object.freeze(new Set<any>());

export const emptyMap = Object.freeze(new Map<any,any>());

export const getEmptyObject = Object.freeze(() => emptyObject);

export const getEmptyArray = Object.freeze(() => emptyArray);

export const identity = Object.freeze(<T>(v: T) => v);

export const increment = Object.freeze((v: number) => v + 1);

export const decrement = Object.freeze((v: number) => v - 1);

export const negate = Object.freeze((v: any) => !v);

export const alwaysNull = Object.freeze(() => null) as (...v: any[]) => null;

export const alwaysTrue = Object.freeze(() => true) as (...v: any[]) => true;

export const alwaysFalse = Object.freeze(() => false) as (...v: any[]) => false;

export const isTrue = Object.freeze((v: any) => !!v);

export const isFalse = Object.freeze((v: any) => !v);

export const asyncIdentity = Object.freeze(<T>(v: T) => Promise.resolve(v));

const ASYNC_VOID = Promise.resolve();
export const asyncVoid = Object.freeze(() => ASYNC_VOID);

export const isString = Object.freeze((v: any): v is string => typeof v === "string");

export const stateReducer = Object.freeze(<T>(state: T, action: T): T => action);

const fz = Object.freeze;

/** Służy do tworzenia krotek literałów.
 *  Tzn. `["foo", "bar"]` normalnie ma typ `string[]`,
 *  ale  `tuple("foo", "bar")`      ma typ `readonly ["foo", "bar"]`.
 *  Jest to potrzebne gdy używamy interfejsów, które używają `|` jako enumów oraz
 *  w szczególności wszelkich z `keyof X` */
export const tuple = Object.freeze(function tuple<T extends (string | number | boolean | symbol | null | undefined | object)[]>(...t: T): Readonly<T> { return fz(t) as any; });

/** Służy do tworzenia krotek literałów.
 *  Tzn. `["foo", "bar"]` normalnie ma typ `string[]`,
 *  ale  `tuple("foo", "bar")`      ma typ `["foo", "bar"]`.
 *  Jest to potrzebne gdy używamy interfejsów, które używają `|` jako enumów oraz
 *  w szczególności wszelkich z `keyof X` */
export const tupleMut = Object.freeze(function tupleMut<T extends (string | number | boolean | symbol | null | undefined | object)[]>(...t: T): T { return t });

/** Podobnie jak tuple wyżej wymusza typ literału, bo domyślnie literały stringowe mają po prostu typ `string` */
export const literal = Object.freeze(function literal<T extends string | number | boolean | symbol | null | undefined | object>(t: T) { return t; });
