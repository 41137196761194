import React from "react";
import { useSelector } from "react-redux";

import { Button, Dropdown } from "components/shared";

import { pages } from "utils/pages";
import { tran } from "utils/language";

import bootstrap from "bootstrap";
import { alwaysFalse, alwaysTrue, emptyArray, tuple } from "../../../utils/constants";
import { useDialog } from "../../shared/Dialog";
import { LoginDialog } from "../../special-pages/Login/LoginDialog";
import { LogoutDialog } from "./LogoutDialog";
import { checkCzytelniaRights } from "../../../utils/globalFunctions";
import { useDerivedState } from "../../../utils/hooks";
import { isStaff } from "../../../utils/roles";
import { isLoaded } from "../../../capi/hooks";
import { useSowaParams } from "../../../utils/params/hooks";

const SOWA_CAT_ID = bootstrap.sowa.firstCatalogue?.cat_id;

const licenseParams = tuple("system.licence.default.modules");

export function HamburgerMenu({ openSettingsWindow }) {
  const currentUser = useSelector(currentUserSelector);
  const loginDialog = useDialog(LoginDialog);
  const logoutDialog = useDialog(LogoutDialog);
  const isStaff = isUserStaff(currentUser);
  
  const licenseRq = useSowaParams(isStaff ? SOWA_CAT_ID : undefined, ...licenseParams);
  const licensedModules = isLoaded(licenseRq) && licenseRq.data[licenseParams[0]] || emptyArray;
  
  const options = useDerivedState(filterMenuItems, currentUser, licensedModules);
  
  return <Dropdown
    trigger={
      <Button 
        icon="bars" 
        className="top-bar__menu-btn" 
        title={tran("pages.topbar.menu")} 
      />
    }
    menuPosition="right"
    className="top-bar__menu"
    options={options}
    onChange={onChange}
  />
  
  function onChange(value) {
    switch (value) {
      case "settings":
        openSettingsWindow && openSettingsWindow();
        break;
      case "logout":
        logoutDialog.open();
        break;
      case "login":
        loginDialog.open();
        break;
    }
  }
}

const currentUserSelector = state => state.currentUser;
const filterMenuItems = (currentUser, licensedModules) => menuItems.filter(({ _display }) => _display(currentUser, licensedModules)).map(it => ({ ...it, _display: undefined }));

const isUserLoggedIn = currentUser => !!currentUser.folks.user_id;
const isUserLoggedOut = currentUser => !currentUser.folks.user_id;
const isUserStaff = currentUser => isStaff(currentUser.folks.role);

const menuItems = bootstrap.sisters.map(({ host, name, icon, proto }) => ({
  text: name,
  link: (location) => `${proto || ""}${proto ? ":" : ""}//${host}${location.pathname || ""}${location.search || ""}${location.hash || ""}`,
  icon: icon || "link",
  iconType: "primary",
  disabled: window.location.hostname === host,
  _display: () => bootstrap.sisters.length > 1,
})).concat([
  {
    ...(<Dropdown.Divider/>),
    _display: () => bootstrap.sisters.length > 1,
  },
  {
    text: tran("pages.list.myProfile.title"),
    link: pages.myProfile.fullPath,
    icon: pages.myProfile.icon,
    iconType: "primary",
    _display: isUserLoggedIn,
  },
  {
    value: "settings",
    text: tran("pages.list.settings.title"),
    icon: "paint-brush",
    iconType: "primary",
    _display: isUserLoggedIn,
  },
  {
    ...(<Dropdown.Divider/>),
    _display: isUserLoggedIn,
  },
  {
    text: tran("app.admin"),
    link: pages.admin.fullPath,
    icon: pages.admin.icon,
    iconType: "primary",
    _display: isUserStaff,
  },
  {
    text: tran("app.teka"),
    link: pages.teka.fullPath,
    icon: pages.teka.icon,
    iconType: "primary",
    _display: bootstrap.teka && bootstrap.teka.url ? alwaysTrue : alwaysFalse,
  },
  {
    text: pages.journal.displayName,
    link: pages.journal.fullPath,
    icon: pages.journal.icon,
    iconType: "primary",
    _display: (currentUser, licensedModules) =>
      licensedModules.includes("CZT") &&
      isUserStaff(currentUser) &&
      checkCzytelniaRights(currentUser),
  },
  {
    ...(<Dropdown.Divider/>),
    _display: bootstrap.teka && bootstrap.teka.url ? alwaysTrue : isUserLoggedIn,
  },
  {
    value: "logout",
    text: tran("unique.logout"),
    icon: "power-off",
    iconType: "error",
    _display: isUserLoggedIn,
  },
  {
    value: "login",
    text: tran("unique.login"),
    icon: "sign-in-alt",
    iconType: "primary",
    _display: isUserLoggedOut,
  }
]);

