import React, { useEffect, useState } from "react";
import Button from "../Button";
import moment from "moment";
import { tran } from "utils/language";

interface TimeProps {
  value?: Date;
  onChange: (value: Date) => void;
  active?: boolean;
}

export function Time({ value, onChange, active }: TimeProps) {
  const [minute, setMinute] = useState<number>(value?.getMinutes() || 0);
  const [hour, setHour] = useState<number>(value?.getHours() || 0);

  if (!active) {
    return (
      <>
        <span>{renderValue(hour)}</span>
        <span style={{ margin: 2 }}>:</span>
        <span>{renderValue(minute)}</span>
      </>
    );
  }

  return (
    <div className="datetime-picker__time">
      <div className="datetime-picker__time__value">
        <Button
          icon="chevron-up"
          size="small"
          onMouseDown={() => onStartClicking(1, "hour")}
          onClick={() => handleHourChange(1)}
          autoFocus
          title={tran("dateTimePicker.nextHour")}
        />
        <p>{renderValue(hour)}</p>
        <Button
          icon="chevron-down"
          size="small"
          onMouseDown={() => onStartClicking(-1, "hour")}
          onClick={() => handleHourChange(-1)}
          title={tran("dateTimePicker.prevHour")}
        />
      </div>

      <p className="datetime-picker__time__divider">:</p>

      <div className="datetime-picker__time__value">
        <Button
          icon="chevron-up"
          size="small"
          onMouseDown={() => onStartClicking(1, "minute")}
          onClick={() => handleMinuteChange(1)}
          title={tran("dateTimePicker.nextMinute")}
        />
        <p>{renderValue(minute)}</p>
        <Button
          icon="chevron-down"
          size="small"
          onMouseDown={() => onStartClicking(-1, "minute")}
          onClick={() => handleMinuteChange(-1)}
          title={tran("dateTimePicker.prevMinute")}
        />
      </div>
    </div>
  );

  function onStartClicking(change: number, type: "hour" | "minute") {
    let body = document.body;
    let increaseTimer: any;

    const timer = setTimeout(() => {
      increaseTimer = setInterval(() => {
        if (type === "hour") {
          handleHourChange(change);
        } else {
          handleMinuteChange(change);
        }
      }, 70);
    }, 500);

    const mouseUpListener = () => {
      clearTimeout(timer);
      clearInterval(increaseTimer);
      body.removeEventListener("mouseup", mouseUpListener);
      body.removeEventListener("touchend", mouseUpListener);
    };

    body.addEventListener("mouseup", mouseUpListener);
    body.addEventListener("touchend", mouseUpListener);
  }

  function handleMinuteChange(change: number) {
    setMinute((prev) => {
      let newMinute;
      if (prev + change > 59) newMinute = 0;
      else if (prev + change < 0) newMinute = 59;
      else newMinute = prev + change;

      const momentDate = moment(value || new Date());
      onChange(momentDate.set({ minute: newMinute }).toDate());

      return newMinute;
    });
  }

  function handleHourChange(change: number) {
    setHour((prev) => {
      let newHour;
      if (prev + change > 23) newHour = 0;
      else if (prev + change < 0) newHour = 23;
      else newHour = prev + change;

      const momentDate = moment(value || new Date());
      onChange(momentDate.set({ hour: newHour }).toDate());

      return newHour;
    });
  }
}

function renderValue(value: number) {
  return (
    <>
      {value < 10 && "0"}
      {value}
    </>
  );
}
