import React, {useRef} from "react";

import {emptyObject} from "../../../utils/constants";
import {useOverlay} from "../../layout/overlays";
import {IOverlay} from "../../layout/overlays/useOverlay";

export const DialogInstanceContext = React.createContext(null);

const DialogHookProto = {
  get isOpen() {
    return this.overlay.isOpen;
  },
  
  get autoClose() {
    return this.overlay.autoClose;
  },
  
  set autoClose(value: boolean) {
    this.overlay.autoClose = value;
  },
  
  set(key: string, value: any) {
    this._props[key] = value;
    return this;
  },
  
  bind(key: string, value: Function) {
    const binds = this._binds;
    binds[key] = value;
    const prop = this._props[key];
    if (!prop || typeof prop !== "function" || !prop._isCallbackProxy) {
      // wywołujemy callbacki przez proxy, które zapewnia że wywoła się najnowszy callback
      // a nie ten ustawiony w momencie otwierania okna dialogowego
      const cb: any = this._props[key] = function callbackProxy() {
        return binds[key].apply(this, arguments);
      };
      cb._isCallbackProxy = true;
    }
    return this;
  },
  
  get(key: string): any {
    return this._props[key];
  },
  
  onClose(callback?: (...args: any[]) => void) {
    this.overlay.onClose(callback);
    return this;
  },
  
  doClose(callback?: (...args: any[]) => void) {
    this.overlay.doClose(callback);
    return this;
  }
} as any as DialogApi<any>;

/** Interfejs do interakcji z oknem dialogowym z wewnątrz */
export interface IDialog {
  /** Zamknięcie okna */
  readonly close: () => void

  /** Zamknięcie okna z przekazaniem  */
  readonly closeWith: (...args: any[]) => void
  
  readonly forceClose: (...args: any[]) => void
}

/** Interfejs do obsługi okna dialogowego utworzonego przez hooka useDialog */
export interface DialogApi<P extends { dialog: IDialog }> extends IDialog {
  /** Czy okno dialogowe powinno się zamknąć jeśli wywołujący komponent zostanie odmontowany? Domyślnie tak. */
  autoClose: boolean
  
  /** Czy okno dialogowe jest aktualnie otwarte? */
  readonly isOpen: boolean
  
  // używamy zbindowanych już funkcji, żeby bez obaw dało się używać w callbackach i useBind
  
  /** Otwarcie okna */
  readonly open: () => void
  
  /** Otwarcie okna z innymi wartościami propsów niż domyslne */
  readonly openWith: (props: { [K in keyof P]?: P[K] }) => void
  
  /** Aktualizacja propsów okna jeśli jest otwarte */
  readonly update: () => void
  
  /** Aktualizacja propsów okna jeśli jest otwarte */
  readonly updateWith: (props: { [K in keyof P]?: P[K] }) => void
  
  /** Ustawienie domyślnego propsa */
  set(key: keyof P, value: P[typeof key]): this
  
  /** Pobranie wartości domyślnego propsa */
  get(key: keyof P): P[typeof key] | undefined
  
  /** Ustawienie propsa będącego callbackiem */
  bind(key: keyof P, value: P[typeof key] & Function): this
  
  /** Ustawienie callbacka wywoływanego asynchronicznie po zamknięciu okna/nakładki */
  onClose(callback?: (...args: any[]) => void): this
  
  /** Ustawienie callbacka wywoływanego synchronicznie zamiast close() */
  doClose(callback?: (...args: any[]) => void): this
}

export default function useDialog<P extends { dialog: IDialog }>(component: React.ComponentType<P>, initialProps: { [K in keyof P]?: P[K] } = emptyObject): DialogApi<P> {
  type PropsSubset = { [K in keyof P]?: P[K] }
  type CallbacksSubset = { [K in keyof P]?: P[K] & Function }
  type State = DialogApi<P> & {
    _props: PropsSubset
    _binds: CallbacksSubset
    overlay: IOverlay
    component: React.ComponentType<P>
  }
  
  const ref = useRef(null as any as State);
  
  const overlay = useOverlay();
  
  if (ref.current === null) {
    const initProps = {} as any;
    const initBinds = {} as any;
    
    for (let prop in initialProps) {
      const val = initialProps[prop];
      if (typeof val === "function")
        initBinds[prop] = val;
      else
        initProps[prop] = val;
    }
    
    const dialog = ref.current = {
      __proto__: DialogHookProto,
      _props: initProps,
      _binds: initBinds,
      overlay,
      component,
      autoClose: true,
      
      close: overlay.close,
      closeWith: overlay.closeWith,
      forceClose: overlay.forceClose,
      
      open: () => {
        dialog.openWith(emptyObject);
      },
      
      openWith: (props: { [K in keyof P]?: P[K] }) => {
        props = { ...dialog._props, ...props };
        const Dialog = dialog.component;
        const element = <Dialog {...(props as P)} dialog={dialog} />
        
        overlay.open("modal", element);
      },
      
      update: () => {
        dialog.updateWith(emptyObject);
      },
      
      updateWith: (props: { [K in keyof P]?: P[K] }) => {
        if (overlay.isOpen)
          dialog.openWith(props);
      },
    } as any as State /* TS nie wspiera __proto__ */;
    
    for (let bind in initBinds)
      dialog.bind(bind as any, initBinds[bind]);
  }
  else {
    ref.current.component = component;
  }

  return ref.current;
};
