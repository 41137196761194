import {
  FETCH_USERS_LIST,
  USERS_LIST_SUCCESS,
  USERS_LIST_ERROR,
  USERS_LIST_LOADING,
  RESET_PASSWORD,
  EDIT_PASSWORD,
  FETCH_USER,
  USER_LOADING,
  USER_ERROR,
  USER_SUCCESS,
  USER_TEKA_SUCCESS,
  USER_TEKA_ERROR,
  USER_SOWA_SUCCESS,
  USER_SOWA_ERROR,
  EDIT_FOLKS_USER,
  EDIT_TEKA_USER,
  EDIT_SOWA_USER,
  FETCH_CURRENT_USER,
  FETCH_PREVIEW_USERS,
  PREVIEW_USERS_LOADING,
  PREVIEW_USERS_SUCCESS,
  CREATE_ADDRESS,
  DELETE_ADDRESS,
  VERIFY_ADDRESS,
  RESEND_ADDRESS,
  CANCEL_ADDRESS,
  SESSION_SUCCESS
} from "sagas/types";

import {
  put,
  call,
  select,
  take,
  takeLatest,
  takeEvery,
  all
} from "redux-saga/effects";

import * as FolksApi from "api/folks";
import * as SowaApi from "api/sowa";
import * as TekaApi from "api/teka";
import * as sowaUserFunctions from "./sowaUserFunctions";

import bootstrap from "bootstrap";
import * as Folks from "../capi/folks";

export default function* usersWatcher() {
  // czekamy aż sessionSaga skończy inicjalizację
  if (!(yield select(state => state.session.session_id)))
    yield take(SESSION_SUCCESS);
  
  yield all([
    yield takeLatest(FETCH_USERS_LIST, fetchUsersList),
    yield takeEvery(RESET_PASSWORD, resetPassword),
    yield takeLatest(FETCH_USER, fetchUser),
    yield takeEvery(EDIT_FOLKS_USER, editFolksUser),
    yield takeEvery(EDIT_TEKA_USER, editTekaUser),
    yield takeEvery(EDIT_SOWA_USER, editSowaUser),
    yield takeEvery(EDIT_PASSWORD, editPassword),
    yield takeLatest(FETCH_PREVIEW_USERS, fetchPreviewUsers),
    yield takeEvery(CREATE_ADDRESS, createAddress),
    yield takeEvery(DELETE_ADDRESS, deleteAddress),
    yield takeEvery(VERIFY_ADDRESS, verifyAddress),
    yield takeEvery(RESEND_ADDRESS, resendAddress),
    yield takeEvery(CANCEL_ADDRESS, cancelAddress)
  ]);
}

function* fetchUsersList({ payload }) {
  const { loading = true, ...rest } = payload;

  if (loading) {
    yield put({ type: USERS_LIST_LOADING });
  }

  try {
    const result = yield call(FolksApi.fetchUsersList, rest);
    if (result.status === 200) {
      yield put({
        type: USERS_LIST_SUCCESS,
        payload: result.data
      });
    } else {
      yield put({
        type: USERS_LIST_ERROR,
        payload: result.status
      });
    }
  } catch (error) {
    yield put({
      type: USERS_LIST_ERROR,
      payload: 500
    });
  }
}

function* resetPassword({ payload }) {
  const { user_id, password, onSuccess, onError, jwtLogin } = payload;
  const current_id = yield select(state => state.currentUser.folks.user_id);
  try {
    const auth = jwtLogin
      ? [300, yield call(jwtLogin)]
      : [100, current_id, bootstrap.domain, password];
    
    const client = bootstrap.folks.client.withAuth(...auth);

    const [{ result }] = yield call(() => client.execute(new Folks.UserPassReset(user_id)));
    
    if (result.status === 200) {
      if (onSuccess) {
        const newPassword = result.data.password;
        onSuccess(newPassword);
      }
    } else {
      if (onError) {
        onError(result.status);
      }
    }
  } catch (error) {
    if (onError) {
      onError(500);
    }
  }
}

/** Pobiera informacje o użytkowniku */
function* fetchUser({ payload }) {
  const {
    user_id,
    teka,
    sowa,
    folks = true,
    loading = true,
    folksConfig = { personal: true, addresses: false, props: true, flags: true, bonds: true }
  } = payload;
  let login;

  if (loading) {
    yield put({ type: USER_LOADING });
  }

  if (folks) {
    const user = yield call(fetchUserFolks, {
      payload: { user_id, folksConfig }
    });
    if (user) {
      login = user.login;
    }
  }

  if (teka && bootstrap.teka && bootstrap.teka.url) {
    yield call(fetchUserTeka, { payload: user_id });
  }

  if (sowa) {
    if (!login) {
      login = yield select(state => state.admin.users.user.folks.login);
    }
    if (login) {
      yield call(fetchUserSowa, { payload: login });
    }
  }

  if (loading) {
    yield put({ type: USER_LOADING, payload: false });
  }
}

/**
 *
 * @param {String} user_id Identyfikator użytkownika
 * @returns {Object} dane użytkownika (null w przypadku błędu)
 */
function* fetchUserFolks({ payload }) {
  const { user_id, folksConfig } = payload;

  try {
    const result = yield call(FolksApi.fetchUserFolks, user_id, folksConfig);
    if (result.status === 200) {
      yield put({
        type: USER_SUCCESS,
        payload: result.data
      });
      return result.data;
    } else {
      yield put({
        type: USER_ERROR,
        payload: result.status
      });
      return null;
    }
  } catch (error) {
    yield put({
      type: USER_ERROR,
      payload: 500
    });
    return null;
  }
}

function* fetchUserTeka({ payload: user_id }) {
  try {
    const result = yield call(TekaApi.fetchUserTeka, user_id);
    if (result.status === 200) {
      yield put({
        type: USER_TEKA_SUCCESS,
        payload: result.data
      });
    } else {
      yield put({
        type: USER_TEKA_ERROR,
        payload: result.status
      });
    }
  } catch (error) {
    yield put({
      type: USER_TEKA_ERROR,
      payload: 500
    });
  }
}

/**
 * Pobranie uprawnień użytkownika we wszystkich dostępnych katalogach
 * @param {String} login login użytkownika
 */
function* fetchUserSowa({ payload: login }) {
  for (let catalogue of bootstrap.sowa.catalogues) {
    try {
      const parsed = yield call(
        sowaUserFunctions.fetchSowaUser,
        login,
        catalogue
      );
      yield put({
        type: USER_SOWA_SUCCESS,
        payload: {
          cat_id: catalogue.cat_id,
          data: parsed
        }
      });
    } catch (error) {
      yield put({
        type: USER_SOWA_ERROR,
        payload: {
          cat_id: catalogue.cat_id,
          error: error.status
        }
      });
    }
  }
}

function* editFolksUser({ payload }) {
  const { user_id, changes, onSuccess, onError, fetchData = true } = payload;
  try {
    const result = yield call(FolksApi.editFolksUser, user_id, changes);
    if (result.status === 204) {
      if (onSuccess) {
        onSuccess();
      }
      const currentUserId = yield select(
        state => state.currentUser.folks.user_id
      );

      if (fetchData) {
        if (currentUserId === user_id) {
          yield put({
            type: FETCH_CURRENT_USER,
            payload: { folks: true, loading: false, folksConfig: {addresses: true, flags: true, props: true} }
          });
        } else {
          yield put({
            type: FETCH_USER,
            payload: { folks: true, user_id, loading: false, folksConfig: {addresses: true, flags: true, props: true} }
          });
        }
      }
    } else {
      if (onError) {
        onError(result.status, result.reason);
      }
    }
  } catch (error) {
    if (onError) {
      onError(500, "");
    }
  }
}

function* editTekaUser({ payload }) {
  const { user_id, changes, onSuccess, onError, fetchData = true } = payload;
  try {
    const result = yield call(TekaApi.editTekaUser, user_id, changes);
    if (result.status === 204) {
      if (onSuccess) {
        onSuccess();
      }

      if (fetchData) {
        const currentUserId = yield select(
          state => state.currentUser.folks.user_id
        );
        if (currentUserId === user_id) {
          yield put({
            type: FETCH_CURRENT_USER,
            payload: { teka: true, loading: false }
          });
        } else {
          yield put({
            type: FETCH_USER,
            payload: { teka: true, user_id, loading: false }
          });
        }
      }
    } else {
      if (onError) {
        onError(result.status);
      }
    }
  } catch (error) {
    if (onError) {
      onError(500, "");
    }
  }
}

function* editSowaUser({ payload }) {
  const {
    login,
    catUrl,
    profile,
    rights,
    onSuccess,
    onError,
    folksConfig = {},
    fetchData = true
  } = payload;
  try {
    const result = yield call(
      SowaApi.editSowaUser,
      catUrl,
      login,
      profile,
      rights
    );
    if (result.status === 204) {
      if (onSuccess) {
        onSuccess();
      }

      if (fetchData) {
        const currentUserLogin = yield select(
          state => state.currentUser.folks.login
        );
        if (currentUserLogin === login) {
          yield put({
            type: FETCH_CURRENT_USER,
            payload: { sowa: true, loading: false }
          });
        } else {
          const user_id = yield select(
            state => state.admin.users.user.folks.user_id
          );
          yield put({
            type: FETCH_USER,
            payload: { sowa: true, user_id, loading: false, folksConfig }
          });
        }
      }
    } else {
      if (onError) {
        onError(result.status);
      }
    }
  } catch (error) {
    if (onError) {
      onError(500);
    }
  }
}

function* editPassword({ payload }) {
  const {
    user_id,
    confirmationPassword,
    newPassword,
    jwtLogin,
    onSuccess,
    onError,
    fetchData = true
  } = payload;
  const editor_id = yield select(state => state.currentUser.folks.user_id);
  try {
    const auth = jwtLogin
      ? [300, yield call(jwtLogin)]
      : [100, editor_id, bootstrap.domain, confirmationPassword];
    
    const client = bootstrap.folks.client.withAuth(...auth);
    
    const [{ result }] = yield call(() => client.execute(new Folks.UserPassSet(user_id, newPassword)));
    
    if (result.status === 200) {
      const expiry = result.data.expiry;
      if (onSuccess) {
        onSuccess(expiry);
      }

      if (fetchData) {
        const currentUserId = yield select(
          state => state.currentUser.folks.user_id
        );
        if (currentUserId === editor_id) {
          yield put({
            type: FETCH_CURRENT_USER,
            payload: { folks: true }
          });
        } else {
          const user_id = yield select(
            state => state.admin.users.user.folks.user_id
          );
          yield put({
            type: FETCH_USER,
            payload: { folks: true, user_id }
          });
        }
      }
    } else {
      if (onError) {
        onError(result.status, result.message);
      }
    }
  } catch (error) {
    console.error(error);
    if (onError) {
      onError(500, "");
    }
  }
}

function* fetchPreviewUsers({ payload: users_ids }) {
  yield put({
    type: PREVIEW_USERS_LOADING
  });

  const users = [];
  for (let i = 0; i < users_ids.length; i++) {
    yield call(handleFolksForPreview, users_ids[i], users, i);
    if (bootstrap.teka && bootstrap.teka.url) {
      yield call(handleTekaForPreview, users_ids[i], users, i);
    }
    if (users[i].folks.login) {
      yield call(handleSowaForPreview, users[i].folks.login, users, i);
    } else {
      users[i].sowa = { error: 500 };
    }
  }

  yield put({
    type: PREVIEW_USERS_SUCCESS,
    payload: users
  });
}

function* handleFolksForPreview(user_id, users, index) {
  const applyResult = result => {
    const user = { ...users[index] } || {};
    user.folks = result;
    users[index] = user;
  };

  try {
    const result = yield call(FolksApi.fetchUserFolks, user_id, {
      props: true,
      addresses: true
    });
    if (result.status === 200) {
      applyResult(result.data);
    } else {
      applyResult({ error: result.status });
    }
  } catch (error) {
    applyResult({ error: 500 });
  }
}

function* handleTekaForPreview(user_id, users, index) {
  const applyResult = result => {
    const user = { ...users[index] } || {};
    user.teka = result;
    users[index] = user;
  };

  try {
    const result = yield call(TekaApi.fetchUserTeka, user_id);
    if (result.status === 200) {
      applyResult(result.data);
    } else {
      applyResult({ error: result.status });
    }
  } catch (error) {
    applyResult({ error: 500 });
  }
}

function* handleSowaForPreview(login, users, index) {
  const sowa = {};
  for (let catalogue of bootstrap.sowa.catalogues) {
    sowa[catalogue.cat_id] = yield call(
      handleCatalogueForPreview,
      login,
      catalogue
    );
  }

  users[index].sowa = sowa;
}

function* handleCatalogueForPreview(login, catalogue) {
  try {
    const result = yield call(
      sowaUserFunctions.fetchSowaUser,
      login,
      catalogue
    );
    return result;
  } catch (error) {
    return { error: 500 };
  }
}

function* createAddress({ payload }) {
  const {
    kind,
    address,
    user_id,
    client,
    confiscate_from,
    onSuccess,
    onError
  } = payload;

  try {
    const result = yield call(
      FolksApi.addressCreate,
      kind,
      address,
      user_id,
      client,
      confiscate_from
    );
    if (result.status === 200) {
      if (onSuccess) onSuccess(result.data);
    } else {
      if (onError) onError(result.status);
    }
  } catch (error) {
    if (onError) onError(500);
  }
}

function* deleteAddress({ payload }) {
  const { kind, address, user_id, onSuccess, onError } = payload;

  try {
    const result = yield call(FolksApi.addressDelete, kind, address, user_id);
    if (result.status === 204) {
      if (onSuccess) onSuccess();
    } else {
      if (onError) onError(result.status);
    }
  } catch (error) {
    if (onError) onError(error);
  }
}

function* cancelAddress({ payload }) {
  const { token, onSuccess, onError } = payload;

  try {
    const result = yield call(FolksApi.addressCancel, token);

    if (result.status === 204) {
      if (onSuccess) onSuccess();
    } else {
      if (onError) onError(result.status);
    }
  } catch (error) {
    if (onError) onError(500);
  }
}

function* verifyAddress({ payload }) {
  const { token, address, confiscate_from, onSuccess, onError } = payload;

  try {
    const result = yield call(
      FolksApi.addressVerify,
      token,
      confiscate_from,
      address
    );

    if (result.status === 204) {
      if (onSuccess) onSuccess();
    } else {
      if (onError) onError(result);
    }
  } catch (error) {
    if (onError) onError(500);
  }
}

function* resendAddress({ payload }) {
  const { kind, address, user_id, client, onSuccess, onError } = payload;

  try {
    const result = yield call(
      FolksApi.addressResend,
      kind,
      address,
      user_id,
      client
    );
    if (result.status === 200) {
      if (onSuccess) onSuccess(result.data);
    } else if (result.status === 204) {
      if (onSuccess) onSuccess();
    } else {
      if (onError) onError(result.status);
    }
  } catch (error) {
    if (onError) onError(500);
  }
}
