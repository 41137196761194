import React, { useRef, useState } from "react";

import { Icon } from "components/shared";
import { useDeviceState } from "../../utils/hooks/storage";

type AccordionProps = {
  header: React.ReactNode
  headerWhileOpen?: React.ReactNode
  
  headerPosition?: "top" | "bottom"
  headerTag?: React.FunctionComponent | string 
  
  fluid?: boolean
  border?: boolean // TODO: pozbyć się?
  
  icon?: string
  iconWhileOpen?: string
  
  open?: boolean
  cache?: boolean // czy trzymamy dzieci zamontowane po zamknięciu
  
  className?: string
  children?: React.ReactNode
  
  style?: React.CSSProperties
}

type SimpleAccordionProps = AccordionProps & {
  persist?: string // identyfikator pod którym zapamiętać stan
}

function SimpleAccordion(props: SimpleAccordionProps) {
  const [open, setOpen] = useDeviceState(props.persist, props.open);
  
  return ControlledAccordion({ ...props, open, onToggle: open => setOpen(!open) }); 
}

type ControlledAccordionProps = AccordionProps & {
  onToggle?: (open: boolean) => void
}

function ControlledAccordion(props: ControlledAccordionProps) {
  const childrenRef = useRef(null as React.ReactNode); // montujemy po pierwszym otwarciu i trzymamy otwarte gdy cache==true
  
  if (props.open)
    childrenRef.current = props.children;
  
  const content = props.cache || props.open
    ? <div className="accordion__content" style={props.open ? undefined : DISPLAY_NONE}>
        {childrenRef.current}
      </div>
    : null;
  
  const position = props.headerPosition || "top";
  
  const iconOpen = props.iconWhileOpen || "caret down";
  const iconClosed = props.icon || "caret right";
  
  // @ts-ignore
  const onToggle = props.onToggle ? () => props.onToggle(props.open) : undefined;
  
  const Header = props.headerTag || "div";
  
  return <div
    className={`accordion__wrapper ${props.className} ${props.fluid ? "fluid" : ""} ${props.border ? "border" : ""} ${props.open ? "open" : ""}`}
    aria-expanded={props.open}
    role="listbox"
    style={props.style}
  >
    {position === "bottom" ? content : null}
    
    <Header
      // @ts-ignore
      onClick={onToggle}
      role="button"
      className="accordion__header"
      tabIndex={0}
    >
      <Icon name={props.open ? iconOpen : iconClosed} />
      {props.open ? props.headerWhileOpen || props.header : props.header}
    </Header>
  
    {position === "top" ? content : null}
  </div>
}

SimpleAccordion.Controlled = ControlledAccordion;

const DISPLAY_NONE = { display: "none" }

export { SimpleAccordion };

export default SimpleAccordion;
