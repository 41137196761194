import { combineReducers } from "redux";

import currentUser from "./currentUserReducer";
import utils from "./utilsReducer";
import session from "./sessionReducer";
import admin from "./adminReducer";
import teka from "./tekaReducer";

export default combineReducers({
  currentUser,
  utils,
  session,
  admin,
  teka
});
