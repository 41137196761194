import React from "react";
import PropTypes from "prop-types";
import Popup from "../Popup";
import { iconNameToChar, regular, solid } from "utils/fontawesome/icons";

const Icon_ = props => {
  let {
    outline,
    brand,
    name,
    className,
    type,
    size,
    pointer,
    handleRef,
    spin,
    label,
    disabled,
    fixed,
    ...rest
  } = props;
  name = name || "";
  const origName = name;
  if (name.endsWith(" outline") || name.endsWith("-outline")) {
    outline = true;
    name = name.substring(0, name.length - 8);
  }
  
  brand = false; // nie używamy aktualnie
  let family = outline ? "far" : brand ? "fab" : "fas";
  
  name = name.trim().replace(/ +/g, "-");
  
  if (!name && !fixed)
    return null;

  const typeClass = type !== "default" ? type : "";
  const sizeClass = ["tiny", "small", "big"].indexOf(size) > -1 ? size : "";
  const pointerClass = (name && pointer) ? "pointer" : "";
  const spinClass = spin ? "fa-spin" : "";
  const disabledClass = disabled ? "disabled" : "";
  
  let content;
  if (!name) {
    content = <i
      className={`sowa icon ${family} ${typeClass} ${sizeClass} ${pointerClass} ${spinClass} ${disabledClass} ${className} ${fixed ? "fa-fw" : ""}`}
      ref={handleRef}
      {...rest}
    />;
  }
  else {
    if (regular.has(name))
      family = "far";
    else if (solid.has(name))
      family = "fas";
    
    let char = iconNameToChar.get(name);
    if (!char) {
      name = "circle-dashed";
      family = "far";
      char = iconNameToChar.get(name);
    }
    
    content = <i
      className={`sowa icon ${family} ${typeClass} ${sizeClass} ${pointerClass} ${spinClass} ${disabledClass} ${className} ${fixed ? "fa-fw" : ""}`}
      ref={handleRef}
      data-name={name}
      data-request={origName}
      data-content={char}
      {...rest}
    />;
  }
  

  if (label === null)
    return content;

  return <Popup trigger={content} on="hover" content={label} position="bottom center" pointing />;

};

// dla TSa
const Icon = React.memo(Icon_);

Icon.defaultProps = {
  outline: false,
  brand: false,
  className: "",
  type: "default",
  size: "default",
  pointer: false,
  spin: false,
  label: null,
  disabled: false
};

Icon.propTypes = {
  outline: PropTypes.bool, // style: regular (https://fontawesome.com/how-to-use/on-the-web/referencing-icons/basic-use)
  brand: PropTypes.bool,
  name: PropTypes.string, // Nazwa czcionki (https://fontawesome.com/icons?d=gallery&m=free)
  type: PropTypes.oneOf([
    "primary",
    "error",
    "secondary",
    "success",
    "warning",
    "default",
    "inverted"
  ]),
  size: PropTypes.oneOf(["tiny", "small", "default", "big"]),
  pointer: PropTypes.bool, // dodaje cursor: "pointer"
  spin: PropTypes.bool, // dodaje klasę fa-spin
  style: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.oneOf([null])]), // popup po najechaniu na ikonę
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string,
  title: PropTypes.string,
  fixed: PropTypes.bool,
};

export default Icon;
