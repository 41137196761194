import React from "react";
import { Link } from "react-router-dom";

import { Blankholder } from "components/shared";

import { translate } from "utils/language";

// strona, na którą zostaje przekierowany użytkownik próbujący wejśc na stronę, do której nie ma dostępu
const BlockedAccesPage = () => (
  <Blankholder
    icon="lock"
    title={translate("error.accessDenied")}
    description={
      <span>
        {translate("error.noPermission.page")}.{" "}
        <Link to="/">{translate("unique.goHomePage")}</Link>.
      </span>
    }
  />
);

export default BlockedAccesPage;
