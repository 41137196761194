import {useEffect, useRef} from "react";

import {useSignal} from "./other";
import {emptyArray} from "../constants";
import {scheduleReactUpdate} from "./batch";

/** Aktualizuje komponent gdy zmieni się rozmiar okna.
 *  Zwraca informację czy zmienił się rozmiar okna. */
export function useWindowResized(): boolean {
  const signal = useSignal();
  
  // Wykorzystujemy dynamiczną naturę JS by przechować coś w obiekcie signal, to w sumie hak, ale nie powinien się zepsuć 
  if (!(signal as any).onMount) {
    (signal as any).onMount = function useWindowSize_onMount() {
      addListener(signal);
      return function useWindowSize_onUnmount() {
        removeListener(signal);
      }
    }
  }
  
  useEffect((signal as any).onMount, emptyArray);
  
  const changed = !!(signal as any).changed;
  (signal as any).changed = false;
  return changed;
}

export function useWindowHeight(): number {
  useWindowResized();
  return window.innerHeight;
}

export function useWindowWidth(): number {
  useWindowResized();
  return window.innerWidth;
}

let SIGNALS = new Set<() => void>();
let lastWidth = 0;
let lastHeight = 0;

function onWindowResize() {
  const h = window.innerHeight, w = window.innerWidth;
  if (lastWidth !== w || lastHeight !== h) {
    lastWidth = w;
    lastHeight = h;
    for (let signal of SIGNALS) {
      (signal as any).changed = true;
      scheduleReactUpdate(signal);
    }
  }
}

function addListener(signal: () => void) {
  if (SIGNALS.size === 0)
    window.addEventListener("resize", onWindowResize);
  
  SIGNALS.add(signal);
}

function removeListener(signal: () => void) {
  SIGNALS.delete(signal);
  
  if (SIGNALS.size === 0)
    window.removeEventListener("resize", onWindowResize);
}