import React from "react";
import { tran } from "utils/language";
import bootstrap from "bootstrap";
import { IDialog } from "components/shared/Dialog/useDialog";
import { DialogPresets } from "components/shared/Dialog";
import { TermsOfService } from "../Terms/TermsOfService";
import { BrandedDialog } from "components/shared/Dialog/comps";

type TermsDialogProps = {
  dialog: IDialog,
  submitFunc: () => void
}

export function TermsDialog({ dialog }: TermsDialogProps) {
  return (
    <DialogPresets.BrandedDialog size="large" dialog={dialog} buttons={null}>
      <BrandedDialog.Heading>
        {tran("signup.header")}
      </BrandedDialog.Heading>
      
      {!bootstrap.WZUW &&
        <h3 className="login-form__subheading">{bootstrap.title}</h3>
      }

      <TermsOfService
        onSubmit={() => dialog.closeWith(true)}
        submitButtonProps={{ content: tran("signup.btn") }}
        onCancel={dialog.close}
      />
    </DialogPresets.BrandedDialog>
  );
}