import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { tran } from "utils/language";

import { useSignal } from "../../../../utils/hooks";
import { getMinutesAndSecondsString } from "./utils";
import bootstrap from "../../../../bootstrap";
import { Icon, Toast } from "../../../shared";
import { emptyArray } from "../../../../utils/constants";
import { LOGOUT } from "../../../../sagas/types";

const TIME_TO_DISPLAY_CLOCK = 300000;

function SessionClock0({ render }) {
  const signal = useSignal();
  const expiresAt = useSelector(expiresAtSelector);
  
  useEffect(() => {
    SESSION_CLOCKS.unshift(signal);
    return () => {
      const at = SESSION_CLOCKS.indexOf(signal);
      SESSION_CLOCKS.splice(at, 1);
      if (at === 0) {
        const head = SESSION_CLOCKS[0];
        head && head(); // informujemy nowo aktywny zegar, żeby zaczął działać
      }
    }
  }, emptyArray);
  
  const thisIsLastClock = SESSION_CLOCKS[0] === signal;
  const isActive = thisIsLastClock && expiresAt;
  
  useEffect(() => {
    if (isActive) {
      const timer = setInterval(signal, 1000);
      return () => clearInterval(timer);
    }
  }, [isActive]);
  
  if (!isActive)
    return null;
  
  const now = Date.now();
  const time = Math.round((expiresAt.getTime() - now));
  
  // celowo pokazujemy zegar na instalacjach deweloperskich, by wiedzieć, że to działa
  if (!bootstrap.devel && (time > TIME_TO_DISPLAY_CLOCK || time < 0))
    return null;
  
  if (time < 0)
    return render(<>{tran("topbar.sessionWillExpire")}:<b> {time}</b><Logout/></>);
  
  return render(<>
    {tran("topbar.sessionWillExpire")}:{" "}
    <b>{getMinutesAndSecondsString(time)}</b>
    {time <= 0 && <Logout/>}
  </>);
}

// rejestr zamontowanych zegarów sesji, żeby aktywny był tylko jeden
const SESSION_CLOCKS = [];

export function SessionClock() {
  return <SessionClock0 render={children =>
    (<div className="top-bar__session-clock">{children}</div>)
  } />
}

// FIXME: dla mobilnych nie mamy zarezerowanego miejsca, tak to było najłatwiej zrobić, ale to hak
export function ToastSessionClock() {
  return <SessionClock0 render={children => (<ToastSessionClockToast children={children} />)} />
}

// wylogowanie tutaj wrzuciliśmy by się zgadzało z zegarem
function Logout() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: LOGOUT });
  }, emptyArray);
  return null;
}

function ToastSessionClockToast({ children }) {
  const toast = useRef(null);
  
  useEffect(() => {
    if (toast.current === null)
      toast.current = Toast.emit(children, { timeout: 1000000, icon: <Icon name="hourglass-half"/> });
    else
      Toast.update(toast.current, { message: children });
  });

  useEffect(() => () => {
    toast.current && Toast.dismiss(toast.current.toastId);
  }, emptyArray);
  
  return null;
}

const expiresAtSelector = ({ session: { activity, session_key, expires_at } }) =>
  activity === false && !!session_key && expires_at;
