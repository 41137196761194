import React, { useState } from "react";
import { IDialog } from "../../shared/Dialog/useDialog";
import { emailMissing, emailUnverified, RegistrationCheck } from "./logic";
import { ensure, isLoaded, useCapiWhile } from "../../../capi/hooks";
import { RequestDialog } from "../../shared/requestUtils";
import { PropsDialogPage } from "./PropsDialogPage";
import { EmailVerificationDialogPage } from "./EmailVerificationDialogPage";
import { ErrorDialog } from "../../shared/Dialog/comps";
import { emptyArray } from "../../../utils/constants";
import { performLogin } from "../Login/loginLogic";
import { CapiClient } from "../../../capi/client";
import { EmailSetDialogPage } from "./EmailSetDialogPage";
import { TermsDialog } from "./TermsDialog";
import bootstrap from "../../../bootstrap";
import { OpenDialog } from "../../shared/Dialog";

type CompleteSignupDialogProps = {
  client: CapiClient
  skipTerms?: boolean
  dialog: IDialog
}

// powielone niestety z SignupDialog
// ale teraz nie każda rejestracja idzie przez SignupDialog...
const DISPLAY_TERMS = bootstrap.WZUW;

export function CompleteSignupDialog({ client, skipTerms, dialog }: CompleteSignupDialogProps) {
  const [unwantedEmail, setUnwantedEmail] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(!DISPLAY_TERMS || skipTerms);
  
  const checkRq = ensure(useCapiWhile(client, emailUnverified, 2000, RegistrationCheck, unwantedEmail /* tylko by wymusić reload */), 204, 409);
  
  if (!isLoaded(checkRq))
    return <RequestDialog request={checkRq} dialog={dialog}/>;
  
  if (!termsAccepted) {
    function termsClose(res: any) {
      if (res)
        setTermsAccepted(true);
      else
        dialog.close();
    }
    return <OpenDialog component={TermsDialog} doClose={termsClose}/>
  }
  else if (emailMissing(checkRq)) {
    return <EmailSetDialogPage client={client} onSubmit={checkRq._refresh} dialog={dialog}/>;
  }
  else if (emailUnverified(checkRq)) {
    const email = checkRq.data.address;
    if (email === unwantedEmail)
      return <EmailSetDialogPage client={client} onSubmit={checkRq._refresh} setUnwantedEmail={setUnwantedEmail} dialog={dialog}/>;
    else
      return <EmailVerificationDialogPage email={email} client={client} onVerified={checkRq._refresh} setUnwantedEmail={setUnwantedEmail} dialog={dialog}/>;
  }
  else if (checkRq.status === 204) {
    return <PerformLogin auth={client.auth}/>
  }
  else if (checkRq.reason === "props_required") {
    return <PropsDialogPage client={client} propValues={checkRq.data.props} onSubmit={checkRq._refresh} dialog={dialog}/>
  }
  
  return <ErrorDialog message="???" dialog={dialog}/>
}

function PerformLogin({ auth }: { auth: any }) {
  React.useEffect(() => {
    void performLogin({ auth });
  }, emptyArray);
  return null;
}