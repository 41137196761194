export const ONLY_NUMS_PATTERN = /^[0-9]\d*$/g;
export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const TELEPHONE_PREFIX_PATTERN = /^\+?[0-9]\d*$/g; // dozwolone znaki: opcjonalny "+" na początku i cyfry 
export const HOUR_PATTERN = /^([0-1][0-9]|[2][0-3])\:[0-5][0-9]$/g; // np. 09:15, 22:00, 00:30
export const LOGIN_PATTERN = /^[a-z0-9\._-]{3,}$/; // Login musi mieć przynajmniej 3 znaki (inaczej serwer zwraca błąd). Dozwolone są tylko litery łacińskie, cyfry, kropka, podkreślnik oraz myślnik.
// export const PESEL_PATTERN = /^[0-9]{11}$/; // PESEL musi składać się z 11 cyfr

// Sprawdzanie poprawności numeru PESEL wyrażeniem: 9×a + 7×b + 3×c + 1×d + 9×e + 7×f + 3×g + 1×h + 9×i + 7×j
// https://pl.wikipedia.org/wiki/PESEL
export function isPeselValid(peselString) {
  // const peselString = "44051401358"; // przykład niepoprawnego numeru PESEL

  // Sprawdzamy, czy PESEL składa się z 11 znaków oraz samych cyfr
  if(peselString.length !== 11 || !/^\d+$/.test(peselString)) {
    return;
  }

  // Jeśli ostatnie 5 cyfr to "00000", to nie weryfikujemy sumy kontrolnej.
  // Obcokrajowcy nie mają peseli, dlatego przyjęte zostało, że można wypełnić to pole wartością YYMMDD00000 (np. 76102000000)).
  if(peselString.length === 11 && peselString.substr(-5) === "00000") {
    return true;
  }

  const weight = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];

  const peselDigits = peselString.split("").map(item => +item);
  const lastDigit = peselDigits[peselDigits.length - 1];

  // cyfra kontrolna = reszta z dzielenia sumy przez 10
  const checkDigit = weight.reduce((total, w, index) => total + (w * peselDigits[index]), 0) % 10;

  return checkDigit === lastDigit;
}

// export function isPeselValid(pesel) {
//   if (typeof pesel !== "string") {
//     return false;
//   }

//   let re = PESEL_PATTERN;
  
//   if (!re.test(pesel)) {
//     return false;
//   }

//   const month = pesel.substr(2, 2);
//   const day = pesel.substr(4, 2);
//   let actualMonth;
//   if (parseInt(month[0], 10) % 2 === 0) {
//     // Pierwsza cyfra miesiąca jest parzysta (lub 0) - miesiące od stycznia do września
//     actualMonth = parseInt(month[1], 10);
//     if (!/[1-9]/.test(month[1])) {
//       // Zła cyfra oznaczająca miesiąc
//       return false;
//     }
//   } else {
//     // Miesiące od października do grudnia
//     actualMonth = parseInt(month[1], 10) + 10;
//     if (!/[0-2]/.test(month[1])) {
//       // Zła cyfra oznaczająca miesiąc
//       return false;
//     }
//   }

//   let maxDaysInMonth = 31;
//   if (actualMonth === 2) {
//     maxDaysInMonth = 29;
//   } else if ([4, 6, 9, 11].indexOf(actualMonth) > -1) {
//     maxDaysInMonth = 30;
//   }
//   if (parseInt(day, 10) < 1 || parseInt(day, 10) > maxDaysInMonth) {
//     // Zła liczba dni w miesiącu
//     return false;
//   }

//   let checkSum = 0;
//   [9, 7, 3, 1, 9, 7, 3, 1, 9, 7].forEach((multiplier, index) => {
//     const toAdd = multiplier * parseInt(pesel[index], 10);
//     checkSum += toAdd;
//   });

//   if (checkSum % 10 !== parseInt(pesel.substr(-1, 1), 10)) {
//     // Nieprawidłowa liczba kontrolna
//     return false;
//   }
//   return true;
// }

export function isEmailValid(email) {
  if (typeof email !== "string") {
    return false;
  }

  // eslint-disable-next-line
  let re = EMAIL_PATTERN;
  return re.test(email);
}

const patternWithProtocol = new RegExp(
  '^(https?:\\/\\/)' +
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.,~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator 

const patternNoProtocol = new RegExp(
  '^(https?:\\/\\/)?' +
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.,~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator 

export function isURLValid(str, isProtocolRequired = false) {
  const pattern = isProtocolRequired ? patternWithProtocol : patternNoProtocol;
  return !!(typeof str === "string" && pattern.test(str));
}

export function isLoginValid(login) {
  if (typeof login !== "string") {
    return false;
  }

  let re = LOGIN_PATTERN;
  return re.test(login);
}

/** Sprawdzenie, czy string jest pusty lub zawiera same białe znaki */
export function isStringEmpty(string) {
  if (typeof string !== "string") {
    return false;
  }
  return string.replace(/\s/g, "").length === 0;
}

export function isStringAValidDate(string) {
  if (typeof string !== "string") {
    return false;
  }
  return !isNaN(Date.parse(string));
}

export function isStringAValidNumber(string) {
  if (typeof string !== "string") {
    return false;
  }
  return !isNaN(parseInt(string, 10));
}
