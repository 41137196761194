import React, { useState } from "react";
import { isLoaded, notYet } from "../../../capi/hooks";
import { useDerivedState } from "../../../utils/hooks";
import { Dictionary } from "../../../utils/types";
import { Tran, tran } from "../../../utils/language";
import { emptyArray, identity, literal } from "../../../utils/constants";
import { FolksParamId, useFolksParamsEx } from "../../../utils/params/hooks";

// To jest konwersja starego komponentu klasowego używającego render propsa,
// robiona na kolanie, niezoptymizowana, bez zmian w logice

const fz = Object.freeze as typeof identity; /* chcemy zamrozić obiekty, ale nie typy, bo używamy dalej sporo typeof */

const INITIAL_STATE = fz({
  values: {
    newPassword: "",
    newPasswordConfirm: "",
    confirmPassword: ""
  },
  validation: {
    minPassLength: false,
    minUppercase: false,
    minLowercase: false,
    minSymbols: false,
    minDigits: false,
    minDistinct: false,
  },
  requirementsMet: 0, // liczba spełnionych wymagań
  errors: {
    newPassword: false,
    newPasswordConfirm: false,
  },
  errorMsgs: [] as React.ReactNode[],
});

const NO_RULES = fz({
  minPassLength: 0,
  minUppercase: 0,
  minLowercase: 0,
  minSymbols: 0,
  minDigits: 0,
  minDistinct: 0,
  minRules: 0
});

const PARAM_IDFS = ["default", "staff", "admin"];

const PARAMS = PARAM_IDFS.map(idf => fz({
  minPassLength: literal(`pwd.policy.${idf}.min_length`),
  minRules: literal(`pwd.policy.${idf}.required_rules`),
  minUppercase: literal(`pwd.rules.${idf}.uppercase`),
  minLowercase: literal(`pwd.rules.${idf}.lowercase`),
  minSymbols: literal(`pwd.rules.${idf}.symbols`),
  minDigits: literal(`pwd.rules.${idf}.digits`),
  minDistinct: literal(`pwd.rules.${idf}.distinct`)
}));

const PARAM_LIST = PARAM_IDFS.map((idf, i) => fz(Object.values(PARAMS[i])));

export function usePasswordValidation(rule_idf: string | typeof notYet) {
  const [state, setState] = useState(INITIAL_STATE);
  const paramsRq = useFolksParamsEx(typeof rule_idf === "string" ? PARAM_LIST[PARAM_IDFS.indexOf(rule_idf)] : emptyArray);

  return useDerivedState(makeApi, rule_idf, state, setState, paramsRq);
}

function makeApi(rule_idf: string | typeof notYet,
                 state:    typeof INITIAL_STATE,
                 setState: React.Dispatch<React.SetStateAction<typeof INITIAL_STATE>>,
                 paramsRq: any) {
  const rules = makeRules(paramsRq, rule_idf);
  
  return {
    paramsRq,
    requirementsProps: {
      rules,
      validation: state.validation,
      passwordLength: state.values.newPassword.length,
      requirementsMet: state.requirementsMet,
    },
    oldPasswordProps: {
      icon: "key",
      type: "password",
      error: state.errors.newPassword,
      onChange: (val: string) => setState(prev => ({ ...prev, values: { ...prev.values, confirmPassword: val }} )),
      label: tran("users.profile.passEdit.yourPass")
    },
    newPasswordProps: {
      icon: "key",
      type: "password",
      error: state.errors.newPassword,
      onChange: (val: string) => {
        setState(prev => ({ ...prev, values: { ...prev.values, newPassword: val }} ));
        liveValidation(val, rules, setState);
      },
      label: tran("password.new")
    },
    confirmPasswordProps: {
      icon: "key",
      type: "password",
      error: state.errors.newPasswordConfirm,
      onChange: (val: string) => setState(prev => ({ ...prev, values: { ...prev.values, newPasswordConfirm: val }} )),
      label: tran("password.confirmNew")
    },
    values: state.values,
    errors: state.errorMsgs,
    validate: validate.bind(null, state, rules, setState)
  };
}

function makeRules(paramsRq: any, rule_idf: string | typeof notYet) {
  if (!isLoaded(paramsRq) || typeof rule_idf !== "string")
    return NO_RULES;
  
  const rules = {} as Dictionary<number>;
  const params = PARAMS[PARAM_IDFS.indexOf(rule_idf)];
  let key: keyof typeof params; // TS2404 nie pozwala nam tego zadeklarować w samej pętli
  for (key in params) {
    const param = params[key];
    rules[key] = paramsRq.data[param] || 0;
  }
  
  return rules as typeof NO_RULES;
}

const DIGITS: Dictionary<number> = { "0": 1, "1": 1, "2": 1, "3": 1, "4": 1, "5": 1, "6": 1, "7": 1, "8": 1, "9": 1, }

function liveValidation(pass: string, rules: typeof NO_RULES, setState: React.Dispatch<React.SetStateAction<typeof INITIAL_STATE>>) {
  let validation = {} as typeof INITIAL_STATE["validation"];
  
  const norm = pass.normalize();
  let numDigits = 0, numLowercase = 0, numUppercase = 0, numSymbols = 0;
  for (let i = 0; i < norm.length; i += 1) {
    const ch = norm.charAt(i);
    const up = ch.toUpperCase();
    const lo = ch.toLowerCase();
    if (up !== lo) {
      if (ch === up) numUppercase++;
      else numLowercase++;
    }
    else if (DIGITS[ch])
      numDigits++;
    else
      numSymbols++;
  }
  
  validation["minDigits"] = numDigits >= rules.minDigits;
  validation["minLowercase"] = numLowercase >= rules.minLowercase;
  validation["minUppercase"] = numUppercase >= rules.minUppercase;
  validation["minSymbols"] = numSymbols >= rules.minSymbols;
  
  let charsMap = {} as any;
  let distinctCounter = 0;
  for (let x of pass) {
    if (!charsMap[x]) {
      charsMap[x] = true;
      distinctCounter++;
    }
  }
  
  validation["minDistinct"] = distinctCounter >= rules.minDistinct;
  
  let requirementsCounter = 0;
  
  for (let x in validation) {
    // @ts-ignore
    if (validation[x]) {
      requirementsCounter++;
    }
  }
  
  setState(prev => ({
    ...prev,
    validation,
    requirementsMet: requirementsCounter
  }));
}

function validate(state: typeof INITIAL_STATE, rules: typeof NO_RULES, setState: React.Dispatch<React.SetStateAction<typeof INITIAL_STATE>>) {
  let errorMsgs = [] as React.ReactNode[];
  let errors = {} as typeof INITIAL_STATE["errors"];
  
  const values = state.values;
  
  if (values.newPassword.length < rules.minPassLength) {
    errorMsgs.push(<Tran id="password.error.length" search="<<1>>" replace={rules.minPassLength} />);
    errors.newPassword = true;
  }
  
  if (values.newPassword !== values.newPasswordConfirm) {
    errorMsgs.push(tran("password.error.identical"));
    errors.newPasswordConfirm = true;
  }
  
  if (state.requirementsMet < rules.minRules) {
    errorMsgs.push(tran("password.error.req"));
    errors.newPassword = true;
  }
  
  if (/\s/g.test(values.newPassword)) {
    errorMsgs.push(tran("password.error.space"));
    errors.newPassword = true;
  }
  
  setState(prev => ({ ...prev, errorMsgs, errors }));
  
  return errorMsgs.length == 0;
};
