import { signalRegistry } from "../signalRegistry";

/** Sygnalizacja markerów aktualizacji, że powinny się zaktualizować;
 *  jeśli chcesz wymusić odświeżenie komendy CAPI zarządzanej przez hooka,
 *  spójrz na getMarkerList klasy komendy, żeby wiedzieć jakie rejestruje markery */
export function signalMarkers(...markers: readonly (string | readonly string[])[]): void {
  REGISTRY.signal(markers.flat());
}

export function signalMarker(marker: string, ...args: any[]): void {
  REGISTRY.signal([marker], ...args);
}

const REGISTRY = signalRegistry<string>()
export const registerUpdateMarker = REGISTRY.register;
export const unregisterUpdateMarker = REGISTRY.unregister;
