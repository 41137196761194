import React from "react";
import PropTypes from "prop-types";

import Radio from "./Radio";

type RadioGroupProps = RadioGroupProps0 & Omit<React.HTMLAttributes<HTMLDivElement>, keyof RadioGroupProps0>; 

type RadioGroupProps0 = {
  onChange?: (value: string) => void
  value?: string
  options: Radio[]
}

type Radio = {
  value: string
  label: React.ReactNode
  disabled?: boolean
}

function RadioGroup(props: RadioGroupProps) {
  const { value, options, className, onChange, ...rest } = props;

  const handleClick = (option: Radio) => {
    if (onChange && !option.disabled) {
      onChange(option.value);
    }
  };

  return (
    <div className={`radio-group ${className}`} {...rest}>
      {options.map(option => {
        const checked = option.value === value;
        return (
          <Radio
            key={option.value}
            {...option}
            checked={checked}
            onClick={() => handleClick(option)}
          />
        );
      })}
    </div>
  );
};

RadioGroup.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.element.isRequired
      ]).isRequired,
      disabled: PropTypes.bool
    })
  ).isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string
};

export default RadioGroup;
