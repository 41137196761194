import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import AttachmentTile from "./Tile";

const AttachmentTiles = ({ items, direction, wrap, compact, ...props }) => {
  const classes = cx("attachment-tiles", props.className);
  const style = { flexDirection: direction, flexWrap: `${!wrap && "no"}wrap`, ...props.style };

  return (
    <div style={style} className={classes}>
      {items.map((e, i) => (
        <AttachmentTile compact={compact} key={""+i} {...e} />
      ))}
    </div>
  );
};

AttachmentTiles.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      thumbnail: PropTypes.node,
      link: PropTypes.string,
      active: PropTypes.bool,
    })
  ),
  direction: PropTypes.oneOf(["row", "column"]),
  wrap: PropTypes.bool,
  compact: PropTypes.bool
};

AttachmentTiles.defaultProps = {
  direction: "row",
  wrap: true,
  compact: false
};

export default AttachmentTiles;
