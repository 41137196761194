import { useEffect, useState } from "react";
import { emptyArray } from "../constants";

export function useTimeoutLatch(milliseconds: number): boolean {
  const [state, setState] = useState(false);
  
  useEffect(() => {
    const timer = setTimeout(setState, milliseconds, true);
    return () => {
      clearTimeout(timer);
    }
  }, emptyArray);
  
  return state;
}
