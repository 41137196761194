import React from "react";
import Dialog from "../Dialog";
import { Button } from "../..";
import { IDialog } from "../useDialog";
import { tran } from "../../../../utils/language";

type ConfirmDialogProps = {
  title?: React.ReactNode
  icon?: string
  description: React.ReactNode
  dialog: IDialog
};

export function ConfirmDialog(props: ConfirmDialogProps) {
  let buttons = <>
    <Button preset="cancel" onClick={props.dialog.close}/>
    <Button preset="confirm" onClick={() => props.dialog.closeWith(true)}/>
  </>;
  
  return <Dialog buttons={buttons} title={props.title || tran("unique.confirmation")} dialog={props.dialog} size="small" icon={props.icon}>
    {props.description}
  </Dialog>;
}

