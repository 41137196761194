import React from "react";
import PropTypes from "prop-types";

import { Icon } from "components/shared";

const availableTypes = ["error", "success", "warning", "info"];
const iconByType = {
  error: "exclamation circle",
  warning: "exclamation triangle",
  success: "check circle",
  info: "info circle"
};

const Message = props => {
  const {
    type,
    content,
    children,
    fluid,
    icon,
    displayIcon,
    hideEmpty,
    className,
    ...rest
  } = props;

  // eslint-disable-next-line
  if (
    hideEmpty &&
    ((!content || content.length <= 0) && (!children || children.length === 0))
  )
    return null;

  const _content = content ? (
    typeof content === "string" ? (
      <p>{content}</p>
    ) : (
      content
    )
  ) : (
    children
  );

  const fluidClass = fluid ? "fluid" : "";
  const typeClass = availableTypes.indexOf(type) > -1 ? type : "";

  const _icon = displayIcon ? (
    icon ? (
      typeof icon === "string" ? (
        <Icon name={icon} />
      ) : (
        icon
      )
    ) : type ? (
      <Icon size="big" name={iconByType[type]} />
    ) : null
  ) : null;

  return (
    <div
      className={`sowa message ${typeClass} ${fluidClass} ${className}`}
      {...rest}
    >
      {_icon}
      {_content}
    </div>
  );
};

Message.defaultProps = {
  fluid: false,
  displayIcon: true,
  className: "",
  hideEmpty: false
};

Message.propTypes = {
  type: PropTypes.oneOf(availableTypes),
  content: PropTypes.any,
  fluid: PropTypes.bool,
  displayIcon: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  hideEmpty: PropTypes.bool // Hides the component, if content is empty.
};

export default Message;
