import React, {useRef} from "react";
import PropTypes from "prop-types";

import {Button, Icon} from "components/shared";
import {Overlay} from "components/layout/overlays";

import {doNothing} from "utils/constants";
import {translate} from "../../utils/language";

// Wykrywamy przycisk zamykania po propsach `pretext`, `preset` i `content`
// bo oryginalne API wymagające podania propsa `exit` w wielu miejscach nie jest przestrzegane
const CLOSE_PRESETS = { close: 1, cancel: 1 };
const CLOSE_CONTENTS = { [translate("unique.close")]: 1, [translate("unique.cancel")]: 1 };

function Modal(props) {
  let buttons = null;
  let onClose = props.close || doNothing;
  let onConfirm = doNothing;
  
  if (!props.open) {
    return null;
  }
  
  if (props.buttons && props.buttons.length > 0) {
    const buttonList = props.buttons.map((btn, id) => {
      const { exit, confirm, ...buttonProps } = btn;
      if (exit || CLOSE_PRESETS[buttonProps.preset || buttonProps.pretext] || CLOSE_CONTENTS[buttonProps.content]) {
        if (!props.close) {
          onClose = btn.onClick;
          buttonProps.onClick = onClose;
        }
        
        return (
          <Button
            variant="text"
            {...buttonProps}
            key={id}
          />
        );
      }
      else if (confirm) {
        onConfirm = btn.onClick;
        buttonProps.onClick = onConfirm;
        return (
          <Button
            variant="contained"
            {...buttonProps}
            key={id}
            focus
          />
        );
      }
      return <Button {...buttonProps} key={id}/>;
    });
    
    buttons = <div className="modal__buttons">{buttonList}</div>;
  }
  
  const headerIcon = props.headerIcon ? (
    typeof props.headerIcon === "string" ? (
      <Icon name={props.headerIcon} />
    ) : (
      <Icon {...props.headerIcon} />
    )
  ) : props.success && !props.header ? (
    <Icon name="check" />
  ) : props.error && !props.header ? (
    <Icon name="exclamation circle" />
  ) : (
    ""
  );

  const alignClass = props.align !== "center" ? `align-${props.align}` : "";

  const headerClass = props.success ? "success" : props.error ? "error" : "";

  const sizeClass = props.size !== "normal" ? `size-${props.size}` : "";
  const headerlessClass = props.displayHeader ? "" : "headerless";

  return (
    <Overlay type="modal" doClose={onClose}>
      <div
        className={`modal ${sizeClass} ${headerlessClass} ${props.className || ""}`}
        role="dialog"
        onSubmit={stopPropagation}
      >
        {props.displayHeader ? (
          <header className={`modal__header ${headerClass}`}>
            <h2>
              {headerIcon}
              {props.header}
            </h2>
          </header>
        ) : null}
        <div className={`modal__content ${alignClass} ${props.barricade ? "barricade" : ""}`}>
          {props.content}
        </div>
        {buttons}
      </div>
      {props.displayCloseButton ? (
        <button className="modal__close-btn" onClick={onClose}>
          <Icon name="times" />
        </button>
      ) : null}
    </Overlay>
  );
};

const stopPropagation = e => e.stopPropagation();

Modal.defaultProps = {
  displayHeader: true,
  displayCloseButton: false,
  open: true,
  size: "normal",
  align: "center",
  buttons: []
};

Modal.propTypes = {
  open: PropTypes.bool,
  headerIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // mozna podac jako string - wtedy jest to nazwa tej ikony, albo jako obiekt z wszystkimi paramtrami
  header: PropTypes.node,
  content: PropTypes.node,
  success: PropTypes.bool, // dodaje klasę do modalHeader zmieniając jego kolor i dodaje ikonę w headerze jeśli ta nie jest ustawiona
  error: PropTypes.bool, // dodaje klasę do modalHeader zmieniając jego kolor i dodaje ikonę w headerze jeśli ta nie jest ustawiona
  buttons: PropTypes.arrayOf(PropTypes.object), // tablica obiektów z parametrami charakterystycznymi dla buttonów (+ parmaetr exit)
  displayHeader: PropTypes.bool,
  displayCloseButton: PropTypes.bool,
  align: PropTypes.oneOf(["center", "left"]),
  size: PropTypes.oneOf(["normal", "wide", "wider"]),
  barricade: PropTypes.bool,
  close: function(props, propName, componentName) {
    if (
      props["displayCloseButton"] &&
      (!props[propName] || typeof props[propName] !== "function")
    ) {
      return new Error(
        `You need to provide 'close' function in ${componentName} props if you wish a close button to be displayed`
      );
    }
  }
};

export default Modal;
