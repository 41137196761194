import React, { useEffect, useReducer } from "react";
import bootstrap from "bootstrap";
import { tran } from "utils/language";
import { Loader } from "components/shared";
import { useSowaParams } from "../../../utils/params/hooks";
import { isLoaded } from "../../../capi/hooks";

// TODO: Do otypowania albo zrefaktorowania dla Mike'a

const DEFAULT_GDPR = {
  paragraph: [],
  footer: [],
};

const GDPR_REDUCER = (state: any, action: any) => {
  switch (action.type) {
    case "paragraph":
      return { ...state, paragraph: [...state.paragraph, action.data] };
    case "footer":
      return { ...state, footer: [...state.footer, action.data] };
    default:
      throw new Error();
  }
};

const SowaGDPR = () => {
  const [rodoContent, dispatchRodoContent] = useReducer(GDPR_REDUCER, DEFAULT_GDPR);
  const paramsRq = useSowaParams(
    bootstrap.sowa.firstCatalogue?.cat_id,
    "cfg_rodo_stopka_t",
    "cfg_rodo_stopka_c",
    "cfg_rodo_stopka_s",
    "cfg_rodo_t_#",
    "cfg_rodo_c_#",
    "cfg_rodo_s_#",
  );
  
  useEffect(() => {
      if (isLoaded(paramsRq)) {
        const response = paramsRq as { data: any }
        // nie wiem, co to robi i nie chcę wiedzieć :P
        Object.keys(response.data)
          .filter(e => e.indexOf("_t") > -1)
          .sort((a, b) => {
            let numberA = parseInt(a[a.length - 1], 10);
            let numberB = parseInt(b[b.length - 1], 10);
            if (isNaN(numberA) || isNaN(numberB)) return -1; // If footer, put it to the bottom.
            return numberB - numberA;
          })
          .reverse()
          .forEach(e => {
            const data = {
              _t: response.data[e],
              _c: response.data[e.replace("_t", "_c")],
              _s: response.data[e.replace("_t", "_s")],
            };
            const type = e.indexOf("_stopka_t") > -1 ? "footer" : "paragraph";
            dispatchRodoContent({ type, data });
          });
      }
  }, [paramsRq.data]);

  if(!rodoContent || (!rodoContent.paragraph && !rodoContent.footer)) return null;
  
  if(rodoContent.paragraph.length <= 0 && rodoContent.footer.length <= 0) return <Loader text={tran("pages.list.privacy-policy.loading.gdpr")} />

  const contentList = [...rodoContent.paragraph, ...rodoContent.footer];

  if(contentList.length <= 0) return null;

  return (
    <>
      <h1>{tran("pages.list.privacy-policy.gdpr")}</h1>
      {contentList.map((e, i) => (
        <p
          key={""+i}
          style={{ fontSize: `${e._c}px`, fontWeight: e._s === "BOLD" ? "bold" : "initial" }}>
          {e._t}
        </p>
      ))}
    </>
  );
};

export default SowaGDPR;
