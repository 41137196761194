import React, { PureComponent } from "react";

import { Button } from "components/shared";
import { tran } from "utils/language";

class ScrollToTopButton extends PureComponent {
  state = {
    displayButton: false
  };

  componentDidMount = () => {
    document
      .querySelector('.global-container')
      .addEventListener("scroll", this.handleScroll);
  };

  componentWillUnmount() {
    document
      .querySelector('.global-container')
      .removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    let scrollPosition = document.querySelector('.global-container').scrollTop;
    let displayButton = false;
    if (scrollPosition > window.innerHeight / 4) {
      displayButton = true;
    }

    this.setState({ displayButton });
  };

  scrollTop = () => {
    document.querySelector('.global-container').scrollTop = 0;
  };

  render = () => {
    return (
      <Button
        onClick={this.scrollTop}
        hideOn="tablet"
        variant="fab"
        icon="arrow up"
        size="small"
        cornered="bottom right"
        toggled={this.state.displayButton}
        title={tran("pages.scrollTop.button")}
      />
    );
  };
}

export default ScrollToTopButton;
