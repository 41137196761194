import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Divider, Loader, Message, ModuleTile, Toast } from "components/shared";
import { Link } from "react-router-dom";

import teka_screen from "images/teka_splash_image.jpg";
import sowa_screen from "images/sowa_splash_image.jpg";
import journal_screen from "images/journal_splash_image.jpg";

import { isStaff } from "utils/roles";
import { LoginDialog } from "components/special-pages/Login/LoginDialog";
import { useDialog } from "components/shared/Dialog";

import { LANGUAGES_BY_ID, Tran, tran } from "utils/language";

import bootstrap from "bootstrap";

import { ensure, isLoaded, useTeka } from "capi/hooks";
import { useBind, useDerivedState, useDeviceState } from "utils/hooks";
import { emptyArray, emptyObject } from "utils/constants";

import { isURLValid } from "utils/validate";
import { WithBreadcrumb } from "../../utils/hooks/breadcrumbs";
import { ColumnLayout } from "../shared/ColumnLayout";
import { LogoutDialog } from "../layout/TopBar/LogoutDialog";
import { checkCzytelniaRights } from "../../utils/globalFunctions";
import { useFolksParams, useSowaParams, useTekaParams } from "../../utils/params/hooks";
import { DocumentInfo, MiscInfo } from "../../capi/teka";
import { Expo } from "components/pages/Teka/Expo/Expo";

const SOWA_CAT_ID = bootstrap.sowa.firstCatalogue?.cat_id;

export const Front = () => {
  const current_user = useSelector(currentUserSelector);
  const isLogged = Boolean(current_user.folks.user_id);

  // Requests
  const folksParamsRq = useFolksParams("core.signup.default.enabled");
  const tekaParamsRq  = useTekaParams("branding.tile.default.url", "branding.exposition.contents");
  const sowaParamsRq  = useSowaParams(SOWA_CAT_ID, "system.licence.default.modules", "opac.registration.default.regulations");
  const tekaStatsRq   = ensure(useTeka(MiscInfo, "stats"), 200);

  // Response data
  const folksParams = folksParamsRq.data;
  const tekaParams  = tekaParamsRq.data;
  const sowaParams  = sowaParamsRq.data;

  // Variables depending on parameters
  const isSignupAvailable   = folksParams && folksParams["core.signup.default.enabled"];
  const brandingUrl         = tekaParams && tekaParams["branding.tile.default.url"];
  const hasRegulations      = isURLValid(sowaParams && sowaParams["opac.registration.default.regulations"]);
  const hasCzytelniaRights  = useDerivedState(checkCzytelnia, current_user, sowaParams || emptyObject);

  const showSignup          = bootstrap.WZUW || (isSignupAvailable && hasRegulations);

  // Dialog
  const loginDialog = useDialog(LoginDialog);
  const logoutDialog = useDialog(LogoutDialog);

  // Thumbnails
  let tekaThumbnail;
  let sowaThumbnail = sowa_screen;
  let journalThumbnail = journal_screen;

  if (brandingUrl && isURLValid(brandingUrl)) {
    tekaThumbnail = brandingUrl;
  }
  else {
    tekaThumbnail = teka_screen;
  }
  
  const expo    = tekaParams && tekaParams["branding.exposition.contents"];
  const hasTeka = bootstrap.teka && bootstrap.teka.url;
  const hasExpo = hasTeka && bootstrap.tekaExpo && expo && expo.length;

  // Module tiles list
  const modules = [
    {
      value: "teka",
      title: tran("app.teka"),
      description: tran("app.documentsDescription"),
      thumbnail: tekaThumbnail,
      icon: "bookmark",
      hidden: !hasTeka || hasExpo
    },
    {
      value: "admin",
      title: tran("app.admin"),
      description: tran("app.adminDescription"),
      thumbnail: sowaThumbnail,
      icon: "id-badge",
      hidden: isLogged ? !isStaff(current_user.folks.role) : true
    },
    {
      value: "journal",
      title: tran("app.journal"),
      description: tran("app.journalDescription"),
      thumbnail: journalThumbnail,
      icon: "clipboard",
      hidden: !hasCzytelniaRights,
    }
  ].filter(mod => !mod.hidden);
  let content;

  if (current_user.loading && isLogged && !hasCzytelniaRights)
    content = <Loader />; // zapobiegamy wyświetleniu dwóch kafelków a potem szybko trzech po załadowaniu info usera z sowy
  else {
    let main = null;
    
    if (hasExpo) {
      main = <>
        <Expo />

        <ColumnLayout cols={"400px"}>
          <Button to={"/teka/index"} content={
            isLoaded(tekaStatsRq)
              ? <span>
                  {tekaStatsRq.data.published_documents}
                  {" "}
                  <Tran id="front.moreDocs" count={tekaStatsRq.data.published_documents} />
                </span>
              : tran("front.moreDocs.L")
          } />
        </ColumnLayout>
        
        {modules.length > 0 && <Divider />}

        {modules.length > 0 && <ColumnLayout cols="repeat(auto-fit, 300px)" margin={0} spacing="2rem" width="100%" hPosition="center">
          {modules.map(e => (<Button key={e.value} variant="contained" to={`/${e.value}`} {...e} content={e.title} />))}
        </ColumnLayout>}
      </>
    }
    else {
      main = modules.length > 0
          ? <ColumnLayout cols="repeat(auto-fit, 400px)" margin={0} spacing="2rem" width="100%" hPosition="center">
              {modules.map(e => (<ModuleTile key={e.value} url={`/${e.value}`} {...e} />))}
            </ColumnLayout>
          : <span style={{ fontSize: "1.5em", color: "var(--primary)" }}>{tran("front.welcomeAnon")}</span>;
    }
    
    content = <>
      {main}

      <Divider />

      <ColumnLayout cols="auto auto" hPosition="end" vAlign="stretch" margin={0} width="100%" className="front__lowerMenu" unresponsive>
        {isLogged
          ? <>
            <Button
              to="/myProfile"
              variant="outlined"
              color="primary"
              align="right"
            >
              {tran("pages.list.myProfile.title")}
            </Button>
            <Button
              variant="outlined"
              onClick={logoutDialog.open}
            >
              {tran("unique.logout")}
            </Button>
          </>
          : <Button
            variant="outlined"
            color="primary"
            onClick={loginDialog.open}
          >
            {showSignup ? tran("front.signinOrSignup") : tran("front.signin")}
          </Button>
        }
      </ColumnLayout>
    </>
  }
  
  if (modules.length > 0 && !bootstrap.WZUW)
    return <WithBreadcrumb label={tran("front.welcomeAnon")}>{content}</WithBreadcrumb>;

  return content;
};

const currentUserSelector = state => state.currentUser;

const getObjectFromRq = rq => isLoaded(rq) ? rq.data : emptyObject;

function checkCzytelnia(currentUser, sowaParams) {
  if (!isStaff(currentUser.folks.role))
    return false;
  if (!(sowaParams["system.licence.default.modules"] || emptyArray).includes("CZT"))
    return false;
  return checkCzytelniaRights(currentUser);
}