import {useRef, Dispatch, SetStateAction} from "react";

import {useBind} from "./bind";

type Setter<T> = (value: T) => void

const propertySetter = <T extends { [key: string]: any }, K extends keyof T>(setState: Dispatch<SetStateAction<T>>, property: K, value: T[K]) => setState(state => ({ ...state, [property]: value }));

/** Zmienia jeden klucz w obiekcie stanu */
export function usePropertySetter<T extends { [key: string]: any }, K extends keyof T>(setState: Dispatch<SetStateAction<T>>, property: K): Setter<T[K]> {
  return useBind(propertySetter, setState, property);
}

/** Zmienia jeden klucz w obiekcie stanu */
export function useProperty<T extends { [key: string]: any }, K extends keyof T>(state: T, setState: Dispatch<SetStateAction<T>>, property: K): [T[K], Setter<T[K]>] {
  return [state[property], usePropertySetter(setState, property)];
}

const settersProxyHandlers = {
  get: function(target: any, name: string) {
    let setter = target[name];
    if (!setter) {
      const setState = target.__setState;
      setter = (v: any) => setState((state: any) => ({ ...state, [name]: v }));
      target[name] = setter;
    }
    return setter;
  }
};

/** Zwraca proxy automatycznie tworzące settery dla pojedynczych członków,
 *  używające podanej funkcji ustawiania stanu. */
export function useSetters<T extends { [key: string]: any }>(setState: Dispatch<SetStateAction<T>>): { [K in keyof T]: Setter<T[K]> } {
  const ref = useRef(null);
  
  if (ref.current === null) {
    ref.current = new Proxy({ __setState: setState }, settersProxyHandlers)
  }
  
  return ref.current as any;
}
