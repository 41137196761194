import React from "react";
import PropTypes from "prop-types";

const SegmentGroup = ({ className, elevation, direction, children }) => (
  <div className={`segment-group ${className} ${setShadow(elevation)} segment-group--${direction}`}>
    {children}
  </div>
);

SegmentGroup.defaultProps = {
  className: "",
  direction: "vertical",
  elevation: 1
};

SegmentGroup.propTypes = {
  direction: PropTypes.oneOf(["horizontal", "vertical"])
};

const Segment = ({ className, children, elevation }) => (
  <div className={`segment ${className} ${setShadow(elevation)}`}>
    {children}
  </div>
);

Segment.propTypes = {
  className: PropTypes.string,
  elevation: PropTypes.oneOf([0, 1, 2, 3, 4, 5])
};

Segment.defaultProps = {
  className: "",
  elevation: 1
};

Segment.Group = SegmentGroup;

export default Segment;

/**
 * @description Combine key and value to return the shadow class.
 * @param {string} value
 * @returns {string}
 */
const setShadow = value => {
  return `z-depth-${value || 0}`;
};
