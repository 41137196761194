import React from "react";
import Dialog from "../Dialog";
import Blankholder from "components/shared/Blankholder";
import { IDialog } from "../useDialog";
import { tran } from "utils/language";

type IProps = {
  buttons?: readonly React.ReactElement[] | React.ReactFragment
  title?: React.ReactNode
  icon?: string
  message?: React.ReactNode
  dialog: IDialog
};

/**
 * Dialog with an error.
 */
const ErrorDialog = ({ title=tran("error.dialogTitle"), icon="exclamation circle", message, buttons, ...props }: IProps) => {
  return (
    <Dialog buttons={buttons} icon={icon} title={title} type="error" size="medium" {...props}>
      <Blankholder icon={icon} description={message} animate={false} />
    </Dialog>
  );
};

export default ErrorDialog;
