import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import MenuItem from "../MenuItem";
import { Icon, Tag } from "components/shared";

import { tran } from "utils/language";

const View = props => {
  const { notifications, removeNotification, clearNotifications } = props;

  const removeButtonClicked = index => () => {
    removeNotification(index);
  };

  const renderContent = () => {
    let showTrash = false;
    const _notifications = notifications.map((item, index) => {
      let content = item.link ? (
        <Link to={item.link}>{item.text}</Link>
      ) : (
        <p>{item.text}</p>
      );
      let closeBtn = null;

      if (item.removable) {
        closeBtn = (
          <Icon
            onClick={removeButtonClicked(index)}
            name="times circle"
            outline
            type="error"
            pointer
          />
        );
        showTrash = true;
      }
      return (
        <div className="topbar-list__item" key={index}>
          <div className="topbar-list__item-content">{content}</div>
          <div className="topbar-list__item-button">{closeBtn}</div>
        </div>
      );
    });

    const trash = showTrash ? (
      <div className="topbar-list__trash">
        <Tag
          inline
          inverted
          compact
          onClick={clearNotifications}
          icon="trash-alt"
        >
          {tran("unique.clear")}
        </Tag>
      </div>
    ) : null;

    return (
      <div className="topbar-list">
        <div className="topbar-list__items">{_notifications}</div>
        {trash}
      </div>
    );
  };

  const buttonProps = {
    icon: "bell",
    title: tran("pages.list.tools.notificationsOld.title"),
    label:
      notifications.length === 0
        ? undefined
        : { content: notifications.length, float: "top right", type: "error" }
  };

  return (
    <MenuItem buttonProps={buttonProps}>
      {notifications.length === 0 ? (
        <i>{tran("topbar.noNotifications")}</i>
      ) : (
        renderContent()
      )}
    </MenuItem>
  );
};

View.displayName = "NotificationsContent";
View.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      link: PropTypes.string,
      removable: PropTypes.bool
    })
  ).isRequired,
  clearNotifications: PropTypes.func.isRequired,
  removeNotification: PropTypes.func.isRequired
};

export default View;
