import { capiDateFromString } from "../capi/client";
//import { CookieStorage } from "cookie-storage";

const SESSION_INFO_KEY = "session_info";
const PASSPORT_INFO_KEY = "passport_info";

// Firefox ma od lat buga w działaniu local storage w trybie prywatnym, który powoduje,
// że inne karty nie widzą wartości zapisanych, zanim zostały otwarte.
// Jest to niesłychanie upierdliwe dla danych logowania, bo otwarcie nowej karty
// powoduje wylogowanie wszystkich innych, kiedy nowa utworzy nową anonimową sesję.
//const expires = new Date(new Date().getTime() + 3600 * 1000 * 24 * 30);
//const localStorage = window._ws_browser.name === "Firefox" ? new CookieStorage({ path: "/", expires }) : window.localStorage;
const localStorage = window.localStorage;

function getInfo(key) {
  const info = localStorage.getItem(key);
  if (!info) {
    return {};
  }
  try {
    return JSON.parse(info);
  }
  catch {
    return {};
  }
}

function saveInfo(key, items) {
  localStorage.setItem(key, JSON.stringify(items));
}

/**
 * Pobranie informacji o sesji z localStorage
 */
export function getSessionInfo() {
  const info = getInfo(SESSION_INFO_KEY) || {};
  if (typeof info.session_expires_at === "string")
    // stary kod trzymał datę liczbowo, ale w np. sso-auth.html nie chcemy parsować stringa na liczbę, bo po co...
    info.session_expires_at = capiDateFromString(info.session_expires_at).getTime();
  return info;
}

/**
 * Pobranie informacji o paszporcie z localStorage
 */
export function getPassportInfo() {
  return getInfo(PASSPORT_INFO_KEY);
}

/**
 * Zapisanie informacji o sesji w localStorage
 * @param {Object} items Parametry do zapisania
 */
export function saveSessionInfo(items) {
  if (items) saveInfo(SESSION_INFO_KEY, items);
}

/**
 * Zapisanie informacji o paszporcie w localStorage
 * @param {Object} items Parametry do zapisania
 */
export function savePassportInfo(items) {
  saveInfo(PASSPORT_INFO_KEY, items);
}

/**
 * Usunięcie informacji o sesji z localStorage
 */
export function removeSessionInfo() {
  localStorage.removeItem(SESSION_INFO_KEY);
}

/**
 * Usunięcie informacji o paszporcie z localStorage
 */
export function removePassportInfo() {
  localStorage.removeItem(PASSPORT_INFO_KEY);
}

/**
 * Nadpisanie jednego klucza w informacjach o sesji w localStorage
 * @param {String} name Nazwa klucza
 * @param {String} value Wartość klucza
 */
export function replaceSessionItem(name, value) {
  const session_info = getSessionInfo();
  session_info[name] = value;
  saveSessionInfo(session_info);
}
