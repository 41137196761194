import {useContext, useEffect, useRef, useState, Dispatch, SetStateAction} from "react";

import { alwaysTrue, doNothing, emptyArray, emptyMap, emptySet, increment } from "../constants";
import {FormContext} from "./context";
import {areHookInputsEqual} from "../hooks/util";
import {callElementWithIncrement} from "./util";
import {Dictionary} from "../types";
import {FormField} from "./formField";
import {
  finishImmediateBatchUpdates,
  scheduleImmediateReactUpdate,
  scheduleReactUpdate,
  startImmediateBatchUpdates
} from "../hooks/batch";

const freeze = Object.freeze;

/** Zredukowany interfejs formularza do interakcji z różnych miejsc. */
export interface FormState {
  readonly name: string,
  readonly id: string,
  readonly fields: Map<string, FormField<any>>
  
  /** Czy w formularzu są niezapisane zmiany? */
  readonly isDirty: boolean
  
  /** Czy wszystkie pola są poprawne? */
  readonly isValid: boolean
  
  /** Czy wszystkie pola są poprawne i istnieją niezapisane zmiany? */
  readonly isReady: boolean
  
  /** Czy submit jest w trakcie przetwarzania? */
  readonly isSubmitting: boolean
  
  /** Czy w formularz zawsze da się zapisać? Np. dla nowych obiektów gdzie nie koncepcji zmian */
  readonly alwaysDirty: boolean
  
  /** Normalnie undefined, pozwala zidentyfikować konkretny przycisk submit, jeśli mają robić różne rzeczy */
  readonly submitId: any
  
  /** Przywrócenie wartości początkowych */
  reset(): void
  
  /** Zapisanie aktualnych wartości jako początkowych */
  save(): boolean
  
  /** Wysłanie formularza */
  submit(): void
  
  /** Wysłanie formularza ustawiając na czas wykonania onSubmit pole submitId */
  submitWithId(submitId: string): void
  
  focusFirst(filter?: (field: FormField<unknown>) => boolean): void
  focusFirstError(): void
}

/** Interfejs formularza zwracanego z hooka useNewForm */
interface NewFormState extends FormState {
  alwaysDirty: boolean
  
  /** Ustawienie callbacka do wysyłania formularza z opcjonalnym argumentem. */
  onSubmit<T>(callback: FormSubmitCallback<T>, data?: T): this
  
  /** Resetowanie formularza gdy podane zależności się zmienią. */
  resetOn(...deps: any[]): this
}

export type FormSubmitCallback<T> = (this: FormState, values: Dictionary, submitData: T) => any; 

export interface FormStatePriv extends NewFormState {
  name: string,
  id: string,
  fields: Map<string, FormField<any>>
  submitId: any
  
  _submitting: boolean,
  _signals: Set<Dispatch<SetStateAction<number>>>
  _onSubmit: FormSubmitCallback<any>
  _submitData: any
  _resetOn: any[]
  _onMount: () => () => void
  
  /** Sygnał zmiany stanu formularza, emitowany gdy zmieni się isDirty, isValid czy isSubmitting. */
  wake(): void
  
  wakeAsync(): void
  
  _submit(submitId: any, event?: Event): Promise<void>
}

const FormStateProto = freeze({
  name       : "__default__",
  id         : "",
  fields     : emptyMap as Map<string, any>,
  alwaysDirty: false,
  _submitting: false,
  _signals   : emptySet as Set<Dispatch<SetStateAction<number>>>,
  _onSubmit  : doNothing,
  _submitData: null,
  _resetOn   : emptyArray as any,
  _onMount   : () => () => {},
  submitId   : undefined,

  get isDirty() {
    if (this.alwaysDirty)
      return true;
    
    for (const field of this.fields.values())
      if (field.isDirty)
        return true;
    return false;
  },

  get isValid() {
    for (const field of this.fields.values())
      if (!field.isValid)
        return false;
    return true;
  },

  get isReady() {
    let dirty = this.alwaysDirty;
    for (const field of this.fields.values()) {
      if (!field.isValid)
        return false;
      dirty = dirty || field.isDirty;
    }
    
    return dirty;
  },
  
  get isSubmitting() {
    return this._submitting;
  },

  reset() {
    for (const field of this.fields.values())
      field.reset();
    this.wake();
  },

  save() {
    if (!this.isValid)
      return false;
    
    for (const field of this.fields.values())
      field.save();
    
    return true;
  },
  
  wake() {
    startImmediateBatchUpdates();
    try {
      this._signals.forEach(callElementWithIncrement);
    }
    finally {
      finishImmediateBatchUpdates();
    }
  },
  
  wakeAsync() {
    this._signals.forEach(signal =>
      scheduleReactUpdate(signal, increment)
    );
  },
  
  toString() {
    return `FormState(name=${this.name})`
  },
  
  onSubmit<T>(callback: FormSubmitCallback<T>, data?: T) {
    this._onSubmit = callback;
    this._submitData = data;
    return this;
  },
  
  resetOn(...deps) {
    if (! areHookInputsEqual(this._resetOn, deps)) {
      this._resetOn = deps; //TODO: opt
      this.reset();
    }
    return this;
  },
  
  async _submit(submitId: any, event?: Event) {
    if (event)
      event.preventDefault();
    
    if (this._submitting || !this.isDirty)
      return;
    
    if (!this.isValid)
      return this.focusFirstError();
    
    const values = { __proto__: null } as any;
    for (const field of this.fields.values())
      values[field.name] = await field.value; //TODO: asynchroniczne pola
    
    try {
      this._submitting = true;
      this.submitId = submitId;
      this.wake();
      return await this._onSubmit.call(this, values, this._submitData);
    }
    finally {
      this._submitting = false;
      this.submitId = undefined;
      this.wake(); // to powoduje warninga w testach, ale nic nie poradzimy
    }
  },
  
  focusFirstError() {
    this.focusFirst(field => !!field.error);
  },
  
  focusFirst(filter: (field: FormField<unknown>) => boolean = alwaysTrue) {
    if (this.fields.size === 0) {
      // TODO: może przenieść jednak focusowanie do FormField i propagować do niskopoz. komponentów inputów
      const focusFirstLater = () => {
        if (this.fields.size > 0) {
          this._signals.delete(focusFirstLater);
          this.focusFirst(filter);
        }
      }
      this._signals.add(focusFirstLater)
      return;
    }
    
    // HAK z tego względu, że poprawnie powinniśmy mieć mechanizm sygnalizacji między komponentami,
    // dzięki któremu focusowanie wykonałoby się po przerenderowaniu inputa.
    // Można sobie wyobrazić sytuacje, w których poniższe podejście zawiedzie, bo focusowanie
    // odbywa się przed renderowaniem, a renderowanie zastąpi focusowany element.
    const elements = document.querySelectorAll(`[form='${this.id}']`) as any as Iterable<HTMLElement>;
    for (let el of elements) {
      const field = this.fields.get(el.getAttribute("name") || "")
      if (field && filter(field)) {
        const lock = this.fields.get(`${field.name}$isLocked`);
        if (lock && lock.value) {
          // specjalne wsparcie dla ParamInputWithLock, bo tam input może być wyłączony
          const lockEl = document.querySelector(`[name='${lock.name}']`) as HTMLElement | null;
          lockEl && lockEl.click();
          break;
        }
        
        el.scrollIntoView({ behavior: "smooth", block: "center" });
        el.focus();
        // @ts-ignore <input/> ma select() ale inne rodzaje pól mogą nie mieć
        el.select && el.select();
        break;
      }
    }
  },
  
  submit: doNothing
} as FormStatePriv);

let ID = 0;

/**
 * `const formState = useFormState(fn, args...)`
 *
 * Gdzie `fn` jest funkcją obliczającą początkowy stan formularza, która zostanie
 * wywołana z `args` za każdym razem jak się zmienią.
 *
 * API:
 *
 * `formState.reset()` - przywrócenie stanu początkowego
 * `formState.save()` - zapisanie stanu aktualnego jako początkowego
 *
 * `formState.isDirty` - czy stan formularza zawiera zmiany
 * `formState.isValid` - czy żadne pole nia ma niepoprawnej wartości
 * `formState.isReady` - oba w/w warunki połączone
 *
 * `formState.fields.x` - zbiorcze API dla pola "x"
 * `formState.fields.x.name` - nazwa pola "x"
 * `formState.fields.x.value` - aktualna wartość pola "x"
 * `formState.fields.x.setter` - funkcja do ustawiania wartości pola "x"
 * `formState.fields.x.setter.invalid` - funkcja do oznaczania wartości pola "x" jako niepoprawnej
 * `formState.fields.x.isValid` - czy wartość tego pola została ustawiona jako poprawna
 *
 * Jak robić walidację?
 *
 * `const setter = formState.fields.foo.setter;`
 * `if (cośtam) setter(bar);`
 * `else        setter.invalid(bar);`
 *
 * Ta implementacja będzie dobrze działąć tylko dla formularzy ze stałym zbiorem pól.
 */
export function useNewForm(name: string): NewFormState {
  name = name || "__default__";

  const [, signal] = useState(0);

  const ref = useRef(null as any as FormStatePriv);
  if (ref.current === null) {
    const formState = ref.current = Object.seal({
      __proto__: FormStateProto,
      
      name,
      id         : `${name}-${ID++}`,
      fields     : new Map(),
      _signals   : new Set(),
      _onSubmit  : FormStateProto._onSubmit,
      _submitData: FormStateProto._submitData,
      _submitting: FormStateProto._submitting,
      _onMount   : FormStateProto._onMount,
      submit     : FormStateProto.submit,
      submitWithId: null,
      submitId   : undefined,
      alwaysDirty: false,
    } as any as FormStatePriv);
    
    const scheduledSignal = (x: any) => scheduleImmediateReactUpdate(signal, x);
    
    // Nie możemy z tym czekać do onMount, bo react może wyrenderować cały
    // formularz zanim onMount się wywoła, i nie będzie w tym czasie zakomunikować zmian
    formState._signals.add(scheduledSignal);
    
    formState._onMount = function FormState_onMount() {
      return function FormState_unmount() {
        formState._signals.delete(scheduledSignal);
      };
    };
    
    formState.submit = FormStateProto._submit.bind(formState, undefined);
    formState.submitWithId = FormStateProto._submit.bind(formState);
  }
  
  useEffect(ref.current._onMount, emptyArray);
  
  return ref.current;
}

export function findForm(forms: readonly FormState[], name?: string): FormState {
  if (name) {
    for (let i = forms.length - 1; i >= 0; i--) {
      if (forms[i].name === name)
        return forms[i];
    }
    throw new Error("Form doesn't exist: " + name);
  }
  else {
    if (forms.length == 0)
      throw new Error("No forms on stack");
  
    return forms[forms.length - 1];
  }
}

export function useFormState(name?: string): FormState {
  const context = useContext(FormContext);
  const ref = useRef(null as any as () => () => void);
  const [, signal] = useState(0);
  
  const formState = findForm(context, name) as FormStatePriv;

  if (ref.current === null) {
    const scheduledSignal = (x: any) => scheduleImmediateReactUpdate(signal, x);
    
    // Nie możemy z tym czekać do onMount, bo react może wyrenderować cały
    // formularz zanim onMount się wywoła, i nie będzie w tym czasie zakomunikować zmian
    formState._signals.add(scheduledSignal);
    
    ref.current = function FormState_onMount() {
      return function FormState_unmount() {
        formState._signals.delete(scheduledSignal);
      };
    };
  }
  
  useEffect(ref.current, emptyArray);
  
  // @ts-ignore
  return formState;
}
