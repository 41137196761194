import React from "react";
import { Link } from "react-router-dom";

import { Blankholder } from "components/shared";

import { translate } from "utils/language";

// strona 404
// najlepiej dodac ją do każdego Routera jako 'domyślny'route, np:

// <Route path={addUserPath} exact component={AddUser} />
// <Route path={MyProfilePath}  component={MyProfile} />
// <Route component={WrongUrl} />

const WrongUrl = () => {
  return (
    <Blankholder
      icon="exclamation triangle"
      title="404"
      description={
        <span>
          {translate("error.notFoundPage")}.{" "}
          <Link to="/">{translate("unique.goHomePage")}</Link>
        </span>
      }
    />
  );
};

export default WrongUrl;
