import { all } from "redux-saga/effects";

import sessionWatcher from "./sessionSaga";
import currentUserWatcher from "./currentUserSaga";
import utilsWatcher from "./utilsSaga";
import usersWatcher from "./usersSaga";
import tekaWatcher from "./tekaSaga";

export default function* rootSaga() {
  yield all([
    sessionWatcher(),
    currentUserWatcher(),
    utilsWatcher(),
    tekaWatcher(),
    usersWatcher()
  ]);
}
