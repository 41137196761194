import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";

import ScrollToTopButton from "components/common/ScrollToTopButton";

import { PasswordRecoveryDialog } from "components/special-pages/PasswordRecovery/PasswordRecoveryDialog";
import { ForgotPasswordDialog } from "components/special-pages/ForgotPasswordDialog";
import ConfirmationPage from "components/special-pages/ConfirmationPage";
import CancelEmail from "components/special-pages/CancelEmail";
import BlockedAccessPage from "components/special-pages/BlockedAccessPage";
import WrongUrl from "components/special-pages/WrongUrl";
import { SessionExpired } from "components/special-pages/SessionExpired";
import { Front } from "components/special-pages/Front";
import PrivacyPolicyPage from "./special-pages/PrivacyPolicy/";
import TermsPage from "./special-pages/Terms/";

import MyProfile from "components/pages/MyProfile";

import { pages } from "utils/pages";
import { lazyComponent } from "utils/pageLoading";
import Footer from "components/layout/Footer";
import bootstrap from "bootstrap";
import NotificationsBanner from "./layout/Notifications";

import cx from 'classnames';
import ErrorBoundary from "./shared/ErrorBoundary";
import { Blankholder, Button } from "./shared";
import { DialogRoute } from 'components/shared/Dialog';

const AdminLoader = lazyComponent(() => import(/* webpackChunkName: "adm" */ "components/pages/Admin"));

const TekaLoader = lazyComponent(() => import(/* webpackChunkName: "teka" */ "components/pages/Teka"));

const JournalLoader = lazyComponent(() => import(/* webpackChunkName: "jrnl" */ "components/pages/Journal"), "Journal");

// TODO: Nowa struktura katalogów i paczek?
//       * shell - rama aplikacji
//       * user  - wszystko dot. zalogowanego usera
//       * admin
//         * admin/main
//         * admin/teka
//         * admin/sowa
//       * teka
//         * teka/edit - kod tylko dla redaktorów i personelu
//       * lib   - współdzielone libki (bez osobnej paczki)
//       * comps - współdzielone komponenty (bez osobnej paczki)

const AppContainer = props => {
  // 'centrum' aplikacji, zawiera w sobie wszystkie podstrony

  // WAŻNE
  // strony, które zawierają dalsze podstrony (np. users) nie mogą zawieracc'exact' w parametrach, ponieważ zablokuje to dostęp do tych podstron
  // pozostałe powinno to miec

  let content;

  if(props.sessionExpired) {
    content = <SessionExpired />;
  } else {
    content = CONTENT;
  }

  return (
    <div className="global-container">
      <div className={cx("main-container", {"WZUW": bootstrap.WZUW}, {"Front": props.location.pathname === "/"})}>
        <ErrorBoundary fallback={message => <ErrorContent message={message}/>} resetKey={props.location /* Zmiana adresu resetuje błąd */}>
          {content}
        </ErrorBoundary>
      </div>
      {bootstrap.no_footer ? null : <Footer />}
      <ScrollToTopButton />
    </div>
  );
};

let FRONT;
switch (bootstrap.home) {
  case "teka":
    FRONT = <Redirect path="/" exact to="/teka/index" />
    break;
  case "prasa":
    FRONT = <Redirect path="/" exact to="/teka/prasa" />
    break;
  default:
    FRONT = <Route path="/" exact component={Front} />
}

// Centralny routing
// <Switch> wybierze pierwszą pasującą dyrektywę
const ROUTING_SWITCH = <Switch>
  <Route
    path={pages.teka.fullPath}
    render={() => {
      if (window.bootstrap.teka && window.bootstrap.teka.url) {
        return <TekaLoader />;
      } else {
        return <Redirect to="/" />;
      }
    }}
  />

  <Route
    path={pages.admin.fullPath}
    component={AdminLoader}
  />

  <DialogRoute path="/journal" component={JournalLoader} />

  <Route path={pages.myProfile.fullPath} component={MyProfile} />

  <Route path="/accessDenied" exact component={BlockedAccessPage} />

  {FRONT}

  <Route path="/privacy-policy" exact component={PrivacyPolicyPage} />

  <DialogRoute path="/recovery" component={PasswordRecoveryDialog} />

  <DialogRoute path="/forgot" component={ForgotPasswordDialog} />

  <Route path="/confirm" component={ConfirmationPage} />

  <Route path="/cancel" exact component={CancelEmail} />

  <Route path="/terms" exact component={TermsPage} />

  <Route component={WrongUrl} />
</Switch>;
  
const CONTENT = <>
  <NotificationsBanner />
  {ROUTING_SWITCH}
</>;
  
function ErrorContent({ message }) {
  const errorBoundary = ErrorBoundary.useApi();
  return <div>
    <Blankholder icon="exclamation circle" description={message}/>
    <Button.Group align="center">
      <Button preset="refresh" onClick={errorBoundary.clearError}/>
    </Button.Group>
  </div>
}

AppContainer.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  sessionExpired: PropTypes.bool.isRequired
};

const READ = state => ({
  loggedIn: state.session.isUserLoggedIn,
  sessionExpired: state.session.sessionExpired
});

export default withRouter(connect(READ)(AppContainer));
