import React, { useState } from "react";
import * as Folks from "capi/folks";
import { useBind } from "utils/hooks";
import { tran } from "utils/language";
import bootstrap from "bootstrap";
import { Button, Input, Blankholder, ColumnView } from "components/shared";
import { IDialog } from "components/shared/Dialog/useDialog";
import { DialogPresets } from "components/shared/Dialog";
import { BrandedDialog } from "components/shared/Dialog/comps";
import { promiseTimeout } from "../../utils/async";

type ForgotPasswordDialogProps = {
  identifier?: string
  dialog: IDialog
}

export function ForgotPasswordDialog({ identifier, dialog }: ForgotPasswordDialogProps) {
  const [input, setInput] = useState(identifier || "");
  const [success, setSuccess] = useState(false);

  const sendEmail = useBind(sendPasswordResetEmail, input)
    .preventDefault()
    .then(setSuccess);

  const buttons = [
    <Button preset="cancel" onClick={dialog.close} />,
    <Button
      disabled={input.length <= 0}
      type="submit"
      form="forgot-password-form"
      onClick={sendEmail}
    >
      {success
        ? tran("forgotPass.sendAgain")
        : tran("users.profile.passReset.reset")
      }
    </Button>
  ];

  return (
    <DialogPresets.BrandedDialog size="large" dialog={dialog} buttons={buttons}>
      <form className="login-form" id="forgot-password-form">
        {success ? (
          <>
            <Blankholder
              icon="check"
              title={tran("forgotPass.success")}
            />
          </>
        ) : (
          <>
            <BrandedDialog.Heading>
              {tran("users.profile.passReset.name")}
            </BrandedDialog.Heading>

            <p style={{ marginBottom: 20 }}>
              {tran("forgotPass.provide")}
            </p>

            <ColumnView nameBold={false} className="login-form__column-view">
              <Input
                labelCls="custom-input__label--login"
                required
                fluid
                value={input}
                type="text"
                label={tran("forgotPass.inputPlaceholder")}
                onChange={val => setInput(val)}
                disabled={!!identifier}
              />
            </ColumnView>
          </>
        )}
      </form>
    </DialogPresets.BrandedDialog>
  );
}

async function sendPasswordResetEmail(identifier: string) {
  const prr = promiseTimeout(5000);
  
  const [resp] = await bootstrap.folks.client.execute(new Folks.UserEmailResetRequest(bootstrap.domain, identifier));
  
  await prr;
  
  resp.ensure(204);
  
  return true;
}