import { useSelector } from "react-redux";
import { isDarkTheme, getThemeByName } from "utils/themes";
import {useDerivedState} from "./hooks/derived";

const contrastSelector = (state: any) => state.utils.appSettings.contrast;
const themeNameSelector = (state: any) => state.utils.appSettings.theme;

function buildResult(isContrast: boolean, themeName: string) {
  const isDark = isDarkTheme(isContrast ? "contrast" : themeName);
  return { isDark, isContrast, theme: getThemeByName(themeName) }
}

export default function useTheme() {
  const isContrast = useSelector(contrastSelector);
  const themeName = useSelector(themeNameSelector);
  return useDerivedState(buildResult, isContrast, themeName);
};
