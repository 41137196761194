import React from "react";
import { ensure, isLoaded, useFolks } from "../../../capi/hooks";
import { useLocation } from "react-router-dom";
import * as Folks from "capi/folks";
import { IDialog } from "components/shared/Dialog/useDialog";
import { PasswordRecoveryFormDialog } from "./PasswordRecoveryFormDialog";
import { RequestDialog } from "components/shared/requestUtils";
import { tran } from "utils/language";

function getTokenFromUrl(hash: string) {
  let token = hash.slice(1);
  
  // Dekodowanie tokenu
  try {
    token = decodeURIComponent(token);
  } catch(err) {
    console.error(err);
    return "";
  }

  if (!token)
    return "";

  return token;
};

type PasswordRecoveryDialogProps = {
  dialog: IDialog
}

export function PasswordRecoveryDialog({ dialog }: PasswordRecoveryDialogProps) {
  const location = useLocation();
  const hash = location.hash;

  const token = decodeURIComponent(getTokenFromUrl(hash));

  // Sprawdzenie ważności tokenu
  const passwordRecoveryRq = ensure(useFolks(Folks.UserEmailResetCheck, token), 200);
  
  if (!isLoaded(passwordRecoveryRq))
    return <RequestDialog request={passwordRecoveryRq} dialog={dialog} title={tran('passRecovery.title')} />;

  return (
    <PasswordRecoveryFormDialog
      dialog={dialog}
      rule_idf={passwordRecoveryRq.data.rule_idf}
      token={token}
    />
  );
}