import React, { useEffect, useState } from "react";
import * as Folks from "capi/folks";
import { saveStorageKey } from "api/folks";
import { capiDateToString } from "capi/client";
import { tran } from "utils/language";
import { isEmailValid } from "utils/validate";
import bootstrap from "bootstrap";
import useDialog, { IDialog } from "components/shared/Dialog/useDialog";
import { DialogPresets } from "components/shared/Dialog";
import { BrandedDialog } from "components/shared/Dialog/comps";
import { Input, Message, Button } from "components/shared";
import { Requirements } from "components/common/PasswordForm/Requirements";
import { TermsDialog } from "./TermsDialog";
import { usePasswordValidation } from "components/common/PasswordForm/usePasswordValidation";
import { useBind, isRunning } from "utils/hooks";
import { isLoaded } from "capi/hooks";
import { RequestDialog } from "components/shared/requestUtils";
import { emptyArray } from "../../../utils/constants";
import { CompleteSignupDialog } from "./CompleteSignupDialog";

type SignupDialogProps = {
  dialog: IDialog,
}

const DISPLAY_TERMS = bootstrap.WZUW;

export function SignupDialog({ dialog }: SignupDialogProps) {
  const [email, setEmail] = useState("");
  const [emailErrors, setEmailErrors] = useState<readonly React.ReactNode[]>([]);

  const [serverErrors, setServerErrors] = useState<readonly string[]>([]);

  const [termsAccepted, setTermsAccepted] = useState(!DISPLAY_TERMS);

  const passwordValidationState = usePasswordValidation("default");

  const onSubmit = useBind(handleSubmit).preventDefault();

  const termsDialog = useDialog(TermsDialog)
    .onClose(res => res && setTermsAccepted(true));
  
  const completeDialog = useDialog(CompleteSignupDialog);
  
  useEffect(() => {
    if (email)
      void onSubmit();
  }, [termsAccepted])
  
  if (!isLoaded(passwordValidationState.paramsRq))
    return <RequestDialog request={passwordValidationState.paramsRq} dialog={dialog} />;

  return (
    <DialogPresets.BrandedDialog size="large" dialog={dialog} buttons={null}>
      <BrandedDialog.Heading>
        {tran("signup.header")}
      </BrandedDialog.Heading>

      {!bootstrap.WZUW &&
        <h3 className="login-form__subheading">{bootstrap.title}</h3>
      }

      {/* Wyświetlamy wszystkie błędy w jednym miejscu */}
      {emailErrors.length + passwordValidationState.errors.length + serverErrors.length > 0 && (
        <Message
          fluid
          type="error"
          content={
            <p>
              {[...emailErrors, ...passwordValidationState.errors, ...serverErrors].map(err => (
                <span>
                  {err}
                  <br />
                </span>
              ))}
            </p>
          }
        />
      )}

      <form
        onSubmit={onSubmit}
        className="login-form"
      >
        <Input
          labelCls="custom-input__label--login"
          value={email}
          id="email"
          fluid
          required
          type="email"
          onChange={setEmail}
          error={emailErrors.length > 0}
          label={tran("signup.fld.email")}
        />

        <Input
          labelCls="custom-input__label--login"
          {...passwordValidationState.newPasswordProps}
          fluid
          required
          icon={undefined}
          enablePasswordPreview
          label={tran("signup.fld.password")}
        />

        <Input
          labelCls="custom-input__label--login"
          {...passwordValidationState.confirmPasswordProps}
          fluid
          required
          icon={undefined}
          enablePasswordPreview
          label={tran("signup.fld.repassword")}
        />

        <Requirements {...passwordValidationState.requirementsProps} cls="login-form__pass-requirements" />

        <Button.Group align="center">
          <Button
            type="button"
            pretext="cancel"
            variant="contained"
            inverted
            onClick={dialog.close}
          />
          <Button type="submit" loading={isRunning(onSubmit)}>
            {tran(!DISPLAY_TERMS ? "signup.btn" : "unique.continue")}
          </Button>
        </Button.Group>
      </form>
    </DialogPresets.BrandedDialog>
  );
  
  async function handleSubmit() {
    const isMailValid = isEmailValid(email);
    
    if (isMailValid && passwordValidationState.validate()) {
      setEmailErrors(emptyArray);
      
      if (termsAccepted) {
        const { error, userId } = await signUp(email, passwordValidationState.values.newPassword);
        if (error)
          setServerErrors([error]);
        else {
          setServerErrors(emptyArray);
          completeDialog.openWith({ client: bootstrap.folks.client.withAuth(100, userId, bootstrap.domain, passwordValidationState.values.newPassword), skipTerms: true });
        }
        return;
      }
      else {
        termsDialog.open();
      }
    }
    else {
      setEmailErrors(isMailValid ? emptyArray : [tran("error.email.provide")]);
      setServerErrors(emptyArray);
    }
  }
}

async function signUp(email: string, password: string) {
  const results = await bootstrap.folks.client.execute(new Folks.UserSignup(bootstrap.domain, email, password));
  const [result] = results;
  
  result.ensure(200, "invalid_email", "duplicate_email", "invalid_password");
  
  if(result.result.message) {
    return { error: result.result.message };
  }
  
  saveStorageKey("tos", capiDateToString());
  
  return { userId: result.result.data.user_id };
}
