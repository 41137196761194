import React from "react";
import { Tran, tran } from "utils/language";
import { Blankholder, Button } from "components/shared";
import { IDialog } from "components/shared/Dialog/useDialog";
import { DialogPresets } from "components/shared/Dialog";
import { DateOnly } from "../../shared/dateUtils";

type ConfirmationDialogProps = {
  expiry: Date,
  dialog: IDialog
};

export function ConfirmationDialog({ expiry, dialog }: ConfirmationDialogProps) {
  const buttons = (
    <Button
      content={tran("unique.goToApp")}
      onClick={dialog.close}
    />
  );

  return (
    <DialogPresets.BrandedDialog size="medium" dialog={dialog} buttons={buttons}>
      <form>
        <Blankholder
          icon="check-circle"
          title={tran("passRecovery.success")}
          description={<Tran id="passRecovery.expirationDate" search="<<1>>" replace={<DateOnly at={expiry} long />} />}
        />
      </form>
    </DialogPresets.BrandedDialog>
  );
}