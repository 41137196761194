import React from "react";
import { Responsive, Icon } from "components/shared";

import PropTypes from "prop-types";

/**
 * @deprecated since the implementation of ExpansionPanel!
 */
class Collapsible extends React.Component {
  static Entry = class Entry extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        active: undefined
      };
    }

    activate = () => {
      if (this.props.activator !== undefined)
        this.props.activator(this.props.id);
      else if (this.props.callback !== undefined)
        this.props.callback(this.props.id || null);
      else this.setState({ active: !this.state.active });
    };

    render() {
      const cls = this.props.className || "";
      const icon =
        (this.props.icon !== null) & (this.props.icon !== undefined) ? (
          <Icon className="ex-icon" name={this.props.icon} />
        ) : null;
      return (
        <div
          className={`ex-container ${cls} ${
            this.props.compact ? "compact" : ""
          } ${this.props.active || this.state.active ? "active" : ""} ${
            this.props.faded ? "faded" : ""
          } ${this.props.standOut ? "stand-out" : ""} ${
            this.props.flat ? "flat" : ""
          } ${this.props.popout ? "popout" : ""} ${
            this.props.striped ? "striped" : ""
          } ${this.props.disabled ? "disabled" : ""}`}
          tabIndex="0"
        >
          <div
            tabIndex="0"
            onBlur={this.props.onBlur}
            onFocus={this.props.onFocus}
            className="ex-head"
            onClick={() => this.activate()}
          >
            <div className="ex-title">
              {" "}
              {icon} {this.props.title}
            </div>
            <Responsive minWidth={767}>
              <div className="ex-description" title={this.props.description}>
                {this.props.description}
              </div>
            </Responsive>
            <div
              className={`ex-button ${
                !this.props.actionsHidden ? "noHide" : ""
              }`}
            >
              {this.props.action || ""}
              <Icon id="rollout" name={this.state.active ? "angle-up" : "angle-down"}/>
            </div>
          </div>
          <div className="ex-body">
            <Responsive maxWidth={767}>
              <p>{this.props.description}</p>
              <br />
            </Responsive>
            {this.props.children}
          </div>
        </div>
      );
    }
  };

  static Footer = class Footer extends React.Component {
    render() {
      return <div className="ex-action">{this.props.children}</div>;
    }
  };

  render() {
    return <div className="ex-panel">{this.props.children}</div>;
  }
}

export default Collapsible;

Collapsible.defaultProps = {
  compact: false,
  active: false,
  standOut: false,
  faded: false,
  flat: false,
  popout: false,
  striped: false,
  disabled: false,
  actionsHidden: true,
  icon: null
};

Collapsible.propTypes = {
  compact: PropTypes.bool, // Kompaktowy widok
  active: PropTypes.bool,
  standOut: PropTypes.bool, // Disconnects from the other elements which makes a standing-out effect
  faded: PropTypes.bool, // Wygaszony element
  flat: PropTypes.bool, // Eliminuje cień oraz zmienia kolor całego elementu
  popout: PropTypes.bool, // Zwiększa serokość aktywnego elementu
  striped: PropTypes.bool, // Przyciemnia pasek z tytułem
  disabled: PropTypes.bool, // Nieaktywny
  actionsHidden: PropTypes.bool, // Akcje ukrywają się podczas ekspansji komponentu
  callback: PropTypes.func, // Callback po otwarciu/zamknięciu.

  id: PropTypes.string, // ID
  title: PropTypes.string, // Tytuł
  description: PropTypes.string, // Opis
  action: PropTypes.element, // Akcja
  onFocus: PropTypes.func, // Gdy zyskuje focus
  onBlur: PropTypes.func, // Gdy traci focus

  icon: PropTypes.string
};
