import { translate } from "utils/language";

const roles = {
  spectator: {
    id: "spectator",
    rate: 0
  },
  aspirant: {
    id: "aspirant",
    rate: 10
  },
  patron: {
    id: "patron",
    rate: 20
  },
  staff: {
    id: "staff",
    rate: 30
  },
  senior: {
    id: "senior",
    rate: 50
  },
  gdpr: {
    id: "gdpr",
    rate: 70
  },
  admin: {
    id: "admin",
    rate: 80
  },
  sysadmin: {
    id: "sysadmin",
    rate: 100
  }
};

/**
 * Czy uzytkownik z daną rolą ma prawa administratorskie
 * @param {String} role Identyfikator roli
 * @returns {Boolean}
 */
export function isAdmin(role) {
  return Boolean(roles[role] && roles[role].rate >= 70);
}

/**
 * Czy uzytkownik z daną rolą ma prawa administratorskie
 * @param {String} role Identyfikator roli
 * @returns {Boolean}
 */
 export function isSysAdmin(role) {
  return Boolean(roles[role] && roles[role].rate == 100);
}

/**
 * Czy uzytkownik z daną rolą jest pracownikiem
 * @param {String} role Identyfikator roli
 * @returns {Boolean}
 */
export function isStaff(role) {
  return Boolean(roles[role] && roles[role].rate >= 30);
}

/**
 * Czy uzytkownik z daną rolą jest bibliotekarzem systemowym
 * @param {String} role Identyfikator roli
 * @returns {Boolean}
 */
export function isSenior(role) {
  return Boolean(roles[role] && roles[role].rate === 50);
}


/**
 * Zwraca idf parametru (reguł hasłowych) dla podanej roli, który jest przekazywany hookowi `usePasswordValidation`
 * @param {String} role Identyfikator roli
 * @returns {String}
 */
 export function getRuleIdf(role) {
  if(isAdmin(role)) {
    return "admin";
  } else if(isStaff(role)) {
    return "staff";
  } else {
    return "default";
  }
}

/**
 * Czy uzytkownik jest zarejestrowany i zatwierdzony
 * @param {String} role Identyfikator roli
 * @returns {Boolean}
 */
export function isApproved(role) {
  if (role && role.role)
    role = role.role;
  return !!(role && roles[role] && roles[role].rate > 10);
}

/**
 * Zwraca obiekt z rolami użytkwoników
 * @returns {Object}
 */
export function getRoles() {
  return roles;
}

/**
 * Sprawdza, czy rola a jest wyżej w hierarchii (bądź na tym samym poziomie), co rola b
 * @param {String} a Identyfikator roli
 * @param {String} b Identyfikator roli
 * @returns {Boolean}
 */
export function isRoleHigherOrEqual(a, b) {
  if (roles[a] && roles[b]) {
    return roles[a].rate >= roles[b].rate;
  } else {
    return false;
  }
}

/**
 * Zwraca obiekt roles tylko z rolami niższymi lub równymi rangą z rolą podaną w parametrze
 * @param {String} role Identyfikator roli
 * @returns {Object}
 */
export function getLowerOrEqualRoles(role) {
  let lowerOrEqual = {};
  for (let r in roles) {
    if (isRoleHigherOrEqual(role, r)) {
      lowerOrEqual[r] = roles[r];
    }
  }
  return lowerOrEqual;
}

/**
 * Zwraca listę ról względem rate
 * @param {number} min Minimalny rate
 * @param {number} max Maksymalny rate
 * @returns {array}
 */
export function getRolesByRate(min, max) {
  return Object.keys(roles).filter(
    e => roles[e].rate >= min && roles[e].rate <= max
  );
}

export const staffRoles = Object.freeze(getRolesByRate(30, 1000));
export const seniorRoles = Object.freeze(getRolesByRate(50, 1000));
export const adminRoles = Object.freeze(getRolesByRate(80, 1000));

export default roles;
