import PropTypes from "prop-types";
import React, { Component } from "react";
import cx from 'classnames';
import { Popup, Responsive, Dropdown, Icon } from "../";
import { AriaLabelParent } from "../../../utils/aria";

export default class QuickAccess extends Component {
  /**
   * @description Composes the quick-bar.
   * @param {array} actions List of available actions.
   */
  composeBar(actions, title) {
    const hasInput = actions.some(action => action.specs.input);

    return (
      <div className={`quick-bar 
      ${this.props.className && this.props.className}
      ${hasInput ? "quick-bar--input" : ""}`}>
        <div className="row">
          {actions
            .filter(
              action =>
                !action.specs ||
                !action.specs.float ||
                action.specs.float === "left"
            )
            .map((action, id) => {
              return this.composeAction(action, id);
            })}
        </div>
        <Responsive minWidth={Responsive.onlyTablet.minWidth}>
          <p className="title" style={{ display: "inline-block" }}>
            {title}
          </p>
        </Responsive>
        <div className="row">
          {actions
            .filter(action => action.specs.float === "right")
            .map((action, id) => {
              return this.composeAction(action, id);
            })}
        </div>
      </div>
    );
  }

  /**
   * @description Composes the quick-drawer.
   * @param {node} content Content of the drawer.
   */
  composeDrawer(content) {
    if (!content) return "";

    return <div className="quick-drawer">{content}</div>;
  }

  /**
   * @description Composes a component spitting out the right form of action.
   * @param {object} action A specific action.
   */
  composeAction(action, id) {
    if (action.specs === undefined) action.specs = {};

    const handleClick = e => 
      !action.disabled && action.onClick && action.onClick(e);

    const symbol = (
      <button  
        tabIndex={(action.value === "" || action.disabled) ? -1 : 0}
        aria-expanded={action.highlighted}
        className={
          cx("quick-action", {"switch": action.specs.switch, "active": action.active, "disabled": action.disabled, "blank": action.value === ""} )
        }
        key={id}
        onClick={handleClick}
      >
        {React.isValidElement(action.value) ? (
          action.value
        ) : action.value !== "" ? (
          Number.isInteger(action.value) ||
          !isNaN(parseInt(action.value, 10)) ? (
            <b>{action.value}</b>
          ) : (
            <Icon
              name={action.value}
              style={{ margin: 0, ...action.style }}
              className={action.highlighted ? "highlightedText" : ""}
            />
          )
        ) : (
          ""
        )}
        <AriaLabelParent noTitle={action.disabled}>{action.text}</AriaLabelParent>
      </button>
    );

    let output = symbol;

    const isDropdown = action.specs.dropdown && action.menu && !action.disabled;
    const isPopup = false; // TODO: wywalić w ogóle stąd popupy, bo title (AriaLabelParent) wystarczy
      //action.specs.popup && action.text !== undefined && !action.disabled;
    const isInput = action.specs.input && !action.disabled;
    
    if (isInput) {
      output = action.value;
    }

    if (isDropdown) {
      const popup = isPopup
        ? {
            on: "hover",
            pointing: false,
            position: "top center",
            content: action.text
          }
        : null;

      const options = action.menu.map((option, id) => {
        if (option.divider) {
          return <Dropdown.Divider key={id} />;
        }
        return { ...option };
      });
      output = (
        <Dropdown
          key={id}
          trigger={output}
          triggerPopup={popup}
          menuPosition={action.menuPosition}
          options={options}
          style={{ margin: 0 }}
        />
      );
    } else if (isPopup) {
      output = (
        <Popup
          on="hover"
          key={id}
          pointing={false}
          position="top center"
          trigger={output}
          content={action.text}
          style={{ zIndex: 9999 }}
        />
      );
    }

    return output;
  }

  /**
   * @description Assembles the component including the bar, actions and drawer.
   */
  assembleComponent() {
    const actions = this.props.actions.filter(action => action !== null);

    return (
      <React.Fragment>
        {this.composeBar(actions, this.props.title)}
        {this.props.drawer ? (
          this.composeDrawer(this.props.drawer)
        ) : (
          <React.Fragment />
        )}
      </React.Fragment>
    );
  }

  render() {
    const { hideTitleOnMobile, title } = this.props;

    return (
      <div className="quick-access">
        {hideTitleOnMobile ? (
          <React.Fragment />
        ) : (
          <Responsive maxWidth={Responsive.onlyTablet.minWidth}>
            <p>{title}</p>
          </Responsive>
        )}
        {this.assembleComponent()}
      </div>
    );
  }
}

QuickAccess.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node
      ]).isRequired, // Name of icon / Number for statistic.
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]), // Text shown on popup.
      active: PropTypes.bool, // Is action active?
      highlighted: PropTypes.bool,
      specs: PropTypes.shape({
        popup: PropTypes.bool, // Shall the popup be shown?
        switch: PropTypes.bool, // Shall the action appear as a switch with outlines etc.?
        dropdown: PropTypes.bool, // Shall the action contain a dropdown?
        float: PropTypes.oneOf(["right", "left"]) // Float direction of the action.
      }),
      onClick: PropTypes.func, // onClick event.
      menu: PropTypes.array, // Dropdown menu. (Dropdown specification must be true)
      disabled: PropTypes.bool,
      menuPosition: PropTypes.oneOf(["right", "left"])
    })
  ).isRequired,
  hideTitleOnMobile: PropTypes.bool,
  drawer: PropTypes.node // Component inside drawer visible right under the bar.
};

QuickAccess.defaultProps = {
  title: "",
  actions: [
    {
      specs: {
        popup: false,
        switch: false,
        menu: false,
        float: "left"
      },
      menu: null,
      menuPosition: "left",
      disabled: false
    }
  ],
  hideTitleOnMobile: true,
  drawer: null
};
