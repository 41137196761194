import React from 'react';
import cx from 'classnames';
import { ProgressProps, DEFAULT_MAX_VALUE } from './index';

/**
 * Szczupły pasek progresu ze wskaźnikiem procentów poniżej paska.
 */
export const ProgressThin = (props: ProgressProps) => {
  const classes = cx(props.className, "variant-thin");

  return (
    <div className={classes} style={props.style}>
      <progress
        value={props.value.toString()}
        max={props.max?.toString() ?? DEFAULT_MAX_VALUE}>{props.value}%</progress>

      {!props.hidePercentage &&
        <p>{props.value}%</p>}
    </div>
  );
};
