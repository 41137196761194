import { tabbable } from "tabbable";

import {emptyObject} from "../../../utils/constants";

export function captureTab(event: KeyboardEvent) {
  // złapaliśmy TAB

  // szukamy oznaczonego przez nas elementu, w którym trzymamy focus
  const captureRoot = document.getElementById("overlayService__tabRoot");
  if (!captureRoot)
    return;

  // znajdujemy wszystkie focusowalne rzeczy w środku
  const candidates = tabbable(captureRoot, emptyObject);
  const L = candidates.length;

  if (L == 0)
    return;

  const reverse = event.shiftKey;

  // z pierwszego elementu wstecz idziemy na ostatni
  if (event.target === candidates[0] && reverse) {
    event.preventDefault();
    candidates[L-1].focus();
    return;
  }

  // z ostatniego elementu naprzód idziemy na pierwszy
  if (event.target === candidates[L - 1] && !reverse) {
    event.preventDefault();
    candidates[0].focus();
    return;
  }

  const focused = document.activeElement;

  // z elementu poza obszarem ograniczenia idziemy na pierwszy w obszarze
  if (focused === null || candidates.indexOf(focused as HTMLElement) < 0) {
    event.preventDefault();
    for (const cand of candidates) {
      // HAK: dodaliśmy tabIndex={0} do dialogów podczas prac z pełnoekranowym dialogiem
      // żeby kliknięcie w jego tło nie focusowało <body/> ale może to lepiej przemyśleć wypada
      if (!cand.classList.contains("dialog")) {
        cand.focus();
        return;
      }
    }
    candidates[0].focus();
  }
}
