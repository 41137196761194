import { translate } from "utils/language";
import bootstrap, { BootstrapCatalogue } from "bootstrap";

import * as Folks from "capi/folks";
import * as Sowa from "capi/sowa";
import * as Teka from "capi/teka";
import * as Kasa from "capi/kasa";
import { useRef } from "react";
import { emptyArray } from "../../../../../utils/constants";
import { CapiClient, CapiCommand } from "../../../../../capi/client";
import { ICatalogue } from "../../../../../types/params";

type StrixModuleType = "sowa" | "folks" | "teka" | "kasa"

export interface StrixModule extends ICatalogue /* burdel z tymi typami... >_> */ {
  readonly description: string;
  // alias z innego typu, który najwyraźniej jest mieszany z tym
  readonly text: string;
  readonly type: StrixModuleType;
  readonly commands: { [name: string]: new(...args: any[]) => CapiCommand };
  // alias bo w kodzie róznie używali...
  readonly module: { [name: string]: new(...args: any[]) => CapiCommand };
  readonly paramsGet: string;
  readonly url: string;
  readonly id: string;
  readonly modId: string;
  readonly path: readonly string[];
  readonly client: CapiClient;
};

type SowaCatalogue = BootstrapCatalogue;

// Ten hook jest w zasadzie niepotrzebny, bo to stałe informacje i bez przeładowania strony
// się nie zmieniają, ale zostawiamy, bo w przyszłości po integracji z mOPAC bootstrap może
// przestać być stałą

// mały hak by kodu nie zmieniać (był useRef)
let modules: { current: StrixModule[] } = { current: emptyArray as any };

/**
 * Zwraca listę modułów podanych w bootstrap, w tym też wszystkich katalogów Sowa.
 */
export const useStrixModules = (): readonly StrixModule[] => {
  //const modules = useRef<StrixModule[]>(emptyArray as any);

  if (modules.current === emptyArray as any) {
    modules.current = [];

    // Katalogi modułu Sowa
    modules.current.push(
      ...bootstrap.sowa.catalogues.map((catalogue: SowaCatalogue) => Object.freeze({
        description: catalogue.name,
        text: catalogue.name,
        type: "sowa" as StrixModuleType,
        commands: Sowa,
        module: Sowa,
        paramsGet: "sowaParamsGet",
        url: catalogue.url,
        id: catalogue.cat_id,
        modId: `sowa-${catalogue.cat_id}`,
        path: Object.freeze(["sowa", "cataloguesById", catalogue.cat_id]),
        client: catalogue.client,
      }))
    );

    // Moduł Folks
    modules.current.push(Object.freeze({
      description: translate("app.folks"),
      text: translate("app.folks"),
      type: "folks",
      commands: Folks,
      module: Folks,
      paramsGet: "folksParamsGet",
      url: bootstrap.folks.url,
      id: "folks",
      modId: "folks",
      path: Object.freeze(["folks"]),
      client: bootstrap.folks.client,
    }));

    // Moduł Teka
    if (bootstrap.teka && bootstrap.teka.url)
      modules.current.push(Object.freeze({
        description: translate("app.teka"),
        text: translate("app.teka"),
        type: "teka",
        commands: Teka,
        module: Teka,
        paramsGet: "tekaParamsGet",
        url: bootstrap.teka.url,
        id: "teka",
        modId: "teka",
        path: Object.freeze(["teka"]),
        client: bootstrap.teka.client,
      }));

    // Moduł Kasa
    if (bootstrap.kasa && bootstrap.kasa.url)
      modules.current.push(Object.freeze({
        description: "Kasa",
        text: "Kasa",
        type: "kasa",
        commands: Kasa,
        module: Kasa,
        paramsGet: "kasaParamsGet",
        url: bootstrap.kasa.url,
        id: "kasa",
        modId: "kasa",
        path: Object.freeze(["kasa"]),
        client: bootstrap.kasa.client,
      }));
  }

  return modules.current as StrixModule[];
};

export function useStrixModule(id: string | number): StrixModule | undefined {
  const modules = useStrixModules();
  if (typeof id === "number")
    return modules[id];
  else
    return modules.find(mod => mod.modId === id);
}
