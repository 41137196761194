import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useWindowResized } from "./window";
import { emptyArray } from "../constants";
import { areHookInputsEqual } from "./util";
import { useSignal } from "./other";
import { scheduleReactUpdate } from "./batch";

export interface StableHeightApi {
  resetOn(...deps: readonly any[]): StableHeightApi
}

/** Zapewniam, że wysokość podanego elementu nigdy się nie zmniejszy.
 *  Przydatne przy paginacji by ustabilizować wysokość strony.
 * */
export function useStableHeight(ref: React.RefObject<HTMLElement>, ...deps: any[]): StableHeightApi {
  interface State extends StableHeightApi {
    lastSize: number
    lastResetDeps: readonly any[]
    reset(): void
    effect: () => void
  }
  
  const stateRef = useRef(null as any as State);
  if (stateRef.current === null) {
    const state = stateRef.current = {
      lastSize: 0,
      lastResetDeps: emptyArray,
      reset() {
        this.lastSize = 0;
        if (ref.current) ref.current.style.minHeight = "";
      },
      resetOn(...deps: readonly any[]) {
        if (!areHookInputsEqual(this.lastResetDeps, deps)) {
          this.reset();
          this.lastResetDeps = deps;
        }
        return this;
      },
      effect: () => {
        if (ref.current) {
          if (ref.current.clientHeight > state.lastSize) {
            state.lastSize = ref.current.clientHeight;
            ref.current.style.minHeight = ref.current.clientHeight + "px";
          }
        }
      }
    }
  }
  const state = stateRef.current;
  
  if (useWindowResized()) {
    state.reset();
  }
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(state.effect, deps);
  
  return state;
}

export type VisibleChildCounter<T extends Element> = readonly [
  /** ref, którego należy użyć na elemencie listy, której liczbę dzieci zliczamy */
  HybridReference<T>,
  /** obliczona liczba widocznych dzieci, undefined znaczy, że aktualnie nie wiemy (można uznać za stan ładowania) */
  number | undefined,
  /** obliczona liczba niewidocznych dzieci */
  number | undefined,
];

export type HybridReference<T> = ((node: T | null) => void) & { current: T | null };

/** Zlicza widocznego dzieci elementu, naturalnie powinien on mieć overflow:hidden
 *  UWAGA: argument selector nie powinien się zmieniać, nie ma logiki śledzącej zmiany
 */
export function useVisibleChildCounter<T extends Element = Element>(selector?: string): VisibleChildCounter<T> {
  interface State {
    ref: (node: T | null) => void
    visCount: number | undefined
    invCount: number | undefined
    mut: MutationObserver | null
    int: IntersectionObserver | null
    vis: Map<T, boolean | null> | null
    onMount: () => () => void
    selector?: string
  }
  
  const signal = useSignal();
  const stateRef = useRef<State | null>(null);
  if (stateRef.current === null) {
    const state: State = {
      visCount: undefined,
      invCount: undefined,
      mut: null,
      int: null,
      vis: null,
      selector,
      onMount: () => () => state.ref(null),
      ref: (el) => {
        // @ts-ignore
        state.ref.current = el;
        
        if (el) {
          const vis = state.vis = new Map()
          const sel = state.selector;
          
          // używamy MutationObserver, aby automatycznie dostawać informację o dzieciach elementu
          // bez pieprzenia się z przypisywaniem im indywidualnych refów
          function onMutation(reports: Iterable<MutationRecord>) {
            let changed = false;
            for (const report of reports) {
              if (report.type === "childList") {
                const { addedNodes, removedNodes } = report;
                for (let i = 0; i < removedNodes.length; i++) {
                  const node = removedNodes[i];
                  if (node instanceof Element && (!sel || node.matches(sel))) {
                    vis.delete(node);
                    int.unobserve(node);
                    changed = true;
                  }
                }
                for (let i = 0; i < addedNodes.length; i++) {
                  const node = addedNodes[i];
                  if (node instanceof Element && (!sel || node.matches(sel))) {
                    vis.set(node, null);
                    int.observe(node);
                    state.visCount = undefined;
                  }
                }
              }
            }
            
            if (changed) {
              state.visCount = undefined;
              scheduleReactUpdate(signal);
            }
          }
          
          function onIntersection(reports: Iterable<IntersectionObserverEntry>) {
            let changed = false;
            for (const report of reports) {
              const child = report.target;
              const prev = vis.get(child);
              const next = report.isIntersecting;
              switch (prev) {
                case true:
                case false:
                case null:
                  if (prev !== next) {
                    vis.set(child, next);
                    changed = true;
                  }
                  if (child instanceof HTMLElement) {
                    if (next)
                      child.dataset.visible = "1";
                    else
                      child.dataset.visible = "0";
                  }
                    
                default:
              }
            }
            
            if (changed) {
              state.visCount = undefined;
              scheduleReactUpdate(signal);
            }
          }
          
          const mut = state.mut = new MutationObserver(onMutation);
          const int = state.int = new IntersectionObserver(onIntersection, { root: el, threshold: 0.05 });
          
          mut.observe(el, { childList: true });
          
          const children = el.children;
          let i = 0; 
          for (; i < children.length; i++) {
            if (!sel || children[i].matches(sel))
              int.observe(children[i]);
          }
          
          if (i > 0)
            state.visCount = state.invCount = undefined;
          else
            state.visCount = state.invCount = 0;
        }
        else {
          if (state.mut) {
            state.mut.disconnect()
            state.mut = null;
          }
          
          if (state.int) {
            state.int.disconnect()
            state.int = null;
          }
          
          if (state.vis) {
            state.vis.clear();
            state.vis = null;
          }
          
          state.visCount = state.invCount = undefined;
        }
      },
    }
    
    Object.defineProperty(state.ref, "current", {
      value: null,
      configurable: false,
      writable: true,
    })
    stateRef.current = state;
  }
  
  const state = stateRef.current;
  
  useEffect(state.onMount, emptyArray);
  
  if (state.visCount === undefined && state.vis) {
    let visCount = 0;
    let invCount = 0;
    for (const [k, v] of state.vis.entries()) {
      switch (v) {
        case true:
          visCount++;
          break;
        case false:
          invCount++;
          break;
        default:
          return [state.ref as HybridReference<T>, state.visCount, state.invCount];
      }
    }
    state.visCount = visCount;
    state.invCount = invCount;
  }
  
  return [state.ref as HybridReference<T>, state.visCount, state.invCount];
}
