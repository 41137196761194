import {useRef, useEffect} from "react";
import {FormField, FormFieldPriv, useNewField} from "./formField";
import {useDerivedState} from "../hooks/derived";
import { emptyArray } from "../constants";

const INVALID = Symbol("INVALID") as any;

/** Mapowanie wartości pola na inny typ:
 *  - downward mapuje wartość otrzymywaną z modelu formularza na naszą
 *  - upward   mapuje naszą wartość na wysyłaną dalej do formularza
 *  
 *  Generowanie używamy tego tak, że deklarujemy sobie nowe pole przy pomocy
 *  useField/useNewField, które ma używać pożądanego typu,
 *  a potem używamy useConversion by utworzyć pole operujące na stringach,
 *  które możemy przekazać do inputa.
 *  
 *  Możemy też tego używać do walidacji.
 *  
 *  Konwersja wartości z modelu formularza na wartość niskopoziomową dla pola formularza,
 *  w przypadku błędu zwracana wartość powinna być poprawna:
 *      function downward(value: U) => [value: D, error: ReactNode?]
 *  
 *  Konwersja wartości wprowadzonej w polu formularza na wartość dla modelu formularza,
 *  w przypadku błędu zwracana wartość jest ignorowana:
 *      function upward(value: D) => [value: U, error: ReactNode?] 
 */
export function useConversion<U, D>(field: FormField<U>,
                                    downward: (value: U) => [D, React.ReactNode?],
                                    upward: (value: D) => [U, React.ReactNode?]): FormField<D> {
  interface State {
    lastOverValue: U
    lastUnderValue: D
    lastDownward: (value: U) => [D, React.ReactNode?]
    lastUpward: (value: D) => [U, React.ReactNode?]
  }
  
  const [initial, initialError] = useDerivedState(applyConversion, downward, field.initial);
  // to wewnętrzne pola ma tę samą nazwę co nadrzędne, ale nie będzie kolidować, bo form=null
  const underlying = useNewField<D>(field.name, null, initial, initialError) as FormFieldPriv<D>;
  const overlying = field as FormFieldPriv<U>;
  
  const ref = useRef(null as any as State);
  if (ref.current === null) {
    underlying._self.parent = overlying; // FIXME: API do tego
    // HAK: nie chemy rejestrować tego w formularzu, więc nie podajemy go do useNewField,
    // ale chcemy wiedzieć jednak jaki to formularz, żeby ustawić atrybut form,  więc ustawiamy ręcznie propsa
    underlying._self.formState = overlying.formState;
    
    //console.log(`CONVERT initial='${initial}' initialError='${initialError || ""}'`)
    ref.current = {
      lastOverValue: field.initial,
      lastUnderValue: underlying.value,
      lastDownward: downward,
      lastUpward: upward,
    }
  }
  else {
    ref.current.lastDownward = downward;
  }
  
  const state = ref.current;
  
  useEffect(() => {
    // overlying onChange - to się generalnie dzieje tylko przy wywołaniu API .set na nadrzędnym polu
    // wcześniej to był useEffect na każdej zmianie overlying.value, ale to dodawało 1 render opóźnienia
    // do zmiany wartości podległego pola, co uniemożliwiało np. zaznaczenie wartości przez HTMLElement.select()
    
    function trickleDown() {
      const newValue = overlying.value;
      
      if (!Object.is(newValue, state.lastOverValue)) {
        if (newValue !== INVALID) {
          const [underValue, underError] = state.lastDownward(overlying.value);
          
          if (underError)
            // FormField.wake ma ochronę przed rekurencją, więc możemy tutaj wywołać
            overlying.setError(underError);
          
          // wynik downward() powinien być zawsze dobrego typu
          // musimy podstawić nową wartość nawet przy błędzie, żeby dało się np. wyczyścić wymagane pole
          underlying.set(underValue);
          state.lastUnderValue = underValue;
        }
        
        state.lastOverValue = newValue;
      }
    }
    overlying._signals.add(trickleDown);
    return () => {
      overlying._signals.delete(trickleDown);
    }
  }, emptyArray);
  
  useEffect(() => {
    // underlying onChange
    if (underlying._error) {
      overlying.set(INVALID, underlying._error);
    }
    else if (!Object.is(underlying.value, state.lastUnderValue) || upward !== state.lastUpward) {
      let [newValue, newError] = upward(underlying.value);
      
      if (newError)
        newValue = INVALID;
      
      overlying.set(newValue, newError);
      
      state.lastOverValue = newValue;
    }
  
    state.lastUnderValue = underlying.value;
    state.lastUpward = upward;
    
    //console.log(`underlying=${underlying.value}/${underlying._error}/${underlying.isValid}, overlying=${overlying.value}/${overlying._error}/${overlying.isValid}`)
  }, [underlying.value, underlying._error, upward]);
  
  return underlying;
}

function applyConversion<U, D>(conversion: (value: U) => [D, React.ReactNode?], input: U): [D, React.ReactNode?] {
  return conversion(input);
}