import React, { ReactNode, useEffect } from "react";
import { emptyArray } from "../constants";

export function KeyDownListenerService({ children }: { children: ReactNode }) {
  useEffect(effect, emptyArray);

  return children;
}

function effect() {
  document.addEventListener("keydown", handleKeydown);
  return () => document.removeEventListener("keydown", handleKeydown);
}

function handleKeydown(event: KeyboardEvent) {
  if (event.key === "Enter" || event.key === " ") {
    const target = event.target as HTMLElement;
    if (!(target instanceof HTMLElement))
      return;
    const clicky = target.classList.contains("clicky");
    
    // Uniknięcie podwójnego wywołania kliknięcia po wciśnięciu Enter
    switch (target.tagName) {
      case "BUTTON":
      case "A":
      case "INPUT":
      case "TEXTAREA":
      case "SELECT":
      case "OPTION":
        if (!clicky)
          return;
    }
    
    if (target.isContentEditable && !clicky)
      return;
    
    switch (target.getAttribute("role")) {
      case "button":
      case "checkbox":
      case "tab":
        break;
        
      case "link":
        // wg ARIA linki nie odpowiadają na spację
        if (event.key === " ")
          return;
        break;
        
      default:
        if (!clicky)
          return;
    }
    
    event.preventDefault();
    event.stopPropagation();
    
    console.log("synthetic click", target); // nie kasować
    target.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        cancelable: true,
        bubbles: true,
      })
    );
  }
}
