import {CapiCache} from "./hooks";
import bootstrap from "../bootstrap";
import * as Folks from "./folks";
import {EnumDictionaryCache} from "./enumDict";

class UserInfoCache extends CapiCache {
  constructor() {
    super(bootstrap.folks.client, 16)
  }
  
  makeCommand = id => new Folks.UserInfo({ user_id: id });
  
  objectIsLoading = id => ({
    domain: bootstrap.domain,
    user_id: id,
    role: "spectator",
    kind: "person",
    label: "Ładowanie...",
  }); 
  
  objectFromError = (id, err) => ({
    user_id: id,
    label: "Dane chwilowo niedostępne",
  });
  
  objectFromResult(id, result) {
    switch (result.status) {
      case 200:
        return result.data;
        
      case 404:
        return {
          user_id: id,
          label: "<Nieistniejący użytkownik>",
          _state: "not_found",
        };
        
      default:
        return this.objectFromError(id);
    }
  }
}

export const USER_INFO = new UserInfoCache();

export const FOLKS_ENUMS = new EnumDictionaryCache(bootstrap.folks.client?.withAuth(110, bootstrap.domain));
