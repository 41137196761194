import React, { createElement } from "react";
import PropTypes from "prop-types";
import {emptyObject} from "../../utils/constants";
import {useSmallScreen} from "../../utils/hooks";

function FlexWrapper0(props, ref) {
  const isMobileOnly = useSmallScreen();
  
  const childProps = filterProps(props);
  
  childProps.style = composeStyle(props, isMobileOnly);
  childProps.ref = ref;
  
  return createElement("div", childProps);
}

function composeStyle(props, isMobileOnly) {
  let styles = { ...(props.style || emptyObject), display: props.inline ? "inline-flex" : "flex" }; // Initial style.

  // Adds props to style.
  for (let i = 0; i < STYLE_PROPS.length; i++) {
    const sp = STYLE_PROPS[i];
    if (props[sp])
      styles[sp] = props[sp];
  }

  if(props.columnOnMobile && isMobileOnly) styles.flexDirection = "column";

  return styles;
}

function filterProps(props) {
  const props2 = { ...props };

  // Delete all props defined in PropTypes.
  for (let i = 0; i < ALL_PROPS.length; i++) {
    const p = ALL_PROPS[i];
    if (PASSTHROUGH_PROPS.indexOf(p) < 0) delete props2[p];
  }

  return props2;
}

const FlexWrapper = React.forwardRef(FlexWrapper0);
FlexWrapper.displayName = "FlexWrapper";

FlexWrapper.styleProps = {
  justifyContent: PropTypes.oneOf([
    "center",
    "safe center",
    "unsafe center",
    "start",
    "end",
    "flex-start",
    "flex-end",
    "left",
    "right",
    "normal",
    "space-between",
    "space-around",
    "space-evenly", // Not supported in Edge 18
    "stretch",
    "inherit",
    "initial",
    "unset"
  ]),
  alignItems: PropTypes.oneOf([
    "baseline",
    "first baseline",
    "last baseline",
    "center",
    "safe center",
    "unsafe center",
    "start",
    "end",
    "flex-start",
    "flex-end",
    "normal",
    "stretch",
    "inherit",
    "initial",
    "unset"
  ]),
  alignContent: PropTypes.oneOf([
    "baseline",
    "first baseline",
    "last baseline",
    "center",
    "safe center",
    "unsafe center",
    "start",
    "end",
    "flex-start",
    "flex-end",
    "normal",
    "space-between",
    "space-around",
    "space-evenly", // Not supported in Edge 18
    "stretch",
    "inherit",
    "initial",
    "unset"
  ]),
  justifyItems: PropTypes.oneOf([
    "auto",
    "baseline",
    "first baseline",
    "last baseline",
    "center",
    "safe center",
    "unsafe center",
    "start",
    "end",
    "flex-start",
    "flex-end",
    "self-start",
    "self-end",
    "left",
    "right",
    "normal",
    "stretch",
    "inherit",
    "initial",
    "unset"
  ]),
  flexDirection: PropTypes.oneOf([
    "row",
    "row-reverse",
    "column",
    "column-reverse"
  ]),
  flexWrap: PropTypes.oneOf(["nowrap", "wrap", "wrap-reverse"]),
  flexFlow: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
};

FlexWrapper.propTypes = {
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  className: PropTypes.string,
  columnOnMobile: PropTypes.bool,
  onClick: PropTypes.any,
  role: PropTypes.string,
  inline: PropTypes.bool,
  ...FlexWrapper.styleProps
};

FlexWrapper.defaultProps = {
  justifyContent: "space-between",
  inline: false
};

const STYLE_PROPS = Object.freeze(Object.keys(FlexWrapper.styleProps));
const ALL_PROPS = Object.freeze(Object.keys(FlexWrapper.propTypes));
const PASSTHROUGH_PROPS = Object.freeze(["className", "children", "role", "onClick"]);

export default FlexWrapper;
