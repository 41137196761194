import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Step from "./Step";

class StepGroup extends React.PureComponent {
  renderSteps = () => {
    const { children, steps, ordered } = this.props;

    if (!steps || steps.length <= 0) return children;

    return steps.map((e, i) => (
      <Step
        number={ordered ? (i + 1).toString() : e.number}
        key={`step_shorthand_${i}`}
        {...e}
      />
    ));
  };

  render() {
    const classes = cx("sowa", "step", "group");

    return <div className={classes}>{this.renderSteps()}</div>;
  }
}

StepGroup.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      icon: PropTypes.string,
      link: PropTypes.bool,
      number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      active: PropTypes.bool,
      completed: PropTypes.bool
    })
  ),
  children: PropTypes.node,
  ordered: PropTypes.bool
};

StepGroup.defaultProps = {
  steps: [],
  ordered: false
};

export default StepGroup;
