import React, { Component } from "react";

import { Icon } from "components/shared";
import PropTypes from "prop-types";
import {doNothing} from "utils/constants";
import cx from "classnames";
import { copyTextToTitle } from "../../utils/dom";

class ExpansionPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.initialActive || false
    };
  }

  handleClick = id => {
    if (this.props.onClick) this.props.onClick(id);
    this.setState((state, props) => {
      return { active: props.active || !state.active };
    });
  };

  render() {
    const {
      content,
      title,
      subtitle,
      description,
      onFocus,
      onBlur,
      compact,
      loading,
      id,
      actions,
      headerAlign,
      wrap,
      active,
      hideChevron,
      agendaHeaderCls,
      agendaIconCls,
    } = this.props;

    const _active = loading
      ? "loading"
      : active !== null
      ? active
        ? "active"
        : ""
      : this.state.active
      ? "active"
      : "";
    const _compact = compact ? "compact" : "";
    const _actions =
      actions.length > 0 ? (
        <div className="expansion-panel__actions">{actions}</div>
      ) : (
        <React.Fragment />
      );

    // Oblicza flex-basis dla tytułu i opisu w %
    const getHeaderAlign = i => {
      return !headerAlign[i] || headerAlign[i] <= 0
        ? !headerAlign[Number(!i)]
          ? "initial"
          : `${100 - headerAlign[Number(!i)]}%`
        : `${headerAlign[i]}%`;
    };

    return (
      <div className={`expansion-panel ${_active} ${_compact}`} key={id}>
        <div
          className={cx(`expansion-panel__header`, {"expansion-panel--nowrap": !wrap}, {"expansion-panel__header--agenda" : agendaHeaderCls})}
          tabIndex="0"
          onFocus={() => onFocus()}
          onBlur={() => onBlur()}
          onClick={() => this.handleClick(id)}
          role="button"
          aria-expanded={this.state.active}
        >
          <div className="expansion-panel__header-summary">
            <div style={{flexBasis: getHeaderAlign(0), maxWidth: getHeaderAlign(0)}}>
              <div className={cx(`expansion-panel__header-title`, {"expansion-panel__header-titleWithSubtitle": subtitle})} onMouseEnter={copyTextToTitle}>{title}</div>
              {subtitle && <div className="expansion-panel__header-subtitle" onMouseEnter={copyTextToTitle}>{subtitle}</div>}
            </div>
            {description && <div className="expansion-panel__header-description" style={{flexBasis: getHeaderAlign(1), maxWidth: getHeaderAlign(1)}} onMouseEnter={copyTextToTitle}>
              {description}
            </div>}
            <div className={cx(`expansion-panel__header-icon`, {"expansion-panel__header-icon--agenda" : agendaIconCls})} aria-hidden="true">
              {hideChevron ? null : <Icon name="angle down" />}
            </div>
          </div>
        </div>
        <div className="expansion-panel__content">
          {content}
          {_actions}
          <div style={{ clear: "both" }} />
        </div>
      </div>
    );
  }
}

export default ExpansionPanel;

ExpansionPanel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.any,

  compact: PropTypes.bool,
  initialActive: PropTypes.bool,
  active: PropTypes.bool,
  loading: PropTypes.bool,
  hideChevron: PropTypes.bool,

  onClick: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  actions: PropTypes.array,
  wrap: PropTypes.bool,
  headerAlign: PropTypes.arrayOf(PropTypes.number)
};

ExpansionPanel.defaultProps = {
  active: null,
  compact: false,
  wrap: true,
  initialActive: false,
  title: "",
  description: "",
  content: [],
  actions: [],
  headerAlign: [33.33, -1],
  hideChevron: false,

  onFocus: doNothing,
  onClick: doNothing,
  onBlur: doNothing,
};
