import React from "react";
import PropTypes from "prop-types";

import { translate } from 'utils/language';
import Button from "../Button";
import { useForkedState } from "utils/hooks";
import { Overlay } from "../../layout/overlays";

const FullScreenDialog = ({ trigger, title, content, fluid = false, actions=[], header = true, ...props }) => {
  const [open, setOpen] = useForkedState(v => v, props.open);

  const handleOpen = () => props.open === undefined && setOpen(true);
  const handleClose = () => {
    props.open === undefined && setOpen(false);
    props.onClose && props.onClose();
  };

  return (
    <React.Fragment>
      {trigger && <span onClick={handleOpen}>{trigger}</span>}

      {open ? ( <Overlay type="modal" doClose={handleClose}>
        <div role="dialog" className={`sowa fullscreen-dialog fullscreen-dialog--open ${fluid ? "fullscreen-dialog--fluid" : ""}`} aria-labelledby="dialogTitle">
          <div className="fullscreen-dialog-container">

            {/* === Header === */}
            {header && (
              <div className="fullscreen-dialog-header">
                <div className="fullscreen-dialog-header__container">
                  <h3 className="fullscreen-dialog-header__title" id="dialogTitle">{title}</h3>
                  <Button
                    content={translate("unique.close")}
                    variant="text"
                    onClick={handleClose}
                    className="fullscreen-dialog-header__close"
                    />
                 </div>
               </div>
            )}
   

            {/* === Toolbar === */}
            {actions.length > 0 && 
            <div className="fullscreen-dialog-toolbar">
              <div className="fullscreen-dialog-toolbar__container">
                {actions.map(e => React.isValidElement(e) ? e : <Button {...e} />) }
              </div>
            </div>}

              {/* === Content === */}
              <div className={`fullscreen-dialog-content ${header ? "" : "fullscreen-dialog-content--no-header"}`}>
                <div className="fullscreen-dialog-content__container">
                  {content}
                </div>
              </div>
            </div>
          </div>
        </Overlay>
      ) : null}
    </React.Fragment>
  );
};

const Separator = () => <div className="fullscreen-dialog-toolbar__separator" />;

FullScreenDialog.Separator = Separator;

FullScreenDialog.propTypes = {
  open: PropTypes.bool,
  trigger: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  actions: PropTypes.array,
  fluid: PropTypes.bool,
  header: PropTypes.bool,
  onClose: PropTypes.func,
};

export default FullScreenDialog;