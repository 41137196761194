import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Tag } from "components/shared";

import MenuItem from "../MenuItem";
import { tran } from "utils/language";

const ShortcutsList = props => {
  const { shortcuts, appSettings } = props;

  const displayShortcuts =
    shortcuts.length > 0 && appSettings.enableShortcuts !== false;

  if (!displayShortcuts) {
    return null;
  }

  return (
    <MenuItem
      buttonProps={{
        icon: "keyboard outline",
        className: "top-bar__shortcuts-btn",
        title: tran("pages.list.tools.params.complexEditor.shortcuts")
      }}
    >
      <div className="topbar-list">
        {shortcuts.map((shortcut, id) => (
          <div className="top-bar__shortcuts topbar-list__item" key={id}>
            <div className="topbar-list__item-content">
              {shortcut.description}
            </div>
            <div className="topbar-list__item-button">
              <Tag badge compact inline style={{ margin: 0 }}>
                {typeof shortcut.key === "string" ? (
                  <pre>{shortcut.key}</pre>
                ) : (
                  shortcut.key
                )}
              </Tag>
            </div>
          </div>
        ))}
      </div>
    </MenuItem>
  );
};

ShortcutsList.propTypes = {
  appSettings: PropTypes.object.isRequired,
  // ustawienia użytkownika
  shortcuts: PropTypes.array.isRequired
  // lista dostępnych skrótow klawiszowych
};

const READ = state => ({
  appSettings: state.utils.appSettings,
  shortcuts: state.utils.shortcutList
});

export default connect(READ)(ShortcutsList);
