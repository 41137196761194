import React, { useState } from "react";
import cx from "classnames";

import { useBind, useTouchDevice } from "../../../utils/hooks";
import { TouchLink } from "../../shared/TouchLink";
import { emptyArray } from "../../../utils/constants";
import { tran } from "../../../utils/language";
import { Icon } from "../../shared";

export type TekaTileLinkProps = {
  to: string
  className?: string
  badges?: readonly Badge[]
  imageUrl?: string
  name: string /** nazwa dokumentu */
  slideout?: React.ReactNode
  description?: React.ReactNode
  buttons?: React.ReactNode
  style?: React.CSSProperties
  innerStyle?: React.CSSProperties
}

export function TekaTileLink({
                               to,
                               className,
                               badges,
                               imageUrl,
                               name,
                               slideout,
                               description,
                               buttons,
                               style,
                               innerStyle
                             }: TekaTileLinkProps) {
  const [download, setDownload] = useState(false);
  const setDownloadTrue = useBind(setDownload, true);
  
  let slideout0 = EMPTY_SLIDEOUT;
  
  if (download) {
    if (slideout) {
      slideout0 = <div className="teka-tile__slideout gradient filled">
        {slideout}
      </div>
    }
  }
  
  return <TouchLink
    to={to}
    className={cx(className, "teka-tile")}
    onMouseEnter={setDownloadTrue}
    onFocus={setDownloadTrue}
    role="listitem" //button albo listitem
    style={style}
    aria-label={name}
    tabIndex={0} //musi być, ale iOS wykrył element i go focusował (TouchLink.tsx onFocus)
  >
    <div className="teka-tile__main" style={innerStyle}>
      <TileThumbnail imageUrl={imageUrl}/>
      {slideout0}
      <TileBadges badges={badges || emptyArray}/>
      {buttons && <div className="teka-tile__buttons">{buttons}</div>}
    </div>
    
    <div className="grid-item__info grid-item__info--document">
      {description}
    </div>
  </TouchLink>;
}

export type TekaTileWithButtonsProps = {
  className?: string
  imageUrl?: string
  buttons: React.ReactNode
  style?: React.CSSProperties
  innerStyle?: React.CSSProperties
}

export function TekaTileWithButtons({ className, imageUrl, buttons, style, innerStyle }: TekaTileWithButtonsProps) {
  return <div
    className={cx(className, "teka-tile")}
    role="listitem"
    style={style}
    tabIndex={0}
  >
    <div className="teka-tile__main" style={innerStyle}>
      <TileThumbnail imageUrl={imageUrl}/>
      {buttons && <div className="teka-tile__buttons">{buttons}</div>}
    </div>
  </div>;
}

type Badge = {
  type: "warning" | "default",
  text: React.ReactNode,
  icon: string,
  key?: string,
}
const EMPTY_SLIDEOUT = <div className="teka-tile__item empty"/>
// skalowanie obrazów przed pobraniem, na ekranach o niskim DPI skalujemy 2x w dół
const DZIUPLA_SCALE = window.devicePixelRatio <= 1 ? "?imdc=2" : "";

function TileBadges({ badges }: { badges: readonly Badge[] }) {
  const isTouchDevice = useTouchDevice();
  
  return <div className="teka-tile__badge-wrapper">
    {badges && badges.map((badge, i) => {
      return <div className={`teka-tile__badge ${badge.type === "warning" ? "warning" : ""} `} key={badge.key || ("" + i)}>
        <Icon name={badge.icon}/>
        {badge.text}
      </div>
    })}
    
    {isTouchDevice && (
      <div className="teka-tile__badge info show-on-focus">
        <Icon name="info-circle"/>
        {tran("teka.explorer.badge.touch")}
      </div>
    )}
  </div>
}

const TileThumbnail = React.memo(function TileThumbnail({ imageUrl }: { imageUrl?: string }) {
  return imageUrl
    ? <div className="teka-tile__thumbnail" style={{ backgroundImage: `url('${imageUrl}${DZIUPLA_SCALE}')` }}/>
    : null
});