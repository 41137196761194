import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Responsive, Dropdown, Button } from "components/shared";

import Tag from "./Tag";

class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props.active,
      breakPoint: 600
    };
  }

  componentDidMount() {
    setTimeout(() => this.handleResize(), 1000);

    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    if (!this.desktopRef) return;

    if (this.desktopRef && isOverflown(this.desktopRef)) {
      this.setState({ breakPoint: window.innerWidth });
    }
  };

  setActive = id => {
    this.setState({ active: id });
  };

  /**
   * @description Exclude props which have been already used.
   * @returns {object}
   */
  sortProps() {
    const propTypes = Object.keys(Menu.propTypes),
      props = { ...this.props };

    // Delete all props applied in classes etc..
    for (var i = 0; i < propTypes.length; i++) delete props[propTypes[i]];

    return props;
  }

  renderDesktop = () => {
    const { items, align, type, className } = this.props;

    const classes = cx(
      "sowa",
      "menu-bar",
      fillKey("type", type),
      align,
      className
    );

    return (
      <div
        ref={e => (this.desktopRef = e)}
        {...this.sortProps()}
        className={classes}
      >
        {this.renderItems(items)}
      </div>
    );
  };

  renderMobile = () => {
    const { items, type } = this.props;
    const { active } = this.state;

    if (items.length <= 0) return <React.Fragment />;

    let _active = active;

    const _items = items.map((e, i) => {
      if (e.active) _active = i;
      return {
        value: i,
        text: e.text,
        onClick: () => {
          e.onClick && e.onClick();
          this.props.onChange && this.props.onChange(i);
        }
      };
    });

    let activeEl = _items[_active];
    if (!activeEl) activeEl = _items[0];

    if(_items.length === 1) 
      return <Button size={type === "secondary" ? "small" : "medium"}
              inverted
              variant="contained"        
              ref={e => (this.mobileRef = e)}
              {...this.props.dropdownProps}>
                {activeEl.text}
              </Button>;

    return (
      <Dropdown
        trigger={
          <Button
            size={type === "secondary" ? "small" : "medium"}
            inverted
            icon="chevron-down"
            variant="contained"
          >
            {activeEl.text}
          </Button>
        }
        menuPosition="right"
        value={_active}
        onChange={value => {
          this.setActive(value);
        }}
        ref={e => (this.mobileRef = e)}
        options={_items}
        {...this.props.dropdownProps}
      />
    );
  };

  renderResponsive = () => {
    return (
      <React.Fragment>
        <Responsive maxWidth={this.state.breakPoint}>
          {this.renderMobile()}
        </Responsive>
        <Responsive minWidth={this.state.breakPoint}>
          {this.renderDesktop()}
        </Responsive>
      </React.Fragment>
    );
  };

  renderItems = items => {
    return items.map((e, i) => {
      const isActive =
        e.active !== undefined ? e.active : this.props.active === i;

      return (
        <Tag
          {...e}
          onClick={() => {
            e.onClick && e.onClick();
            this.props.onChange && this.props.onChange(i);
          }}
          id={i}
          key={i}
          highlight={this.props.highlightActive && isActive}
          underline={this.props.underlineActive && isActive}
          shadow={this.props.shadow}
          active={isActive}
        />
      );
    });
  };

  render() {
    return this.renderResponsive();
  }
}

export default Menu;

Menu.propTypes = {
  active: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func
    })
  ),
  align: PropTypes.oneOf(["left", "center", "right"]),
  highlightActive: PropTypes.bool,
  underlineActive: PropTypes.bool,
  shadow: PropTypes.bool,
  type: PropTypes.oneOf(["primary", "secondary"]),
  onChange: PropTypes.func,
  dropdownProps: PropTypes.object
};

Menu.defaultProps = {
  items: [],
  active: 0,
  align: "center",
  highlightActive: false,
  underlineActive: false,
  shadow: true,
  type: "primary"
};

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight * 1.8 /* wykrywamy zawinięcie */ ||
    element.scrollWidth > element.clientWidth
  );
}

/**
 * @description Combine key and value returning a class.
 * @param {string} key
 * @param {string} value
 * @returns {string}
 */
const fillKey = (key, value) => {
  return `${key}__${value}`;
};
