import React from "react";
import PropTypes from "prop-types";

import Checkbox from "./Checkbox";

const CheckboxGroup = props => {
  const { options, values, onChange, className, ...rest } = props;

  const handleClick = option => {
    if (onChange) {
      const values = [...props.values];
      const index = values.indexOf(option.value);
      if (index === -1) {
        values.push(option.value);
      } else {
        values.splice(index, 1);
      }
      onChange(values);
    }
  };

  return (
    <div className={`checkbox-group ${className}`} {...rest}>
      {options.map(option => (
        <Checkbox
          key={option.value}
          {...option}
          checked={values.indexOf(option.value) !== -1}
          onClick={() => handleClick(option)}
        />
      ))}
    </div>
  );
};

CheckboxGroup.defaultProps = {
  className: ""
};

CheckboxGroup.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string,
      disabled: PropTypes.bool
    })
  ).isRequired
};

export default CheckboxGroup;
