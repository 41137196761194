import { sessionClientByPath } from "capi/appclient";
import * as Teka from "capi/teka";

import store from "store";

/**
 * Pobranie informacji o użytkowniku z Teki
 * @param {String} user_id
 */
export function fetchUserTeka(user_id) {
  const Client = sessionClientByPath(store, "teka");
  return Client.executeSingle(new Teka.UserInfo({ user_id }));
}

export function editTekaUser(user_id, changes) {
  const Client = sessionClientByPath(store, "teka");
  return Client.executeSingle(new Teka.UserChange(user_id, changes));
}

export function fetchDocumentData(document_id) {
  return sessionClientByPath(store, "teka").executeSingle(
    new Teka.DocumentInfo(document_id)
  );
}

/**
 *
 * @param {Array} documents Lista identyfikatorów dokumentów
 */
export function fetchDocumentsList(documents) {
  const Client = sessionClientByPath(store, "teka");
  return Client.execute(...documents.map(doc => new Teka.DocumentInfo(doc)));
}

export function deleteDocument(id) {
  return sessionClientByPath(store, "teka").executeSingle(
    new Teka.DocumentDelete(id)
  );
}

export function documentAttachmentEdit(attachment_id, kind, selected) {
  return sessionClientByPath(store, "teka").executeSingle(
    new Teka.AttachmentEdit(attachment_id, { kind, selected })
  );
}

export function fetchDocumentStats(document_id, leases_from, leases_to) {
  return sessionClientByPath(store, "teka").executeSingle(
    new Teka.DocumentStats(document_id, leases_from, leases_to)
  );
}

export function applicationNew(kind, document_id, message) {
  return sessionClientByPath(store, "teka").executeSingle(
    new Teka.ApplicationNew(kind, { document_id, message })
  );
}

export function applicationInfo(application_id) {
  return sessionClientByPath(store, "teka").executeSingle(
    new Teka.ApplicationInfo(application_id)
  );
}

export function applicationList(kind, volume, offset, document_id) {
  return sessionClientByPath(store, "teka").executeSingle(
    new Teka.ApplicationList(kind, { volume, offset, document_id })
  );
}

export function applicationQueue(kind, volume, offset, document_id) {
  return sessionClientByPath(store, "teka").executeSingle(
    new Teka.ApplicationQueue(kind, { volume, offset, document_id })
  );
}

export function applicationEdit(
  application_id,
  message,
  verdict,
  claim,
  state
) {
  return sessionClientByPath(store, "teka").executeSingle(
    new Teka.ApplicationEdit(application_id, { message, verdict, claim, state })
  );
}

export function fetchLicenses() {
  const Client = sessionClientByPath(store, "teka");
  return Client.executeSingle(new Teka.LicenseList());
}

export function reportCount(report_name, date_from, date_to, top_count) {
  const Client = sessionClientByPath(store, "teka");
  return Client.executeSingle(new Teka.ReportCount(report_name, date_from, date_to, top_count));
}

export function reportLeases(user_id, date_from, date_to) {
  const Client = sessionClientByPath(store, "teka");
  return Client.executeSingle(new Teka.ReportLeases(user_id, date_from, date_to));
}
