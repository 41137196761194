import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import reducer from "./reducers/reducer";

import { APP_HIDDEN, APP_SHOWN, APP_LOAD, ACTIVITY_CHANGE, CHECK_SESSION } from "./sagas/types";

export const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const middleware = [sagaMiddleware];

if (window.bootstrap.devel) {
  middleware.unshift(store => next => action => {
    console.log("DISPATCH", (new Date()).toISOString(), action.type, action)
    return next(action);
  });
}

const store = createStore(
  reducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(...middleware))
);

// Monitorowanie czy jesteśmy aktywną kartą w przeglądarce
(() => {
  const SHOWN = Object.freeze({ type: APP_SHOWN });
  const HIDDEN = Object.freeze({ type: APP_HIDDEN });
  
  let debouncer = null;
  let visible = false;
  
  document.addEventListener("visibilitychange", () => {
    const visibleNow = document.visibilityState === "visible";
    if (visibleNow !== visible) {
      visible = visibleNow;
      clearTimeout(debouncer);
      debouncer = setTimeout(store.dispatch, 100, visibleNow ? SHOWN : HIDDEN);
    }
  })
})();

// Wykrywanie aktywności użytkownika
// Z dokładnością do minuty, bo co minutę instalujemy event handlery wykrywające interakcje ze stroną
// (Nie chcemy żeby chodziły w tle cały czas.)
(() => {
  const ACTIVE = Object.freeze({ type: ACTIVITY_CHANGE, payload: true });
  const INACTIVE = Object.freeze({ type: ACTIVITY_CHANGE, payload: false });
  const SUSPEND = Object.freeze({ type: ACTIVITY_CHANGE, payload: null });
  const CHECK = Object.freeze({ type: CHECK_SESSION });
  const listenerOptions = { capture: true };
  
  let timer = null;
  let visible = document.visibilityState === "visible";
  let activity = null;
  let trapSet = false;
  let minutesInactive = 0;

  function trap() {
    // "pułapka" to event handler wykrywający aktywność
    trapSet = false;
    minutesInactive = 0;
    unbind();
    if (!activity) {
      activity = true;
      store.dispatch(ACTIVE);
    }
  }
  
  function unbind() {
    window.removeEventListener("mousemove", trap, listenerOptions);
    window.removeEventListener("keydown", trap, listenerOptions);
    window.removeEventListener("touchstart", trap, listenerOptions);
  }
  
  function enable() {
    activity = true;
    minutesInactive = 0;
    store.dispatch(ACTIVE);
    if (timer === null) {
      timer = setInterval(() => {
        minutesInactive++;
        if (minutesInactive > 1 && activity) {
          activity = false;
          store.dispatch(INACTIVE);
        }
        else
          store.dispatch(CHECK);
  
        if (!trapSet) {
          window.addEventListener("mousemove", trap, listenerOptions);
          window.addEventListener("keydown", trap, listenerOptions);
          window.addEventListener("touchstart", trap, listenerOptions);
        }
      }, 60e3);
    }
  }
  
  function disable() {
    clearInterval(timer);
    timer = null;
    activity = null;
    unbind();
    store.dispatch(SUSPEND);
  }
  
  document.addEventListener("visibilitychange", () => {
    const visibleNow = document.visibilityState === "visible";
    if (visibleNow !== visible) {
      visible = visibleNow;
      if (visibleNow)
        enable()
      else
        disable()
    }
  });
  
  if (visible)
    enable();
  else
    disable();
})();


(() => {
  const LOAD = Object.freeze({ type: APP_LOAD });
  window.addEventListener("load", () => {
    store.dispatch(LOAD);
  })
})();

export default store;
