import React from "react";
import PropTypes from "prop-types";

import Heading from "./Heading";
import Item from "./Item";

const ColumnView = props => {
  const {
    collapse,
    nameBold,
    items,
    children,
    itemsAlign,
    className,
    ...rest
  } = props;

  const nameBoldClass = nameBold ? "name-bold" : "";
  const collapseClass = collapse ? "collapse" : "";
  const alignClass =
    ["top", "bottom"].indexOf(itemsAlign) > -1 ? `align-${itemsAlign}` : "";

  return (
    <div
      className={`column-view ${nameBoldClass} ${collapseClass} ${alignClass} ${className}`}
      {...rest}
    >
      {items.length > 0
        ? items.map((item, id) => (
            <Item key={item.key || id} name={item[0]} value={item[1]} />
          ))
        : children}
    </div>
  );
};

ColumnView.defaultProps = {
  items: [],
  collapse: true,
  nameBold: true,
  itemsAlign: "center",
  className: ""
};

ColumnView.propTypes = {
  items: PropTypes.array,
  collapse: PropTypes.bool,
  // true -> flex-direcion: column na małych ekranach
  nameBold: PropTypes.bool,
  // pogrubienie
  itemsAlign: PropTypes.oneOf(["top", "center", "bottom"]),
  // wyrównanie w pionie dla każdego wiersza
  children: PropTypes.node,
};

ColumnView.Heading = Heading;
ColumnView.Item = Item;

export default ColumnView;
