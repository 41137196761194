import React, { Component } from "react";
import PropTypes from "prop-types";

class Responsive extends Component {
  state = {
    width: window.innerWidth
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = e => {
    this.setState({ width: window.innerWidth });
    if (this.props.onUpdate) this.props.onUpdate(e);
  };

  normalizeProps = () => {
    const props = { ...this.props };

    delete props.maxWidth;
    delete props.minWidth;
    delete props.as;

    return props;
  };

  static onlyMobile = { minWidth: 320, maxWidth: 767 };
  static onlyTablet = { minWidth: 768, maxWidth: 991 };
  static onlyComputer = { minWidth: 992 };
  static onlyLargeScreen = { minWidth: 1200, maxWidth: 1919 };
  static onlyWidescreen = { minWidth: 1920 };

  render() {
    const { minWidth, maxWidth, as } = this.props;
    const { width } = this.state;

    if (!canRender(minWidth, maxWidth, width)) return <React.Fragment />;

    const ElementType = as;

    return (
      <ElementType {...this.normalizeProps()}>
        {this.props.children}
      </ElementType>
    );
  }
}

function canRender(min, max, value) {
  if (!value) return false;

  if (min) {
    const minWidth =
      typeof min === String ? Number.parseInt(min.split("px")[0], 10) : min;
    if (value <= minWidth) return false;
  }

  if (max) {
    const maxWidth =
      typeof max === String ? Number.parseInt(max.split("px")[0], 10) : max;
    if (value > maxWidth) return false;
  }

  return true;
}

Responsive.propTypes = {
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onUpdate: PropTypes.func,
  children: PropTypes.node.isRequired,
  getWidth: PropTypes.func,
  as: PropTypes.any
};

Responsive.defaultProps = {
  as: React.Fragment
};

export default Responsive;
