import React from "react";

import isEqual from "lodash.isequal";

import { Param, ParamDef } from 'utils/params/types';
import { emptyArray } from "../constants";
import { tran } from "../language";
import { signalMarkers } from "../hooks";

/** Podstawia słowa w PIDzie parametru wzorcowego by utworzyć nazwę parametru prostego */
export function paramNameFromPid(pid: string, ...substitutions: string[]) {
  return pid.replace(/#\d*/g, $0 => substitutions.shift() ?? $0)
}

export function paramDefsByPid(paramDefs: readonly ParamDef[]): Map<string, ParamDef> {
  return new Map(paramDefs.map(paramDef => [paramDef.pid, paramDef]));
}

export function domainParamFromDef(paramDef: ParamDef, ...substitutions: readonly string[]): Param {
  const name = paramNameFromPid(paramDef.pid, ...substitutions);
  return {
    name,
    description: paramDef.description,
    pid: paramDef.pid,
    type: paramDef.type,
    initial: getValueFromScopes(paramDef, name, DOMAIN),
    options: getOptionsFromParam(paramDef),
    default: getValueFromScopes(paramDef, name, DEFAULT_GLOBAL),
    widget: paramDef.widget,
  };
}

export function globalParamFromDef(paramDef: ParamDef, ...substitutions: readonly string[]): Param {
  const name = paramNameFromPid(paramDef.pid, ...substitutions);
  return {
    name,
    description: paramDef.description,
    pid: paramDef.pid,
    type: paramDef.type,
    initial: getValueFromScopes(paramDef, name, GLOBAL),
    options: getOptionsFromParam(paramDef),
    default: getValueFromScopes(paramDef, name, DEFAULT),
    widget: paramDef.widget,
  };
}

export function domainParamFromDefWithDefault(paramDef: ParamDef, substitutions: readonly string[], defaultSubstitutions: readonly string[]): Param {
  if (isEqual(substitutions, defaultSubstitutions))
    return domainParamFromDef(paramDef, ...substitutions);
  return paramFromDefWithDefault(paramDef, substitutions, defaultSubstitutions);
}

export function globalParamFromDefWithDefault(paramDef: ParamDef, substitutions: readonly string[], defaultSubstitutions: readonly string[]): Param {
  if (isEqual(substitutions, defaultSubstitutions))
    return globalParamFromDef(paramDef, ...substitutions);
  return paramFromDefWithDefault(paramDef, substitutions, defaultSubstitutions);
}

/** Nie jestem jeszcze pewien czy to tak winno działać */
function paramFromDefWithDefault(paramDef: ParamDef, substitutions: readonly string[], defaultSubstitutions: readonly string[]): Param {
  const name = paramNameFromPid(paramDef.pid, ...substitutions);
  const def  = paramNameFromPid(paramDef.pid, ...defaultSubstitutions);
  return {
    name,
    description: paramDef.description,
    pid: paramDef.pid,
    type: paramDef.type,
    initial: getValueFromScopes(paramDef, name, ALL),
    options: getOptionsFromParam(paramDef),
    default: getValueFromScopes(paramDef, def, ALL),
    widget: paramDef.widget,
  };
}

type Scope = "domain" | "global" | "default";

const DEFAULT = Object.freeze(["default"] as Scope[]);
const GLOBAL = Object.freeze(["global"] as Scope[]);
const DOMAIN  = Object.freeze(["domain"] as Scope[]);
const DEFAULT_GLOBAL = Object.freeze(["default", "global"] as Scope[]);
const ALL = Object.freeze(["default", "global", "domain"] as Scope[]);

function getValueFromScopes(paramDef: ParamDef, name: string, scopes: readonly Scope[]): unknown {
  // serwer zwraca scope'y w kolejności malejącej szczegółowości, więc pierwsza wartość wygrywa
  
  const values = paramDef.values;
  for (let i = 0; i < values.length; i++) {
    const scope = values[i];
    if (scopes.includes(scope.scope as Scope)) {
      const val = scope.values[name];
      if (val !== undefined)
        return val;
    }
  }
  return undefined;
}

const NULL_OPT: [unknown, React.ReactNode] = [null, tran("paramInput.null")];
const BOOL_OPTS: readonly [unknown, React.ReactNode][] = [[false, tran("unique.no")], [true, tran("unique.yes")]];
const NBOOL_OPTS: readonly [unknown, React.ReactNode][] = [NULL_OPT, ...BOOL_OPTS];

function getOptionsFromParam(paramDef: ParamDef): readonly [unknown, React.ReactNode][] {
  let result: readonly [unknown, React.ReactNode][] = emptyArray;
  
  const T = paramDef.type;
  switch (T.kind) {
    case "enum":
      result = T.list || emptyArray;
      if (T.null)
        result = [NULL_OPT, ...result];
      break;
      
    case "bool":
      if (T.null)
        result = NBOOL_OPTS;
      else
        result = BOOL_OPTS;
  }
  
  return result;
}

export function signalParamMarkers(url: string, paramsSet: { [name: string]: { pid: string } }) {
  signalMarkers(
    Object.entries(paramsSet)
      .flatMap(([name, { pid }]) =>
        pid === name
          ? `param:${name}@${url}`
          : [`param:${name}@${url}`, `param:${pid}@${url}`]
      )
  );
}
