import React from "react";
import PropTypes from "prop-types";

import { Button, Blankholder } from "components/shared";

import { translate } from "utils/language";
import bootstrap from 'bootstrap';

import { Overlay } from "../../layout/overlays";

const useNeutral = true; // bootstrap.WZUW

const Loader = props => {
  if (!props.active)
    return null;
  
  const cls = props.inline ? "inline" : "";
  const loaderCls = useNeutral ? "loaderNeutral" : "loaderLogo";
  const loaderPulseCls = useNeutral ? "" : "loaderPulse pulse";
  // <img src={sowaImg} alt="" className="loaderImg"/>
  
  const content = (
    <div className={`loaderBox ${cls}`} onClick={props.page ? props.pageProps && props.pageProps.onClickOutside : undefined}>
      {props.error ? (
        <Blankholder
          icon="exclamation circle"
          title={translate("loading.error")}
          button={
            <Button
              onClick={props.retry}
              content={translate("loading.retry")}
            />
          }
        />
      ) : (
        <React.Fragment>
          <div className={loaderPulseCls}>
            <div className={loaderCls} />
          </div>
          <p className="loaderText">
            {props.timedOut
              ? translate("loading.timedOut") + "..."
              : props.text}
          </p>
          {props.timedOut ? (
            <Button
              onClick={props.retry}
              content={translate("loading.retry")}
            />
          ) : null}
        </React.Fragment>
      )}
    </div>
  );

  if (props.page) {
    const handler = props.pageProps && props.pageProps.onClickOutside;
    return (
      <Overlay type="modal" doClose={handler}>
        {content}
      </Overlay>
    );
  }
  
  return <React.Fragment>{content}</React.Fragment>;
};

Loader.defaultProps = {
  text: translate("unique.loading") + "...",
  active: true,
  error: null,
  timedOut: false,
  page: false
};

Loader.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  // tekst wyświetlany pod kropeczkami
  active: PropTypes.bool,
  // wyświetlac czy nie
  inline: PropTypes.bool,
  // false - wyświetlanie na środku kontenera rodzica'
  error: PropTypes.instanceOf(Error),
  // błąd przekazywany z react-loadable
  timedOut: PropTypes.bool,
  // true -> ładowanie trwa długo
  retry: PropTypes.func,

  page: PropTypes.bool,
  // Wyświetla dimmer z tyłu loadera
  pageProps: PropTypes.object
  // Propsy dla dimmera
};

export default Loader;
