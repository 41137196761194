import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import bootstrap from "bootstrap";
import PropTypes from "prop-types";

import { tran } from "utils/language";
import { isStaff } from "utils/roles";

import cx from 'classnames';
import { useDialog } from "components/shared/Dialog";
import { AppInfoDialog } from "./AppInfoDialog";
import { useSowaParams } from "../../../utils/params/hooks";
import { isLoaded } from "../../../capi/hooks";
import { useDerivedState } from "../../../utils/hooks";
import { emptyArray } from "../../../utils/constants";

/**
 * @description Main Footer component. 
 */
const Footer = ({ displayFooter, currentUser }) => {
  const aboutDialog = useDialog(AppInfoDialog);

  if (bootstrap.no_footer || !displayFooter) return null;

  // WZUW specific configuration.
  if (bootstrap.WZUW)
    return (
      <footer className="footer">
        <div className="footer__content">
          <FooterLinks noApplications={true} />
          <FooterCapital />
          <FooterVersion />
        </div>
      </footer>
    );

  const noApplications = bootstrap.links ? bootstrap.links.length <= 0 || !isStaff(currentUser.role) : true;

  return (
    <React.Fragment>
      <footer className="footer">
        {isStaff(currentUser.role) ? <FooterApplications /> : null}
        <FooterLinks noApplications={noApplications} openAboutModal={aboutDialog.open} />
        <FooterCapital />
      </footer>
    </React.Fragment>
  );
};

/**
 * @description Returns logos of Fundusze Europejskie.
 * Zakomentowane ze względu na to, że potrzebowaliśmy prop isContrast.
 */
const FooterCapitalConst = (() => {
  if (!bootstrap.WZUW) return null; // WZUW perferences
  const links = bootstrap.wzuwLinks;
  if (!links) return null;

  return (
    <div className={"footer__capital"}>
      {links.map(({url, img_h, img_v, name, margin}) => (
        <a key={url} target="_blank" rel='noopener noreferrer' href={url}>
          <img alt={name} className="h" src={img_h} style={{ margin }}/>
          <img alt={name} className="v" src={img_v} style={{ margin }}/>
        </a>
      ))} 
    </div>
  );
})();
const FooterCapital = () => FooterCapitalConst;


/**
 * @description Returns version
 */
const FooterVersion = () => {
  if (!(bootstrap.displayVersion && process.env.WS_VER))
    return null;

  return <p className={cx("footer__version")} style={bootstrap.WZUW ? {color: "var(--footerInv)"} : null}>{process.env.WS_VER}.{process.env.WS_BLD}</p>;
};

/**
 * @description Returns logos of applications configured in bootstrap.
 */
const FooterApplications = () => {
  if(!bootstrap.links || bootstrap.links.length <= 0) return null;
  const items = bootstrap.links;

  return (
    <div className="footer__container">
      <div>
        {items
          .filter(item => item.url)
          .map(item => (
            <div className="footer__item" key={item.name}>
              <a target="_blank" rel="noopener noreferrer" href={item.url}>
                <img className="footer__img" src={item.img} alt={item.name} />
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};

/**
 * @description Returns useful links.
 */
const FooterLinks = ({noApplications, openAboutModal}) => {
  const sowaParamsRq = useSowaParams(!bootstrap.WZUW && !hasRegulationsFile ? bootstrap.sowa.firstCatalogue?.cat_id : undefined)
  const helpLinks = useDerivedState(buildLinks, sowaParamsRq, openAboutModal);
  
  if (helpLinks.length <= 0) return null;

  return (
    <div className="footer__info">
      <div className={cx("footer__info-links", { "no-applications": noApplications })}>
        {helpLinks.map(({ path, newTab, name, ...rest }, i) => {
          const isExternal = path && (path.startsWith("http") || path.startsWith("//"))

          const elProps = isExternal
            ? { href: path, target: newTab ? "_blank" : "", children: name, ...rest }
            : { to: path, children: name, ...rest };
          const El = React.createElement(path ? (isExternal ? "a" : Link) : "span", elProps);

          return (
            <React.Fragment key={`help_links_${i}`}>
              {i !== 0 && <div style={{ margin: "0 0.2em" }}>•</div>}
              {El}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

const hasRegulationsFile = !!(bootstrap.files && bootstrap.files.terms);

function buildLinks(sowaParamsRq, openAboutModal) {
  const links = [...(bootstrap.textLinks || emptyArray)];

  const regulationsUrl = isLoaded(sowaParamsRq)
    ? sowaParamsRq.data["opac.registration.default.regulations"]
    : "";

  if (regulationsUrl) {
    links.push({
      name: tran("pages.list.regulations.title"),
      path: regulationsUrl,
      newTab: true,
    });
  }
  else if (hasRegulationsFile) {
    links.push({
      name: tran("pages.list.regulations.title"),
      path: '/terms'
    });
  }
  
  if (!bootstrap.noPrivacyPolicy) {
    links.push({
      name: tran("pages.list.privacy-policy.title"),
      path: "/privacy-policy",
    });
  }
  
  if (!bootstrap.WZUW) {
    links.push({
      name: tran("footer.appInfo.title"),
      onClick: openAboutModal,
    });
  }
  
  return links;
}

Footer.propTypes = {
  displayFooter: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired
};

const READ = state => ({
  displayFooter: state.utils.displayFooter,
  currentUser: state.currentUser.folks
});

export default connect(READ)(Footer);
