import React, {useRef} from "react";

import {PositionSync, useSmallScreen, useWindowResized} from "../../../utils/hooks";
import {OptionList} from "./OptionList";
import {HorizontalPos, VerticalPos} from "../../../utils/hooks/positionSync";
import { DropdownOption, DropdownOptions } from "./types";
import {RefSetter} from "../../../utils/hooks/util";
import {Overlay} from "../../layout/overlays";
import {OverlayType} from "../../layout/overlays/OverlayService";
import { OptionListTree } from "./OptionListTree";

/** Komponent odpowiedzialny za renderowanie listy dropdowna pod podanym elementem */
export function DropdownMenu(props: {
  children: (ref?: RefSetter<HTMLElement>) => JSX.Element
  open: boolean
  selectedOption?: DropdownOption
  
  variant?: "free" | "attached"
  horizontalPos?: HorizontalPos
  verticalPos?: VerticalPos
  
  onClose?: () => void
  onClick?: (option: DropdownOption) => void
  
  options: DropdownOptions
  layout?: 'tree' | 'list'
}) {
  // TODO: wersja mobilna bez czarnej magii, tylko z modalną wycentrowaną listą opcji
  const isSmallScreen = useSmallScreen();
  
  if (isSmallScreen) {
    return <Translate>
      {(observeRef, positionRef) => (<>
        {props.children(observeRef)}
        <OptionListOverlay
          type="modal"
          variant="free"
          open={props.open}
          options={props.options}
          selectedOption={props.selectedOption}
          onClick={props.onClick}
          onClose={props.onClose}
          positionRef={positionRef}
          layout={props.layout}
        />
      </>)}
    </Translate>;
  }
  
  return <PositionSync
    active={props.open}
    horizontalPos={props.horizontalPos || "auto-overlap"}
    verticalPos={props.verticalPos || "auto"}
    width={props.layout !== 'tree'}
    height={false}
    onClose={props.onClose}
  >
    {(observeRef, positionRef) => (<>
      {props.children(observeRef)}
      <OptionListOverlay
        type="dropdown"
        variant={props.variant}
        open={props.open}
        options={props.options}
        selectedOption={props.selectedOption}
        onClick={props.onClick}
        onClose={props.onClose}
        positionRef={positionRef}
        layout={props.layout}
      />
    </>)}
  </PositionSync>
};

/** Komponent odpowiedzialny za renderowanie listy dropdowna w nakładce */
function OptionListOverlay(props: {
  type: OverlayType
  open: boolean
  selectedOption?: DropdownOption
  
  variant?: "free" | "attached"
  
  onClose?: () => void
  onClick?: (option: DropdownOption) => void
  
  options: DropdownOptions
  
  positionRef: RefSetter<HTMLElement>
  layout?: 'tree' | 'list'
}) {
  if (!props.open) {
    props.positionRef && props.positionRef(null);
    return null;
  }
  
  return (
    <Overlay type={props.type} doClose={props.onClose}>
      {props.layout === "tree" ? (
        <OptionListTree
          variant={props.variant}
          options={props.options}
          selectedOption={props.selectedOption}
          onClick={props.onClick}
          outerRef={props.positionRef}
        />
      ) : (
        <OptionList
          variant={props.variant}
          options={props.options}
          selectedOption={props.selectedOption}
          onClick={props.onClick}
          outerRef={props.positionRef}
        />
      )}
    </Overlay>
  );
}

/** Komponent ustawiający translację dla wycentrowanego elementu na podstawie pozycji innego elementu */
function Translate(props: {
  children: (observeRef: RefSetter<HTMLElement>, positionRef: RefSetter<HTMLElement>) => JSX.Element
}) {
  // FIXME: To nie jest najlepiej zrobione, bo może nie reagować na zmiany wysokości potomka
  // Nie reaguje też na zmiany kotwicy, ale tym się akurat nie martwimy, bo chcemy stałą pozycję 
  
  const anchor = useRef(null as HTMLElement | null);
  const target = useRef(null as HTMLElement | null);
  
  if (useWindowResized()) {
    setTarget(target.current, true);
  }
  
  return props.children(setAnchor, setTarget);
  
  function setAnchor(element: HTMLElement | null) {
    anchor.current = element;
  }
  
  function setTarget(element: HTMLElement | null, force?: boolean) {
    if (!force && target.current === element && anchor.current !== null)
      return;
    
    target.current = element;
    
    if (anchor.current && element) {
      const rect = anchor.current.getBoundingClientRect();
      const self = element.getBoundingClientRect();
      
      if ((rect.top + rect.bottom) / 2 < window.innerHeight / 2) {
        // górna krawędź
        // początkowa pozycja: window.innerHeight/2 - self.height/2
        // pożądana pozycja  : rect.top
        // minimalna wartość : 10
        const adj = window.innerHeight / 2 - self.height / 2;
        element.style.transform = `translateY(${Math.max(rect.top - adj, 10 - adj)}px)`
      }
      else {
        // dolna krawędź
        // początkowa pozycja: window.innerHeight/2 + self.height/2
        // pożądana pozycja  : rect.bottom
        // maksymalna wartość: window.innerHeight - 10
        const adj = window.innerHeight / 2 + self.height / 2;
        element.style.transform = `translateY(${Math.min(rect.bottom - adj, window.innerHeight - 10 - adj)}px)`
      }
    }
  }
}