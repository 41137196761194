import {useRef} from "react";

import {doNothing} from "../constants";

import {RefSetter} from "./util";
import {usePositionObserver} from "./position";
import {useIntersectionObserver} from "./intersection";

// Ogólnie nie podoba mi się to wcale, mimo że sam to pisałem, bo za wiele polegania na
// bezpośrednich manipulacjach DOMu, ale powinno to być znacznie wydajniejsze niż React.
// TODO: Może przenieść rzeczywiste wartości horizontalPos i verticalPos do reacta zamiast ustawiać w DOMie?

/** Pozycje wybranej krawędzi elementu poruszanego, w relacji do wybranej krawędzi elementu obserwowanego. */
export type VerticalPos = "bottom-to-top" | "top-to-bottom" | "top-to-top" | "bottom-to-bottom" | "auto" | "auto-overlap";
export type HorizontalPos = "left-to-left" | "left-to-right" | "right-to-right" | "right-to-left" | "auto" | "auto-overlap";

// eslint-disable-next-line @typescript-eslint/no-redeclare
export interface PositionSync {
  observeRef: RefSetter<HTMLElement>
  positionRef: RefSetter<HTMLElement>
}

interface PositionSyncPriv extends PositionSync {
  _target: HTMLElement | null
  _rect: DOMRectReadOnly | null
  _observing: HTMLElement | null
  _update: () => void
  _updateX: (rect: DOMRectReadOnly, element: HTMLElement, ref: HTMLElement | null, stretch: boolean) => void
  _updateY: (rect: DOMRectReadOnly, element: HTMLElement, ref: HTMLElement | null, stretch: boolean) => void
  _onClose?: () => void
  _onIntersect: (intersection: IntersectionObserverEntry) => any
}

/**
 * Pozycjonowanie jednego elementu na podstawie drugiego.
 *
 * @param active        czy synchronizacja jest włączona?
 * @param horizontalPos rodzaj synchronizacji poziomej
 * @param verticalPos   rodzaj synchronizacji pionowej
 * @param width         czy synchronizować szerokość
 * @param height        czy synchronizować wysokość
 * @return              obiekt zawierający dwie funkcje do przekazania jako ref do JSX
 */
export function usePositionSync(active: boolean, horizontalPos: HorizontalPos, verticalPos: VerticalPos, width: boolean = true, height: boolean = false, onClose?: () => void): PositionSync {
  const observe = usePositionObserver(active, onObservation);

  const ref = useRef(null as any as PositionSyncPriv)

  if (ref.current === null) {
    const state = ref.current = {
      _target: null,
      _rect: null,
      _observing: null,
      _updateX: doNothing,
      _updateY: doNothing,
      _onClose: onClose,
      observeRef: observe,
      positionRef: (ref) => {
        state._target = ref;
        state._update();
      },
      _update() {
        const rect = this._rect, element = this._target;
        if (rect && element) {
          this._updateX(rect, element, state._observing, width);
          this._updateY(rect, element, state._observing, height);
        }
      },
      _onIntersect: intersection => {
        if (intersection.intersectionRatio < 0.9) {
          state._onClose && state._onClose();
        }
      },
    } as PositionSyncPriv;
  }

  const state = ref.current;
  state._updateX = UPDATERS_X[horizontalPos] || doNothing;
  state._updateY = UPDATERS_Y[verticalPos] || doNothing;
  state._onClose = onClose;

  const intersectRef = useIntersectionObserver(active, state._onIntersect);

  return state;

  function onObservation(rect: DOMRectReadOnly, element: HTMLElement) {
    intersectRef(element);
    const state = ref.current;
    state._rect = rect;
    state._observing = element;
    state._update();
  }
}

const UPDATERS_Y: { [key: string]: (rect: DOMRectReadOnly, element: HTMLElement, ref: HTMLElement | null, stretch: boolean) => void } = {
  "auto": (rect, element, ref, stretch) => {
    const delegate = (rect.top > window.innerHeight - rect.bottom)
      ? UPDATERS_Y["bottom-to-top"]
      : UPDATERS_Y["top-to-bottom"];
    return delegate(rect, element, ref, stretch);
  },
  "auto-overlap": (rect, element, ref, stretch) => {
    const delegate = (rect.bottom > window.innerHeight - rect.top)
      ? UPDATERS_Y["bottom-to-bottom"]
      : UPDATERS_Y["top-to-top"];
    return delegate(rect, element, ref, stretch);
  },
  "bottom-to-top": (rect, element, ref, stretch) => {
    element.style.top = "";
    element.style.bottom = (window.innerHeight - rect.top) + "px";
    if (stretch) element.style.height = rect.height + "px";
    else element.style.maxHeight = rect.top + "px";
    element.setAttribute("vert-pos", "bottom-to-top");
    ref && ref.setAttribute("vert-pos", "bottom-to-top");
  },
  "bottom-to-bottom": (rect, element, ref, stretch) => {
    element.style.top = "";
    element.style.bottom = (window.innerHeight - rect.bottom) + "px";
    if (stretch) element.style.height = rect.height + "px";
    else element.style.maxHeight = rect.bottom + "px";
    element.setAttribute("vert-pos", "bottom-to-bottom");
    ref && ref.setAttribute("vert-pos", "bottom-to-bottom");
  },
  "top-to-top": (rect, element, ref, stretch) => {
    element.style.top = rect.top + "px";
    element.style.bottom = "";
    if (stretch) element.style.height = rect.height + "px";
    else element.style.maxHeight = (window.innerHeight - rect.top) + "px";
    element.setAttribute("vert-pos", "top-to-top");
    ref && ref.setAttribute("vert-pos", "top-to-top");
  },
  "top-to-bottom": (rect, element, ref, stretch) => {
    element.style.top = rect.bottom + "px";
    element.style.bottom = "";
    if (stretch) element.style.height = rect.height + "px";
    else element.style.maxHeight = (window.innerHeight - rect.bottom) + "px";
    element.setAttribute("vert-pos", "top-to-bottom");
    ref && ref.setAttribute("vert-pos", "top-to-bottom");
  },
}

const UPDATERS_X: { [key: string]: (rect: DOMRectReadOnly, element: HTMLElement, ref: HTMLElement | null, stretch: boolean) => void } = {
  "auto": (rect, element, ref, stretch) => {
    const delegate = (rect.left > window.innerWidth - rect.right)
      ? UPDATERS_X["right-to-left"]
      : UPDATERS_X["left-to-right"];
    return delegate(rect, element, ref, stretch);
  },
  "auto-overlap": (rect, element, ref, stretch) => {
    const delegate = (rect.right > window.innerWidth - rect.left)
      ? UPDATERS_X["right-to-right"]
      : UPDATERS_X["left-to-left"];
    return delegate(rect, element, ref, stretch);
  },
  "left-to-left": (rect, element, ref, stretch) => {
    element.style.right = "";
    element.style.left = rect.left + "px";
    if (stretch) {
      element.style.minWidth = rect.width + "px";
      element.style.maxWidth = rect.width + "px";
    }
    else element.style.maxWidth = (window.innerWidth - rect.left) + "px";
    element.setAttribute("horz-pos", "left-to-left");
    ref && ref.setAttribute("horz-pos", "left-to-left");
  },
  "left-to-right": (rect, element, ref, stretch) => {
    element.style.right = "";
    element.style.left = rect.right + "px";
    if (stretch) {
      element.style.minWidth = rect.width + "px";
      element.style.maxWidth = rect.width + "px";
    }
    else element.style.maxWidth = (window.innerWidth - rect.right) + "px";
    element.setAttribute("horz-pos", "left-to-right");
    ref && ref.setAttribute("horz-pos", "left-to-right");
  },
  "right-to-left": (rect, element, ref, stretch) => {
    element.style.right = (window.innerWidth - rect.left) + "px";
    element.style.left = "";
    if (stretch) {
      element.style.minWidth = rect.width + "px";
      element.style.maxWidth = rect.width + "px";
    }
    else element.style.maxWidth = rect.left + "px";
    element.setAttribute("horz-pos", "right-to-left");
    ref && ref.setAttribute("horz-pos", "right-to-left");
  },
  "right-to-right": (rect, element, ref, stretch) => {
    element.style.right = (window.innerWidth - rect.right) + "px";
    element.style.left = "";
    if (stretch) {
      element.style.minWidth = rect.width + "px";
      element.style.maxWidth = rect.width + "px";
    }
    else element.style.maxWidth = rect.right + "px";
    element.setAttribute("horz-pos", "right-to-right");
    ref && ref.setAttribute("horz-pos", "right-to-right");
  },
}

/** Wersja komponentowa do użytku w starych komponentach klasowych */
export function PositionSync(props: { active: boolean, horizontalPos: HorizontalPos, verticalPos: VerticalPos, width?: boolean, height?: boolean, onClose?: () => void, children: (observeRef: RefSetter<HTMLElement>, positionRef: RefSetter<HTMLElement>) => JSX.Element }) {
  const { observeRef, positionRef } = usePositionSync(props.active, props.horizontalPos, props.verticalPos, props.width ?? true, props.height || false, props.onClose);
  return props.children(observeRef, positionRef);
}
