import React from "react";
import { Modal } from "components/shared";

import { translate } from "utils/language";

const ErrorPage = props => {
  return (
    <Modal
      error={true}
      headerIcon="exclamation-circle"
      content={
        <React.Fragment>
          <p>{translate("appError.info")}</p>
        </React.Fragment>
      }
      buttons={[
        {
          content: translate("unique.refresh"),
          onClick: () => {
            window.location.reload();
          },
          variant: "text",
          color: "error"
        }
      ]}
    />
  );
};

export default ErrorPage;
