import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import Shortcuts from "components/common/Shortcuts";
import { LogoutDialog } from "./LogoutDialog";
import { LoginDialog } from "components/special-pages/Login/LoginDialog";
import Settings from "components/special-pages/Settings";
import { SessionClock, ToastSessionClock } from "./SessionClock";
import Navigation from "./Navigation/index";
import LanguageMenu from "./LanguageMenu";
import ContrastSwitch from "./ContrastSwitch";
import Notifications from "./Notifications";
import ShortcutsList from "./ShortcutsList";
import { HamburgerMenu } from "./HamburgerMenu";
import ProfileLink from "./ProfileLink";
import PageTitle from "./PageTitle";
import { useSmallScreen, useSmallOrMediumScreen, useMediaQuery } from "utils/hooks";
import { useDialog } from "components/shared/Dialog";
import { lazyComponent } from "../../../utils/pageLoading";

export function TopBar() {
  const isLandscape = useMediaQuery("screen only and (orientation: landscape)");
  const isMobileMedium = useSmallOrMediumScreen();
  const isMobile = useSmallScreen();
  const [windowOpen, setWindowOpen] = useState<null | string>(null);
  const isUserLoggedIn = useSelector(isUserLoggedInSelector);

  const loginDialog = useDialog(LoginDialog);
  const logoutDialog = useDialog(LogoutDialog);

  /**
   * @param {"login" | "logout" | "settings"} window 
   */
  const handleWindowToggle = (window: string) => {
    setWindowOpen(currentWindow => currentWindow === window ? null : window);
  }

  function handleShortcuts(action: string) {
    if (action === "logout") {
      if (isUserLoggedIn) {
        logoutDialog.open();
      } else {
        loginDialog.open();
      }
    }
  }

  const canRenderLowerBar = !isMobile && (!isMobileMedium && !isLandscape);
    
  return <Shortcuts global handler={handleShortcuts} className="top-bar">
    <TopBarUpper toggleWindow={handleWindowToggle} showMobileNav={!canRenderLowerBar} />
    {canRenderLowerBar && <TopBarLower />}
    
    {windowOpen === "settings"  && <Settings close={() => handleWindowToggle("settings")} />}
  </Shortcuts>
}

/**
 * Górna część paska nawigacji.
 */

type TopBarUpperProps = {
  toggleWindow: (window: string) => void,
  showMobileNav: boolean,
};

const TopBarUpper = React.memo(({ toggleWindow, showMobileNav }: TopBarUpperProps) => {
  return <>
    <div className="top-bar__upper">
      <div className="top-bar__upper-container">
        <PageTitle />
        <div className="top-bar__menu-box">
          <ProfileLink />
          {showMobileNav ? <ToastSessionClock /> : null}
          {showMobileNav ? <TopBarMainControls /> : null}
          <HamburgerMenu
            openSettingsWindow={() => toggleWindow("settings")}
          />
        </div>
      </div>
    </div>
  </>
});

/**
 * Dolna część paska nawigacji.
 */
const TopBarLower = React.memo(() => {
  return <>
    <div className="top-bar__lower">
      <div className="top-bar__lower-container">
        <Navigation />
        <div className="top-bar__items-box">
          <SessionClock />
          <AdminSearchConditional />{/* TODO: portal? */}
          <ShortcutsList />
          <TopBarMainControls />
        </div>
      </div>
    </div>
  </>
});

const TopBarMainControls = React.memo(() => {
  return <>
    <ContrastSwitch />
    <LanguageMenu />
    <Notifications />
  </>
})

function AdminSearchConditional() {
  const location = useLocation();
  const isUserLoggedIn = useSelector(isUserLoggedInSelector);
  
  if (location.pathname.startsWith("/admin") && isUserLoggedIn)
    return <AdminSearchLoader/>
  else
    return null;
}

const AdminSearchLoader = lazyComponent(() => import(/* webpackChunkName: "adm" */ "components/pages/Admin/AdminSearch"));

const isUserLoggedInSelector = (state: any) => state.session.isUserLoggedIn;
