import React, { useEffect, useRef } from "react";
import isEqual from "lodash.isequal";
import { CapiClient, CapiCommand } from "../../capi/client";
import { LOADING_RESULT, NOT_INSTALLED_RESULT, notYet, Request } from "../../capi/hooks";
import {
  registerUpdateMarker, signalMarkers,
  unregisterUpdateMarker,
  useDedup,
  useDerivedState
} from "../hooks";
import { doNothing, emptyArray, emptyMap, emptyObject, emptySet, tuple } from "../constants";
import bootstrap from "../../bootstrap";
import { TekaParamTypes } from "./TekaParamTypes";
import { useVersionedSignal } from "../hooks/other";
import { Dictionary } from "../types";
import { escapeRegExp } from "../globalFunctions";
import { FolksParamTypes } from "./FolksParamTypes";
import { SowaParamTypes } from "./SowaParamTypes";
import { signalRegistry, SignalRegistry } from "../signalRegistry";

// UWAGA: jeśli żądamy np. `foo_#` oraz `foo_bar` to będą cache'owane i odświeżane
//        niezależnie

// TODO: Wsparcie notYet w nazwach parametrów?

/** Czas ważności wartości parametru, po tym czasie zostanie odświeżona przy kolejnej okazji */
const VALID_DURATION_MS = 10 * 60 * 1000;
/** Błędy chcemy częściej odświeżać */
const ERROR_VALID_DURATION_MS = 10 * 1000;
/** Czas ważności metadanych klienta, jeśli przez ten czas nie został odczytany z jego cache'a
 *  żaden parametr, to kasujemy tego cache'a.
 *  Robimy to, bo nie wiemy, kiedy obiekt klienta przestaje być używany. WeakRef jest zbyt świeży. */
const GC_AFTER = 3 * VALID_DURATION_MS; 

export type StrixParams<P extends {}> = Request<Partial<P>>

type ParamCache = Map<string, [number, any, Error?]>

type ClientState = {
  refs: number
  client: CapiClient
  paramCache: ParamCache
  signalRegistry: SignalRegistry<string>
  timer: number
  queue: Set<string>
  fetching: Set<string>
  fetchingAdd: (pid: string) => void
  fetchingSub: (pid: string) => void
  command: string,
  gcTimer: number
  destroyed: boolean
  onParamsUpdated: (keys: readonly string[]) => void
}

//

/** To jest niskopoziomowa funkcja, używamy tylko w szczególnych miejscach, jak edytor parametrów */
export function useStrixParams<P extends Dictionary>(client: CapiClient | undefined, command: string, durationMs: number, pids: readonly (keyof Pick<P, string>)[]): StrixParams<P> {
  const [version, hookSignal] = useVersionedSignal();
  
  // useEffect nie lubi, jak się długość tablicy zależności zmienia, więc nie możemy użyć pids bezpośrednio,
  // więc musimy zapewnić, że ma stabilną tożsamość
  const stablePids = useDedup(pids);
  
  let state: ClientState = CLIENTS.get(client || emptyObject)!;
  if (!state && client) {
    // Jak przepływają notyfikacje:
    //  1. ktoś aktualizuje parametr i ParamsSet wywołuje signalMarkers
    //  2. ClientState zostaje obudzone bo nasłuchuje na markerach parametrów
    //  3. ClientState pobrało nowe wartości i emituje lokalne sygnały
    //  4. Zamontowane hooki zostają obudzone i mogą odczytać nowe wartości
    
    // UWAGA: Parameter `command` nie może się zmieniać w wywołaniu bez zmiany również `client`
    state = createClientState(client, command);
    
    CLIENTS.set(client, state);
  }
  
  useEffect(client && stablePids.length > 0 ? () => {
    if (state.destroyed) {
      // bardzo rzadko może się zdarzyć, że zniszczenie nastąpiło między renderowaniem a efektem,
      // w tym wypadku wymuszamy re-renderowanie i otrzymanie nowego ClientState (bo CLIENTS zniszczonych nie zawiera)
      hookSignal();
      return;
    }
      
    if (state.gcTimer) {
      clearTimeout(state.gcTimer);
      state.gcTimer = 0;
    }
    state.refs++;
    
    // pobierane są wszystkie zarejestrowane parametry, można by to zmienić rozbudowując signalRegistry
    // o przekazywanie sygnalizowanych kluczy do callbacka...
    const clientSignal = () => queueParamFetch(state, stablePids);
    const signalRegistry = state.signalRegistry;
    for (const pid of stablePids) {
      const key = `param:${pid}@${client.url}`
      // <2> ClientState musi dostawać notyfikacje o zmienionych parametrach
      registerUpdateMarker(key, clientSignal);
      // musimy też zarejestrować notyfikację na wypadek aktualizacji, gdy żaden komponent nie ma zamontowanego danego parametru
      registerUpdateMarker(key, state.onParamsUpdated);
      // <4> Hook musi dostawać notyfikacje o aktualizacji cache'a
      signalRegistry.register(pid, hookSignal);
    }
    
    return () => {
      signalRegistry.unregister(hookSignal);
      unregisterUpdateMarker(clientSignal);
      state.refs--;
      if (state.refs === 0) {
        state.gcTimer && clearTimeout(state.gcTimer);
        state.gcTimer = setTimeout(gcClientState, GC_AFTER, state);
      }
    }
  } : doNothing, [client, stablePids])
  
  return useDerivedState(resultFromCache, version, client && state, stablePids, durationMs);
}

const CLIENTS = new Map<CapiClient | typeof emptyObject, ClientState>();

//

function createClientState(client: CapiClient, command: string) {
  const fetching = new Set<string>();
  const state: ClientState = {
    refs: 0,
    client,
    paramCache: new Map(),
    signalRegistry: signalRegistry(), // tutaj jest sygnalizowane zakończenie pobierania i aktualizacja paramCache'a
    timer: 0,
    queue: new Set(),
    fetching,
    fetchingAdd: fetching.add.bind(fetching),
    fetchingSub: fetching.delete.bind(fetching),
    command: command,
    gcTimer: 0,
    destroyed: false,
    onParamsUpdated: keys => setTimeout(onParamsUpdated, 0, state, keys),
  }
  return state;
}

//

function resultFromCache<P extends Dictionary>(version: number, state: ClientState | undefined, pids: readonly string[], durationMs: number): StrixParams<any> {
  if (!state)
    return NOT_INSTALLED_RESULT;
  
  if (state.destroyed)
    return LOADING_RESULT;
  
  if (pids.length === 0)
    return resultProto; // pusty wynik
  
  const cache = state.paramCache;
  
  for (const pid of pids) {
    const p = cache.get(pid);
    if (!p) {
      // zwracamy wszystkie żądane parametry albo żaden
      // to jest efekt uboczny, ale nie powinien przeszkadzać react'owi, bo nie aktualizuje stanu żadnego komponentu
      queueParamFetch(state, pids);
      return LOADING_RESULT;
    }
    else if (p[2]) {
      // przy pierwszym renderowaniu próbujemy zaktualizować błędne odczyty, lub gdy wygasną
      if (version === 0 || p[0] <= Date.now() - Math.min(durationMs, ERROR_VALID_DURATION_MS))
        queueParamFetch(state, pids);
      
      const err = p[2] as any;
      return Object.freeze({
        _state: "error",
        status: err.capiStatus || 500,
        reason: err.capiReason,
        message: err.enduserMessage || "Wystąpił problem w komunikacji z serwerem.",
        _refresh,
      });
    }
  }
  
  const url = state.client.url;
  function _refresh() {
    signalMarkers(pids.map(pid => `param:${pid}@${url}`));
  }
  
  const expired = Date.now() - durationMs;
  const data = {} as any;
  const result = {
    __proto__: resultProto,
    data,
    _refresh
  } as any;
  let hasAll = true;
  for (const pid of pids) {
    const [ts, v] = cache.get(pid)!;
    if (v instanceof Map) {
      // jako osobny obiekt mapy byłoby czasem wygodniej dostawać wynik, ale trudniej to otypować,
      // więc rozpłaszczamy naszą mapę do obiektu wyniku (tak samo, jak to serwer robi)
      for (const [name, val] of v.entries())
        data[name] = val;
    }
    else {
      data[pid] = v;
    }
    
    hasAll &&= ts > expired;
  }
  
  // Hmm, aktualnie jak coś pobieramy, to wszystko pobieramy
  // czy chcemy pobierać tylko zmienione parametry?
  // Tworzyłoby to dodatkową okazję na niespójności
  
  if (!hasAll)
    queueParamFetch(state, pids);
  
  return result;
}

const resultProto = Object.freeze({
  _state: "ok",
  status: 200,
  data: emptyObject,
  _refresh: doNothing,
})

//

function queueParamFetch(state: ClientState, pids?: readonly string[]) {
  if (pids)
    for (const pid of pids) {
      if (!state.fetching.has(pid)) // optymizacja: nie kolejkujemy takich, co już są w trakcie ładowania
        state.queue.add(pid);
    }
  
  if (state.timer)
    return;
  
  state.timer = window.setTimeout(() => {
    state.timer = 0;
    const queue = [...state.queue];
    queue.forEach(state.fetchingAdd);
    state.queue.clear();
    
    state.client.execute(new ParamsGet(state.command, queue))
      .finally(() => {
        queue.forEach(state.fetchingSub);
      })
      .then(([paramsGet]) => {
        paramsGet.ensure(200);
  
        const now = Date.now();
        const cache = state.paramCache;
        const changedPids: string[] = [];
        const data = paramsGet.result.data;
        const detailsOk = !!data["#details"]; // nowy czy stary protokół?
        //delete data["#details"];
        
        for (const pid of queue) {
          const old = cache.get(pid);
          if (pid.includes("#")) {
            const wasInCache = old && (old[1] instanceof Map);
            let changed = !wasInCache; // musimy coś wyemitować za pierwszym razem
            const oldresults = wasInCache ? old[1] : emptyMap;
            const subresults = new Map<string, any>();
            const re = detailsOk ? undefined as never : pidToRegex(pid)!;
            for (const name in data) {
              let newsub = data[name];
              if (newsub && ((detailsOk && newsub.pid === pid) || (!detailsOk && re.test(name)))) {
                if (detailsOk)
                  newsub = newsub.val;
                
                // kopiujemy stare wartości do nowej mapy, jeśli są identyczne,
                // tożsamość mapy nie ma znaczenia,
                // ale wartości tak, bo są zwracane do kodu klienckiego
                const oldsub = oldresults.get(name);
                const different = (oldsub === undefined) || !isEqual(oldsub, newsub);
                changed ||= different;
                subresults.set(name, different ? newsub : oldsub);
              }
            }
            if (changed || oldresults.size !== subresults.size)
              changedPids.push(pid);
            cache.set(pid, [now, subresults]);
          }
          else {
            let newValue = data[pid];
            if (detailsOk && newValue)
              newValue = newValue.val;
            if (!old || old[2] || !isEqual(old[1], newValue)) {
              // wartości nie było albo był błąd, albo się zmieniła
              changedPids.push(pid);
              cache.set(pid, [now, newValue]);
            }
            else {
              // odświeżamy czas wygasania
              old[0] = now;
            }
          }
        }
        
        return changedPids;
      })
      .catch(err => {
        console.error(err);
        const now = Date.now();
        const cache = state.paramCache;
        for (const pid of queue) {
          cache.set(pid, [now, undefined, err]);
        }
        return queue;
      })
      .then(changed => {
        state.signalRegistry.signal(changed);
      });
    
  }, 0);
}

//

// Jak wartość parametru siedzi w cache'u, ale żaden komponent nie używa aktualnie tej wartości
// to sygnał `clientSignal` nie zinwaliduje/odświeży tej wartości.
// Więc tutaj nasłuchujemy i takim nieużywanym parametrom oznaczamy wartości jako wygasłe.
function onParamsUpdated(state: ClientState, keys: readonly string[]) {
  const { paramCache, signalRegistry, client } = state;
  const { url: sfx } = client;
  const pfx = "param:";
  const p = pfx.length, s = sfx.length + 1 /* @url */;
  for (const key of keys) {
    if (key.startsWith(pfx) && key.endsWith(sfx)) {
      const pid = key.substring(p, key.length - s);
      const row = paramCache.get(pid);
      if (row && row[0] && !signalRegistry.hasSignalsFor(pid)) {
        row[0] = 0; // wygaśnięcie, nie ma potrzeby sygnalizować zmiany, bo nic nie nasłuchuje
      }
    }
  }
}

//

function gcClientState(state: ClientState) {
  state.destroyed = true;
  CLIENTS.delete(state.client);
  unregisterUpdateMarker(state.onParamsUpdated);
}

//

function pidToRegex(pid: string) {
  const parts = pid.split(/(#\d*)/);
  if (parts.length === 1)
    return undefined;
  else
    return new RegExp(pid.split(/(#\d*)/).map(part => {
      if (part.startsWith("#"))
        return ".*?";
      else
        return escapeRegExp(part);
    }).join(""));
}

class ParamsGet extends CapiCommand {
  constructor(command: string, params: string[]) {
    super([command, [params, emptyArray], DETAILS]);
  }
}
const DETAILS = { details: 1 };

//

export type FolksParamId = keyof FolksParamTypes
export type FolksParamIds = readonly FolksParamId[]
export type FolksParams<A extends FolksParamIds> = Request<SelectParams<FolksParamTypes, A>>

export function useFolksParams<A extends FolksParamIds>(...pids: A): FolksParams<A> {
  return useFolksParamsEx<A>(pids);
}

export function useFolksParamsEx<A extends FolksParamIds>(pids: A, emptyOk?: boolean): FolksParams<A> {
  return useStrixParams<FolksParamTypes>(emptyOk || pids.length > 0 ? bootstrap.folks.domainClient : undefined, "folksParamsGet", VALID_DURATION_MS, pids) as any;
}

//

export type TekaParamId = keyof TekaParamTypes
export type TekaParamIds = readonly TekaParamId[]
export type TekaParams<A extends TekaParamIds> = Request<SelectParams<TekaParamTypes, A>>

export function useTekaParams<A extends TekaParamIds>(...pids: A): TekaParams<A> {
  return useTekaParamsEx(pids);
}

export function useTekaParamsEx<A extends TekaParamIds>(pids: A, emptyOk?: boolean): TekaParams<A> {
  return useStrixParams<TekaParamTypes>(emptyOk || pids.length > 0 ? bootstrap.teka.client : undefined, "tekaParamsGet", VALID_DURATION_MS, pids) as any;
}

//

export type SowaParamId = keyof SowaParamTypes
export type SowaParamIds = readonly SowaParamId[]
export type SowaParams<A extends SowaParamIds> = Request<SelectParams<SowaParamTypes, A>>

export function useSowaParams<A extends SowaParamIds>(catId: string | undefined | typeof notYet, ...pids: A): SowaParams<A> {
  const cat = typeof catId === "string" ? bootstrap.sowa.cataloguesById[catId] : undefined;
  return useStrixParams<SowaParamTypes>(cat && cat.client, "sowaParamsGet", VALID_DURATION_MS, pids) as any;
}

//

/** filtruje definicje parametrów do obecnych na liście */
type SelectParams<T, A extends readonly (keyof T)[]> = {
  [K in WidenParamIds<keyof T, A[number]>]: T[K]
}

/** zmienia typ K postaci "foo_#" na typ postaci `foo_${string}` należący do Id */
type WidenParamIds<Id, K extends Id> =
  K extends `${infer L}#${infer M}#${infer R}`
    ? Extract<Id, `${L}${string}${M}${string}${R}`> // to działa jak cast z `${L}${string}${M}${string}${R}` na Id
    : K extends `${infer L}#${infer R}`
      ? Extract<Id, `${L}${string}${R}`>
      : K;

//

if (false) { // test kompilacji
  function Component() {
    const foo = tuple("edition.id", "lease.by_kind.#.duration", "lease.by_pool.physical.enabled");
    const x = useTekaParams(...foo).data!;
    const x1: string = x["edition.id"]
    const x2: number = x["lease.by_kind.request.duration"]
    const x3: boolean = x["lease.by_pool.physical.enabled"]
    // @ts-expect-error
    const x4 = x["edition.features.access_mgmt"]
    // @ts-expect-error
    const x5 = x["lease.by_pool.virtual.enabled"]
  }
}
