import { Button, DateTimePicker } from 'components/shared';
import MaskedInput from 'components/shared/MaskedInput';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { tran } from 'utils/language';
import { PopupPosition } from '../DateTimePicker/DateTimePicker';

export type MaskedDateInputProps = {
  label?: string,
  required?: boolean,
  mask?: string,
  fluid?: boolean,
  value: string,
  field?: any
  onChange?: (value: string) => void,
  disabled?: boolean,
  allowInvalidDate?: boolean;
  position?: PopupPosition;
};

export default function MaskedDateInput(props: MaskedDateInputProps) {
  const [lastValidDate, setLastValidDate] = useState<string>("");

  const dateIsValid = props.value?.length > 0 && moment(props.value, "YYYY-MM-DD").isValid();

  useEffect(() => {
    dateIsValid && setLastValidDate(props.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value])

  function handleChange(e: any) {
    if (props.onChange) {
      if (
        props.allowInvalidDate ||
        moment(e.replace(/-_/g, "-0").replace(/_/g, "1"), "YYYY-MM-DD").isValid()
      ) {
        props.onChange(e);
      } else {
        props.onChange(cleanIncorrectValue(props.value, e));
      }
    }
  }

  function handleDateChange(date: Date) {
    if (props.onChange) {
      props.onChange(moment(date).format("YYYY-MM-DD"));
    }
  }

  const _props = {
    label: props.label ? props.label : "",
    required: props.required ? props.required : false,
    mask: props.mask ? props.mask : "9999-99-99",
    fluid: props.fluid ? props.fluid : false,
  }

  return (
    <div className="maskedDateInput">
      <MaskedInput {..._props} value={props.value} onChange={handleChange} fluid />
      <DateTimePicker className={`maskedDateInput__dateTimePicker ${props.label ? "labeled" : ""}`}
        gutter={false}
        position={props.position}
        trigger={(popupOpen: boolean, onTriggerClick: any) => (
          <Button
            size="small"
            className="maskedDateInput__dateTimeTrigger"
            icon="calendar-plus"
            onClick={onTriggerClick}
            title={tran("dateTimePicker.pickDate")}
          />)
        }
        type="date"
        value={dateIsValid ? convertToDate(props.value) : convertToDate(lastValidDate)}
        onChange={handleDateChange} 
        closeOnChange
      />
    </div>
  );
}

const convertToDate = (v: string) => moment(v, "YYYY-MM-DD").toDate();

function cleanIncorrectValue(oldValue: string, newValue: string) {
  // Jeśli po zmianie otrzymujemy niepoprawną datę to zmieniony znak zastępujemy mask placeholderem
  const cleanedValue = [...oldValue]
    .map((char, index) => (char === newValue[index] ? char : "_"))
    .join("");

  return cleanedValue;
}
