import React from "react";
import { useDerivedState } from "utils/hooks";
import { Theme } from "utils/themes";
import useTheme from "utils/useTheme";

const logoUrls = Object.freeze({
  short: {
    solid: require("icons/sowa-logo-solid.svg").default,
    outline: require("icons/sowa-logo-outline.svg").default,
  },
  full: {
    solid: require("icons/sowa-full-logo-solid.svg").default,
    outline: require("icons/sowa-full-logo-outline.svg").default,
  },
});

interface LogoProps {
  themeColor?: string; // Kolor loga będący nazwą z theme
  themeBgColor?: string; // Kolor tła loga będący nazwą z theme
  variant?: "short" | "full";
  height?: string;
}

export const Logo = React.memo(function Logo({
  themeColor = "mainInv",
  themeBgColor = "main",
  variant = "short",
  height = "100%",
}: LogoProps) {
  const { theme } = useTheme();
  const activeUrl = useDerivedState(getActiveUrl, theme, themeColor, themeBgColor, variant);
  
  // najlepiej by tu użyć aspect-ratio, ale to dopiero Safari 15+,
  // więc używamy ukrytego obrazu

  return (
    <div
      className="logo"
      style={{
        maskImage: `url(${activeUrl})`,
        WebkitMaskImage: `url(${activeUrl})`,
        background: `var(--${themeColor})`,
        height,
      }}
    >
      <img src={activeUrl} style={{ visibility: "hidden" }} height={height} />
    </div>
  );
})

function getActiveUrl(
  theme: Readonly<Theme>,
  themeColor: string,
  themeBgColor: string,
  variant: "short" | "full"
) {
  if (
    getPerceptualBrightness(theme.colors[themeBgColor]) >
    getPerceptualBrightness(theme.colors[themeColor])
  ) {
    return logoUrls[variant].solid;
  } else {
    return logoUrls[variant].outline;
  }
}

function getPerceptualBrightness(hex: string) {
  if (!hex.startsWith("#")) return false;

  const c = hex.substring(1); // strip #
  const rgb = parseInt(c, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  return luma;
}
