import React from "react";
import { tran } from "utils/language";
import { Button, Tile } from "components/shared";
import { DialogPresets, useDialog } from "components/shared/Dialog";
import { IDialog } from "components/shared/Dialog/useDialog";
import { LoginLocalDialog } from "./LoginLocalDialog";
import { SignupDialog } from "../Signup/SignupDialog";
import { ErrorDialog } from "../../shared/Dialog/comps";
import { useOnMessage } from "../../../utils/hooks";
import { emptyArray } from "../../../utils/constants";
import { CompleteSignupDialog } from "../Signup/CompleteSignupDialog";
import bootstrap from "../../../bootstrap";
import uuid4 from "uuid/v4";

type LoginChoiceDialogProps = {
  dialog: IDialog,
  offerSignup: boolean,
  ssoPrompt?: string
};

export function LoginChoiceDialog({ dialog, offerSignup, ssoPrompt }: LoginChoiceDialogProps) {
  const loginLocalDialog = useDialog(LoginLocalDialog);
  const signupDialog = useDialog(SignupDialog);
  const errorDialog = useDialog(ErrorDialog);
  const completeDialog = useDialog(CompleteSignupDialog);
  const tabId = React.useRef("");
  if (!tabId.current)
    tabId.current = uuid4();
  
  useOnMessage("sso.message", message => errorDialog.openWith({
    title: tran("login.err.sso"),
    message: message.message,
  }));
  
  // czekamy na informację zwrotną od WK czy innego autentykatora wspierającego wypełnianie danych podczas rejestracji
  React.useEffect(() => {
    const key = `${tabId.current}_sso`;
    // TODO: ładniej zrobić, np. z APP_SHOWN; opakować w ogólny mechanizm komunikacji
    const timer = setInterval(() => {
      const sso = localStorage.getItem(key);
      if (sso) {
        localStorage.removeItem(key);
        const ssoData = JSON.parse(sso);
        if (ssoData.message)
          errorDialog.openWith({ title: tran("login.err.sso"), message: ssoData.message });
        else
          completeDialog.openWith({ client: bootstrap.folks.client.withAuth(ssoData.authenticator, ssoData.user_id, bootstrap.domain, ssoData.authenticator_key), skipTerms: false });
      }
    }, 1000);
    return () => clearInterval(timer);
  }, emptyArray);
  
  // FIXME: the komponenty Tile mają 0 semantyki, powinny być buttonami albo linkami
  const tiles = [
    {
      value: "usos",
      name: ssoPrompt || "Zaloguj kontem centralnym",
      icon: "angle-double-right",
      link: `/sso.html#${tabId.current}`,
      target: bootstrap.devel ? "sso" : "_blank",
      wide: true,
    },
    {
      value: "local",
      name: tran("login.btn.local"),
      icon: "angle-right",
      clicked: () => loginLocalDialog.openWith({ offerSignup }),
      inverted: true,
      narrow: true,
    },
  ];

  const buttons = (
    <Button
      variant="contained"
      inverted
      content={tran("unique.cancel")}
      onClick={dialog.close}
    />
  );

  return (
    <DialogPresets.BrandedDialog size="large" dialog={dialog} buttons={buttons}>
      <div className="tile-container">
        {tiles.map((tile, index) => {
          return (
            <Tile
              key={index}
              icon={tile.icon}
              text={tile.name}
              link={tile.link}
              target={tile.target}
              clicked={tile.clicked}
              inverted={tile.inverted}
              wide={tile.wide}
              narrow={tile.narrow}
              animate={false}
              className={"tile--signup"}
            />
          );
        })}
      </div>

      {offerSignup && (
        <div style={SIGNUP_DIV_STYLE}>
          <Button
            variant="text"
            align="right"
            icon="user-plus"
            onClick={signupDialog.open}
            content={tran("login.btn.noAccount")}
          />
        </div>
      )}
    </DialogPresets.BrandedDialog>
  );
};

const SIGNUP_DIV_STYLE = { display: "flex", justifyContent: "flex-end", marginTop: 12 };
