import React from 'react'; 
import cx from 'classnames';
import { ProgressThick } from './ProgressThick';
import { ProgressThin } from './ProgressThin';

export const DEFAULT_MAX_VALUE = 100;

export type ProgressProps = {
  value: number;
  max?: number;
  hidePercentage?: boolean;
  
  className?: string;
  style?: React.CSSProperties;
}

/**
 * Komponent paska progresu.
 * @description Posiada dwa warianty, thin oraz thick. Domyślnie zwracany jest wariant thick.
 * 
 * @example
 * <Progress value={55} variant="thin" />
 */
const Progress = (props: ProgressProps & { variant?: "thin" | "thick" }) => {
  const classes = cx("sowa", "progress", props.className);

  if(props.variant === "thin") {
    return <ProgressThin {...props} className={classes} />;
  }

  // Default variant: "thick"
  return <ProgressThick {...props} className={classes} />;
}

export default Progress;