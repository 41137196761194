import React from "react";

/** Możemy podać tę funkcję do zdarzenia onMouseEnter dla elementu,
 *  żeby automatycznie skopiował zawarty wewnątrz tekst jako swój tooltip,
 *  ale tylko jeśli tekst się nie mieści. */
export function copyTextToTitle(event?: React.MouseEvent<HTMLElement, MouseEvent>) {
  if (event) {
    const gcs = getComputedStyle;
    const tgt = event.currentTarget;
    let cnt = tgt, style = gcs(cnt);
    // heurystyka: sprawdzamy pierwszego blokowego rodzica z włączonym ucinaniem,
    //             ale może to nie wystarczy...
    while (style.display === "inline" || style.overflowX !== "hidden") {
      const t = cnt.parentElement;
      if (t) {
        const cl = t.classList;
        if (cl.contains("global-container") || cl.contains("dialog__content"))
          return;
        cnt = t;
        style = gcs(t);
      }
      else
        return;
    }
    if (cnt.scrollWidth > cnt.clientWidth) {
      tgt.title = tgt.innerText;
    }
  }
}

/** Możemy podać tę funkcję do zdarzenia onMouseEnter dla elementu,
 *  żeby automatycznie skopiował zawarty wewnątrz tekst jako swój tooltip. */
export function copyTextToTitleAlways(event?: React.MouseEvent<HTMLElement, MouseEvent>) {
  if (event) {
    const tgt = event.currentTarget;
    tgt.title = tgt.innerText;
  }
}
