import React, { ReactNode, useState } from "react";
import moment, { Moment } from "moment";

import { Tag, Popup, Button } from "..";
import { tran } from "utils/language";
import cx from "classnames";

import { Calendar } from "./Calendar";
import { Time } from "./Time";

// TODO: przycisk wyboru aktualnego czasu
// TODO: tarcza zegara zamiast dwóch spinnerów?

interface DateTimePickerProps {
  className?: string;
  divider?: ReactNode;
  gutter?: boolean;
  header?: ReactNode | ReactNode[];
  maxDate?: Date;
  minDate?: Date;
  trigger?: ReactNode | ReactNode[];
  type?: "time" | "date" | "datetime";
  value?: any; // string | Moment | Date | (string | Moment | Date)[]
  onChange: (value: any) => void; // Date | Date[]
  closeOnChange?: boolean;
  position?: PopupPosition;
}

const DateTimePicker = ({
  divider = "-",
  type = "datetime",
  gutter = true,
  closeOnChange,
  position,
  ...props
}: DateTimePickerProps) => {
  const [activeView, setActiveView] = useState({ 0: "date", 1: "date" });

  /**
   * Zwraca format używany przez bibliotekę moment w zależności od typu wybieranej daty
   * @returns {String}
   */
  const formatByType =
    type === "date"
      ? "DD.MM.YYYY"
      : type === "datetime"
      ? "DD.MM.YYYY HH:mm"
      : type === "time"
      ? "HH:mm"
      : "";

  /**
   * Sprawdzenie, czy daty w podwójnym pickerze są w odpowiedniej kolejności
   * @param {Array} time
   * @returns {Boolean}
   */
  const isTimespanPositive = (time: (Date | Moment)[]): boolean => {
    if (!time[0] || !time[1]) {
      // Przynajmniej jedna z dat nie jest ustawiona
      return true;
    }

    const format = formatByType;

    const a = moment(time[0], format);
    const b = moment(time[1], format);

    return a < b;
  };

  /**
   * Sprawdzenie, czy powinien zostać wyrenderowany podwójny picker
   * @returns {Boolean}
   */
  const isPickerDouble = () => {
    const { value } = props;
    return value && value.constructor === Array;
  };

  const handleChange = (date: Date, id: number, hidePopup: () => void) => {
    const { minDate, maxDate } = props;

    // Kolejna walidacja najwcześniejszej i najpóźniejszej daty, bo komponent Datetime nie zawsze sobie z tym radzi
    if (minDate && moment(minDate).isAfter(date)) {
      // Wybrana data jest wcześniejsza niż minDate
      return;
    }
    if (maxDate && moment(maxDate).isBefore(date)) {
      // Wybrana data jest późniejsza niż maxDate
      return;
    }

    if (isPickerDouble()) {
      const time = [...props.value];
      time[id] = date;

      if (isTimespanPositive(time)) {
        closeOnChange && hidePopup();
        props.onChange(time as any);
      }
    } else {
      closeOnChange && hidePopup();
      props.onChange(date);
    }
  };

  const renderPicker = (date: Date | Moment, id: 0 | 1) => {
    const { header, minDate, maxDate, trigger } = props;

    const _header = header ? (header.constructor === Array ? header[id] : header) : null;

    const _trigger = trigger ? (
      trigger.constructor === Array ? (
        trigger[id]
      ) : (
        trigger
      )
    ) : (
      <Tag style={{ margin: 0 }}>
        {date
          ? moment(date, formatByType).format(formatByType)
          : type === "time"
          ? tran("dateTimePicker.pickTime")
          : tran("dateTimePicker.pickDate")}
      </Tag>
    );

    const hasCalendar = type === "date" || type === "datetime";
    const hasTime = type === "time" || type === "datetime";
    
    return (
      <Popup
        on="click"
        pointing
        trigger={_trigger}
        position={position}
        content={(hidePopup: () => void) => (
          <div className="datetime-picker">
            {_header && <h4 className="datetime-picker__header">{_header}</h4>}

            <ViewWrapper
              hidden={!hasCalendar}
              active={type === "date" || activeView[id] === "date"}
              view="date"
              onClick={(view) => setActiveView((prev) => ({ ...prev, [id]: view }))}>
              <Calendar
                minDate={minDate}
                maxDate={maxDate}
                value={date}
                locale="pl"
                onChange={(newDate) => handleChange(newDate, id, hidePopup)}
                active={type === "date" || activeView[id] === "date"}
              />
            </ViewWrapper>

            <ViewWrapper
              hidden={!hasTime}
              active={type === "time" || activeView[id] === "time"}
              view="time"
              onClick={(view) => setActiveView((prev) => ({ ...prev, [id]: view }))}>
              <Time
                value={moment(date || new Date(), formatByType).toDate()}
                onChange={(newDate) => handleChange(newDate, id, hidePopup)}
                active={type === "time" || activeView[id] === "time"}
              />
            </ViewWrapper>

            <div className="datetime-picker__buttons">
              <Button preset="close" onClick={hidePopup} size="small" />
            </div>
          </div>
        )}
      />
    );
  };

  const { value } = props;
  let content;

  if (isPickerDouble()) {
    // Podwójny picker
    content = (
      <React.Fragment>
        {renderPicker(value[0], 0)}
        <span className="datetime-picker__divider">{divider}</span>
        {renderPicker(value[1], 1)}
      </React.Fragment>
    );
  } else {
    content = renderPicker(value, 0);
  }

  const classes = cx("datetime-picker", gutter && "datetime-picker__gutter", props.className);

  return <div className={classes}>{content}</div>;
};

export default DateTimePicker;

interface ViewWrapperProps {
  hidden: boolean;
  active: boolean;
  view: "date" | "time";
  onClick: (view: "date" | "time") => void;
  children: ReactNode;
}

function ViewWrapper({ hidden, active, view, onClick, children }: ViewWrapperProps) {
  if (hidden) return null;
  if (active) {
    return <>{children}</>;
  }

  return (
    <button className="datetime-picker__view-button" onClick={() => onClick(view)}>
      {children}
    </button>
  );
}

//Na podstawie PropType z Popup.js
export type PopupPosition =
  | "top left"
  | "top right"
  | "bottom left"
  | "bottom right"
  | "right top"
  | "right bottom"
  | "left top"
  | "left bottom"
  | "left center"
  | "top center"
  | "right center"
  | "bottom center";
