import { DropdownOption, DropdownOptions, GeneralizedDropdownOption } from "./types";
import React, { useEffect, useRef, useState } from "react";

import { Button, Icon } from "../index";
import { RefSetter } from "utils/hooks/util";
import { Tran, tran, useLanguage } from "utils/language";
import { Ahref } from "../Ahref";
import Input from "../Input/Input";
import { debounce } from "utils/globalFunctions";
import { useDerivedState } from "utils/hooks";
import { HL, SearchContext } from "utils/hooks/search";

type DropdownVariants = "free" | "attached";

type OptionListTreeProps = {
  selectedOption?: DropdownOption;
  variant?: DropdownVariants;
  onClick?: (option: DropdownOption) => void;
  options: DropdownOptions;
  outerRef: RefSetter<HTMLElement>;
};

type TreeExpandedState = {
  value: boolean;
  status: "initial" | "updated";
};

type SearchResultState = {
  search?: string;
  options: DropdownOptions | null;
};

export function getFlatTreeOptions<T>(options: DropdownOptions<T>): DropdownOptions<T> {
  return options.flatMap(flattenOption);
}

function flattenOption<T>(option: GeneralizedDropdownOption<T>): DropdownOptions<T> | GeneralizedDropdownOption<T>{
  const { subOptions } = (option as DropdownOption<T>);
  if (subOptions) {
    return subOptions.flatMap(flattenOption);
  }
  return option;
}

/** Komponent odpowiedzialny za renderowanie dropdown jako drzewo bez wodotrysków */
export function OptionListTree({
  variant = "free",
  options,
  selectedOption,
  onClick,
  outerRef,
}: OptionListTreeProps) {
  const { translate } = useLanguage();
  const initiallyFocusedRef = useRef(false);
  const optionsContainerRef = useRef<HTMLDivElement>(null);

  const [treeExpanded, setTreeExpanded] = useState<TreeExpandedState>({
    value: true,
    status: "initial",
  });
  const [searchResult, setSearchResult] = useState<SearchResultState>({
    options: null,
  });
  const [focused, setFocused] = useState<HTMLElement | null>(null);

  const flattenedOptions = useDerivedState(getFlatTreeOptions, options);

  return (
    <div
      ref={outerRef}
      className={`dropdown__options dropdown__options--${variant}`}
      onKeyDown={onKeyPress}
      aria-activedescendant={focused?.id}
      tabIndex={-1}>
      <div className="dropdown__options__tree-tools">
        {flattenedOptions.length > 10 && (
          <Input
            autoFocus
            fluid
            placeholder={translate("dropdown.searchOptions")}
            onChange={debounce(handleSearch, 400)}
          />
        )}
        <ExpandButton
          disabled={Boolean(searchResult.search)}
          isExpanded={treeExpanded.value}
          onClick={() => setTreeExpanded((prev) => ({ value: !prev.value, status: "updated" }))}
        />
      </div>
      <div ref={optionsContainerRef}>
        {options.length < 1 ? (
          <Tran id="unique.noOptionsToShow" className="dropdown__option" />
        ) : (
          <>
            {/* Całkowite odmontowanie dla uniknięcia migotania po usunięciu wyszukiwanej frazy */}
            {searchResult.search && (
              <>
                {searchResult.options && searchResult.options.length < 1 ? (
                  <Tran id="unique.noOptionsToShow" className="dropdown__option" />
                ) : (
                  <SearchContext phrase={searchResult.search} prop="text">
                    {searchResult.options?.map((option, index) => (
                      <TreeElement
                        key={"result" + index}
                        option={option}
                        selectedOption={selectedOption}
                        onOptionClick={onClick}
                        initialRef={initialRef}
                        position={index + ""}
                        treeExpanded={treeExpanded}
                      />
                    ))}
                  </SearchContext>
                )}
              </>
            )}

            {options?.map((option, index) => (
              <TreeElement
                key={index + ""}
                option={option}
                selectedOption={selectedOption}
                onOptionClick={onClick}
                initialRef={initialRef}
                position={index + ""}
                treeExpanded={treeExpanded}
                hidden={Boolean(searchResult.search)} //Ukrycie aby zachować stany wyświetlania gałęzi drzewa
              />
            ))}
          </>
        )}
      </div>
    </div>
  );

  function handleSearch(value: string) {
    if (!value) {
      // Ustawianie początkowego rozmiaru po wyjściu z trybu filtrowania
      if (optionsContainerRef.current) {
        optionsContainerRef.current.style.height = "initial";
        optionsContainerRef.current.style.minWidth = "initial";
      }

      return setSearchResult({ options: null });
    }

    // Ustawianie minimalnego rozmiaru kontenera opcji tak aby podczas filtrowania jego
    // rozmiary nie zmniejszały się po znalezieniu mniejszej ilości opcji
    if (!searchResult.search && optionsContainerRef.current) {
      const parentEl: any = optionsContainerRef?.current?.parentElement;

      optionsContainerRef.current.style.height =
        Number(parentEl?.clientHeight) - Number(parentEl?.firstChild?.clientHeight) - 1 + "px";
      optionsContainerRef.current.style.minWidth = optionsContainerRef?.current?.offsetWidth + "px";
    }

    setSearchResult({
      search: value,
      options: flattenedOptions.filter(
        (opt: any) =>
          typeof opt?.text === "string" && opt.text?.toLowerCase()?.includes(value.toLowerCase())
      ),
    });
  }

  function initialRef(chosen: any) {
    if (chosen && chosen.focus && !initiallyFocusedRef.current) chosen.focus();
    initiallyFocusedRef.current = true;
  }

  function onKeyPress(event: any) {
    if (event.key === "ArrowUp" || "ArrowDown") {
      const container = event.target.closest(".dropdown__options");
      const optionsNodes = container.querySelectorAll(OPT_SELECTOR);
      const opt = focused || event.target.closest(OPT_SELECTOR);

      const matchIndex = Array.from(optionsNodes).findIndex((node: any) => node.isEqualNode(opt));

      if (!optionsNodes) return;
      let to: HTMLElement | null = null;

      switch (event.key) {
        case "ArrowUp":
          for (let i = matchIndex - 1; i >= 0; i--) {
            if (optionsNodes[i] && optionsNodes[i].matches(":not(.disabled)")) {
              to = optionsNodes[i];
              break;
            }
          }
          break;
        case "ArrowDown":
          if (!opt) {
            to = optionsNodes[0];
          } else {
            for (let i = matchIndex + 1; i < optionsNodes.length; i++) {
              if (optionsNodes[i] && optionsNodes[i].matches(":not(.disabled)")) {
                to = optionsNodes[i];
                break;
              }
            }
          }
          break;
        case "Enter":
          if (focused) {
            focused.click();
            event.preventDefault();
            event.stopPropagation();
            return;
          }
          break;
        default:
          return;
      }

      if (!to) return;

      setFocused((prev) => {
        if (to) {
          if (prev) {
            delete prev.dataset["focused"];
          }
          to.dataset["focused"] = "true";
          to.scrollIntoView({ block: "center" });
          return to;
        }
        return prev;
      });

      event.preventDefault();
      event.stopPropagation();
    }
  }
}

interface TreeElementProps {
  option: any;
  selectedOption?: DropdownOption;
  onOptionClick?: (option: DropdownOption) => void;
  initialRef: any;
  position: string;
  treeExpanded: TreeExpandedState;
  hidden?: boolean;
}

function TreeElement({
  option,
  selectedOption,
  onOptionClick,
  initialRef,
  position,
  treeExpanded,
  hidden,
}: TreeElementProps) {
  const [isOpen, setOpen] = useState(option.subOptionsState === "open" ? true : false);

  useEffect(() => {
    // Jeśli mamy sprecyzowany stan początkowy gałęzi to pomijamy ustawianie
    // zgodne ze stanem początkowym całego drzewa
    if (!option.subOptionsState || treeExpanded.status === "updated") {
      setOpen(treeExpanded.value);
    }
  }, [option.subOptionsState, treeExpanded]);

  if (!option.subOptions)
    return (
      <Option
        id={position}
        option={option}
        isSelected={Object.is(option, selectedOption)}
        onOptionClick={onOptionClick}
        hidden={hidden}
        initialRef={initialRef}
      />
    );

  return (
    <div className={`dropdown__options__suboptions ${hidden ? "hidden" : ""}`}>
      <Button
        id={position + "-arrow"}
        className={`dropdown__options__suboptions__arrow ${hidden ? "hidden" : ""}`}
        icon={isOpen ? "chevron-up" : "chevron-down"}
        size="small"
        onClick={() => setOpen((prev) => !prev)}
      />
      <span className="dropdown__options__suboptions__title">{option.text}</span>

      {isOpen && (
        <div className="dropdown__options__suboptions__items">
          {option.subOptions?.map((suboption: DropdownOption, index: number) => (
            <TreeElement
              key={`${position}.${index}`}
              option={suboption}
              selectedOption={selectedOption}
              onOptionClick={onOptionClick}
              position={`${position}.${index}`}
              treeExpanded={treeExpanded}
              hidden={hidden}
              initialRef={initialRef}
            />
          ))}
        </div>
      )}
    </div>
  );
}

interface OptionProps {
  option: DropdownOption;
  onOptionClick: any;
  isSelected: boolean;
  initialRef?: any;
  id: any;
  hidden?: boolean;
}

function Option({ id, option, isSelected, initialRef, onOptionClick, hidden }: OptionProps) {
  if (React.isValidElement(option)) {
    return <React.Fragment>{option}</React.Fragment>;
  }

  const {
    value,
    text,
    icon,
    iconType,
    disabled,
    image,
    link,
    onClick,
    className,
    ...rest
  } = option;

  const _icon =
    icon && icon.length > 0 ? (
      <Icon name={icon} className={`dropdown__option-icon`} type={iconType || "default"} />
    ) : null;

  const _img =
    image && image.length > 0 ? (
      <img src={image} alt="" className={`dropdown__option-img`} />
    ) : null;

  const _content = (
    <>
      {_icon || _img ? (
        <div className={`dropdown__option-label`}>
          {_icon}
          {_img}
        </div>
      ) : null}
      {typeof text === "string" ? <HL value={text} /> : text || NBSP}
    </>
  );

  const _className = `dropdown__option ${className || ""} ${isSelected ? "active" : ""} ${
    disabled ? "disabled" : ""
  } ${hidden ? "hidden" : ""}
    `;

  const initial = isSelected;

  if (link && (typeof link === "function" || link.length > 0) && !disabled)
    return (
      <Ahref
        id={id}
        ref={initial ? initialRef : undefined}
        className={_className}
        to={link}
        onClick={() =>
          onOptionClick(option)
        } /* nie jestem pewien czy to działa i czy jest potrzebne */
        role="option"
        children={_content}
        {...rest}
      />
    );

  return (
    <button
      id={id}
      ref={initial ? initialRef : undefined}
      className={`stealth-button ${_className}`}
      disabled={disabled}
      onClick={disabled ? undefined : () => onOptionClick(option)}
      role="option"
      aria-selected={isSelected}
      children={_content}
      {...rest}
    />
  );
}

interface ExpandButtonProps {
  onClick: () => void;
  isExpanded: boolean;
  disabled: boolean;
}

function ExpandButton({ onClick, isExpanded, disabled }: ExpandButtonProps) {
  return (
    <Button
      icon={isExpanded ? "compress-alt" : "expand-alt"}
      size="small"
      className="dropdown__options__tree-tools__expand"
      onClick={onClick}
      title={tran(isExpanded ? "dropdown.collapseTree" : "dropdown.expandTree")}
      disabled={disabled}
    />
  );
}

const NBSP = Object.freeze(<>&nbsp;</>);
const OPT_SELECTOR =
  ".dropdown__option:not(.hidden),.dropdown__options__suboptions__arrow:not(.hidden)";
