import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Link } from "react-router-dom";
import { Icon } from "..";

class Tag extends Component {
  render() {
    const {
      id,
      link,
      shadow,
      text,
      active,
      underline,
      highlight,
      onClick,
      className,
      icon
    } = this.props;

    const classes = cx(
      "menu-items",
      active && "active",
      underline && "underline",
      shadow && "shadow",
      highlight && "highlight",
      className
    );

    const content = (
      <button className={classes} onClick={onClick}>
        {text}
        {icon && <Icon className="menu-items__icon" name={icon} />}
      </button>
    );

    if (link)
      return (
        <Link to={link} key={id}>
          {content}
        </Link>
      );

    return content;
  }
}

export default Tag;

Tag.propTypes = {
  active: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  onClick: PropTypes.func,
  highlight: PropTypes.bool,
  underline: PropTypes.bool,
  shadow: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.string
};

Tag.defaultProps = {
  active: false,
  highlight: false,
  shadow: true,
  underline: false
};
