import bootstrap from "bootstrap";
import { translate } from "utils/language";

const pages = {
  home: {
    path: "/home",
    fullPath: "/home",
    displayName: translate("app.home"),
    disableForSearching: true,
    displayInBreadcrumb: false
  },
  admin: {
    path: "/admin",
    fullPath: "/admin",
    icon: "id badge",
    displayName: translate("app.admin"),
    disableForSearching: true,
    children: {
      users: {
        path: "/users",
        fullPath: "/admin/users",
        displayName: translate("pages.list.users.title"),
        description: translate("pages.list.users.description"), // wykorzystywane w wyświetlaniu wyników wyszukiwania i w headerze
        disableForSearching: false, // true -> nie pokazuje w wynikach wyszukiwania na stronie głównej (nie trzeba podawac jeśli false)
        icon: "users", // wykorzystywane w wyświetlaniu wyników wyszukiwania
        displayInBreadcrumb: true,
        access: ["staff", "gdpr", "admin", "sysadmin"],
        keywords: [
          "użytkownik",
          "lista",
          "edycja",
          "pracownicy",
          "administratorzy",
          "users",
          "list",
          "workers",
          "admin"
        ],
        children: {
          v: {
            displayName: translate("pages.list.users.single.title"),
            disableForSearching: true,
            path: "/v",
            fullPath: "/admin/users/v/:id",
            nextStepRequired: true, // link w breadcrumbach będzie generowany na podstawie atrybutu 'path',
            // w przypadku stronu użytkownika link wygląda /users/view/login
            // nextStepRequired zaznacza, że w trakcie generowania tego linku potrzebujemy ten 'krok' dalej,
            // tak, żeby nie wygenerować tylko /users/view/
            children: {
              edit: {
                displayName: translate("pages.list.users.single.edit.title"),
                path: "/edit",
                fullPath: "/admin/users/v/:id/edit"
              },
              "edit-password": {
                displayName: translate("pages.list.users.single.pass.title"),
                path: "/edit-password",
                fullPath: "/admin/users/v/:id/edit-password"
              },
              "edit-login": {
                displayName: translate("pages.list.users.single.login.title"),
                path: "/edit-login",
                fullPath: "/admin/users/v/:id/edit-login"
              }
            }
          }
        }
      }, // users
      groups: {
        path: "/groups",
        fullPath: "/admin/groups",
        displayName: translate("pages.list.groups.title"),
        description: translate("pages.list.groups.description"), // wykorzystywane w wyświetlaniu wyników wyszukiwania i w headerze
        disableForSearching: false, // true -> nie pokazuje w wynikach wyszukiwania na stronie głównej (nie trzeba podawac jeśli false)
        icon: "users", // wykorzystywane w wyświetlaniu wyników wyszukiwania
        displayInBreadcrumb: true,
        nextStepRequired: true,
        keywords: ["grupy"],
        children: {
          v: {
            displayName: translate("pages.list.users.single.title"),
            disableForSearching: true,
            path: "/v",
            fullPath: "/admin/groups/v/:id",
            nextStepRequired: true // link w breadcrumbach będzie generowany na podstawie atrybutu 'path',
            // w przypadku stronu użytkownika link wygląda /users/view/login
            // nextStepRequired zaznacza, że w trakcie generowania tego linku potrzebujemy ten 'krok' dalej,
            // tak, żeby nie wygenerować tylko /users/view/
          }
        }
      }, // groups
      sessions: {
        path: "/sessions",
        fullPath: "/admin/sessions",
        keywords: [
          "zalogowani",
          "aktywni",
          "wyloguj",
          "logged",
          "active",
          "online"
        ],
        access: ["gdpr", "admin", "sysadmin"],
        displayName: translate("pages.list.sessions.title"),
        description: translate("pages.list.sessions.description"),
        icon: "user-clock"
      }, // sessions
      licenses: {
        path: "/licenses",
        fullPath: "/admin/licenses",
        access: ["staff", "admin", "sysadmin"],
        displayName: translate("pages.list.licenses.title"),
        description: translate("pages.list.licenses.description"),
        disableForSearching: !Boolean(bootstrap.teka.url),
        icon: "copyright",
        children: {
          v: {
            path: "/v",
            fullPath: "/admin/licenses/v/:license_id",
            nextStepRequired: true,
            displayName: translate("pages.list.licenses.single.title"),
            description: translate("pages.list.licenses.single.description"),
            disableForSearching: true
          }
        }
      }, // licenses
      applications: {
        path: "/applications",
        fullPath: "/admin/applications",
        access: ["staff", "admin", "sysadmin"],
        keywords: ["wniosek"],
        icon: "handshake-outline",
        displayName: translate("pages.list.applications.title"),
        description: translate("pages.list.applications.description"),
        disableForSearching: !Boolean(bootstrap.teka.url),
        children: {
          editor: {
            path: "/editor",
            fullPath: "/admin/applications/editor",
            disableForSearching: true,
            displayInBreadcrumb: false,
            keywords: ["wniosek"],
            icon: "handshake-outline",
            displayName: translate("pages.list.applications.title"),
            description: translate("pages.list.applications.description")
          },
          catalogue: {
            path: "/catalogue",
            fullPath: "/admin/applications/catalogue",
            disableForSearching: true,
            displayInBreadcrumb: false,
            keywords: ["wniosek"],
            icon: "handshake-outline",
            displayName: translate("pages.list.applications.title"),
            description: translate("pages.list.applications.description")
          },
          access: {
            path: "/access",
            fullPath: "/admin/applications/access",
            disableForSearching: true,
            displayInBreadcrumb: false,
            keywords: ["wniosek"],
            icon: "handshake-outline",
            displayName: translate("pages.list.applications.title"),
            description: translate("pages.list.applications.description")
          },
          edit: {
            path: "/edit",
            fullPath: "/admin/applications/edit/:id",
            disableForSearching: true,
            displayInBreadcrumb: false,
            nextStepRequired: true,
            icon: "handshake-outline",
            displayName: translate("pages.list.applications.edit.title"),
            description: translate("pages.list.applications.edit.description")
          }
        }
      }, // applications
      issues: {
        path: "/issues",
        fullPath: "/admin/issues",
        access: ["staff", "gdpr", "admin", "sysadmin"],
        keywords: [
          "issues",
          "reports",
          "teka",
          "documents",
          "dokumenty",
          "zgłoszenia",
          "problemy"
        ],
        icon: "exclamation-triangle",
        displayName: translate("pages.list.issues.title"),
        description: translate("pages.list.issues.description"),
        disableForSearching: !Boolean(bootstrap.teka.url)
      },
      reports: {
        path: "/reports",
        fullPath: "/admin/reports",
        access: ["staff", "gdpr", "admin", "sysadmin"],
        keywords: ["reports", "raporty"],
        icon: "chart-mixed",
        displayName: translate("pages.list.reports.title"),
        description: translate("pages.list.reports.description"),
        disableForSearching: !Boolean(bootstrap.teka.url),
        children: {
          report: {
            displayName: translate("pages.list.reports.report.title"),
            disableForSearching: true,
            path: "/report",
            fullPath: "/admin/reports/report/:id",
            nextStepRequired: true // link w breadcrumbach będzie generowany na podstawie atrybutu 'path',
            // w przypadku stronu użytkownika link wygląda /users/view/login
            // nextStepRequired zaznacza, że w trakcie generowania tego linku potrzebujemy ten 'krok' dalej,
            // tak, żeby nie wygenerować tylko /users/view/
          }
        }
      },
      tools: {
        path: "/tools",
        fullPath: "/admin/tools",
        access: ["admin", "sysadmin"],
        keywords: [
          "konfiguracja",
          "parametry",
          "ustawienia",
          "maile",
          "agendy",
          "tłumaczenia",
          "wyszukiwanie",
          "configuration",
          "parameters",
          "settings",
          "options",
          "search"
        ],
        displayName: translate("pages.list.tools.title"),
        disableForSearching: true,
        icon: "wrench",
        children: {
          agenda: {
            path: "/agenda",
            fullPath: "/admin/tools/agenda",
            displayName: translate("pages.list.tools.agenda.title"),
            description: translate("pages.list.tools.agenda.description"),
            icon: "building",
            keywords: ["filia", "agenda", "oddział"],
            disableForSearching: true
          },
          translations: {
            path: "/translations",
            fullPath: "/admin/tools/translations",
            displayName: translate("pages.list.tools.translations.title"),
            description: translate("pages.list.tools.translations.description"),
            icon: "building",
            keywords: ["tłumaczenia", "translate", "enum"],
            disableForSearching: true
          },
          buyproposal: {
            path: "/buyproposal",
            fullPath: "/admin/tools/buyproposal",
            displayName: translate("pages.list.tools.buyproposal.title"),
            description: translate("pages.list.tools.buyproposal.description"),
            icon: "shopping-basket",
            keywords: ["kupno", "propozycja"],
          },
          passwords: {
            path: "/passwords",
            fullPath: "/admin/tools/passwords",
            displayName: translate("pages.list.tools.passwords.title"),
            description: translate("pages.list.tools.passwords.description"),
            icon: "key",
            keywords: [
              "użytkownicy",
              "hasło",
              "długość hasła",
              "passwords",
              "security",
              "users"
            ]
          },
          parameters: {
            path: "/parameters",
            fullPath: "/admin/tools/parameters",
            displayName: translate("pages.list.tools.params.title"),
            description: translate("pages.list.tools.params.description"),
            keywords: ["wszystko"],
            icon: "hashtag",
            children: {
              editor: {
                path: "/editor",
                fullPath: "/admin/tools/parameters/editor",
                displayName: translate("pages.list.tools.params.editor"),
                disableForSearching: true
              }
            }
          },
          reports: {
            path: "/reports",
            fullPath: "/admin/tools/reports",
            displayName: translate("pages.list.tools.reports.title"),
            description: translate("pages.list.tools.reports.description"),
            keywords: ["wszystko"],
            icon: "flag"
          },
          eprasaVendors: {
            path: "/eprasavendors",
            fullPath: "/admin/tools/eprasavendors",
            displayName: "Dostawcy e-prasy",
            description: "Skonfiguruj sposób importu e-prasy od dostawców",
            keywords: ["wszystko"],
            icon: "newspaper"
          },
          serials: {
            path: "/serials",
            fullPath: "/admin/tools/serials",
            displayName: "E-Prasa",
            description: "Zarządzaj czasopismami udostępnianymi cyfrowo",
            keywords: ["wszystko"],
            icon: "newspaper"
          },
          schedule: {
            path: "/schedule",
            fullPath: "/admin/tools/schedule",
            displayName: translate("pages.list.tools.schedule.title"),
            description: translate("pages.list.tools.schedule.description"),
            keywords: ["wszystko"],
            icon: "calendar alt outline",
            disableForSearching: true
          },
          sharingrules: {
            path: "/sharingrules",
            disableForSearching: false,
            fullPath: "/admin/tools/sharingrules",
            displayName: translate("pages.list.tools.sharingrules.title"),
            description: translate("pages.list.tools.sharingrules.description"),
            keywords: [
              "reguły udostępniania",
              "reguły",
              "rules",
              "sharing rules"
            ],
            icon: "sitemap",
            children: {
              editor: {
                path: "/editor",
                fullPath: "/admin/tools/sharingrules/editor",
                displayName: translate(
                  "pages.list.tools.sharingrules.editor.title"
                ),
                disableForSearching: true
              }
            }
          },
          discardrules: {
            path: "/discardrules",
            disableForSearching: false,
            fullPath: "/admin/tools/discardrules",
            displayName: translate("pages.list.tools.discardrules.title"),
            description: translate("pages.list.tools.discardrules.description"),
            keywords: [
              "reguły ubytkowania",
              "reguły",
              "rules",
              "lossing rules"
            ],
            icon: "sitemap",
            children: {}
          },
          servers: {
            path: "/doc_file_servers",
            fullPath: "/admin/tools/doc_file_servers",
            access: ["admin", "sysadmin"],
            keywords: [
              "servers",
              "file",
            ],
            icon: "server",
            displayName: translate("pages.list.tools.docFileServers.title"),
            description: translate("pages.list.tools.docFileServers.description"),
            disableForSearching: !Boolean(bootstrap.teka.url)
          },
          payments: {
            path: "/payments",
            fullPath: "/admin/tools/payments",
            displayName: translate("pages.list.tools.payments.title"),
            description: translate("pages.list.tools.payments.description"),
            keywords: ["payments", "payu"],
            icon: "credit card",
            disableForSearching: !Boolean(bootstrap.kasa.url)
          },
          leasing: {
            path: "/leasing",
            fullPath: "/admin/tools/leasing",
            displayName: translate("pages.list.tools.leasing.title"),
            description: translate("pages.list.tools.leasing.description"),
            keywords: ["leasing"],
            disableForSearching: false,
            icon: "book-reader",
            displayInBreadcrumb: true
          },
          sms: {
            path: "/sms",
            fullPath: "/admin/tools/sms",
            displayName: translate("pages.list.tools.sms.title"),
            description: translate("pages.list.tools.sms.description"),
            keywords: ["sms"],
            icon: "sms",
            displayInBreadcrumb: true,
            disableForSearching: false
          },
          mailboxes: {
            path: "/mailboxes",
            fullPath: "/admin/tools/mailboxes",
            displayName: translate("pages.list.tools.mailboxes.title"),
            description: translate("pages.list.tools.mailboxes.description"),
            keywords: ["mail"],
            disableForSearching: false,
            icon: "envelope",
            displayInBreadcrumb: true
          },
          usersForms: {
            path: "/usersforms",
            fullPath: "/admin/tools/usersforms",
            displayName: translate("pages.list.tools.usersForms.title"),
            description: translate("pages.list.tools.usersForms.description"),
            disableForSearching: true,
            icon: "users",
            displayInBreadcrumb: true,
            children: {
              patron: {
                displayInBreadcrumb: false,
                path: "/patron",
                fullPath: "/admin/tools/usersforms/patron"
              },
              aspirant: {
                displayInBreadcrumb: false,
                path: "/aspirant",
                fullPath: "/admin/tools/usersforms/aspirant"
              },
              staff: {
                displayInBreadcrumb: false,
                path: "/staff",
                fullPath: "/admin/tools/usersforms/staff"
              },
              spectator: {
                displayInBreadcrumb: false,
                path: "/spectator",
                fullPath: "/admin/tools/usersforms/spectator"
              },
              admin: {
                displayInBreadcrumb: false,
                path: "/admin",
                fullPath: "/admin/tools/usersforms/admin"
              }
            }
          },
          notificationsOld: {
            path: "/notificationsOld",
            fullPath: "/admin/tools/notificationsOld",
            displayName: translate("pages.list.tools.notificationsOld.title"),
            description: translate(
              "pages.list.tools.notificationsOld.description"
            ),
            disableForSearching: true,
            icon: "envelope",
            displayInBreadcrumb: false,
            children: {
              main: {
                path: "/main",
                fullPath: "/admin/tools/notificationsOld/main",
                displayName: "Główne ustawienia",
                disableForSearching: true
              },
              footer: {
                path: "/footer",
                fullPath: "/admin/tools/notificationsOld/footer",
                displayName: "Treść stopki",
                disableForSearching: true
              },
              content: {
                path: "/content",
                fullPath: "/admin/tools/notificationsOld/content",
                displayName: "Treść wiadomości",
                disableForSearching: true
              },
            }
          },
          search: {
            path: "/search",
            fullPath: "/admin/tools/search",
            access: ["admin", "sysadmin"],
            keywords: ["search", "reindeks"],
            disableForSearching: false,
            displayName: translate("pages.list.search.title"),
            description: translate("pages.list.search.description"),
            icon: "search"
          },
          singleSignOn: {
            path: "/singleSignOn",
            fullPath: "/admin/tools/singleSignOn",
            access: ["admin", "sysadmin"],
            keywords: ["logowanie", "authentication"],
            disableForSearching: false,
            displayName: translate('pages.list.tools.singleSignOn.title'),
            description: translate("pages.list.tools.singleSignOn.description"),
            icon: "key"
          }
        }
      }
    }
  }, // admin
  teka: {
    path: "/teka",
    icon: "bookmark",
    fullPath: "/teka",
    displayName: translate("app.teka"),
    description: "Teka", // wykorzystywane w wyświetlaniu wyników wyszukiwania
    disableForSearching: false, // true -> nie pokazuje w wynikach wyszukiwania na stronie głównej (nie trzeba podawac jeśli false)
    access: [],
    keywords: [],
    children: {
      doc: {
        path: "/doc",
        fullPath: "/teka/doc/:id",
        displayName: translate("pages.list.teka.document.title"),
        disableForSearching: true,
        terminateBreadcrumb: true, // hak? kończy przetwarzanie statycznych okruszków, nie dopasowuje tej i nast. ścieżek
        nextStepRequired: true, // link w breadcrumbach będzie generowany na podstawie atrybutu 'path',
        // w przypadku stronu użytkownika link wygląda /users/view/login
        // nextStepRequired zaznacza, że w trakcie generowania tego linku potrzebujemy ten 'krok' dalej,
        // tak, żeby nie wygenerować tylko /users/view/
        children: {
          application: {
            path: "/application",
            fullPath: "/teka/doc/:id/application/:app_id",
            displayName: translate("pages.list.applications.edit.title"),
            disableForSearching: true,
            nextStepRequired: true
          },
          online: {
            path: "/online",
            fullPath: "/teka/doc/:id/online",
            displayName: translate("pages.list.teka.documentPreview.title"),
            disableForSearching: true
          }
        }
      },
      index: {
        path: "/index",
        fullPath: "/teka/index",
        displayName: translate("app.teka"),
        disableForSearching: true,
        displayInBreadcrumb: false
      },
      search: {
        path: "/search",
        fullPath: "/teka/search",
        displayName: translate("pages.list.teka.search.title"),
        disableForSearching: true,
        displayInBreadcrumb: false,
        terminateBreadcrumb: true,
      },
      labels: {
        path: "/labels",
        fullPath: "/teka/labels",
        displayName: translate("pages.list.teka.labels.title"),
        disableForSearching: true,
        nextStepRequired: false
      },
      application: {
        path: "/application",
        fullPath: "/teka/application/:id",
        displayName: translate("pages.list.applications.edit.title"),
        disableForSearching: true,
        nextStepRequired: true
      },
      eprasa: {
        path: "/prasa",
        fullPath: "/teka/prasa",
        displayName: "E-Prasa",
        disableForSearching: true,
        displayInBreadcrumb: false,
        terminateBreadcrumb: true,
      }
    }
  },
  journal: {
    path: "/journal",
    fullPath: "/journal",
    displayName: translate("app.journal"),
    disableForSearching: true,
    displayInBreadcrumb: false,
    icon: "clipboard",
  },
  myProfile: {
    path: "/myProfile",
    fullPath: "/myProfile",
    displayName: translate("pages.list.myProfile.title"),
    description: translate("pages.list.myProfile.description"),
    keywords: ["konto", "account"],
    icon: "user",
    children: {
      edit: {
        path: "/edit",
        fullPath: "/myProfile/edit",
        displayName: translate("pages.list.users.single.edit.title"),
        description: translate("pages.list.myProfile.edit.description"),
        icon: "edit"
      },
      "edit-password": {
        path: "/edit-password",
        fullPath: "/myProfile/edit-password",
        displayName: translate("pages.list.users.single.pass.title"),
        description: translate("pages.list.myProfile.pass.description"),
        icon: "lock"
      },
      "edit-address": {
        path: "/edit-address",
        fullPath: "/myProfile/edit-address",
        displayName: translate("pages.list.users.single.address.title"),
        description: translate("pages.list.myProfile.address.description"),
        icon: "phone"
      },
      "edit-login": {
        path: "/edit-login",
        fullPath: "/myProfile/edit-login",
        displayName: translate("pages.list.users.single.login.title"),
        description: translate("pages.list.users.single.login.description"),
        icon: "user-tag"
      },
      groups: {
        path: "/groups",
        fullPath: "/myProfile/groups",
        children: {
          v: {
            path: "/v",
            fullPath: "/myProfile/groups/v/:id",
          }
        }
      }
    }
  },
  confirm: {
    path: "/confirm",
    fullPath: "/confirm",
    disableForSearching: true,
    displayName: translate("pages.list.confirm.title")
  },
  forgot: {
    path: "/forgot",
    fullPath: "/forgot",
    disableForSearching: true,
    displayName: translate("pages.list.forgot.title")
  },
  recovery: {
    path: "/recovery",
    fullPath: "/recovery",
    disableForSearching: true,
    displayName: translate("pages.list.recovery.title")
  },
  privacypolicy: {
    path: "/privacy-policy",
    fullPath: "/privacy-policy",
    disableForSearching: true,
    displayName: translate("pages.list.privacy-policy.title")
  },
  terms: {
    path: "/terms",
    fullPath: "/terms",
    disableForSearching: true,
    displayName: translate("pages.list.regulations.title")
  }
};

/**
 * Zwraca informacje o stronie zapisanej pod podaną ścieżką
 * @param {String} path
 * @return {Object}
 * @example getPage("admin.users.add")
 */
function getPage(path) {
  let parts = path.split(".");
  let page = pages[parts[0]] || {};

  parts.splice(0, 1);

  for (let part of parts) {
    if (page.children) {
      page = page.children[part] || {};
    } else {
      return {};
    }
  }

  return page;
}

/**
 * Pobranie ścieżki URL do wybranej strony
 * @param {String} stringPath ścieżka w obiekcie pages, pod którą zapisana jest strona
 * @param {Object} params Wartości parametrów w ścieżce URL
 * @example getPagePath("teka.doc", { id: 1 }) -> "/teka/doc/1"
 */
function getPagePath(stringPath, params = {}) {
  let fullPath = getPage(stringPath).fullPath || "";

  Object.keys(params).forEach(param => {
    fullPath = fullPath.replace(`:${param}`, params[param]);
  });

  return fullPath;
}

export { pages, getPage, getPagePath };

