import {
  FETCH_CURRENT_USER,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_ERROR,
  CURRENT_USER_LOADING,
  CURRENT_USER_TEKA_SUCCESS,
  CURRENT_USER_TEKA_ERROR,
  CURRENT_USER_SOWA_ERROR,
  CURRENT_USER_SOWA_SUCCESS,
  FETCH_SETTINGS, SESSION_SUCCESS
} from "../sagas/types";

import { put, call, fork, join, select, takeLatest, take } from "redux-saga/effects";

import * as FolksApi from "api/folks";
import * as TekaApi from "api/teka";
import * as sowaUserFunctions from "./sowaUserFunctions";

import bootstrap from "bootstrap";
import { emptyObject } from "../utils/constants";

export default function* currentUserWatcher() {
  // czekamy aż sessionSaga skończy inicjalizację
  if (!(yield select(state => state.session.session_id)))
    yield take(SESSION_SUCCESS);
  
  yield takeLatest(FETCH_CURRENT_USER, fetchCurrentUser);
}

const folksConfig = { addresses: true, flags: true, props: true, member_of: true, bonds: true };

function* fetchCurrentUser({ payload }) {
  const {
    sowa,
    teka,
    settings,
    folks,
    //folksConfig = { adresses: false, flags: true, props: true }
  } = payload || emptyObject;
  yield put({ type: CURRENT_USER_LOADING });

  let user_id, login;
  const tasks = [];

  if (folks) {
    const user = yield call(fetchFolksData, folksConfig);
    if (user) {
      user_id = user.user_id;
      login = user.login;
    }
  }
  if (settings) {
    yield put({ type: FETCH_SETTINGS });
  }

  if (sowa) {
    if (!login) {
      // jeśli nie pobieramy danych z Folks, zakładamy że dane zalogowanego użytkownika są już w storze (pobrane przy zalogowaniu)
      login = yield select(state => state.currentUser.folks.login);
    }
    if (login) {
      tasks.push(yield fork(fetchSowaData, login));
    }
  }

  if (teka && bootstrap.teka && bootstrap.teka.url) {
    if (!user_id) {
      // jeśli nie pobieramy danych z Folks, zakładamy że dane zalogowanego użytkownika są już w storze (pobrane przy zalogowaniu)
      user_id = yield select(state => state.currentUser.folks.user_id);
    }
    if (user_id) {
      tasks.push(yield fork(fetchTekaData, user_id));
    }
  }
  
  if (tasks.length) // czekamy na załadowanie równoległych wątków zanim poinformujemy o końcu ładowania
    yield join(tasks);
  
  yield put({ type: CURRENT_USER_LOADING, payload: false });
}

function* fetchFolksData(folksConfig) {
  try {
    const result = yield call(FolksApi.fetchUserFolks, undefined, folksConfig);
    if (result.status === 200) {
      yield put({
        type: CURRENT_USER_SUCCESS,
        payload: result.data
      });
      return result.data;
    } else {
      yield put({
        type: CURRENT_USER_ERROR,
        payload: result.status
      });
      return null;
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: CURRENT_USER_ERROR,
      payload: 500
    });
    return null;
  }
}

function* fetchTekaData(user_id) {
  if (!user_id) {
    user_id = yield select(state => state.currentUser.folks.user_id);
  }
  try {
    const result = yield call(TekaApi.fetchUserTeka, user_id);
    if (result.status === 200) {
      yield put({
        type: CURRENT_USER_TEKA_SUCCESS,
        payload: result.data
      });
    } else {
      yield put({
        type: CURRENT_USER_TEKA_ERROR,
        payload: result.status
      });
    }
  } catch (err) {
    console.log(err);
    yield put({
      type: CURRENT_USER_TEKA_ERROR,
      payload: 500
    });
  }
}

/**
 * Pobranie uprawnień użytkownika we wszystkich dostępnych katalogach
 * @param {String} login login użytkownika
 */
function* fetchSowaData(login) {
  if (!login) {
    login = yield select(state => state.currentUser.folks.login);
  }

  for (let catalogue of bootstrap.sowa.catalogues) {
    yield fork(fetchCatalogueData, catalogue, login);
  }
}

function* fetchCatalogueData(catalogue, login) {
  try {
    const parsed = yield call(
      sowaUserFunctions.fetchSowaUser,
      login,
      catalogue
    );
    yield put({
      type: CURRENT_USER_SOWA_SUCCESS,
      payload: {
        cat_id: catalogue.cat_id,
        data: parsed
      }
    });
  } catch (error) {
    yield put({
      type: CURRENT_USER_SOWA_ERROR,
      payload: {
        cat_id: catalogue.cat_id,
        error: error.status
      }
    });
  }
}
