import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import View from "./View";

import { translate } from "utils/language";
import { pages } from "utils/pages";
import { howManyDaysTill } from "utils/globalFunctions";

class Notifications extends PureComponent {
  state = {
    notifications: []
  };

  componentDidMount = () => {
    if (
      this.props.isUserLoggedIn &&
      Object.keys(this.props.userInfo).length > 0
    ) {
      this.setNotifications();
    }
  };

  componentDidUpdate = prevProps => {
    if (this.props.isUserLoggedIn) {
      if (
        (Object.keys(prevProps.userInfo).length === 0 &&
          Object.keys(this.props.userInfo).length > 0) || // pobrano informacje o użytkowniku
        this.props.appSettings !== prevProps.appSettings || // zmieniono ustawienia
        (this.props.userInfo.expiry &&
          this.props.userInfo.expiry !== prevProps.userInfo.expiry) // zmiana daty wygaśnięcia hasła
      ) {
        this.setNotifications();
      }
    }
  };

  setNotifications = () => {
    const notifications = [
      ...this.setPasswordNotification()
    ];
    this.setState({ notifications });
  };

  /** Generowanie powiadomienia o wygasającym haśle
   * @returns {Array}
   */
  setPasswordNotification() {
    const notifications = [];
    const expiry = this.props.userInfo.expiry; // data ważności hasła
    const daysToExpiration = howManyDaysTill(expiry); // liczba dni do wygaśnięcia hasła
    const criticalDaysNumber = Number(
      this.props.appSettings.passwordExpirationWarning || 5
    ); // liczba dni, od której zaczyna się wyświetlać powiadomienie
    if (daysToExpiration <= criticalDaysNumber) {
      let msg =
        daysToExpiration < 0
          ? translate("topbar.passExpiry.expired")
          : daysToExpiration === 0
          ? translate("topbar.passExpiry.expiredInDay")
          : daysToExpiration === 1
          ? translate("topbar.passExpiry.tomorrow")
          : translate("topbar.passExpiry.multiple").replace(
              "<<1>>",
              daysToExpiration
            );

      notifications.push({
        text: msg,
        removable: true,
        link: pages.myProfile.children["edit-password"].fullPath
      });
    }
    return notifications;
  }

  /** Generowanie powiadomień na podstawie flag użytkownika
   * @returns {Array}
   */
  setFlagsNotifications() {
    const notifications = [];
    const flags = this.props.userInfo.flags;
    if (flags && flags.nag && Object.keys(flags.nag).length > 0) {
      Object.keys(flags.nag).forEach(flagName => {
        if (flagName === "plzfill") {
          notifications.push({
            text: flags.nag[flagName].reason,
            removable: false,
            link: pages.myProfile.children.edit.fullPath
          });
        } else {
          notifications.push({
            text: flags.nag[flagName].reason,
            removable: false,
            link: null
          });
        }
      });
    }
    return notifications;
  }

  /**
   * Usunięcie pojedynczego powiadomieia
   * @param {Number} index - indeks usuwanego powiadomienia
   */
  removeNotification = index => {
    this.setState(state => {
      const notifications = [...state.notifications];
      notifications.splice(index, 1);
      return { notifications };
    });
  };

  /** Usunięcie wszystkich powiadomień */
  clearNotifications = () => {
    this.setState(state => ({
      notifications: state.notifications.filter(item => !item.removable)
    }));
  };

  render = () => {
    const { isUserLoggedIn } = this.props;
    const { notifications } = this.state;

    if (!isUserLoggedIn) {
      return null;
    }

    return (
      <View
        notifications={notifications}
        clearNotifications={this.clearNotifications}
        removeNotification={this.removeNotification}
      />
    );
  };
}

const READ = state => ({
  isUserLoggedIn: state.session.isUserLoggedIn,
  appSettings: state.utils.appSettings,
  userInfo: state.currentUser.folks
});

Notifications.propTypes = {
  // CONNECT:
  isUserLoggedIn: PropTypes.bool.isRequired,
  // czy jest zalogowany użytkownik
  appSettings: PropTypes.object.isRequired,
  // ustawienia użytkownika
  userInfo: PropTypes.object.isRequired
  // dane użytkownika (Folks)
};

export default connect(READ)(Notifications);
