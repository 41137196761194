import React from "react";
// @ts-ignore
import Loadable from "react-loadable";

import { Loader } from "components/shared";

function Loading(props: any) {
  if (props.pastDelay || props.timedOut || props.error) {
    return <Loader {...props} />;
  } else {
    return null;
  }
}

export function lazyComponent<T extends { readonly default?: any }>(importer: () => PromiseLike<T>): T["default"];
export function lazyComponent<T>(importer: () => PromiseLike<T>, property: ""): T;
export function lazyComponent<T>(importer: () => PromiseLike<T>, property: keyof T): T[typeof property];
export function lazyComponent<T, K extends keyof T>(importer: () => PromiseLike<T>, property?: K): T[K] {
  const key = property ?? "default";
  
  const props = {
    loader: importer,
    timeout: 10000,
    loading: Loading,
  } as { [key: string]: any };
  
  if (key === "") {
    props.render = (module: T, props: any) => {
      return React.createElement(module as any, props);
    };
  }
  else if (key !== "default") {
    props.render = (module: T, props: any) => {
      return React.createElement(module[key] as any, props);
    };
  }
  
  return Loadable(props);
}