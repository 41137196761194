import Toast from "./Toast";
import {tran} from "../../../utils/language";
import {reportError} from "../../../utils/errorReporting";

export default Toast;

export function toastFromError(err) {
  if (! err.enduserMessage)
    reportError(err);
  Toast.emit(err.enduserMessage || tran("error.operation"), { type: "error" });
};

export function toastEmitter(message, options) {
  return function (arg) {
    Toast.emit(message, options);
    return arg;
  }
}
