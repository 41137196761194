import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { getFont, getThemeByName, setTheme, setFont } from "utils/themes";
import { tran } from "utils/language";
import { UPDATE_SETTINGS } from "sagas/types";

import { Modal } from "components/shared";

import View from "./View";

class Settings extends Component {
  state = {
    values: {
      enableShortcuts: this.props.appSettings.hasOwnProperty("enableShortcuts")
        ? this.props.appSettings.enableShortcuts
        : true,
      enableAnimations: this.props.appSettings.hasOwnProperty(
        "enableAnimations"
      )
        ? this.props.appSettings.enableAnimations
        : true,
      passwordExpirationWarning:
        this.props.appSettings.passwordExpirationWarning || "5",
      font: getFont(this.props.appSettings.font).id,
      theme: getThemeByName(this.props.appSettings.theme).id,
      contrast: this.props.appSettings.contrast
    }
  };

  handleSubmit = () => {
    if (this.state.changed) {
      this.sendToDatabase();
    }
    this.props.close();
  };

  sendToDatabase() {
    const {
      theme,
      font,
      enableShortcuts,
      enableAnimations,
      passwordExpirationWarning,
      contrast
    } = this.state.values;
    this.props.updateSettings({
      theme,
      font,
      enableShortcuts,
      enableAnimations,
      passwordExpirationWarning,
      contrast
    });
  }

  /** Zmiana wartości
   * @param {String} name nazwa zmienianej własności
   * @param {String} value nowa wartość */
  changeValue = (name, value) => {
    if (value === this.state.values[name]) {
      return;
    }

    this.setState(state => {
      const values = { ...state.values };
      values[name] = value;

      if (name === "theme") {
        values["contrast"] = false;
      }
      return { values, changed: true };
    });

    switch (name) {
      case "theme": {
        setTheme(value);
        break;
      }

      case "contrast": {
        if (value === true) {
          setTheme("contrast");
        } else {
          setTheme(this.state.values.theme);
        }
        break;
      }

      case "font": {
        setFont(value);
        break;
      }
      default: {
        return;
      }
    }
  };

  render() {
    const { values } = this.state;

    return (
      <Modal
        displayCloseButton
        displayHeader={false}
        align="left"
        size="wider"
        close={this.props.close}
        content={
          <React.Fragment>
            <View changeValue={this.changeValue} values={values} />
          </React.Fragment>
        }
        buttons={[
          {
            content: tran("unique.save"),
            onClick: this.handleSubmit,
            confirm: true,
            variant: "contained"
          }
        ]}
      />
    );
  }
}

Settings.propTypes = {
  appSettings: PropTypes.object.isRequired,
  // ustawienia użytkownika
  updateSettings: PropTypes.func.isRequired,
  // zapisanie nowych ustawień
  close: PropTypes.func.isRequired
  // zamknięcie okna
};

const READ = state => ({
  appSettings: state.utils.appSettings
});

const EMIT = dispatch => ({
  updateSettings: settings =>
    dispatch({ type: UPDATE_SETTINGS, payload: settings })
});

export default connect(
  READ,
  EMIT
)(Settings);
