import React, { useEffect, useRef } from "react";
import { Form, useNewField, FormDropdownProps, FormIsRequiredProps } from "utils/forms";
import { useFieldMonitor } from "utils/forms/formField";

/** Wypełnianie pola płci użytkownika na podstawie wartości pola z numerem PESEL (jego 10. cyfry)
 *  https://pl.wikipedia.org/wiki/PESEL
*/
export function SexDropdown(props: FormIsRequiredProps & FormDropdownProps<string | undefined> & { suspend: boolean }) {
  const sexField = useNewField<string | undefined>(props.name || "", props.form, props.initial || undefined, undefined, props.extra);
  const peselField = useFieldMonitor<string>("pesel", props.form);

  const prevVal = useRef(props.initial || undefined);
  const setPrevVal = useRef(false);
  const updateVal = useRef(true);

  useEffect(() => {
    if(!peselField || props.suspend) {
      return;
    }

    if(peselField.value === "") {
      return;
    }

    if(peselField.error) {
      if(setPrevVal.current) {
        sexField.set(prevVal.current);
        setPrevVal.current = false;
      }
      return;
    }

    // Jeśli 5 ostatnich cyfr numeru PESEL to "00000", to nie wypełniamy pola płci.
    // Obcokrajowcy nie mają peseli, dlatego przyjęte zostało, że można wypełnić to pole wartością YYMMDD00000 (np. 76102000000).
    const foreignerPesel = peselField.value.substr(-5) === "00000";

    if(foreignerPesel) {
      return;
    }

    // wyciągamy z numeru PESEL 10. cyfrę oznaczającą płeć 
    let sex = peselField.value.substring(9, 10);

    // dekodujemy płeć (0, 2, 4, 6, 8 = kobieta; 1, 3, 5, 7, 9 = mężczyzna)
    sex = +sex % 2 === 0 ? "K" : "M"

    prevVal.current = sexField.value;
    setPrevVal.current = true;
    updateVal.current = false;
    sexField.set(sex);
  }, [peselField?.value, peselField?.error]);

  useEffect(() => {
    updateVal.current
      ? prevVal.current = sexField.value
      : updateVal.current = true
  }, [sexField.value]);

  return <Form.Dropdown<string | undefined> {...props} field={sexField}/>
};
