import React from "react";
import { IDialog } from "../../../../../shared/Dialog/useDialog";
import { FormDialog } from "../../../../../shared/Dialog/comps";
import { Form } from "../../../../../../utils/forms";
import { Input } from "../../../../../shared";
import { tran } from "../../../../../../utils/language";
import { Dictionary } from "../../../../../../utils/types";

export type LockedPropDialogProps = {
  dialog: IDialog
  value: string
}

export function LockedPropDialog({ dialog, value }: LockedPropDialogProps) {
  const whitespace = /\s+/g;
  const prepValue = value.replace(whitespace, " ").trim().toUpperCase();
  
  return <FormDialog name="locked-prop-dialog" size="medium" title={tran("locked_props.hdr")} savePreset="apply" onSubmit={submit} dialog={dialog}>
    <p style={{ fontSize: "1.1em" }}>
      {tran("locked_props.info1")}<br/>
      {tran("locked_props.info2")}
    </p>
    <Input disabled className="pre" value={value} fluid />
    <Form.ValidatedInput name="newValue" initial={value} className="pre" fluid required validationFunc={compare} invalidmessage={tran("locked_props.diff")} />
  </FormDialog>
  
  function compare(newValue: string) {
    return newValue.replace(whitespace, " ").trim().toUpperCase() === prepValue;
  }
  
  function submit(values: Dictionary) {
    return values.newValue;
  }
}
