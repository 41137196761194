import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import * as types from "sagas/types";
import { Button } from "components/shared";
import { setTheme } from "utils/themes";
import { tran } from "utils/language";

class ContrastSwitch extends React.Component {
  state = {
    isContrastMode: false
  };

  componentDidMount() {
    sessionStorage.removeItem("isContrastMode");
  }

  componentDidUpdate = prevProps => {
    if (this.props.appSettings.contrast !== prevProps.appSettings.contrast) {
      this.setState({
        isContrastMode: Boolean(this.props.appSettings.contrast)
      });
    }
  };

  handleCheckboxChange = () => {
    if (this.props.isUserLoggedIn) {
      const settings = this.props.appSettings;

      this.props.updateSettings({
        contrast: !settings.contrast
      });
    } else {
      const isContrastMode = !this.state.isContrastMode;
      sessionStorage.setItem("isContrastMode", isContrastMode);
      
      setTheme(isContrastMode ? "contrast" : "");

      this.setState({
        isContrastMode: !this.state.isContrastMode
      });
    }
  };

  render() {
    return (
      <Button
        icon="adjust"
        onClick={this.handleCheckboxChange}
        title={tran("pages.settings.content.themes.contrast")}
      />
    );
  }
}

ContrastSwitch.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired,
  // ustawienia użytkownika
  updateSettings: PropTypes.func.isRequired,
  appSettings: PropTypes.object.isRequired
  // lista dostępnych skrótow klawiszowych
};

const READ = state => ({
  isUserLoggedIn: state.session.isUserLoggedIn,
  appSettings: state.utils.appSettings
});

const EMIT = dispatch => ({
  updateSettings: settings =>
    dispatch({ type: types.UPDATE_SETTINGS, payload: settings })
});

export default connect(
  READ,
  EMIT
)(ContrastSwitch);
