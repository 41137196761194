import React from "react";
import PropTypes from "prop-types";
import { Button } from "components/shared";
import {useSmallOrMediumScreen} from "../../utils/hooks";

const Pagination = ({ hideChevron, hideDoubleChevron, hideNumbers, ...props }) => {
  const defaultProps = {
    onChange: props.onChange || props.changeActivePage,
    activePage: props.activePage,
    totalPages: props.totalPages,
  };
  
  return (
    <div className="sowa paging">
      <div className="pagination">
        {!hideChevron && !hideDoubleChevron && <Chevron side="left" double {...defaultProps} />}
        {!hideChevron && <Chevron side="left" {...defaultProps} />}
        {!hideNumbers && <Numbers {...defaultProps} />}
        {!hideChevron && <Chevron side="right" {...defaultProps} />}
        {!hideChevron && !hideDoubleChevron && <Chevron side="right" double {...defaultProps} />}
      </div>
    </div>
  );
};

const Numbers = ({ totalPages, activePage, onChange }) => {
  const handleChange = number => (activePage !== number ? onChange(Number(number)) : null);
  const isMobile = useSmallOrMediumScreen();
  const MAX_PAGES = isMobile ? 5 : 7;

  return (
    <div className="numbers">
      {doPaging(activePage, totalPages, 1, totalPages > MAX_PAGES ? MAX_PAGES : totalPages).map((e, i) => (
        <div
          key={e}
          className={`number ${Number(activePage) === Number(e) ? "active" : ""}`}
          onClick={() => handleChange(e)}>
          {e}
        </div>
      ))}
    </div>
  );
};

const Chevron = ({ side = "left", double = false, onChange, activePage, totalPages }) => {
  const handleChange = () => {
    if (side === "left") onChange(double ? 1 : activePage - 1);
    if (side === "right") onChange(double ? totalPages : activePage + 1);
  };
  const isMobile = useSmallOrMediumScreen();
  const MAX_PAGES = isMobile ? 5 : 7;

  if (double && totalPages <= MAX_PAGES) return null;

  const disabled = side === "left" ? activePage <= 1 : activePage >= totalPages;

  const props = {
    variant: "icon",
    disabled,
    onClick: handleChange,
    size: "small",
    className: double ? "hide-on-small-only" : "",
    preset: getChevronPreset(double, side)
  };

  return <Button {...props} />;
};

/**
 * @description Calculates the page nummering range.
 * @param {number} current
 * @param {number} range
 * @param {number} pages
 * @param {number} start
 * @returns {array} range
 */
function doPaging(current, pages, start = 1, range = 5) {
  if (!pages) return [];
  if (range === 1) return [current];

  const pagin = [];
  var i = Math.min(pages + start - range, Math.max(start, current - ((range / 2) | 0)));
  const end = i + range;
  while (i < end) {
    pagin.push(`${i++}`);
  }
  return pagin;
}

Pagination.propTypes = {
  activePage: PropTypes.number.isRequired,
  changeActivePage: PropTypes.func.isRequired,
  // funkcja zmieniająca aktywną stronę; jako parametr przyjmuje nową aktywną stronę
  totalPages: PropTypes.number.isRequired,
  hideChevron: PropTypes.bool,
  hideDoubleChevron: PropTypes.bool,
  hideNumbers: PropTypes.bool,
};

export default Pagination;

function getChevronPreset(double, side){
  if (double) {
    if (side === "left") {
      return "paginationFirst"
    } else return "paginationLast"
  } else {
    if (side === "left") {
      return "paginationPrev"
    } else return "paginationNext"
  }
}
