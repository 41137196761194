export function makeIconMaps(chars: string, names: string) {
  const result = new Map<string, string>();
  const regular = new Set<string>();
  const solid = new Set<string>();
  
  let i = 0;
  let from = 0;
  const L = names.length;
  do {
    let to = names.indexOf(";", from);
    if (to < 0) to = L;
    const char = chars[i++];
    const aliases = names.substring(from, to).split(",");
    for (let alias of aliases) {
      if (alias.startsWith("^")) {
        alias = alias.substring(1);
        regular.add(alias);
      }
      else if (alias.startsWith("*")) {
        alias = alias.substring(1);
        solid.add(alias);
      }
      result.set(alias, char);
    }
    from = to + 1;
  } while (from < L);
  
  return [result, regular, solid];
}
