import {
  SHORTCUTS_SUCCESS,
  SETTINGS_SUCCESS,
  DISPLAY_JOURNAL,
  HIDE_JOURNAL,
  DICTIONARIES_ERROR,
  DICTIONARIES_SUCCESS,
  DISPLAY_FOOTER,
  HIDE_FOOTER
} from "../sagas/types";
import { localThemeSave } from "../utils/themes";

const initialState = {
  shortcutList: [],
  appSettings: {},
  displayJournal: false,
  displayFooter: true,
  enumDictionaries: {
    error: null,
    data: {}
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case DISPLAY_JOURNAL: {
      return {
        ...state,
        displayJournal: true
      };
    }

    case HIDE_JOURNAL: {
      return {
        ...state,
        displayJournal: false
      };
    }

    case DISPLAY_FOOTER: {
      return {
        ...state,
        displayFooter: true
      };
    }

    case HIDE_FOOTER: {
      return {
        ...state,
        displayFooter: false
      };
    }

    case SHORTCUTS_SUCCESS: {
      return {
        ...state,
        shortcutList: action.payload
      };
    }

    case SETTINGS_SUCCESS: {
      const newSettings = action.payload;
      localThemeSave(newSettings.contrast ? "contrast" : newSettings.theme, newSettings.font);
      return {
        ...state,
        appSettings: action.payload
      };
    }

    case DICTIONARIES_ERROR: {
      return {
        ...state,
        enumDictionaries: {
          error: action.payload,
          data: {}
        }
      };
    }

    case DICTIONARIES_SUCCESS: {
      return {
        ...state,
        enumDictionaries: {
          error: null,
          data: action.payload
        }
      };
    }

    default: {
      return state;
    }
  }
}
