import {useEffect} from "react";

export function useOnMessage(kind: string, callback: (message: any) => void) {
  useEffect(() => {
    addListener(kind, callback)
    return () => removeListener(kind, callback);
  })
}

let SIGNALS = new Map<string, Set<(message: any) => void>>();

function onMessage(e: MessageEvent) {
  if (e.type !== "message") return;
  
  const message = e.data;
  if (!message) return;
  
  const signals = SIGNALS.get(message.kind);
  if (!signals) return;
  
  for (let signal of signals) {
    setTimeout(signal, 0, message);
  }
}

function addListener(kind: string, signal: (message: any) => void) {
  if (SIGNALS.size === 0)
    window.addEventListener("message", onMessage);
  
  let signals = SIGNALS.get(kind);
  if (!signals) {
    signals = new Set<(message: any) => void>();
    SIGNALS.set(kind, signals);
  }
  
  signals.add(signal);
}

function removeListener(kind: string, signal: (message: any) => void) {
  let signals = SIGNALS.get(kind);
  if (signals) {
    if (signals.size < 2)
      SIGNALS.delete(kind);
    else
      signals.delete(signal);
  }
  
  if (SIGNALS.size === 0)
    window.removeEventListener("message", onMessage);
}