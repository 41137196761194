import React from "react";
import store from "store";
import { isLoaded } from "capi/hooks";
import { sessionClientByPath } from "capi/appclient";
import * as Folks from "capi/folks";
import { Form, FormInputProps, useField, useFormState, useNewField } from "utils/forms";
import { LanguageInfo, tran } from "utils/language";
import { isPeselValid } from "utils/validate";
import { PropFormField, UserFormElement } from "./types";
import { getLowerOrEqualRoles, getRolesByRate, isRoleHigherOrEqual, isSenior } from "utils/roles";
import { FormState } from "utils/forms/formState";
import { FormField } from "utils/forms/formField";
import { Dictionary } from "utils/types";
import { ColumnView } from "components/shared";
import { BirthDateInput } from "../autofilledFormElems/BirthDateInput";
import { SexDropdown } from "../autofilledFormElems/SexDropdown";
import { alwaysTrue, emptyObject } from "../../../../../../utils/constants";
import { CapiClient } from "../../../../../../capi/client";
import { EnumDict } from "../../../../../../capi/enumDict";
import { Button, FlexWrapper } from "../../../../../shared";
import { LockedPropDialog, LockedPropDialogProps } from "./LockedPropDialog";
import { useDialog } from "../../../../../shared/Dialog";
import { finishImmediateBatchUpdates, startImmediateBatchUpdates } from "../../../../../../utils/hooks/batch";

export function preparePropFormModel(formDataRq: any, propValues: { [name: string]: { val: string, sta: string } } = emptyObject): PropFormField[] {
  if (!isLoaded(formDataRq))
    return [];
  
  return formDataRq.data.map((field: UserFormElement) => {
    const pv = propValues[field.name];
    const locked = pv ? pv.sta.startsWith("L") : false;
    return {
      name: field.name,
      desc: field.desc,
      type: field.type,
      options: field.choices
        ? field.choices.map(choice => {
          return {
            text: choice.desc,
            value: choice.name
          };
        })
        : [],
      required: !locked && field.required,
      initial: pv ? pv.val : undefined,
      locked: locked,
      disabled: locked,
    }
  });
}

// również prezentuje pola których nie ma w formDataRq ale są w propValues
export function preparePropFormModelWithMissing(formDataRq: any, enums: any, propValues: { [name: string]: { val: string, sta: string } } = emptyObject): PropFormField[] {
  const model = preparePropFormModel(formDataRq, propValues);
  
  const present = {} as Dictionary<boolean>;
  for (let i in model)
    present[model[i].name] = true;
  
  const userProp = enums.enums.user_property.byKey as Dictionary<any>
  const others = [];
  for (let name in propValues) {
    if (present[name]) continue;
    const pv = propValues[name];
    others.push({
      name,
      desc: userProp[name].desc,
      type: "string",
      options: [],
      initial: pv.val || "",
      required: false,
      disabled: true
    } as PropFormField);
  }
  others.sort((l, r) => l.name.localeCompare(r.name));
  
  return others.concat(model);
}

export function preparePropFormInputs(language: LanguageInfo, roleForm: PropFormField[], isFormReloading?: boolean) {
  let suspendSexFromPesel = false; // FIXME: działa tylko jeśli PESEL występuje przed płcią
  return roleForm.map(field => {
    const icon = field.locked ? "lock" : undefined;
    const title = field.locked ? language.translate("users.profile.prop.lockedBySSO") : undefined;
    const extra = field.locked ? "locked" : undefined; // używamy by przekazać to info do submita
    
    switch(field.type) {
      case "pesel":
        suspendSexFromPesel = !!field.locked && !isPeselValid(field.initial);
        return (
          <ColumnView.Item
            key={field.name}
            required={field.required}
            name={field.desc}
            value={
              <Form.ValidatedInput
                type="text" // zamiana `pesel` na `text`
                name={field.name}
                initial={field.initial}
                fluid={true}
                required={field.required}
                validationFunc={field.locked ? alwaysTrue : isPeselValid}
                invalidmessage={tran("error.pesel")}
                disabled={field.disabled || isFormReloading}
                icon={icon} title={title} extra={extra}
              />
            }
          />
        );

      case "date": // = birth_date
        return (
          <ColumnView.Item
            key={field.name}
            required={field.required}
            name={field.desc}
            value={
              <BirthDateInput
                type="date"
                name={field.name}
                initial={field.initial}
                fluid={true}
                required={field.required}
                invalidmessage={tran("error.provideValidDate")}
                disabled={field.disabled || isFormReloading}
                icon={icon} title={title} extra={extra}
              />
            }
          />
        );

      case "enum":
        // dodajemy pustą opcję i disablujemy ją lub nie dla pól (nie)wymaganych
        if(!field.options.find(opt => opt.value === undefined)) {
          field.options.push({
            text: <span style={{ fontStyle: "italic" }}>{tran("unique.notSelected")}</span>,
            value: undefined,
            disabled: field.required
          });
        }
        
        let dropdown;
        if (field.locked) {
          // zablokowany dropdown nie ma sensu, a tworzy dodatkowe problemy
          // jeśli wartość nie zawiera się w zdefiniowanych opcjach
          // i nie ma ikony kłódki
          
          // TODO: chcielibyśmy zawsze wyświetlić tutaj tekst opcji zamiast gołej wartości,
          //       ale tekst opcji może być elementem, czego nie podamy inputowi,
          //       więc potrzebny byłby pasywny komponent wyglądający jak input;
          //       na szczęście wartości zablokowanych nie wysyłamy do serwera, więc
          //       nie przeszkadza nam, że input trzyma wartość spoza słownika
          
          let opt = field.options?.find(opt => opt.value === field.initial);
          let initial = opt && typeof opt.text === "string" ? opt.text : field.initial;
          
          dropdown = <Form.Input
            initial={initial}
            type="text"
            name={field.name}
            fluid={true}
            required={field.required}
            disabled={true}
            icon="lock"
            title={title}
            extra="locked"
          />
        }
        else if (field.name === "sex") {
          dropdown = <SexDropdown
            name={field.name}
            initial={field.initial || ""}
            fluid={true}
            options={field.options}
            disabled={field.disabled || isFormReloading}
            title={title}
            suspend={suspendSexFromPesel}
            extra={extra}
          />
        }
        else {
          dropdown = <Form.Dropdown
            name={field.name}
            initial={field.initial || ""}
            fluid={true}
            options={field.options}
            disabled={field.disabled || isFormReloading}
            title={title}
            extra={extra}
          />
        }

        return (
          <ColumnView.Item
            key={field.name}
            required={field.required}
            name={field.desc}
            value={dropdown}
          />
        );

      // pozostałe typy (np. `multiline`, `string`, no i oczywiście `text`) są "zamieniane" na `text`
      default:
        const Component = field.locked ? LockedEditableInput : Form.Input;
        return (
          <ColumnView.Item
            key={field.name}
            required={field.required}
            name={field.desc}
            value={
              <Component
                initial={field.initial}
                type="text"
                name={field.name}
                fluid={true}
                required={field.required}
                disabled={field.disabled || isFormReloading}
                icon={icon} title={title} extra={extra}
              />
            }
          />
        );
    }
  });
}

function LockedEditableInput(props: FormInputProps) {
  const dialog = useDialog(LockedPropDialog);
  const field = useNewField<string>(props.name as string, undefined, props.initial as string);
  
  dialog.set("value", field.value);
  dialog.onClose(newValue => typeof newValue === "string" && field.set(newValue));
  
  return <FlexWrapper alignItems="baseline">
    <Form.Input {...props} field={field}/>
    <Button variant="icon" icon="font" onClick={dialog.open} title="Zmień wielkość liter" />
  </FlexWrapper>
}

export function getRoleOptions(currentUserRole: any, userRole: any | null, mode: "add" | "edit", selectedRole: string | undefined, folksEnums: EnumDict, userLogin?: string) {
  const editorRole = currentUserRole;
  
  const current = mode === "edit" ? userRole : null;
  let options = [current || "patron"];
  
  if (isRoleHigherOrEqual(editorRole, "admin"))
    options = Object.keys(getLowerOrEqualRoles(editorRole));
  else if (isSenior(editorRole)) {
    options = Object.keys(getLowerOrEqualRoles("staff"));
  }
  else if (isRoleHigherOrEqual(editorRole, "staff"))
    options = Object.keys(getLowerOrEqualRoles("patron"));
  // nie ma sensu ręczne dodawanie aspirantów
  if (mode === "add" || selectedRole !== "aspirant")
    options = options.filter(role => role !== "aspirant");
  
  // nie można cofnąć pracownika do czytelnika
  if (current && isRoleHigherOrEqual(current, "staff"))
    options = options.filter(role => isRoleHigherOrEqual(role, "staff"));
  
  if (!userLogin) {
    // czytelnik nie może zostać pracownikiem 
    if (current && getRolesByRate(0, 20).includes(current))
      options = options.filter(role => getRolesByRate(0, 20).includes(role));
  }

  options = options.map(role => ({
    text: folksEnums.folks_role.byKey[role].desc,
    value: role
  }));

  if(!selectedRole)
    options.push({
      text: <span style={{ fontStyle: "italic" }}>{tran("unique.notSelected")}</span>,
      value: undefined
    });

  return options;
};

/** Utworzenie lub aktualizacja loginu, etykiety, adresu e-mail, roli lub propsów użytkownika. */
export async function submitUser(this: FormState, /* dostarcza formularz */
                                 client: CapiClient | null, /* dostarcza programista */
                                 userId: string | null,
                                 assumeKind: string | null,
                                 values: Dictionary<string> /* dostarcza formularz */) {
  const folks = client || sessionClientByPath(store, "folks");
  
  const { login, label, email, role, ...props } = values;
  
  for (let k in props) {
    // pól zablokowanych i niezmienionych nie wysyłamy, by nie przechodziły walidacji
    const field = this.fields.get(k)!;
    if (field.extra === "locked" && !field.isDirty)
      delete props[k];
    
    // pusta opcja wybrana w dropdownie ma wartość `undefined` co by nie ustawiło wartości na serwerze
    else if (props[k] === undefined)
      props[k] = "";
  }
  
  let command;
  
  if(userId) {
    command = new Folks.UserChange(userId, { login, label, role, props, assume_kind: assumeKind || undefined });
  } else {
    type UserCreateKwargs = {
      role: string,
      props: Dictionary<string>,
      gen_pass: boolean,
      init: boolean,
      addresses?: {address: string, kind: string}[]
    }

    const userCreateKwargs: UserCreateKwargs = { role, props, gen_pass: role === "spectator" ? false : true, init: true };

    // Po wysłaniu pustego stringa serwer zwraca błąd, więc nie wysyłamy emaila, gdy nie ma uzupełnionej wartości...
    if(email !== "")
      userCreateKwargs.addresses = [{ address: email, kind: "umail" }];

    // Gość nie ma pola loginu, więc musimy wysłać pusty string zamiast `undefined`, żeby uniknąć błędu serwera
    command = new Folks.UserCreate(login !== undefined ? login : "", label, userCreateKwargs);
  }

  const [resp] = await folks.execute(command);
  const { result } = resp;
    
  switch (result.reason) {
    case "duplicate_login":
      this.fields.get("login")!.setError(tran("users.form.error.duplicateLogin"));
      this.focusFirstError();
      return;

    case "duplicate_email":
      this.fields.get("email")!.setError(tran("users.form.error.duplicateEmail"));
      this.focusFirstError();
      return;
      
    case "invalid_login":
    case "unauthorized_login":
      this.fields.get("login")!.setError(result.message);
      this.focusFirstError();
      return;
      
    case "invalid_label":
      this.fields.get("label")!.setError(result.message);
      this.focusFirstError();
      return;
  }
    
  // TODO Tu wyświetlamy komunikat, który przychodzi z serwera...
  const propErrors = result.data?.prop_errors;
  if (propErrors) {
    startImmediateBatchUpdates();
    try {
      for (let k in propErrors) {
        const field = this.fields.get(k) as FormField | undefined;
        if (field) {
          field.setError(propErrors[k]);
        }
      }
    }
    finally {
      finishImmediateBatchUpdates();
    }
    
    this.focusFirstError();
    
    return;
  }
  
  resp.ensure(200, 204);
  
  return { userId: result.data?.user_id || userId, login, password: result.data?.password as string, email, role };
}