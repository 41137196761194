import React, { Component } from "react";
import { Modal, Loader } from "components/shared";

import * as Folks from "capi/folks";
import bootstrap from "bootstrap";

import { translate } from "utils/language";

class ConfirmationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmed: false, // E-Mail confirmed?
      loading: true, // Is Loading?
      error: null, // Error occured?
      owned: null, // Is E-Mail owned by other user
      token: null // Token used in this transaction
    };
  }

  componentDidMount() {
    this.confirm(this.extractToken(this.props.location.hash));
  }

  extractToken(hash) {
    let token = null;

    // Check if query exists
    if (hash === "" || hash === undefined || hash === null) {
      this.setState({
        loading: false,
        confirmed: false,
        error: translate("emailConfirm.error.noToken")
      });
      return null;
    }

    // Remove first hash character
    token = hash.substring(1, hash.length);

    // Decode token
    try {
      token = decodeURIComponent(token);
    } catch(err) {
      console.error(err);
    }

    // Check if token looks alright
    if (token === null && token.length > 0) {
      this.setState({
        loading: false,
        confirmed: false,
        error: translate("emailConfirm.error.noToken")
      });
      return null;
    }

    return token;
  }

  confirm = token => {
    if (token === null) return;

    this.setState({ loading: true, token: token });

    const CapiCli = bootstrap.folks.client;

    CapiCli.execute(new Folks.AddressVerify(token))
      .then(response => {
        const result = response[0].result;
        let error = null;
        let success = false;
        let message = null;
        let owned = null;
        console.log(response);
        switch (result.status) {
          case 409:
            owned = result.data.email_owned_by;
            message = result.message;
            break;
          case 204:
            success = true;
            break;
          default: {
            error = result.message;
            break;
          }
        }

        this.setState({
          loading: false,
          confirmed: success,
          error: error,
          owned: owned,
          message: message
        });
      })
      .catch(err => {
        console.error(err.toString());

        this.setState({
          error: err.toString(),
          loading: false
        });
      });
  };

  confiscate = (token, email) => {
    if (token === null) return;

    this.setState({ loading: true });

    const CapiCli = bootstrap.folks.client;

    CapiCli.execute(new Folks.AddressVerify(token, email))
      .then(response => {
        const result = response[0].result;
        let error = null;
        let success = false;
        switch (result.status) {
          case 204:
            success = true;
            break;
          default: {
            error = result.message;
            break;
          }
        }

        this.setState({
          loading: false,
          error: error,
          finish: success
        });
      })
      .catch(err => {
        console.error(err.toString());

        this.setState({
          error: err.toString(),
          loading: false
        });
      });
  };

  redirectToHomePage = () => {
    this.props.history.push("/");
  };

  render() {
    return (
      <Modal
        error={
          this.state.error ||
          (!this.state.confirmed && !this.state.loading && !this.state.owned)
        }
        headerIcon={
          this.state.error
            ? "exclamation-circle"
            : this.state.loading || (this.state.owned && !this.state.finish)
            ? "mail"
            : this.state.confirmed || this.state.finish
            ? "check-circle"
            : "exclamation-circle"
        }
        content={
          this.state.error ? (
            <React.Fragment>{this.state.error}</React.Fragment>
          ) : this.state.loading ? (
            <React.Fragment>
              <Loader inline text={translate("unique.loading") + "..."} />
            </React.Fragment>
          ) : this.state.finish ? (
            <React.Fragment>
              <p>{translate("emailConfirm.success.move")}.</p>
            </React.Fragment>
          ) : this.state.owned ? (
            <React.Fragment>
              <p>{this.state.message}</p>
            </React.Fragment>
          ) : this.state.confirmed ? (
            <React.Fragment>
              <p>{translate("emailConfirm.success.confirm")}</p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p>{translate("emailConfirm.error.confirm")}.</p>
            </React.Fragment>
          )
        }
        buttons={
          !this.state.loading
            ? this.state.error
              ? [
                  {
                    onClick: this.redirectToHomePage,
                    content: translate("unique.goToApp"),
                    variant: "text",
                    focus: true
                  }
                ]
              : this.state.owned && !this.state.finish
              ? [
                  {
                    variant: "contained",
                    inverted: true,
                    onClick: this.redirectToHomePage,
                    content: translate("unique.cancel"),
                    exit: true
                  },
                  {
                    onClick: () =>
                      this.confiscate(this.state.token, this.state.owned),
                    content: translate("emailConfirm.move"),
                    focus: true
                  }
                ]
              : this.state.confirmed || this.state.finish
              ? [
                  {
                    color: "confirm",
                    onClick: this.redirectToHomePage,
                    content: translate("unique.continue"),
                    focus: true
                  }
                ]
              : [
                  {
                    variant: "text",
                    onClick: this.redirectToHomePage,
                    content: translate("unique.tryAgain"),
                    focus: true
                  }
                ]
            : []
        }
      />
    );
  }
}

export default ConfirmationPage;
