import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

class Row extends React.Component {
  ref = React.createRef();

  componentDidUpdate(prevProps) {
    if (this.props.focus && this.props.focus !== prevProps.focus)
      this.ref.current.focus();
  }

  handleRowClick = (e) => {
    if (
      e.target.tagName !== "LABEL" &&
      e.target.tagName !== "A" &&
      e.target.tagName !== "BUTTON" &&
      e.target.tagName !== "CANVAS"
    ) {
      // sprawdzanie, czy row ma propsa 'onClick' i czy kliknięcie było złapane przez <td>, a nie np. jakis checkbox
      this.props.onClick(e);
    }
  };

  handleRowDoubleClick = (e) => {
    if (
      e.target.tagName !== "LABEL" &&
      e.target.tagName !== "A" &&
      e.target.tagName !== "BUTTON" &&
      e.target.tagName !== "CANVAS"
    ) {
      // sprawdzanie, czy row ma propsa 'onClick' i czy kliknięcie było złapane przez <td>, a nie np. jakis checkbox
      this.props.onDoubleClick(e);
    }
  };

  render() {
    const {
      children,
      style,
      className,
      rounded,
      active,
      onClick,
      onDoubleClick,
      ...rest
    } = this.props;

    const _style = {
      position: "relative",
      cursor:
        this.props.onClick ||
        this.props.onDoubleClick
          ? "pointer"
          : "inherit",
      ...style
    };

    const classes = cx(
      rounded && "rounded",
      { active: active },
      className
    );

    return (
      <tr
        tabIndex={onClick ? "0" : undefined}
        className={classes}
        style={_style}
        onClick={onClick ? this.handleRowClick : undefined}
        onDoubleClick={onDoubleClick ? this.handleRowDoubleClick : undefined}
        ref={this.ref}
        {...rest}
      >
        {children}
      </tr>
    );
  }
}

Row.propTypes = {
  rounded: PropTypes.bool,
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  children: PropTypes.any,
  active: PropTypes.bool,
  focus: PropTypes.bool
};

export default Row;
