import React from "react";
import { useDispatch } from "react-redux";

import { Button } from "components/shared";

import { tran } from "utils/language";
import { LOGOUT } from "sagas/types";
import { Dialog } from "../../shared/Dialog";
import Icon from "../../shared/Icon";

export function LogoutDialog ({ dialog }) {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch({ type: LOGOUT });
  };
  
  return (
    <Dialog
      type="simple"
      size="small"
      children={<div>
        <Icon name="power off" style={{ fontSize: "2em", color: "var(--text)" }}/>
        <br/>
        <br/>
        {tran("unique.logoutWarning")}
      </div>}
      buttons={<>
        <Button preset="cancel" onClick={dialog.close} />
        <Button color="error" content={tran("unique.logout")} focus onClick={logout} />
      </>}
      dialog={dialog}
    />
  );
};

LogoutDialog.propTypes = {};
