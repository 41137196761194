import React, { useState } from "react";
import { tran } from "utils/language";
import { Button, Input, Message } from "components/shared";
import { DialogPresets } from "components/shared/Dialog";
import { BrandedDialog } from "components/shared/Dialog/comps";
import useDialog, { IDialog } from "components/shared/Dialog/useDialog";
import { useBind, useId } from "../../../utils/hooks";
import { usePasswordValidation } from "../../common/PasswordForm/usePasswordValidation";
import { isLoaded } from "../../../capi/hooks";
import { Requirements } from "../../common/PasswordForm/Requirements";
import { RequestDialog } from "../../shared/requestUtils";
import * as Folks from "capi/folks";
import bootstrap from "bootstrap";
import { useHistory } from "react-router-dom";
import { ConfirmationDialog } from "./ConfirmationDialog";

type PasswordRecoveryFormDialogProps = {
  rule_idf: string,
  token: string,
  dialog: IDialog
};

export function PasswordRecoveryFormDialog({ rule_idf = "default", token, dialog }: PasswordRecoveryFormDialogProps) {
  const passwordValidationState = usePasswordValidation(rule_idf);

  const [error, setError] = useState<React.ReactNode>("");

  const onSubmit = useBind(resetPassword, token).preventDefault();

  const history = useHistory();
  
  const confirmationDialog = useDialog(ConfirmationDialog)
    .onClose(() => history.replace("/"));
  
  const formId = useId();
  
  if (!isLoaded(passwordValidationState.paramsRq))
    return <RequestDialog request={passwordValidationState.paramsRq} dialog={dialog} title={tran('passRecovery.title')} />;
  
  const buttons = <>
    <Button preset="cancel" onClick={dialog.close} />
    <Button preset="save" onClick={onSubmit} form={formId} type="submit" />
  </>;

  return (
    <DialogPresets.BrandedDialog size="medium" dialog={dialog} buttons={buttons}>
      <form id={formId} onSubmit={onSubmit}>
        <BrandedDialog.Heading>
          {tran("passRecovery.title")}
        </BrandedDialog.Heading>

        {passwordValidationState.errors.length > 0
          ? <Message type="error" content={<div>{passwordValidationState.errors.map(el => <div>{el}</div>)}</div>} />
          : error && <Message type="error" content={error} />
        }

        <Input
          labelCls="custom-input__label--login"
          {...passwordValidationState.newPasswordProps}
          fluid
          required
          icon={undefined}
          enablePasswordPreview
        />
    
        <Input
          labelCls="custom-input__label--login"
          {...passwordValidationState.confirmPasswordProps}
          fluid
          required
          icon={undefined}
          enablePasswordPreview
        />
        
        <Requirements {...passwordValidationState.requirementsProps} cls="login-form__pass-requirements" />
      </form>
    </DialogPresets.BrandedDialog>
  );

  async function resetPassword(token: string) {
    if (!passwordValidationState.validate())
      return;
    
    const results = await bootstrap.folks.client.execute(new Folks.UserEmailResetFinish(token, passwordValidationState.values.newPassword));
    const [result] = results;

    if(result.result.message) {
      setError(result.result.message);
      return;
    }

    result.ensure(200);
    
    confirmationDialog.openWith({
      expiry: result.result.data.expiry
    });
  }
}
