import {useRef, useState, Dispatch, SetStateAction} from "react";

import {useBind} from "./bind";

/** Zwraca callbacka, który wywołuje podanego callbacka
 *  podaną liczbę milisekund po ostatnim wywołaniu.
 *  Tzn. ogranicza częstość wywołań. */
export function useSteady<T extends (...args: any[]) => void>(fn: T, delay: number): T {
  type State = [
    T,
    T,
    number,
    null | ReturnType<typeof setTimeout> /* typescript coś miesza z sygnaturą tej funkcji... */
  ]
  
  const ref = useRef(null as any as State);
  
  if (ref.current === null) {
    const cb = function() {
      const [_, fn, delay, id] = ref.current;
      
      if (id !== null)
        clearTimeout(id);
      
      ref.current[3] = setTimeout(fn, delay, ...arguments);
    };
    
    ref.current = [cb as any, fn, delay, null];
  }
  
  return ref.current[0];
}

const wrapFn = <T extends (...args: any[]) => any>(fn: T, setState: Dispatch<SetStateAction<T>>) => {
  setState(() => function (this: any) { return fn.apply(this, arguments as any); } as T);
};

/** Tworzy nową tożsamość dla funkcji po wywołaniu zwróconej metody. */
export function useNewFn<T extends (...args: any[]) => any>(fn: T): [T, () => void] {
  if (typeof fn !== "function")
    throw new Error(`useNewFn expects a function, not: ${typeof fn}`);
  
  const [state, setState] = useState(null as null | T);
  const refresh = useBind(wrapFn, fn, setState);
  
  if (state === null) {
    return [fn, refresh];
  }
  
  return [state, refresh];
}
