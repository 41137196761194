import React from "react";
import Dialog from "../Dialog";
import { Button } from "../..";
import { IDialog } from "../useDialog";

type IProps = {
  onSecondary?: () => void
  onPrimary?: () => void
  title?: string;
  description?: string
  type?: "simple" | "header"
  dialog: IDialog
};

/**
 * Dialog containing a question. Expects a user's decision.
 */
const AlertDialog = ({ title, description, onPrimary, onSecondary, ...props }: IProps) => {
  let buttons = [];

  if (onSecondary)  buttons.push(<Button key="onSecondary-cancel-button" preset="cancel" onClick={onSecondary} />);
  if (onPrimary)    buttons.push(<Button key="onPrimary-agree-button" preset="agree" onClick={onPrimary} />);

  return (
    <Dialog buttons={buttons} title={title} {...props}>
      {description}
    </Dialog>
  );
};

export default AlertDialog;
