import React from "react";
import { isLoaded } from "capi/hooks";
import { emptyObject } from "utils/constants";
import { isURLValid } from "utils/validate";
import bootstrap from "bootstrap";
import { RequestDialog } from "components/shared/requestUtils";
import { LoginChoiceDialog } from "./LoginChoiceDialog";
import { LoginLocalDialog } from "./LoginLocalDialog";
import { IDialog } from "components/shared/Dialog/useDialog";
import { useLanguage } from "../../../utils/language";
import { useFolksParams, useSowaParams } from "../../../utils/params/hooks";

const SOWA_CAT_ID = bootstrap.sowa.firstCatalogue?.cat_id;

type LoginDialogProps = {
  dialog: IDialog
}

export function LoginDialog({ dialog }: LoginDialogProps) {
  const language = useLanguage();
  const ssoPromptParam: `sso.messages.${string}.login_prompt` = `sso.messages.${language.serverId.toLowerCase()}.login_prompt`;
  
  const folksParamsRq = useFolksParams(
    "core.signup.default.enabled",
    "sso.general.default.mechanism",
    ssoPromptParam
  );
  
  const sowaParamsRq = useSowaParams(SOWA_CAT_ID, "opac.registration.default.regulations");

  if (!isLoaded(folksParamsRq))
    return <RequestDialog request={folksParamsRq} dialog={dialog} />;
  
  if (SOWA_CAT_ID && !isLoaded(sowaParamsRq))
    return <RequestDialog request={sowaParamsRq} dialog={dialog} />;
  
  const folksParams = folksParamsRq.data!;
  const sowaParams = sowaParamsRq.data!;
  
  const haveSSO = folksParams["sso.general.default.mechanism"] && folksParams["sso.general.default.mechanism"] != "none";
  const isSignupAvailable = !!folksParams["core.signup.default.enabled"];
  const hasRegulations = (sowaParams === emptyObject) || isURLValid(sowaParams["opac.registration.default.regulations"]);
  
  if (haveSSO && (!bootstrap.privateSSO || localStorage.getItem("allow_private_SSO")))
    return (
      <LoginChoiceDialog
        dialog={dialog}
        offerSignup={(isSignupAvailable && hasRegulations) || bootstrap.WZUW}
        ssoPrompt={folksParams[ssoPromptParam]}
      />
    );

  return (
    <LoginLocalDialog
      dialog={dialog}
      offerSignup={isSignupAvailable && hasRegulations}
    />
  );
}