import React from "react";
import { SMALL_BREAKPOINT, useDerivedState, useWindowWidth } from "../../utils/hooks";

export type ColumnLayoutProps = {
  /** Liczba kolumn albo wartość CSS do podania jako grid-template-columns */
  cols?: string | number
  
  /** Liczba wierszy albo wartość CSS do podania jako grid-template-rows,
   *  potrzebne tylko jak używamy `flow=column`
   */
  rows?: string | number
  
  /** Marginesy pionowe */
  vMargin?: number | string
  
  /** Marginesy poziome */
  hMargin?: number | string
  
  /** Marginesy wszystkie */
  margin?: number | string | readonly [number | string, number | string]
  
  /** Odstępy między elementami */
  spacing?: number | string | readonly [number | string, number | string]
  
  /** Skrót ustawiający stretch w pionie i poziomie */
  stretch?: boolean
  
  vAlign?: "start" | "end" | "center" | "stretch" | "baseline"
  hAlign?: "start" | "end" | "center" | "stretch"
  
  hPosition?: "start" | "end" | "center" | "stretch" | "space-around" | "space-between" | "space-evenly"
  vPosition?: "start" | "end" | "center" | "stretch" | "space-around" | "space-between" | "space-evenly"
  
  flow?: "row" | "column" | "row dense" | "column dense"
  
  width?: number | string
  height?: number | string
  
  /** Szerokość okna do łamania i aplikacji liczby kolumn z `responsive` */
  breakpoint?: number
  
  /** Liczba/specyfikacja kolumn do użycia po złamaniu */
  responsive?: number | string | boolean // domyślnie 1fr
  
  /** Wyłączenie łamania */
  unresponsive?: boolean
  
  inline?: boolean
  
  as?: keyof React.ReactHTML
  className?: string
  children?: React.ReactNode
}

// nie wiem czy jest sens pakować w React.memo, bo children będzie się zmieniać co render
// a głębokie porównywanie children może być kosztowne

/**
 * Komponent udostępniający podzbiór funkcjonalności CSS grid w celu prezentacji potomków w kolumnach.
 * 
 * Tak jak CSS grid posiada 2 tryby:
 *   - domyślny flow=row:
 *     wypełnia komórki od lewej do prawej, od góry do dołu
 *     wymaga podania liczby kolumn
 *   - flow=column - wypełnia komórki od góry do dołu, od lewej do prawej
 *     wymaga podania stałej liczby wierszy
 * 
 * Komponent jest responsywny i poniżej zadanego breakpointa włącza alternatywne liczby kolumn i wierszy:
 *   - liczba kolumn ustawiana jest na jedną (albo wartość propsa `responsive`)
 *   - liczba wierszy ustawiania jest na "nieskończoną", a tryb na flow=row  
 */
export function ColumnLayout(props: ColumnLayoutProps) {
  const width = useWindowWidth();
  const breakpoint = props.breakpoint || SMALL_BREAKPOINT;
  const responsive = props.responsive ?? !props.unresponsive;
  const breaking = responsive && (width <= breakpoint);
  const columns = breaking
    ? (typeof responsive === "boolean" ? defaultResponsiveColumns : responsive)
    : props.cols;
  
  const style = useDerivedState(computeStyle,
    columns,
    breaking ? undefined : props.rows,
    breaking ? undefined : props.flow,
    props.hMargin, props.vMargin,
    props.margin, props.spacing,
    props.width, props.height,
    props.stretch ? "stretch" : props.vAlign,
    props.stretch ? "stretch" : props.hAlign,
    props.stretch ? "stretch" : props.vPosition,
    props.stretch ? "stretch" : props.hPosition);
  
  let cls = props.inline ? "column-layout inline" : "column-layout";
  if (props.className)
    cls = `${cls} ${props.className}`
  
  const As = props.as || "div";
  
  return <As className={cls} style={style} role="table">
    {props.children}
  </As>;
}

const defaultSpacing = "1rem";
const defaultResponsiveColumns = "1fr";

function computeStyle(cols: undefined | number | string,
                      rows: undefined | number | string,
                      flow: undefined | string,
                      hMargin: undefined | number | string,
                      vMargin: undefined | number | string,
                      margin: undefined | number | string | readonly [number | string, number | string],
                      spacing: undefined | number | string | readonly [number | string, number | string],
                      width: undefined | number | string,
                      height: undefined | number | string,
                      vAlign: undefined | string,
                      hAlign: undefined | string,
                      vPosition: undefined | string,
                      hPosition: undefined | string) {
  cols = cols || 2;
  const colSpec = typeof cols === "number" ? `repeat(${cols}, 1fr)` : cols;
  
  rows = rows || undefined;
  const rowSpec = typeof rows === "number" ? `repeat(${rows}, min-content)` : rows;
  
  hMargin = hMargin ?? ((typeof margin === "object" ? margin[1] : margin ?? 0) || 0);
  vMargin = vMargin ?? ((typeof margin === "object" ? margin[0] : margin ?? defaultSpacing) || 0);
  
  const hSpacing = ((spacing === "object" ? spacing[1] : spacing) ?? defaultSpacing) as string | number;
  const vSpacing = ((spacing === "object" ? spacing[0] : spacing) ?? defaultSpacing) as string | number 
  
  return {
    marginLeft: hMargin,
    marginRight: hMargin,
    marginTop: vMargin,
    marginBottom: vMargin,
    columnGap: hSpacing,
    gridColumnGap: hSpacing, // FF 60, Chrome 65
    rowGap: vSpacing,
    gridRowGap: vSpacing, // FF 60, Chrome 65
    gridTemplateColumns: colSpec,
    gridTemplateRows: rowSpec,
    alignItems: vAlign || "baseline",
    justifyItems: hAlign,
    justifyContent: hPosition,
    alignContent: vPosition,
    gridAutoFlow: flow,
    width,
    height
  }
}
