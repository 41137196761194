import React from "react";
import cx from "classnames";

const Indicator = ({
  active,
  compact,
  onClick,
  className,
  number,
  loading
}) => {
  const classes = cx(
    "number",
    active && "active",
    compact && "compact",
    loading && active && "loading pulse",
    className
  );

  const inner = active ? number : "";

  return (
    <div className={classes} onClick={onClick}>
      {inner}
    </div>
  );
};

export default Indicator;
