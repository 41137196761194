import React, {useState, useEffect, useRef} from 'react';
import {useHistory, useLocation} from "react-router";

import { Toast, Blankholder, Loader, Button } from 'components/shared';
import bootstrap from 'bootstrap';
import { translate } from 'utils/language';
import { isURLValid } from 'utils/validate';

/**
 * Komponent do wyświetlania zewnętrznych dokumentów HTML.
 * @param {string} resource - string zawierający URL dokumentu lub nazwę pliku z bootstrap, który chcemy wyświetlić.
 */
export const ExternalDocument = React.memo(({ resource, locationScrolling }: { resource: string, locationScrolling?: boolean }) => {
  // Waliduje url, jeśli nie jest poprawne, próbuje wyciągnąć url z plików bootstrap.
  const url = isURLValid(resource) ? resource : bootstrap?.files?.[resource];
  
  const ref = useRef<HTMLDivElement>(null);
  const [document, setDocument] = useState<string | null>(null);
  const [error, setError] = useState("");
  
  const history = useHistory();
  const location = useLocation();
  
  useEffect(() => void handleFetch(), []);
  
  useEffect(handleHashChange, [ref.current, location.hash]);

  if(document === null) return <Loader inline text={translate("externalDocument.loading")} />
  if(error)             return <Blankholder title={error} icon="exclamation-triangle" button={<Button pretext="tryAgain" onClick={handleFetch}  />} />

  return <div ref={ref} className="external-documents" dangerouslySetInnerHTML={{__html: document}}/>;
  
  async function handleFetch() {
    if(!url) {
      setError(translate("externalDocument.error.notFound"));
      return;
    }
    
    try {
      const response = await fetch(url)
      
      switch (response.status) {
        case 200:
          const data = await response.text();
          if (!data) return void setError(translate("externalDocument.error.empty"));
          setDocument(data);
          return;
          
        case 404:
          setError(translate("externalDocument.error.notFound"));
          break;
        
        default:
          setError(translate("error.operation"));
          break;
      }
      
      setDocument(null);
    }
    catch (err) {
      console.error(err);
      setDocument(null);
      setError(translate("externalDocument.error.operation"));
    }
  }
  
  // FIXME: to monopolizuje stronę, tzn. komponent zachowuje się jakby był jedyną treścią strony
  function handleHashChange() {
    if (!locationScrolling)
      return;
    
    if (!location.hash && ref.current) {
      const to = ref.current.firstElementChild;
      to && to.scrollIntoView();
    }
    else if (location.hash) {
      const to = window.document.getElementById(location.hash.substring(1));
      to && to.scrollIntoView();
    }
  }
})
