import React from "react";
import { Icon, Button } from "..";
import { copyTextToTitle } from "../../../utils/dom";

export const toastSignals = new Set();

const AUTOCLOSE_TIME = 6000; // ms

export let toastModule = null;

let toastPromise = null;
function load() {
  if (toastPromise === null)
    toastPromise = import(/* webpackChunkName: "tost" */ "react-toastify").then(module => {
      console.log("toastify", module);
      toastModule = module;
      toastSignals.forEach(signal => signal(module))
      return module;
    });
  return toastPromise;
}

/**
 * @description Enqueue a new Toast.
 * @param {React.ReactNode} message
 * @param {object} options
 * @returns {object} Toast options including toastId
 */
const emit = (
  message,
  options = {
    type: "info",
    timeout: AUTOCLOSE_TIME,
    button: null
  }
) => {
  let opt = parseOptions({ message, ...options });
  const container = renderContainer(opt);

  // Wprowadziłem asynchroniczne ładowanie bez zmiany API, tylko teraz "toastId" zawiera obietnicę a nie idek
  // i nie da się tej wartości drukować
  const _t = load().then(module => [module.toast, module.toast(() => container, opt)]);

  return { ...opt, toastId: _t };
};

/**
 * @description Updates an existing Toast.
 * @param {object} prevOptions Options of the current Toast
 * @param {object} nextOptions Changes for the current Toast
 * @returns {object} New toast options including toastId
 */
const update = (prevOptions, nextOptions) => {
  if (!prevOptions)
    return emit(null, nextOptions);
  
  const toastPromise = prevOptions.toastId;

  // Remove old icon if a new type is provided to avoid showing the old icon.
  if (nextOptions.type && !nextOptions.icon && prevOptions.icon)
    delete prevOptions.icon;

  nextOptions = parseOptions({ ...prevOptions, toastId: undefined, ...nextOptions });
  
  const _t = toastPromise.then(([toast, toastId]) => {
    toast.update(toastId, {
      ...nextOptions,
      type: normalizeType(nextOptions.type),
      render: renderContainer(nextOptions)
    });
    return [toast, toastId];
  });

  return { ...nextOptions, toastId: _t };
};

/**
 * @description Dismisses the Toast.
 * @param {Promise | undefined} toastId
 */
const dismiss = toastId => {
  toastId && (toastId.toastId || toastId).then(([toast, toastId]) => {
    toast.dismiss(toastId);
    return [toast, toastId];
  });
};

/**
 * @description Parses options.
 * @param {object} options
 * @returns {object}
 */
const parseOptions = options => {
  // Backwards compatibility for timeout.
  if (!options.autoClose && options.timeout) 
    options.autoClose = options.timeout || AUTOCLOSE_TIME;

  if(options.timeout) delete options.timeout;

  // Default timeout in ms.
  if (!options.autoClose && options.type !== "wait") options.autoClose = AUTOCLOSE_TIME;

  // Default type
  if (!options.type) options.type = "info";
  options.type = normalizeType(options.type);

  return {...options};
};

/**
 * @description Returns the content of the Toast.
 * @param {object} options
 * @returns {node}
 */
const renderContainer = options => {
  // Get icon and button components.
  const button = renderButton(options.button);
  const icon = renderIcon(options.type, options.icon);

  // Manage custom style for the root component.
  let rootStyle = {};
  if (button) rootStyle.paddingRight = "8px";
  else rootStyle.paddingRight = "16px";

  return (
    <div className="toast-content" style={rootStyle}>
      <div className="toast-info">
        {icon}
        <div onMouseEnter={copyTextToTitle}>
          <p>{options.message}</p>
        </div>
      </div>
      {button}
    </div>
  );
};

/**
 * @description Returns the button component.
 * @param {object} button
 * @returns {node}
 */
const renderButton = button => {
  let btn = null;

  if (button) {
    if (button.text) button.content = button.text;

    btn = (
      <div className="toast-action">
        <Button
          size="small"
          variant="text"
          {...button}
        />
      </div>
    );
  }

  return btn;
};

/**
 * @description Returns the icon component.
 * @param {string} type
 * @returns {node}
 */
const renderIcon = (type = null, icon) => {
  let typeIcon = "";

  if (icon) {
    typeIcon = React.isValidElement(icon) ? (
      icon
    ) : typeof icon === "string" ? (
      <Icon name={icon} size="big" />
    ) : null;
  } else {
    let _i = {
      name: "info",
      size: "big",
      type: normalizeType(type.toLowerCase()),
      style: {}
    };

    switch (type) {
      case "error":
        _i.name = "exclamation-circle";
        break;
      case "warning":
        _i.name = "exclamation triangle";
        break;
      case "success":
        _i.name = "check circle";
        break;
      case "wait":
        _i.name = "hourglass-half";
        _i.style.color = "#ddd";
        break;
      case "info":
        delete _i.type;
        break;
      default:
        typeIcon = null;
        break;
    }

    typeIcon = <Icon {..._i} />;
  }

  return typeIcon ? <div className="toast-icon">{typeIcon}</div> : null;
};

const normalizeType = type => {
  if (["success", "warning", "error", "info", "default"].indexOf(type) <= -1)
    return "default";
  return type;
};

const Toast = {
  emit: emit,
  update: update,
  dismiss: dismiss
};

export default Toast;
