import {
  USERS_LIST_LOADING,
  USERS_LIST_SUCCESS,
  USERS_LIST_ERROR,
  USER_LOADING,
  USER_SUCCESS,
  USER_ERROR,
  USER_TEKA_ERROR,
  USER_TEKA_SUCCESS,
  USER_SOWA_SUCCESS,
  USER_SOWA_ERROR,
  PREVIEW_USERS_LOADING,
  PREVIEW_USERS_SUCCESS,
  APPLICATION_QUEUE_ERROR,
  APPLICATION_QUEUE_SUCCESS,
  APPLICATION_QUEUE_LOADING,
  APPLICATION_INFO_ERROR,
  APPLICATION_INFO_SUCCESS,
  APPLICATION_INFO_LOADING,
} from "../sagas/types";

const initialState = {
  sessions: {
    data: [],
    error: null,
    loading: false
  },
  users: {
    list: {
      error: null,
      data: [],
      loading: false
    },
    list2: {
      error: null,
      data: [],
      loading: false
    },
    user: {
      error: null,
      folks: {},
      teka: {},
      sowa: {},
      loading: false
    },
    preview: {
      loading: false,
      data: []
    }
  },
  applications: {
    queue: {
      editor: {
        error: null,
        data: {},
        loading: false
      },
      access: {
        error: null,
        data: {},
        loading: false
      },
      catalogue: {
        error: null,
        data: {},
        loading: false
      }
    },
    application: {
      error: null,
      data: {},
      loading: false
    }
  },
  issues: {
    list: {
      data: {},
      loading: false,
      error: null
    },
    single: {
      data: {},
      loading: false,
      error: null
    }
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case USERS_LIST_LOADING: {
      return {
        ...state,
        users: {
          ...state.users,
          list: {
            ...state.users.list,
            loading: true
          }
        }
      };
    }

    case USERS_LIST_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          list: {
            loading: false,
            error: null,
            data: action.payload
          }
        }
      };
    }

    case USERS_LIST_ERROR: {
      return {
        ...state,
        users: {
          ...state.users,
          list: {
            loading: false,
            data: [],
            error: action.payload
          }
        }
      };
    }

    case USER_LOADING: {
      return {
        ...state,
        users: {
          ...state.users,
          user: {
            ...state.users.user,
            loading: action.hasOwnProperty("payload") ? action.payload : true
          }
        }
      };
    }

    case USER_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          user: {
            ...state.users.user,
            error: null,
            folks: action.payload
          }
        }
      };
    }

    case USER_ERROR: {
      return {
        ...state,
        users: {
          ...state.users,
          user: {
            ...state.users.user,
            folks: {},
            error: action.payload
          }
        }
      };
    }

    case USER_TEKA_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          user: {
            ...state.users.user,
            teka: action.payload
          }
        }
      };
    }

    case USER_TEKA_ERROR: {
      return {
        ...state,
        users: {
          ...state.users,
          user: {
            ...state.users.user,
            teka: { error: action.payload }
          }
        }
      };
    }

    case USER_SOWA_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          user: {
            ...state.users.user,
            sowa: {
              ...state.users.user.sowa,
              [action.payload.cat_id]: action.payload.data
            }
          }
        }
      };
    }

    case USER_SOWA_ERROR: {
      return {
        ...state,
        users: {
          ...state.users,
          user: {
            ...state.users.user,
            sowa: {
              ...state.users.user.sowa,
              [action.payload.cat_id]: { error: action.payload.error }
            }
          }
        }
      };
    }

    case PREVIEW_USERS_LOADING: {
      return {
        ...state,
        users: {
          ...state.users,
          preview: {
            ...state.users.preview,
            loading: true
          }
        }
      };
    }

    case PREVIEW_USERS_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          preview: {
            data: action.payload,
            loading: false
          }
        }
      };
    }

    case APPLICATION_QUEUE_LOADING: {
      return {
        ...state,
        applications: {
          ...state.applications,
          queue: {
            ...state.applications.queue,
            [action.payload.kind]: {
              ...state.applications.queue[action.payload.kind],
              loading: true
            }
          }
        }
      };
    }

    case APPLICATION_QUEUE_SUCCESS: {
      return {
        ...state,
        applications: {
          ...state.applications,
          queue: {
            ...state.applications.queue,
            [action.payload.kind]: {
              loading: false,
              error: null,
              data: action.payload.data
            }
          }
        }
      };
    }

    case APPLICATION_QUEUE_ERROR: {
      return {
        ...state,
        applications: {
          ...state.applications,
          queue: {
            ...state.applications.queue,
            [action.payload.kind]: {
              loading: false,
              data: {},
              error: action.payload.error
            }
          }
        }
      };
    }

    case APPLICATION_INFO_LOADING: {
      return {
        ...state,
        applications: {
          ...state.applications,
          application: {
            ...state.applications.application,
            loading: true
          }
        }
      };
    }

    case APPLICATION_INFO_SUCCESS: {
      return {
        ...state,
        applications: {
          ...state.applications,
          application: {
            ...state.applications.application,
            loading: false,
            error: null,
            data: action.payload
          }
        }
      };
    }

    case APPLICATION_INFO_ERROR: {
      return {
        ...state,
        applications: {
          ...state.applications,
          application: {
            ...state.applications.application,
            loading: false,
            data: {},
            error: action.payload
          }
        }
      };
    }
    
    default: {
      return state;
    }
  }
}
