import React from "react";
import { Shortcuts } from "react-shortcuts";
import PropTypes from "prop-types";

/**
 * Wrapper do osbsługi skrótów klawiszowych
 */
const ShortcutsWrapper = props => {
  const targetNodeSelector = props.targetNodeSelector
    ? props.targetNodeSelector
    : props.global
    ? "body"
    : null;

  return (
    <Shortcuts {...props} isolate targetNodeSelector={targetNodeSelector}>
      {props.children}
    </Shortcuts>
  );
};

ShortcutsWrapper.defaultProps = {
  name: "MAIN",
  global: false
};

ShortcutsWrapper.propTypes = {
  name: PropTypes.string,
  // namespace zdefiniowane w utils/keymap.js
  global: PropTypes.bool,
  // czy skróty mają być łapane przez całą aplikację, a nie tylko przez renderowany komponent
  handler: PropTypes.func.isRequired,
  // funkcja do obsługi skrótów
  isolate: PropTypes.bool,
  // Use this when a child component has React's key handler (onKeyUp, onKeyPress, onKeyDown). Otherwise, React Shortcuts stops propagation of that event due to nature of event delegation that React uses internally.
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ShortcutsWrapper;
