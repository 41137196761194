import React from 'react';

import { isLoaded, notYet, useTeka } from "../../../../capi/hooks";
import { DocumentInfo } from "../../../../capi/teka";
import { TekaTileLink, TekaTileWithButtons } from "../Tile";
import { getSlideoutForDocument } from "../AltExplorer/Documents/DocTreeSlideout";
import { Button } from "../../../shared";

type DocTileProps = {
  documentId: number,
  newDocumentId?: number,
  big: boolean,
  isEditor: boolean,
  updateTiles: (index: number, documentId: number) => any
  index: number
}

export const ExpoTile = React.memo(function ExpoTile({ documentId, newDocumentId, big, isEditor, updateTiles, index }: DocTileProps) {
  const docRq = useTeka(DocumentInfo, documentId || notYet);
  
  const style = big ? BIG_TILE : SMALL_TILE;
  
  if (documentId && !isLoaded(docRq))
    return <div style={style} />;
  
  const doc = documentId ? docRq.data : undefined; // warunek ważny by przestać wyświetlać dokument po ustawieniu id na 0
  
  const cover = doc && doc.attachments && doc.attachments.find((att: { kind: string; }) => att.kind === "front");
  const imageUrl = cover ? cover.url : undefined;
  
  if (isEditor) {
    const actionButtons = (
      <div style={EDIT_BUTTON_STYLES}>
        {newDocumentId && documentId !== newDocumentId && <Button
          size="small"
          style={{ marginBottom: "10px" }}
          onClick={() => updateTiles(index, newDocumentId)}>
          {doc ? "Zastąp" : "Umieść"}
        </Button>}
        
        {doc && <Button
          size="small"
          preset="delete"
          onClick={() => updateTiles(index, 0)} />}
      </div>
    );
    
    return (
      <TekaTileWithButtons
        imageUrl={imageUrl}
        style={style}
        buttons={actionButtons}
        innerStyle={{ ...SLIDEOUT_STYLE, outline: "1px solid var(--border)" }}
      />
    );
  } else {
    if (!doc || !documentId)
      return <div style={style}></div>
    
    return <TekaTileLink
      to={`/teka/doc/${documentId}`}
      slideout={getSlideoutForDocument(doc)}
      imageUrl={imageUrl}
      name={doc.name}
      style={style}
      innerStyle={SLIDEOUT_STYLE}
    />
  }
});

const BIG_TILE = {
  gridColumnEnd: "span 2",
  gridRowEnd: "span 2",
  width: "100%",
  height: 0,
  paddingBottom: "125%"
}

const SMALL_TILE = {
  width: "100%",
  height: 0,
  paddingBottom: "125%",
  fontSize: '10px'
};

const SLIDEOUT_STYLE = {
  paddingBottom: "125%", borderRadius: '0'
}

const EDIT_BUTTON_STYLES = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column" as "column",
  alignItems: "center",
  height: "100%"
}
