import React from "react";
import { Segment } from "components/shared";
import { TermsOfService } from "./TermsOfService";

const noButton = Object.freeze({ style: { display: "none" } });

const TermsPage = () => {
  return <div className="plain-page__container">
    <Segment className="plain-page__content">
      <TermsOfService
        cancelButtonProps={noButton}
        submitButtonProps={noButton}
        simple
      />
    </Segment>
  </div>;
};

export default TermsPage;
