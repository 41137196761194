import React from "react";
import { SimpleAccordion, Icon } from "components/shared";
import { tran } from "utils/language";
import { Dictionary } from "utils/types";

type RequirementsProps = {
  rules: Dictionary<number>,
  validation: Dictionary<boolean>,
  passwordLength: number,
  requirementsMet: number,
  cls: string
};

export function Requirements({ rules, validation, passwordLength, requirementsMet, cls }: RequirementsProps) {
  const getValidationIcon = (param: string) => {
    if (validation[param]) {
      return <Icon style={{ marginLeft: 8 }} name="check" type="success" />;
    } else {
      return <Icon style={{ marginLeft: 8 }} name="times" type="error" />;
    }
  };

  const passLengthClass = passwordLength >= rules.minPassLength ? "confirmText" : "errorText";
  const passRequirementsClass = requirementsMet >= rules.minRules ? "confirmText" : "errorText";

  return (
    <div className={cls}>
      <p>
        <b>
          {tran("password.length")}:{" "}
          <span className={`${passLengthClass}`}>{passwordLength}</span>
        </b>
        /{rules.minPassLength}
        <br />
        <b>
          {tran("passwrod.reqMet")}:{" "}
          <span className={`${passRequirementsClass}`}>{requirementsMet}</span>
        </b>
        /{rules.minRules}
      </p>
      
      <SimpleAccordion
        header={tran("password.showReq")}
        headerWhileOpen={tran("unique.hide")}
      >
        <div>
          <p>
            <b>{tran("tools.passwords.lowercase")}</b>:{" "}
            {rules.minLowercase}
            {getValidationIcon("minLowercase")}
            <br />
            <b>{tran("tools.passwords.uppercase")}</b>:{" "}
            {rules.minUppercase}
            {getValidationIcon("minUppercase")}
            <br />
            <b>{tran("tools.passwords.digits")}</b>: {rules.minDigits}
            {getValidationIcon("minDigits")}
            <br />
            <b>{tran("tools.passwords.symbols")}</b>: {rules.minSymbols}
            {getValidationIcon("minSymbols")}
            <br />
            <b>{tran("tools.passwords.distinct")}</b>: {rules.minDistinct}
            {getValidationIcon("minDistinct")}
            <br />
          </p>
        </div>
      </SimpleAccordion>
    </div>
  );
};