import React from "react";
import { Link } from "react-router-dom";
import Ink from "react-ink";
import cx from "classnames";

const ModuleTile = ({ hidden, ...props }) => {
  if (hidden) return <React.Fragment />;

  const classes = cx("sowa", "module-tile");

  return (
    <Link className={classes} to={props.url}>
      <div>
        <Thumbnail {...props} />
        <Content {...props} />
      </div>
      <Ink />
    </Link>
  );
};

const Content = ({ title, description }) => {
  const classes = cx("content");

  return (
    <div className={classes}>
      <div>
        <span>{title}</span>
        <p>{description}</p>
      </div>
    </div>
  );
};

const Thumbnail = ({ thumbnail }) => {
  if (!thumbnail) return <React.Fragment />;

  const classes = cx("thumbnail");

  return (
    <div className={classes}>
      <div />
      <img src={thumbnail} alt="module-tile-thumbnail" />
    </div>
  );
};

export default ModuleTile;
