import React from "react";
import { Icon, Button } from "components/shared";
import { useSelector } from "react-redux";
import cx from "classnames";
import { pages } from "utils/pages";
import { translate } from "utils/language";

const NotificationsBanner = () => {
  const currentUser = useSelector(state => state.currentUser.folks);

  if (!currentUser.user_id || !currentUser.flags || !currentUser.flags.nag) return null;

  const list = Object.keys(currentUser.flags.nag).map(e => ({
    name: e,
    reason: getFlagText(e) || <p>{currentUser.flags.nag[e].reason}</p>,
    link: getFlagLink(e),
  }));

  return (
    <div className="notify-banner">
      {list &&
        list.map((e) => (
          <div className="notify-banner__container" key={e.name}>
            <div className={cx("notify-banner__content")}>
              <Icon name="bell" outline type="error" />
              <div>{e.reason}</div>
            </div>
            {e.link && (
              <Button className="notify-banner__action" size="small" to={e.link.path} variant="text">
                {e.link.name}
              </Button>
            )}
          </div>
        ))}
    </div>
  );
};

// Todaj dodajemy linki do wybranych flag.
const getFlagLink = name => {
  switch (name) {
    case "aspirantNoProps":
      return {path: pages.myProfile.children.edit.fullPath, name: translate("unique.goToProfile")};
    case "aspirantNoEmail":
      return {path: pages.myProfile.children["edit-address"].fullPath, name: translate("unique.goToAddresses")};
    default:
      return;
  }
};

const getFlagText = name => {
  switch (name) {
    case "aspirantNoProps":
      return <React.Fragment>{translate("flags.aspirantNoProps").split("\n").map(text => (<p>{text}</p>))}</React.Fragment>;
    case "aspirantNoEmail":
      return <React.Fragment>{translate("flags.aspirantNoEmail").split("\n").map(text => (<p>{text}</p>))}</React.Fragment>;
    default:
      return;
  }
};

export default NotificationsBanner;
