import { TekaParams, useTekaParams } from "./params/hooks";
import { isLoaded, Request } from "../capi/hooks";
import { useDerivedState } from "./hooks";
import bootstrap from "../bootstrap";
import { doNothing, emptyArray, tuple } from "./constants";
import { parseSubfields } from "./smarc/marq";

const FILE_EXT = /\.(?:tar\.|ps\.)?[a-z0-9]{1,6}$/i;
const EMPTY = Object.freeze(["", ""]);

/** Oddzielenie rozszerzenia od nazwy pliku */
export function splitExt(name: string) {
  if (!name)
    return EMPTY;
  
  const m = name.match(FILE_EXT)
  if (m)
    return [name.substring(0, name.length - m[0].length), m[0]];
  else 
    return [name, ""];
}

const editionParams = tuple("edition.id", "edition.features.access_mgmt", "edition.features.custody_mgmt", "edition.features.editors", "edition.features.licenses", "edition.features.viewer_limits", "edition.features.serials");

export function useTekaEdition(): TekaParams<typeof editionParams> & {
  edition?: string
  hasEditors?: boolean
  hasAccessManagement?: boolean
  hasCustodyManagement?: boolean
  hasLicenses?: boolean
  hasViewerLimits?: boolean
  hasSerials: boolean
} {
  if (!bootstrap.teka.url)
    return noTekaEdition;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useDerivedState(processFeatures, useTekaParams(...editionParams)) as any;
}

function processFeatures(params: TekaParams<typeof editionParams>) {
  if (!isLoaded(params))
    return params;
  
  const data = params.data!;
  const ed = data["edition.id"]!;
  const pro = ed === "pro", uni = ed === "uni" || ed === "max";
  
  return {
    __proto__: params,
    edition: ed,
    hasEditors: data["edition.features.editors"] ?? uni,
    hasAccessManagement: data["edition.features.access_mgmt"] ?? (pro || uni),
    hasCustodyManagement: data["edition.features.custody_mgmt"] ?? uni,
    hasLicenses: data["edition.features.licenses"] ?? uni,
    hasViewerLimits: data["edition.features.viewer_limits"] ?? (pro || uni),
    hasSerials: data["edition.features.serials"],
  }
}

const noTekaEdition = Object.freeze({
  _state: "ok",
  status: 200,
  edition: undefined,
  hasEditors: false,
  hasAccessManagement: false,
  hasCustodyManagement: false,
  hasLicenses: false,
  hasViewerLimits: false,
  hasSerials: false,
  _refresh: doNothing,
})

//

/** Parsowanie pól B i D rekordu N z sowaRecordList view=marc21 */
export function issueNrAndDate(record: any /* TODO: typy */) {
  if (!(record && record.rec_no || "").startsWith("N") || !record.view || !record.view.custom)
    return emptyArray;
  
  const B = record.view.custom.find(isB);
  const Bp = B && parseSubfields("B", B[1]);
  let issueNr: string | undefined = undefined;
  if (Bp) {
    const m = Bp.first("m").value.trim();
    const n = Bp.filter("rvn").trimJoin("/");
    if (n) {
      issueNr = n;
      if (m)
        issueNr = `${n} (${m})`;
    }
    else if (m)
      issueNr = m;
  }
  const D = record.view.custom.find(isD);
  const issueDate = D ? unsowaDate(D[1]) : undefined;
  
  return [issueNr, issueDate];
}

const isB = ([tag]: any) => tag == "B";
const isD = ([tag]: any) => tag == "D";

function unsowaDate(date: string) {
  return `${date.substring(6, 8)}.${date.substring(4, 6)}.${date.substring(0, 4)}`
}
