import bootstrap from "bootstrap";

import {
  APP_SHOWN,
  SESSION_ERROR,
  SESSION_SUCCESS,
  SESSION_EXPIRATION_UPDATED,
  RESET_SESSION_CLOCK,
  UPDATE_SESSION_INFO,
  LOGOUT,
  LOGOUT_SUCCESS,
  FETCH_CURRENT_USER,
  UPDATE_SHORTCUTS,
  CURRENT_USER_SUCCESS, APP_LOAD, CLEAR_USER, CHECK_SESSION, ACTIVITY_CHANGE,
} from "../sagas/types";

import { actionChannel, put, call, take, select, delay } from "redux-saga/effects";

import uuid4 from "uuid/v4";

import * as FolksApi from "api/folks";
import * as Folks from "capi/folks";

import {
  getSessionInfo,
  saveSessionInfo,
  getPassportInfo,
  removeSessionInfo,
  removePassportInfo,
  replaceSessionItem,
} from "utils/localStorageSession";
import {reportError} from "../utils/errorReporting";
import { alwaysFalse, emptyObject } from "../utils/constants";
import { sessionAuth } from "../capi/client";
import store from "../store";
import { Toast } from "../components/shared";

// TODO: zapis roli sesji i odczyt w currentUserSaga?

export default function* sessionWatcher() {
  const channel = yield actionChannel([
    APP_SHOWN,
    LOGOUT,
    LOGOUT_SUCCESS,
    RESET_SESSION_CLOCK,
    ACTIVITY_CHANGE,
    CHECK_SESSION
  ]);
  
  yield take(APP_LOAD);
  console.log("APP_LOAD");
  
  if (!document.hidden)
    yield call(syncSession);
  
  while (true) {
    const request = yield take(channel);
    switch (request.type) {
      case LOGOUT_SUCCESS:
      case APP_SHOWN:
        yield call(syncSession);
        break;
      
      case ACTIVITY_CHANGE:
      case CHECK_SESSION:
        yield call(checkSession);
        break;
        
      case LOGOUT:
        yield call(logout, request.payload || emptyObject);
        break;
      
      case RESET_SESSION_CLOCK:
        yield call(resetSessionClock);
        break;
      
      default:
        break;
    }
  }
}

/** Utworzenie nowej sesji użytkownika na starcie aplikacji */
function* createNewSession() {
  const { passport_id, passport_key } = getPassportInfo();

  if (passport_id && passport_key) {
    // W localStorage były zapisane informacje o paszporcie
    yield call(createSessionWithPassport, passport_id, passport_key);
  } else {
    // Brak informacji o paszporcie - tworzymy sesję anonimową
    yield call(createAnonymousSession);
  }
}

/** Tworzenie sesji za pomocą paszportu */
function* createSessionWithPassport(passport_id, passport_key) {
  try {
    const result = yield call(
      FolksApi.createSessionWithPassport,
      passport_id,
      passport_key
    );
    if (result.status === 200) {
      yield call(onSessionSuccess, result.data, false);
    } else {
      // Nie udało się utworzyć sesji przy pomocy paszportu - tworzymy sesję anonimową
      removePassportInfo();
      yield call(createAnonymousSession);
    }
  } catch (err) {
    reportError(err);
    removePassportInfo();
    yield call(createAnonymousSession);
  }
}

function* createAnonymousSession() {
  const folks = bootstrap.folks.client;
  try {
    
    // TODO: re-użycie poprzedniego ssid
    //const [{ existing }] = yield folks.withAuth([112, session_id, bootstrap.domain]).execute(new Folks.SessionInfo());
    
    const session_id = uuid4()
    const [{ result }] = yield folks.withAuth([112, session_id, bootstrap.domain]).execute(new Folks.SessionInfo());
    if (result.status === 200) {
      yield call(onSessionSuccess, { ...result.data, session_key: "" }, true);
    } else {
      const error = result.message;
      yield put({ type: SESSION_ERROR, payload: error });
    }
  } catch (error) {
    yield put({ type: SESSION_ERROR, payload: error });
  }
}

/**
 * Callback po utworzeniu sesji
 * @param {Object} responseData Obiekt zwrócony przez zepytanie createSession
 * @param {Boolean} anonymous Czy jest to sesja anonimowa
 */
function* onSessionSuccess(responseData, anonymous = false) {
  const { expires_at, session_id, session_key, timeout } = responseData;
  
  saveSessionInfo({
    session_id,
    session_key,
    session_expires_at: expires_at.getTime(),
    anonymous: String(anonymous),
    timeout
  });

  yield put({
    type: SESSION_SUCCESS,
    payload: {
      session_id,
      session_key,
      expires_at,
      timeout,
      anonymous
    }
  });
  yield put({
    type: UPDATE_SHORTCUTS,
    payload: []
  });

  if (!anonymous) {
    yield put({
      type: FETCH_CURRENT_USER,
      payload: { folks: true, teka: true, sowa: true, settings: true }
    });
  }
  else {
    yield put({ type: CLEAR_USER });
  }
}

/** Sprawdzenie, czy w localStorage są informacje o nowej sesji */
function* syncSession() {
  // Karta przeglądarki została aktywowana...
  try {
    let cologinToken = sessionStorage.getItem("cologin_token");
    // cologin przez serwer
    if (cologinToken) {
      sessionStorage.removeItem("cologin_token");
      const folks = bootstrap.folks.client;
      const [{ result }] = yield folks.withAuth([110, bootstrap.domain]).execute(new Folks.SessionTokenUse(cologinToken));
      if (result.status !== 200) {
        cologinToken = undefined;
        Toast.emit("Nie powiodło się przekazanie sesji z klienta, spróbuj ponownie.")
      }
      else
        saveSessionInfo({
          session_id: result.data.session_id,
          session_key: result.data.session_key,
          session_expires_at: 0,
        });
    }
     
    // cologin javascriptowy
    if (!cologinToken && !COLOGIN_DONE && !sessionStorage.getItem("cologin")) {
      dontAttemptCologinAgain();
      const cologin = yield COLOGIN_PROMISE;
      saveSessionInfo(cologin);
    }
    
    const loaded = yield call(loadSession);
    switch (loaded) {
      case "not_found":
      case "not_good":
        // brak sesji, zaczynamy nową
        yield call(createNewSession);
        return;
      case "same":
        return;
      default:
        
    }
    
    yield call(onSessionLoaded, loaded);
  } catch (err) {
    reportError(err);
  }
}

function* loadSession() {
  // Sprawdzamy czy inne karty utworzyły sesję
  const {
    session_id: storage_session_id,
    session_key: storage_session_key,
    session_expires_at: storage_expires_at,
    logout_url,
    anonymous
  } = yield call(getSessionInfo);

  console.log(`LOAD SESSION, storage_session_id=${storage_session_id}, anonymous=${anonymous}`);

  if (!storage_session_id)
    // Brak sesji
    return "not_found";

  const {
    session_id,
    session_key,
    expires_at
  } = yield select(state => state.session);

  if (session_id === storage_session_id && session_key === storage_session_key) {
    if (expires_at && expires_at.getTime() < +storage_expires_at) {
      yield put({
        type: SESSION_EXPIRATION_UPDATED,
        payload: {
          expires_at: new Date(+storage_expires_at)
        }
      });
    }

    console.log(`LOAD SESSION done, session same`);
    // Sesja w localStorage i state są takie same
    return "same";
  }

  const isAnonymous = anonymous === "true";

  console.log(`LOAD SESSION, partaking in session`);

  // sprawdzamy sesję czy nie wygasła
  const folks = bootstrap.folks.client;
  const [{ result }] = yield folks.withAuth(sessionAuth(storage_session_id, storage_session_key)).execute(new Folks.SessionInfo());
  if (result.status >= 400) {
    console.log("LOAD SESSION, session is bad");
    // sesja wygasła albo inaczej niepoprawna
    return "not_good";
  }
  
  return {
    session_id: storage_session_id,
    session_key: storage_session_key,
    expires_at: new Date(+result.data.expires_at),
    timeout: +result.data.timeout,
    logoutUrl: logout_url,
    anonymous: isAnonymous
  }
}

function* onSessionLoaded(loaded) {
  yield put({
    type: SESSION_SUCCESS,
    payload: loaded
  });

  if (!loaded.anonymous) {
    // Pobranie informacje o użytkowniku, do którego należy sesja
    yield put({
      type: FETCH_CURRENT_USER,
      payload: { folks: true, teka: true, sowa: true, settings: true }
    });
  } else {
    yield put({
      type: UPDATE_SHORTCUTS,
      payload: []
    });
    yield put({
      type: CURRENT_USER_SUCCESS,
      payload: {}
    });
  }
}

function* logout({ sessionExpired, sessionKey }) {
  try {
    if (!sessionExpired) {
      const folks = bootstrap.folks.client;
      const { session_id, session_key } = yield select(state => state.session);
      yield folks.withAuth(sessionAuth(session_id, session_key)).executeSingle(new Folks.SessionDelete());
    }
    
    removeSessionInfo();
    removePassportInfo();
    
    const { logoutUrl } = yield(select(state => state.session));
    if (logoutUrl)
      window.location.href = logoutUrl;
    else
      window.location.reload();

    // yield put({
    //   type: LOGOUT_SUCCESS,
    //   payload: { sessionExpired: sessionExpired && sessionKey /* Nie informujemy o wygaśnięciu sesji anonimowej */ }
    // });
    
    // yield delay(100);
    // yield put({
    //   type: UPDATE_SHORTCUTS,
    //   payload: []
    // });
    // yield call(createAnonymousSession);

  } catch (err) {
    console.log(err);
  }
}

/** Przedłużanie ważności sesji użytkownika */
function* resetSessionClock() {
  const { timeout } = yield select(state => state.session);
  
  // Timeout to długość trwania sesji (w minutach) dla zalogowanego użytkownika (zależne od jego roli)
  const newExpirationDate = new Date(Date.now() + timeout * 60000 - 10000);
  // Zastąpienie informacji o dacie wygaśnięcia sesji w localStorage
  replaceSessionItem("session_expires_at", newExpirationDate.getTime());

  // Zastąpienie informacji o dacie wygaśnięcia sesji w reducerze
  yield put({
    type: SESSION_EXPIRATION_UPDATED,
    payload: {
      expires_at: newExpirationDate
    }
  });
}

let lastCheck = Date.now();
function* checkSession() {
  const { activity, session_id, session_key } = yield select(state => state.session);
  if (!activity)
    return;
  const now = Date.now();
  const time = now - lastCheck;
  if (time >= 60000 /* 1min */) {
    lastCheck = now;
    const folks = bootstrap.folks.client;
    const [{ result }] = yield folks.withAuth(sessionAuth(session_id, session_key)).execute(new Folks.SessionInfo());
    if (result.status >= 400) {
      console.log("CHECK SESSION, session is bad");
      // może inna karta utworzyła nową sesję
      const other = yield call(loadSession);
      switch (other) {
        case "not_found":
        case "not_good":
        case "same":
          yield put({ type: LOGOUT });
          break;
        default:
          yield call(onSessionLoaded, other);      
      }
    }
    else {
      yield call(resetSessionClock);
    }
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
/// COLOGIN - logowanie z OPACa ////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// TODO: wygasanie sesji - powinno sprawdzać czy rzeczywiście wygasa?

// dozwolone domeny, z których pozwalamy na cologin; aktualnie tylko OPAC
const [acceptOrigin, acceptUrl] = !bootstrap.opac?.url ? [alwaysFalse, alwaysFalse] : (() => {
  const url1 = bootstrap.opac?.url;
  const url2 = url1.indexOf("//www.") >= 0
    ? url1.replace("//www.", "//")
    : url1.replace("//", "//www.");
  return [
    origin => origin && (url1.startsWith(origin) || url2.startsWith(origin)),
    url => url && (url.startsWith(url1) || url.startsWith(url2))
  ];
})();

const originFromUrl = url => url.replace("://", "\0").split("/")[0].replace("\0", "://");

function dontAttemptCologinAgain() {
  COLOGIN_DONE = true;
  sessionStorage.setItem("cologin", "+");
}

let COLOGIN_DONE = false;
let COLOGIN_PROMISE = Promise.resolve(undefined);

(() => {
  const ref = document.referrer;
  const opener = window.opener;
  if (acceptUrl(ref)) {
    console.log("cologin? ref:", ref, "opac:", bootstrap.opac?.url, "opener:", !!opener);
    if (window.opener) {
      COLOGIN_PROMISE = attemptCologin(originFromUrl(ref), opener);
      return;
    }
  }
  dontAttemptCologinAgain();
})();

function attemptCologin(origin, opener) {
  const domain = bootstrap.domain;
  const folks  = bootstrap.folks.url;

  return new Promise(resolve => {
    window.addEventListener("message", onMessage);

    function done(result) {
      window.removeEventListener("message", onMessage);
      resolve(result);
      clearTimeout(timeout);
    }

    function onMessage(event) {
      console.log("cologin message", event);
      
      if (!acceptOrigin(event.origin) || !event.data)
        return;

      const data = event.data;

      if (data.domain !== domain || data.server !== folks)
        return;

      switch (data.type) {
        case "cologin_reply":
          console.log("cologin success")
          done({ ...data, anonymous: data.session_key ? false : "true" });
          break;

        case "cologin_error":
          console.warn("cologin error", data);
          done(null);
          break;
      }
    }

    let timeout = setTimeout(done, 1000, null);

    opener.postMessage({ type: "cologin_request", domain: bootstrap.domain, server: bootstrap.folks.url }, origin);
  });
} 
