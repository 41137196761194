import React, { useEffect } from "react";

import { default as Dialog, DialogProps } from "../Dialog";

import {FormSubmitCallback, useNewForm} from "../../../../utils/forms/formState";
import {Form} from "../../../../utils/forms";
import Button from "../../Button";
import {useBind} from "../../../../utils/hooks/bind";
import { emptyArray } from "../../../../utils/constants";

type Props<T> = {
  name: string
  onSubmit: FormSubmitCallback<T>
  submitData?: T
  alwaysDirty?: boolean
  cancelPreset?: string
  savePreset?: string
  onCancel?: () => void
} & DialogProps;

/**
 *  W sumie to hak. Potrzebowaliśmy dialogów z formularzami a nie komponowały się naturalnie.
 *  Lepsze rozwiązanie używałoby jakiegoś systemu rejestrowania akcji w kontekśćie dialogu jako buttony.
 */
export function FormDialog<T>({ name,
                                onSubmit,
                                submitData,
                                alwaysDirty,
                                cancelPreset,
                                savePreset,
                                onCancel,
                                children,
                                ...dialogProps }
                                : Props<T>) {
  const submit = useBind(onSubmit).then(result => result && dialogProps.dialog.closeWith(result));
  
  const form = useNewForm(name)
    .onSubmit(submit, submitData);
  
  form.alwaysDirty = !!alwaysDirty;
  
  useEffect(() => {
    form.focusFirst();
  }, emptyArray);
  
  const buttons = <>
    <Button
      preset={cancelPreset || "cancel"}
      onClick={onCancel || dialogProps.dialog.close}
    />
    <Button
      preset={savePreset || "save"}
      loading={form.isSubmitting}
      disabled={!form.isReady}
      form={form.id}
      onClick={form.submit}
    />
  </>;
  
  return <Dialog {...dialogProps} buttons={buttons}>
    <Form.External state={form}>
      {children}
    </Form.External>
  </Dialog>;
};
