import {
  DOCUMENT_SUCCESS,
  DOCUMENT_ERROR,
  DOCUMENT_LOADING,
  LABELS_LIST_LOADING,
  LABELS_LIST_ERROR,
  LABELS_LIST_SUCCESS,
  LABEL_LOADING,
  LABEL_ERROR,
  LABEL_SUCCESS,
  TEKA_CATALOGUES_LOADING,
  TEKA_CATALOGUES_SUCCESS,
  TEKA_CATALOGUES_ERROR,
  DOCUMENT_STATS_LOADING,
  DOCUMENT_STATS_SUCCESS,
  DOCUMENT_STATS_ERROR,
  LEASE_LOADING,
  LEASE_SUCCESS,
  LEASE_ERROR
} from "../sagas/types";

const initialState = {
  document: {
    data: {},
    loading: false,
    error: null
  },
  labels: {
    list: {
      data: [],
      loading: false,
      error: null
    },
    single: {
      data: {},
      loading: false,
      error: null
    }
  },
  catalogues: {
    data: [],
    loading: false,
    error: null
  },
  stats: {
    data: [],
    loading: false,
    error: null
  },
  lease: {
    data: {},
    loading: false,
    error: null
  }
};

export default function reducer(state = initialState, action) {
  switch (action.type) {

    case DOCUMENT_SUCCESS: {
      return {
        ...state,
        document: {
          ...state.document,
          data: action.payload,
          error: null,
          loading: false,
          reloading: false
        }
      };
    }

    case DOCUMENT_ERROR: {
      return {
        ...state,
        document: {
          ...state.document,
          data: {},
          error: action.payload,
          loading: false,
          reloading: false
        }
      };
    }

    case DOCUMENT_LOADING: {
      return {
        ...state,
        document: {
          ...state.document,
          loading: true
        }
      };
    }

    // chcemy przeładować dokument, ale bez przebudowywania całej strony dokumentu (loading)
    case "DOCUMENT_RELOADING": {
      return {
        ...state,
        document: {
          ...state.document,
          reloading: true
        }
      };
    }

    case LABELS_LIST_LOADING: {
      return {
        ...state,
        labels: {
          ...state.labels,
          list: {
            ...state.labels.list,
            loading: action.payload
          }
        }
      };
    }

    case LABELS_LIST_SUCCESS: {
      return {
        ...state,
        labels: {
          ...state.labels,
          list: {
            data: action.payload,
            loading: false,
            error: null
          }
        }
      };
    }

    case LABELS_LIST_ERROR: {
      return {
        ...state,
        labels: {
          ...state.labels,
          list: {
            error: action.payload,
            loading: false,
            data: []
          }
        }
      };
    }

    case LABEL_LOADING: {
      return {
        ...state,
        labels: {
          ...state.labels,
          single: {
            ...state.labels.single,
            loading: action.payload
          }
        }
      };
    }

    case LABEL_SUCCESS: {
      return {
        ...state,
        labels: {
          ...state.labels,
          single: {
            data: action.payload,
            loading: false,
            error: null
          }
        }
      };
    }

    case LABEL_ERROR: {
      return {
        ...state,
        labels: {
          ...state.labels,
          single: {
            error: action.payload,
            loading: false,
            data: {}
          }
        }
      };
    }

    case TEKA_CATALOGUES_LOADING: {
      return {
        ...state,
        catalogues: {
          ...state.catalogues,
          loading: action.payload
        }
      };
    }

    case TEKA_CATALOGUES_SUCCESS: {
      return {
        ...state,
        catalogues: {
          ...state.catalogues,
          loading: false,
          data: action.payload,
          error: null
        }
      };
    }

    case TEKA_CATALOGUES_ERROR: {
      return {
        ...state,
        catalogues: {
          ...state.catalogues,
          loading: false,
          data: [],
          error: action.payload
        }
      };
    }

    case DOCUMENT_STATS_LOADING: {
      return {
        ...state,
        stats: {
          ...state.stats,
          loading: true
        }
      };
    }

    case DOCUMENT_STATS_SUCCESS: {
      return {
        ...state,
        stats: {
          ...state.stats,
          loading: false,
          data: action.payload,
          error: null
        }
      };
    }

    case DOCUMENT_STATS_ERROR: {
      return {
        ...state,
        stats: {
          ...state.stats,
          loading: false,
          data: [],
          error: action.payload
        }
      };
    }

    case LEASE_LOADING: {
      return {
        ...state,
        lease: {
          ...state.lease,
          loading: action.payload
        }
      };
    }

    case LEASE_SUCCESS: {
      return {
        ...state,
        lease: {
          data: action.payload,
          loading: false,
          error: null
        }
      };
    }

    case LEASE_ERROR: {
      return {
        ...state,
        lease: {
          error: action.payload,
          loading: false,
          data: {}
        }
      };
    }

    default: {
      return state;
    }
  }
}
