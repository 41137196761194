import React, { useEffect, useState } from "react";
import Icon from "../Icon";
import moment, { Moment } from "moment";
import { useId } from "utils/hooks";
import { useLanguage } from "utils/language";
import { lazyComponent } from "utils/pageLoading";

type LocaleSupported = "pl";

type View = "decade" | "year" | "month";

interface DateProps {
  locale: LocaleSupported;
  onChange: (date: Date) => void;
  minDate?: Date;
  maxDate?: Date;
  value?: Date | Moment;
  active?: boolean;
}

const ReactCalendarLazy = lazyComponent(() => import(/* webpackChunkName: "rccr" */ "react-calendar"));

export function Calendar(props: DateProps) {
  const uniqueId = useId();
  const { translate } = useLanguage();
  const [activeView, setActiveView] = useState<View>("month");

  useEffect(() => {
    focusFirstButton();
  }, []);

  if (!props.active) return <>{moment(props.value || new Date()).format("DD.MM.YYYY")}</>;
  return (
    <div id={uniqueId}>
      <ReactCalendarLazy
        locale="pl"
        formatShortWeekday={(locale: any, date) => formatWeekdays(locale, date.getDay())}
        onChange={(date) => handleChange(date as Date)}
        minDate={props.minDate}
        maxDate={props.maxDate}
        value={moment(props.value || new Date(), "DD.MM.YYYY").toDate()}
        minDetail="decade"
        onViewChange={handleViewChange}
        prevLabel={<Icon name="chevron-left" title={translate(titles.prev[activeView])} />}
        prev2Label={<Icon name="angle-double-left" title={translate(titles.prev2[activeView])} />}
        nextLabel={<Icon name="chevron-right" title={translate(titles.next[activeView])} />}
        next2Label={<Icon name="angle-double-right" title={translate(titles.next2[activeView])} />}
        showFixedNumberOfWeeks
      />
    </div>
  );

  function handleChange(date: Date) {
    if (date) {
      const momentValue = moment(props.value || new Date());
      const dateValue = momentValue.toDate();
      dateValue.setFullYear(date.getFullYear(), date.getMonth(), date.getDate());
      props.onChange(dateValue);
    }
  }

  function handleViewChange({ view }: any) {
    setActiveView(view);
    focusFirstButton();
  }

  function focusFirstButton() {
    const el: any = document.querySelector(".react-calendar__navigation > :not([disabled])");
    el?.focus();
  }
}

const weekdays = {
  pl: ["Nd", "Pn", "Wt", "Śr", "Cz", "Pt", "So"],
};

const titles = {
  prev: {
    month: "dateTimePicker.prevMonth",
    year: "dateTimePicker.prevYear",
    decade: "dateTimePicker.prevDecade",
  },
  prev2: {
    month: "dateTimePicker.prevYear",
    year: "dateTimePicker.prevDecade",
    decade: "dateTimePicker.prevCentury",
  },
  next: {
    month: "dateTimePicker.nextMonth",
    year: "dateTimePicker.nextYear",
    decade: "dateTimePicker.nextDecade",
  },
  next2: {
    month: "dateTimePicker.nextYear",
    year: "dateTimePicker.nextDecade",
    decade: "dateTimePicker.nextCentury",
  },
};

function formatWeekdays(locale: LocaleSupported, day: number) {
  return weekdays[locale][day];
}
