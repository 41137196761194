import React from "react";
import { Link, LinkProps } from "react-router-dom";
import { useLocation } from "react-router";
import type { LocationState } from "history";

/** Opakowanie <Link/>, żeby obsługiwał nawigację do innych domen */

type AhrefProps = Omit<LinkProps<LocationState>, "innerRef">; 

export const Ahref = React.memo(React.forwardRef(function Ahref(props: AhrefProps, ref: React.ForwardedRef<HTMLAnchorElement>) {
  const location = useLocation();
  let to = typeof props.to === "function" ? props.to(location) : props.to;
  if (typeof to === "string") {
    const m = protocolRegex.exec(to);
    if (m) {
      let [, proto, url] = m;
      const [, oproto, origin] = protocolRegex.exec(window.origin)!;
      proto = proto || oproto;
      if (!url.startsWith(origin) || (proto !== "http:" && proto !== "https:")) {
        const a = {
          ...props,
          ref,
          href: to,
        }
        // @ts-ignore
        delete a.to;
        // @ts-ignore
        delete a.component;
        // @ts-ignore
        delete a.innerRef;
        // @ts-ignore
        delete a.replace;
        return <a {...a} />;
      }
      else {
        // <Link/> nie rozumie "https://", ale "//" tak
        to = "//" + url;
      }
    }
  }
  
  return <Link {...props} to={to} />;
}));

const protocolRegex = /^(\w+:)?\/\/(.*)/i;
