import React from "react";

import { Icon } from "components/shared";
import { useFeedbackLoop } from "utils/hooks";
import { emptyArray } from "utils/constants";
import Button from "../Button";
import { useDialog } from "../Dialog";
import { ListEditorDialog } from "components/shared/ListEditorDialog";
import { tran } from "utils/language";

export type MultipleInputProps = {
  value: readonly string[]
  placeholder?: string
  fluid?: boolean
  className?: string
  label?: string
  required?: boolean
  error?: string
  disabled?: boolean
  form?: string
  name?: string
  fullscreen?: boolean
  
  onChange?: (value: readonly string[]) => void
}

// To jest stara wersja przepisana na komponent funkcyjny
// TODO: zoptymizować, nie jestem pewien jak, ale pewnie trzymając wartość lokalnie
// TODO: zoptymizować, napisać hooka na bazie useFeedbackLoop do renderowania tablic elementów?
// TODO: drag&drop?

export function MultipleInput(props: MultipleInputProps) {
  const value = [...props.value, ""];
  
  const removals = React.useRef(null as any as string[]);
  if (removals.current === null) {
    removals.current = [];
  }
  
  const ids = useFeedbackLoop(emptyArray, deriveIds, value, removals.current);

  const handleSubmit = (val: string[] | undefined) => {
    if(props.onChange && val !== undefined)
      props.onChange(val);
  }

  const editorDialog = useDialog(ListEditorDialog);
  if (props.fullscreen !== false) {
    editorDialog.set("kind", "array");
    editorDialog.set("hideCopyPaste", true);
    editorDialog.set("data", props.value);
    editorDialog.onClose(handleSubmit);
  }
  
  return <>
    <div ref={setLabel} className={`multiple-input ${props.className || ""} ${props.fluid ? "multiple-input--fluid" : ""} ${props.error ? "multiple-input--error" : ""} ${props.disabled ? "multiple-input--disabled" : ""}`}>
      <div className="multiple-input__container">
        
        {value.map((val, idx) =>
          <div className="multiple-input__input-wrapper" key={ids[idx]}>
            <input
              value={val}
              placeholder={!val && idx === value.length - 1 ? props.placeholder : undefined}
              disabled={props.disabled}
              className="multiple-input__input"
              onBlur={handleBlur}
              onChange={handleChange}
              name={props.name}
              form={props.form}
            />
            <button
              className="multiple-input__remove-btn"
              onClick={handleRemoval}
              tabIndex={-1}
            >
              <Icon name="times" />
            </button>
          </div>)}
          
      </div>
      {props.fullscreen !== false && <Button 
        size="small" 
        icon="list" 
        onClick={editorDialog.open} />}
    </div>
    {props.error
      ? <div className="multiple-input__error-msg">
          {props.error}
        </div>
      : null}
  </>
  
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const idx = findDOMIndex(event.currentTarget);
    const val = [...props.value];
    val[idx] = event.target.value;
    props.onChange && props.onChange(val);
  }
  
  function handleBlur(event: React.FocusEvent<HTMLInputElement>) {
    const idx = findDOMIndex(event.currentTarget);
    if (!props.value[idx]) {
      const val = [...props.value];
      val.splice(idx, 1);
      removals.current.push(ids[idx]);
      props.onChange && props.onChange(val);
    }
  }
  
  function handleRemoval(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    
    const idx = findDOMIndex(event.currentTarget);
    const val = [...props.value];
    val.splice(idx, 1);
    removals.current.push(ids[idx]);
    props.onChange && props.onChange(val);
  }
  
  function setLabel(element: null | HTMLDivElement)
  {
    let label = props.label || tran("shared.multipleInput.title");
    // mały heurystyczny hak
    // ustawiamy tytuł dialogu na nazwę pola z ColumnView.Item
    if (element) {
      const item = element.closest(".column-view__item");
      if (item) {
        const name = item.querySelector(".column-view__item-name");
        if (name)
          label = name.textContent || "";
      }
    }
    // to powinno działać, ale proszę na tym nie polegać nigdzie
    // gdyby nie działało, to trzeba przepisać z użyciem .openWith zamiast .set
    editorDialog.set("title", label);
  }
}

function findDOMIndex(input: Node) {
  // @ts-ignore (parentNode nie będzie null)
  return Array.prototype.indexOf.call(input.parentNode.parentNode.childNodes, input.parentNode);
}

let ID = 0;

/** Co to za straszydło? 
 *  Próbuje utrzymywać stałe idki/klucze dla poszczególnych pól pomimo usuwania.
 *  Wywoływane przez `useFeedbackLoop` dostaje jako pierwszy argument poprzednio zwróconą wartość,
 *  następnie usuwa te idki, które zostały usunięte przez komponent,
 *  następnie przypisuje nowe idki nowym wartościom z `value`,
 *  i zwraca nową listę idków.
 *  Cała ta gimnastyka jest potrzebna m.in. by TAB sensownie działał. */
function deriveIds(ids: readonly string[], value: readonly string[], removals: readonly string[]): readonly string[] {
  const result = [...ids];
  
  for (let i = removals.length - 1; i >= 0; i--) {
    const removal = removals[i]
    const at = result.indexOf(removal);
    if (at < 0)
      break;
    else {
      result.splice(at, 1);
    }
  }
  
  if (result.length < value.length) {
    while (result.length < value.length) {
      result.push(ID++ + "");
    }
  }
  
  return result;
}

