import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Modal, Loader } from "components/shared";

import { translate } from "utils/language";

import { FETCH_CURRENT_USER } from "sagas/types";
import { addressCancel } from "api/folks";

class CancelEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      msg: "",
      success: false,
      loading: true,
      noToken: false
    };
  }

  componentDidMount() {
    this.getToken();
  }

  /** Wydobywanie tokenu z adresu URL */
  getToken() {
    const token = this.props.location.hash.slice(1);
    if (token) {
      this.cancelEmail(token);
    } else {
      // w przypadku braku tokenu
      this.setState({
        loading: false,
        noToken: true
      });
    }
  }

  cancelEmail = token => {
    addressCancel(token)
      .then(response => {
        const { message, status } = response;

        if (status === 204) {
          this.props.updateCurrentUserInfo();
          this.setState({
            msg: translate("user.cancelEmail.success"),
            loading: false,
            success: true
          });
        } else {
          this.setState({
            msg: message || translate("user.cancelEmail.error"),
            loading: false,
            success: false
          });
        }
      })
      .catch(err => {
        this.setState({
          msg: translate("user.cancelEmail.error"),
          loading: false,
          success: false
        });
      });
  };

  render() {
    const { msg, loading, success, noToken } = this.state;

    return (
      <Modal
        headerIcon={
          loading ? "" : success ? "check-circle" : "exclamation-circle"
        }
        error={!loading && (!success || noToken)}
        content={
          loading ? (
            <div style={{ height: "200px" }}>
              <Loader />
            </div>
          ) : noToken ? (
            <p>{translate("user.cancelEmail.noToken")}</p>
          ) : (
            <p>{msg}</p>
          )
        }
        buttons={
          loading
            ? []
            : success || noToken
            ? [
                {
                  content: translate("unique.goToApp"),
                  variant: "text",
                  onClick: () => this.props.history.push("/")
                }
              ]
            : [
                {
                  content: translate("unique.tryAgain"),
                  variant: "text",
                  onClick: () => this.getToken()
                },
                {
                  content: translate("unique.goToApp"),
                  confirm: true,
                  onClick: () => this.props.history.push("/")
                }
              ]
        }
      />
    );
  }
}

const EMIT = dispatch => ({
  updateCurrentUserInfo: () => dispatch({ type: FETCH_CURRENT_USER })
});

export default withRouter(
  connect(
    null,
    EMIT
  )(CancelEmail)
);
