import { sessionClientByPath, sessionClient } from "capi/appclient";
import * as Sowa from "capi/sowa";

import store from "store";

/**
 * Pobranie informacji o uprawnieniach użytkownika w danym katalogu
 * @param {String} login Login użytkownika
 * @param {Object} cat informacje o katalogu
 */
export function fetchUserSowa(login, cat) {
  const Client = sessionClient(store, cat.client);
  return Client.executeSingle(new Sowa.UserGet([login]));
}

export function editSowaUser(catUrl, login, profile, rights) {
  const Client = sessionClientByPath(store, "sowa", "cataloguesByUrl", catUrl);
  return Client.executeSingle(new Sowa.UserSet([login, profile, rights]));
}

export function userDef(cat_id) {
  const Client = sessionClientByPath(store, "sowa", "cataloguesById", cat_id);
  return Client.executeSingle(new Sowa.UserDef());
}