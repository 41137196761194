import {useRef, useState} from "react";
import ErrorBoundary from "../../components/shared/ErrorBoundary";
import {increment} from "../constants";
import {scheduleReactUpdate} from "./batch";
import {useSignal} from "./other";
import {PromiseStrip, PromiseWrap} from "../types";

/** Interfejs zbindowanej funkcji rozszerzamy o dodatkowe API, zadeklarowane tutaj */
export interface BoundFunction<A extends any[], R> {
  (...args: A): R
  
  // skopiowane z lib.es5.d.ts
  then<TResult1 = PromiseStrip<R>, TResult2 = never>(onfulfilled?: ((value: PromiseStrip<R>) => TResult1 | PromiseLike<TResult1>) | undefined | null, onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null): BoundFunction<A, PromiseWrap<R>>;
  catch<TResult = never>(onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null): BoundFunction<A, PromiseWrap<R>>;
  finally(onfinally?: (() => void) | undefined | null): BoundFunction<A, PromiseWrap<R>>
  preventDefault(preventDefault?: boolean): this;
}

const slice = Array.prototype.slice;

type ForwarderSlot = [string, any[]]

function callForwarder(name: string) {
  // tworzymy funkcję która po wywołaniu na wyniku useBind zachowa argumenty
  // i zakolejkuje wywołanie funkcji o ten samej nazwie na wyniku wywołania argumentu useBind
  return function (this: { _bindState: BindState }) {
    const bindState = this._bindState;
    const index = bindState.numForwards;
    const slot = bindState.forwards[index] || ["", []];
    slot[0] = name; // zachowujemy nazwę
    const args = slot[1];
    args.length = arguments.length;
    for (let i = 0; i < arguments.length; i++)
      args[i] = arguments[i]; // i argumenty
    bindState.forwards[index] = slot;
    bindState.numForwards++; // zwiększamy liczbę funkcji zakolejkowanych
    return this; // zwracamy oryginalny wynik useBind bo to API typu fluent
  };
}

const forwardThen = callForwarder("then");
const forwardCatch = callForwarder("catch");
const forwardFinally = callForwarder("finally");

function forwardPreventDefault(this: { _bindState: BindState }, preventDefault: boolean = true) {
  this._bindState.preventDefault = preventDefault;
  return this;
}

type BindState = {
  bound: Function // wrapper w czasie spoczynku
  bound2?: Function // wrapper w czasie wykonywania
  fn: Function
  args: any[]
  forwards: ForwarderSlot[]
  numForwards: number
  preventDefault: boolean
  promise: Promise<any> | null
}

/** Utworzenie stałego callbacka, bindując argumenty do podanej funkcji. */
export function useBind<O extends any[], R>(fn: (...args: O) => R): BoundFunction<O, R>;
export function useBind<O extends any[], R>(fn: (...args: O) => R, ...args: O): BoundFunction<any[], R>; // to zwraca funkcję przyjmującą dowolne argumenty a nie żadne, bo nie jesteśmy w stanie wykryć czy oryginalna sygnatura kończyła się trzema kropkami czy nie...
export function useBind<T1, O extends any[], R>(fn: (v1: T1, ...args: O) => R, v1: T1): BoundFunction<O, R>;
export function useBind<T1, T2, O extends any[], R>(fn: (v1: T1, v2: T2, ...args: O) => R, v1: T1, v2: T2): BoundFunction<O, R>;
export function useBind<T1, T2, T3, O extends any[], R>(fn: (v1: T1, v2: T2, v3: T3, ...args: O) => R, v1: T1, v2: T2, v3: T3): BoundFunction<O, R>;
export function useBind<T1, T2, T3, T4, O extends any[], R>(fn: (v1: T1, v2: T2, v3: T3, v4: T4, ...args: O) => R, v1: T1, v2: T2, v3: T3, v4: T4): BoundFunction<O, R>;
export function useBind<T1, T2, T3, T4, T5, O extends any[], R>(fn: (v1: T1, v2: T2, v3: T3, v4: T4, v5: T5, ...args: O) => R, v1: T1, v2: T2, v3: T3, v4: T4, v5: T5): BoundFunction<O, R>;
export function useBind<T1, T2, T3, T4, T5, T6, O extends any[], R>(fn: (v1: T1, v2: T2, v3: T3, v4: T4, v5: T5, v6: T6, ...args: O) => R, v1: T1, v2: T2, v3: T3, v4: T4, v5: T5, v6: T6): BoundFunction<O, R>;
export function useBind<T1, T2, T3, T4, T5, T6, T7, O extends any[], R>(fn: (v1: T1, v2: T2, v3: T3, v4: T4, v5: T5, v6: T6, v7: T7, ...args: O) => R, v1: T1, v2: T2, v3: T3, v4: T4, v5: T5, v6: T6, v7: T7): BoundFunction<O, R>;
export function useBind<T1, T2, T3, T4, T5, T6, T7, T8, O extends any[], R>(fn: (v1: T1, v2: T2, v3: T3, v4: T4, v5: T5, v6: T6, v7: T7, v8: T8, ...args: O) => R, v1: T1, v2: T2, v3: T3, v4: T4, v5: T5, v6: T6, v7: T7, v8: T8): BoundFunction<O, R>;
export function useBind<T1, T2, T3, T4, T5, T6, T7, T8, T9, O extends any[], R>(fn: (v1: T1, v2: T2, v3: T3, v4: T4, v5: T5, v6: T6, v7: T7, v8: T8, v9: T9, ...args: O) => R, v1: T1, v2: T2, v3: T3, v4: T4, v5: T5, v6: T6, v7: T7, v8: T8, v9: T9): BoundFunction<O, R>;
export function useBind<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10, O extends any[], R>(fn: (v1: T1, v2: T2, v3: T3, v4: T4, v5: T5, v6: T6, v7: T7, v8: T8, v9: T9, v10: T10, ...args: O) => R, v1: T1, v2: T2, v3: T3, v4: T4, v5: T5, v6: T6, v7: T7, v8: T8, v9: T9, v10: T10): BoundFunction<O, R>;
export function useBind(fn: Function /* + arguments */) {
  const ref = useRef(null as any as BindState);
  const errorBoundary = ErrorBoundary.useApi();
  const signal = useSignal();
  
  if (typeof fn !== "function")
    throw new Error(`useBind expects a function, not: ${typeof fn}`);
  
  if (ref.current === null) {
    const bound = function(this: any) {
      const state = ref.current;
      const { fn, args, forwards, numForwards, promise } = state;
      
      if (promise)
        return promise;
      
      let result;
      try {
        if (state.preventDefault) {
          const event = arguments[0] as { preventDefault(): void };
          if (event && typeof event.preventDefault === "function") {
            event.preventDefault();
          }
        }
        
        if (arguments.length > 0)
          result = fn.call(this, ...args, ...arguments);
        else
          result = fn.apply(this, args);
      }
      catch (error) {
        if (errorBoundary.installed)
          errorBoundary.softReportError(error);
      }
      
      const wasPromise = result instanceof Promise;
      if (wasPromise || numForwards) {
        if (!wasPromise) {
          // jeśli użytkownik wywołał then/catch/finally, ale podał synchroniczną funkcję
          // to musimy opakować wynik w promesę
          result = Promise.resolve(result);
        }
        
        // wywołujemy rekurencyjnie zakolejkowane ("przekazane") funkcje na wyniku
        // czyli np. then/catch/finally na zwróconym Promise
        for (let i = 0; i < numForwards; i++) {
          const [fname, fargs] = forwards[i];
          result = (result as any)[fname](...fargs);
        }
        
        if (errorBoundary.installed) {
          result = result.catch(errorBoundary.softReportError)
        }
        
        signal();
        state.promise = result = result.finally(() => {
          state.promise = null;
          scheduleReactUpdate(signal);
        });
      }
      else {
        if (errorBoundary.installed)
          errorBoundary.clearError();
      }
      
      return result;
    } as any;
    
    ref.current = {
      bound,
      fn,
      args: slice.call(arguments, 1),
      forwards: [],
      numForwards: 0,
      promise: null,
      preventDefault: false
    };
    
    bound._bindState = ref.current;
    bound.then = forwardThen;
    bound.catch = forwardCatch;
    bound.finally = forwardFinally;
    bound.preventDefault = forwardPreventDefault;
    
    return bound;
  }
  else {
    const state = ref.current;
    
    state.fn = fn;
    const oldArgs = state.args;
    for (let i = 1; i < arguments.length; i++) {
      oldArgs[i - 1] = arguments[i];
    }
    
    // resetujemy callbacki promisowe, bo powinny zostać ponownie ustawione
    state.numForwards = 0;
    
    if (state.promise /* czyli isRunning */) {
      let runningWrapper = state.bound2 as any;
      
      if (! runningWrapper) {
        // potrzebujemy zmienić tożsamość funkcji gdy zmieniamy stan isRunning(),
        // więc tworzymy inną funkcję, która wywołuje oryginalną
        const bound = state.bound;
        runningWrapper = function (this: any) { return bound.apply(this, arguments) } as any;
        
        runningWrapper._bindState = state;
        runningWrapper.then = forwardThen;
        runningWrapper.catch = forwardCatch;
        runningWrapper.finally = forwardFinally;
        runningWrapper.preventDefault = forwardPreventDefault;
      }
      
      return runningWrapper;
    }
    
    return state.bound;
  }
}

/** Sprawdzenie czy callback utworzony przez useBind się w tle wykonuje */
export function isRunning(bound: any) {
  const bindState = bound && (bound as any)._bindState as BindState;
  return !!(bindState && bindState.promise);
}
