export const keymap = {
  MAIN: {
    left: "left",
    right: "right",
    up: "up",
    down: "down",
    page_up: "pageup",
    page_down: "pagedown",
    home: "home",
    end: "end",
    vhome: "shift+home",
    vend: "shift+end",
    close: "esc",
    help: "ctrl+f1",
    logout: "ctrl+shift+l",
    zoomIn: "ctrl+plus",
    zoomOut: "ctrl+minus",
    zoomReset: "ctrl+0",
    confirm: "enter",
    search: "shift+f",
    space: "space",
    left_supported: "shift+left",
    right_supported: "shift+right",
    a: "a",
    b: "b",
    c: "c",
    d: "d",
    e: "e",
    f: "f",
    g: "g",
    h: "h",
    i: "i",
    j: "j",
    k: "k",
    l: "l",
    m: "m",
    n: "n",
    o: "o",
    p: "p",
    q: "q",
    r: "r",
    s: "s",
    t: "t",
    u: "u",
    v: "v",
    w: "w",
    x: "x",
    y: "y",
    z: "z",
    plus: "+",
    minus: "-",
    tilde: "`",
    backspace: "backspace",
    back: "shift+backspace",
    "0": "0",
    "1": "1",
    "2": "2",
    "3": "3",
    "4": "4",
    "5": "5",
    "6": "6",
    "7": "7",
    "8": "8",
    "9": "9"
  },
  MODAL: {
    close: "esc",
    confirm: "enter"
  },
  TEKA: {
    confirm: "enter",
    space: "space",
    left: "left",
    right: "right",
    up: "up",
    down: "down",
    backspace: "backspace"
  }
};
