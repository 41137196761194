import React, { useEffect, useReducer, useRef, useState } from "react";
import { alwaysTrue, emptyArray, stateReducer } from "./constants";

type Updater = { update(children?: React.ReactNode): void };

/** Dostarczacz kontekstu, który nie budzi dzieci przy każdej zmianie wartości kontekstu,
 *  I co ważniejsze, nie budzi konsumentów przy każdej zmianie dzieci. */
export function BetterContextProvider<T>(props: { context: React.Context<T>, value: T, children?: React.ReactNode }): React.ReactElement {
  type State = {
    provider: React.ReactNode
    context: React.Context<T>
    value: T
  }
  
  const [updater, setUpdater] = useState(null as any as Updater);
  const [state, setState] = useState(createOrUpdateState);
  
  useEffect(updateChildrenEffect, [props.children, updater]);
  useEffect(updateContextEffect, [props.value, props.context]);
  
  return state.provider as any;
  
  function updateChildrenEffect() {
    updater && updater.update(props.children);
  }
  
  function updateContextEffect() {
    const ns = createOrUpdateState(state);
    if (ns !== state)
      setState(ns);
  }
  
  function createOrUpdateState(state?: State): State {
    const { children, context, value } = props;
    if (state && state.context === context && state.value === value)
      return state;
    
    const Provider = context.Provider;
    // ten element zostanie zwrócony z BetterContextProvider
    return {
      provider: <Provider value={value}>
        <MemoizedChildren initialChildren={children} setUpdater={setUpdater}/>
      </Provider>,
      context,
      value,
    };
  }
}

const MemoizedChildren = React.memo((props: { initialChildren?: React.ReactNode, setUpdater: React.Dispatch<React.SetStateAction<Updater>> }) => {
  // useReducer zamiast useState bo podajemy nieznane dane i gdyby były funkcją, to useState by ją źle zinterpretował
  const [children, setChildren] = useReducer(stateReducer<typeof props["initialChildren"]>, props.initialChildren);
  
  useEffect(() => {
    props.setUpdater({
      // komponent wyżej wywoła ten sygnał i nas obudzi jeśli zmienią się potomkowie
      update: setChildren
    });
  }, emptyArray);
  
  return (children || null) as any as React.ReactElement;
}, /* propsAreEqual = */ alwaysTrue /* bo setUpdater się nie zmieni, a initialChildren nie ma znaczenia po pierwszym renderze */);
