import { DropdownOption, DropdownOptions } from "./types";
import React, { useRef } from "react";

import { Icon } from "../index";
import { Link } from "react-router-dom";
import { RefSetter } from "utils/hooks/util";
import { Tran } from "utils/language";
import isEqual from "lodash.isequal";
import { useDerivedState } from "../../../utils/hooks";
import { Ahref } from "../Ahref";

type DropdownVariants = "free" | "attached";

type OptionListProps = {
  selectedOption?: DropdownOption;
  variant?: DropdownVariants;
  onClick?: (option: DropdownOption) => void;
  options: DropdownOptions;
  outerRef: RefSetter<HTMLElement>;
};

/** Komponent odpowiedzialny za renderowanie listy dropdowna bez wodotrysków */
export function OptionList({ variant = "free", options, selectedOption, onClick, outerRef }: OptionListProps) {
  const elements = useDerivedState(optionsToElements, options, onClick, selectedOption, initialRef);
  const focused = useRef(false);
  
  return <div ref={outerRef} className={`dropdown__options dropdown__options--${variant}`} onKeyDown={onKeyPress}>
    {elements}
  </div>
  
  function initialRef(chosen:any) {
    if (chosen && chosen.focus && !focused.current)
      chosen.focus();
    focused.current = true;
  }
  
  function onKeyPress(event:any) {
    const opt = event.target.closest(".dropdown__option");
    if (!opt) return;
    let to = opt;
    switch (event.key) {
      case "ArrowUp":
        do { to = to.previousElementSibling; }
        while (to && !to.matches(".dropdown__option:not(.disabled)"));
        break;
      case "ArrowDown":
        do { to = to.nextElementSibling; }
        while (to && !to.matches(".dropdown__option:not(.disabled)"));
        break;
      default:
        return;
    }
    if (!to || !to.focus) return;
    event.preventDefault();
    event.stopPropagation();
    to.focus();
  }
}

const optionsToElements = <T = any>(
  options: DropdownOptions<T>,
  onOptionClick: any,
  selectedOption?: DropdownOption<T>,
  initialRef?: any
) => {
  if (options.length < 1)
    return <Tran id="unique.noOptionsToShow" className="dropdown__option"/>;
  return options.map((option_, index) => {
    if (React.isValidElement(option_)) {
      return <React.Fragment key={"" + index}>{option_}</React.Fragment>;
    }
    const option = option_ as DropdownOption<T>;
    
    const {
      value,
      text,
      icon,
      iconType,
      disabled,
      image,
      link,
      onClick,
      className,
      ...rest
    } = option;
    
    const active = Object.is(option, selectedOption);
    
    const _icon =
      icon && icon.length > 0 ? (
        <Icon
          name={icon}
          className={`dropdown__option-icon`}
          type={iconType || "default"}
        />
      ) : null;
    
    const _img =
      image && image.length > 0 ? (
        <img src={image} alt="" className={`dropdown__option-img`}/>
      ) : null;
    
    const _content = <>
      {_icon || _img ? (
        <div className={`dropdown__option-label`}>
          {_icon}
          {_img}
        </div>
      ) : null}
      {text || NBSP}
    </>;
    
    const _className = `dropdown__option ${className || ""} ${active ? "active" : ""} ${disabled ? "disabled" : ""}`;
    
    const initial = active || !selectedOption
    
    if (link && (typeof link === "function" || link.length > 0) && !disabled)
      return <Ahref
        ref={initial ? initialRef : undefined}
        className={_className}
        to={link}
        onClick={() => onOptionClick(option)} /* nie jestem pewien czy to działa i czy jest potrzebne */
        role="option"
        key={"" + index}
        children={_content}
        {...rest}
      />;
    
    return <button
      ref={initial ? initialRef : undefined}
      className={`stealth-button ${_className}`}
      disabled={disabled}
      onClick={disabled ? undefined : () => onOptionClick(option)}
      role="option"
      aria-selected={active}
      key={"" + index}
      children={_content}
      {...rest}
    />;
  })
};
// Chcemy głębokie porównanie w useDerivedState
optionsToElements.comparison = isEqual;

const NBSP = Object.freeze(<>&nbsp;</>);
