import bootstrap from "bootstrap";
import { CapiClient, sessionAuth } from "./client";

import { LOGOUT_SUCCESS, RESET_SESSION_CLOCK } from "sagas/types";
import { call, select } from "redux-saga/effects";
import {emptyObject} from "../utils/constants";
import {getLanguage} from "../utils/language";
import store from "store";

const languageMap = { en_US: "en_GB" }

let cachedClients = null;
let cachedSession = null;
let cachedSessionKey = null;

class ScatterGatherClient extends CapiClient {
  constructor(parentClient, auth) {
    super(parentClient.url)
    this.parentClient = parentClient;
    this.auth = auth;
    this.mode = 0;
    this.lang = parentClient.lang;
    this.log = parentClient.log;
    this._onExecuted = [...parentClient._onExecuted];
    this._queue = [];
    this._promise = null;
  }
  
  async execute(...commands) {
    const L = commands.length;
    if (L > 1) {
      // wielu komend nie agregujemy, bo `mode` może mieć znaczenie
      this.mode = this.parentClient.mode;
      this._doRetry = this.parentClient._doRetry;
      return super.execute(...commands);
    }
    
    const i = this._queue.length;
    //if (i > 0)
    //  console.log(`Optimized CAPI request: ${this._queue.map(command => command.exec[0]).join(",")} + ${commands.map(command => command.exec[0]).join(",")}`);
    
    this._queue.push(...commands);
    
    if (this._promise === null) {
      this._promise = new Promise(this.promisedExecution)
    }
    
    // promise jest dla agregacji wszystkich komend, więc musimy wyciąć wyniki tych z aktualnego wywołania
    const all = await this._promise;
    return all.slice(i, i + L);
  }
  
  promisedExecution = (resolve, reject) => {
    setTimeout(this.executeLater, 0, resolve, reject)
  }
  
  executeLater = (resolve, reject) => {
    const queue = this._queue;
    this._queue = [];
    this._promise = null;
    
    this.mode = 0;
    this._doRetry = true;
    
    super
      .execute(...queue)
      .catch(err => reject(err))
      .then(results => resolve(results))
  }
}

function getCachedSessionClient(parentClient, ssid, sskey) {
  if (ssid !== cachedSession || sskey !== cachedSessionKey) {
    cachedClients = new Map();
    cachedSession = ssid;
    cachedSessionKey = sskey;
  }
  
  let cachedClient = cachedClients.get(parentClient)
  if (typeof cachedClient === "undefined") {
    cachedClient = new ScatterGatherClient(parentClient, sessionAuth(ssid, sskey));
    cachedClients.set(parentClient, cachedClient)
  }
  
  return cachedClient;
}

// utworzenie klienta logującego się sesją mając podanego klienta już skonfigurowanego pod jakiś URL
export function sessionClient(store, parentClient, throwOnFailure = true) {
  if (!parentClient) throw new Error("No parent client given.");

  const state = typeof store.getState === "function" ? store.getState() : store;
  const session = state.session;
  if (!session || !session.session_id) {
    if (throwOnFailure) throw new Error("No session to create client");
    else return null;
  }
  
  const client = getCachedSessionClient(parentClient, session.session_id, session.session_key);
  
  // FIXME: na razie przeładowujemy stronę przy zmianie języka, więc nie monitorujemy zmian tego
  const language = ((state.utils || emptyObject).appSettings || emptyObject).language || getLanguage();
  if (language) client.lang = languageMap[language] || language;

  client.onExecuted(onExecuted);

  return client;
}

let resetTimer = null;
function resetClock() {
  resetTimer = null;
  if (document.visibilityState === "visible")
    store.dispatch({ type: RESET_SESSION_CLOCK });
}

function onExecuted(commands) {
  if (!commands.length) return;
  
  if (commands[0].status !== 401) {
    if (
      commands[0].exec[0] !== "folksSessionInfo" &&
      commands[0].exec[0] !== "folksSessionDelete"
    ) {
      // FIXME: to jest bez sensu, trzeba przepisać
      
      if (resetTimer === null)
        resetTimer = setTimeout(resetClock, 5000);
    }
  } else {
    const auth = this.auth;
    if (commands[0].exec[0] !== "folksSessionInfo" && (auth[0] === 102 || auth[0] == 112)) {
      // na starcie aplikacji wczytujemy session_id z localStorage i wysyłamy zapytanie folksSessionInfo
      // może się okazać, że session_id jest już nieaktualne - wtedy nie dojdzie do zalogowania, a więc nie wysyłamy
      // także akji do wylogowania
      store.dispatch({ type: LOGOUT_SUCCESS, payload: { sessionExpired: true, sessionId: auth[1], sessionKey: auth[0] === 102 ? auth[2] : "" } });
    }
  }
}

// utworzenie klienta logującego się sesją na postawie ścieżki obiektowej w `bootstrap`,
// np. sessionClientByPath(store, "folks") === sessionClient(store, bootstrap.folks.client)
export function sessionClientByPath(store, ...path) {
  let where = bootstrap;
  for (let i = 0; i < path.length; i++) {
    where = where[path[i]];
    if (!where) throw new Error(`No such app: ${path.join(".")}`);
  }

  const client = where.client;
  if (!client) throw new Error(`No such client: ${path.join(".")}`);

  return sessionClient(store, client);
}

// efekt dla redux-saga
// TODO: by mieć testowalną wersję tego trzeba być użyć getContext
export function* sagaCapi(...args) {
  function apiCall(state, args) {
    let i = 0;
    while (typeof args[i] !== "object") i++;
    return sessionClientByPath(state, ...args.slice(0, i)).execute(
      ...args.slice(i)
    );
  }

  const state = yield select();
  const result = yield call(apiCall, state, args);
  return result;
}
