import { CapiCommand } from "../../../capi/client";

export const emailMissing = (result: any) => result.status === 409 && result.reason === "email_missing";

export const emailUnverified = (result: any) => result.status === 409 && result.reason === "email_unverified";

export class RegistrationCheck extends CapiCommand {
  constructor() {
    super(["folksRegistrationCheck"]);
  }
  
  static dontCache = true;
}