import React from "react";
import { IDialog } from "../../shared/Dialog/useDialog";
import Dialog from "../../shared/Dialog/Dialog";
import { Button, FlexWrapper, Loader } from "../../shared";
import { Tran, tran, useLanguage } from "../../../utils/language";
import { CapiClient } from "../../../capi/client";
import { AddressResend, AddressVerify } from "../../../capi/folks";
import { useBind } from "../../../utils/hooks";
import { FormDialog } from "../../shared/Dialog/comps";
import { Dictionary } from "../../../utils/types";
import { Form } from "../../../utils/forms";
import bootstrap from "../../../bootstrap";
import { useDialog } from "../../shared/Dialog";
import { promiseTimeout } from "../../../utils/async";

type EmailVerificationProps = {
  email: string
  client: CapiClient
  onVerified?: () => void
  setUnwantedEmail: React.Dispatch<React.SetStateAction<string>>
  dialog: IDialog
}

// ten komponent jest "stroną" w CompleteSignupDialog

export function EmailVerificationDialogPage({ client, email, onVerified, setUnwantedEmail, dialog }: EmailVerificationProps) {
  const resendAction = useBind(resendEmail, client, email);
  const changeAction = useBind(setUnwantedEmail, email);
  const codeDialog = useDialog(CodeInputDialog)
    .onClose(onVerified && (res => res && onVerified()));
  
  return <Dialog size="medium" dialog={dialog}>
    <Loader inline text={<>
      <Tran id="signup.prompt.email.1" search="$1" replace={<b>{email}</b>} />
      <br/>
      {tran("signup.prompt.email.2")}
    </>} />
    <FlexWrapper alignItems="start">
      <Button
        className="m0"
        variant="text"
        type="button"
        onClick={resendAction}
        children={tran("forgotPass.sendAgain")}
      />
      <Button
        className="m0"
        variant="text"
        type="button"
        onClick={changeAction}
        children={tran("signup.btn.change")}
      />
      <Button
        className="m0"
        variant="text"
        type="button"
        onClick={codeDialog.open}
        children={tran("signup.btn.code")}
      />
    </FlexWrapper>
  </Dialog>;
}

async function resendEmail(client: CapiClient, email: string) {
  const slowdown = promiseTimeout(5000);
  const [command] = await client.execute(new AddressResend("umail", email));
  command.ensure(200, 204);
  await slowdown;
  return true;
}

type CodeInputDialogProps = {
  dialog: IDialog
}

function CodeInputDialog({ dialog }: CodeInputDialogProps) {
  const lang = useLanguage();
  
  return <FormDialog title={tran("signup.header.code")} name="email-code-form" size="small" dialog={dialog} onSubmit={confirmEmail} savePreset="confirm">
    <div className="mb mt ml mr">
      {tran("signup.prompt.code")}
    </div>
    <div className="mb mt ml mr">
      <Form.Input
        name="code"
        aria-label={lang.translate("signup.fld.code")}
        required
        fluid
      />
    </div>
  </FormDialog>
}

async function confirmEmail(values: Dictionary<unknown>) {
  const [result] = await bootstrap.folks.client.withAuth(110, bootstrap.domain).execute(new AddressVerify(values.code));
  result.ensure(200, 204);
  return true;
}