import React from "react";
import PropTypes from "prop-types";

import {
  SimpleAccordion as Accordion,
  Divider,
  Checkbox,
  Dropdown,
  ColumnView
} from "components/shared";
import ColorPalette from "components/shared/ColorPalette";

import { FONT_LIST, THEME_LIST } from "utils/themes";
import { tran } from "utils/language";

const fontList = FONT_LIST.map(opt => {
  if (opt.id == "serif")
    return { value: opt.id, text: <i>{tran("font.serif")}</i> }
  if (opt.id == "sans-serif")
    return { value: opt.id, text: <i>{tran("font.sans-serif")}</i> }
  return { value: opt.id, text: opt.name };
});

const View = props => {
  const renderThemes = (a, b) => {
    let themePalettes = [];
    for (let i = a; i <= b; i++) {
      if(!themesList[i]) continue;

      themePalettes.push(
        <div className="themes__item">
          <ColorPalette
            animated={false}
            shadow
            tooltip={false}
            onClick={() => props.changeValue("theme", themesList[i].value)}
            active={
              !props.values.contrast &&
              props.values.theme === themesList[i].value
            }
            value={themesList[i].value}
            name={themesList[i].text}
            colors={themesList[i].props()}
          />
        </div>
      );
    }
    return themePalettes;
  };
  
  const basicThemes = renderThemes(0, 2);
  const moreThemes = renderThemes(3, themesList.length - 1);

  return (
    <React.Fragment>
      <ColumnView>
        <ColumnView.Item
          name={tran("pages.settings.content.shortcuts")}
          value={
            <Checkbox
              checked={props.values.enableShortcuts}
              onClick={() => {
                props.changeValue(
                  "enableShortcuts",
                  !props.values.enableShortcuts
                );
              }}
            />
          }
        />

        <ColumnView.Item
          name={tran("pages.settings.content.animations")}
          value={
            <Checkbox
              checked={props.values.enableAnimations}
              onClick={() =>
                props.changeValue(
                  "enableAnimations",
                  !props.values.enableAnimations
                )
              }
            />
          }
        />

        <ColumnView.Item
          name={tran("pages.settings.content.passwordExpiration.text")}
          value={
            <Dropdown
              value={props.values.passwordExpirationWarning}
              options={passwordExpirationOptions}
              onChange={value =>
                props.changeValue("passwordExpirationWarning", value)
              }
            />
          }
        />

        <Divider />

        <ColumnView.Item
          name={tran("pages.settings.content.fontType.title")}
          value={
            <Dropdown
              placeholder="..."
              value={props.values.font}
              options={fontList}
              onChange={value => props.changeValue("font", value)}
            />
          }
        />

        <ColumnView.Item
          name={tran("pages.settings.content.themes.contrast")}
          value={
            <Checkbox
              checked={props.values.contrast}
              onClick={() => {
                props.changeValue("contrast", !props.values.contrast);
              }}
            />
          }
        />

        <ColumnView.Item
          name={tran("pages.settings.content.themes.text")}
          value={null}
        />

        <div className="themes">
          <div className="themes__items">{basicThemes}</div>
          {
            moreThemes.length > 0 ? 
            <Accordion
              className="settings__themes-accordion"
              header={tran("pages.settings.content.themes.showAll")}
              headerWhileOpen={tran("unique.showLess")}
              icon="caret down"
              iconWhileOpen="caret up"
              headerPosition="bottom"
              fluid
            >
              <div className="themes__items">{moreThemes}</div>
            </Accordion> : null
          }
        </div>
      </ColumnView>
    </React.Fragment>
  );
};

const themesList = THEME_LIST.map(theme => ({ value: theme.id, text: theme.name, props: () => theme.colors }));

const passwordExpirationOptions = [
  {
    value: "1",
    text: tran("pages.settings.content.passwordExpiration.options.single")
  },
  {
    value: "2",
    text: <span>2 {tran("pages.settings.content.passwordExpiration.options.multiple")}</span>
  },
  {
    value: "3",
    text: <span>3 {tran("pages.settings.content.passwordExpiration.options.multiple")}</span>
  },
  {
    value: "5",
    text: <span>5 {tran("pages.settings.content.passwordExpiration.options.multiple")}</span>
  },
  {
    value: "10",
    text: <span>10 {tran("pages.settings.content.passwordExpiration.options.multiple")}</span>
  }
];

View.propTypes = {
  values: PropTypes.shape({
    enableShortcuts: PropTypes.bool.isRequired,
    passwordExpirationWarning: PropTypes.oneOf(["1", "2", "3", "5", "10"])
      .isRequired,
    font: PropTypes.string.isRequired,
    theme: PropTypes.string.isRequired,
    contrast: PropTypes.bool.isRequired
  }).isRequired,
  changeValue: PropTypes.func.isRequired
};

export default View;
