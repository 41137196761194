import React from "react";
import Button from "../../Button";
import { IDialog } from "../useDialog";
import { tran } from "utils/language";
import { ErrorDialog } from "./index";

type IProps = {
  title?: React.ReactNode
  icon?: string
  message?: React.ReactNode
  onRetry: () => void
  dialog: IDialog
};

/**
 * Dialog with an error.
 */
const TryAgainDialog = ({ title=tran("error.dialogTitle"), icon="exclamation circle", message, dialog, onRetry }: IProps) => {
  const buttons = <>
    <Button preset="close" onClick={dialog.close}/>
    <Button preset="tryAgain" onClick={onRetry || (() => dialog.closeWith(true))}/>
  </>;
  
  return <ErrorDialog
    title={title}
    icon={icon}
    message={message}
    buttons={buttons}
    dialog={dialog}
  />
};

export default TryAgainDialog;
