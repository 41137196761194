import React, { useEffect } from "react";

import bootstrap from "bootstrap";
import store from "store";

import { TermsOfService } from "./TermsOfService";
import { ensure, useFolks, isLoaded, notYet } from "capi/hooks";
import * as Folks from "capi/folks";
import { signalMarkers, useBind } from "utils/hooks";
import { capiDateToString } from "capi/client";
import { BrandedDialog } from "../../shared/Dialog/comps";
import { OpenDialog } from "../../shared/Dialog";
import { sessionClientByPath } from "../../../capi/appclient";
import { doNothing } from "../../../utils/constants";
import { Button } from "../../shared";

function TermsCheckOn() {
  const storageRq = ensure(useFolks(Folks.StorageGet, "tos"), 200);
  const helloRq = ensure(useFolks(Folks.StorageGet, bootstrap.WZUW ? "hello" : notYet), 200);
  
  if (isLoaded(storageRq) && !storageRq.data)
    return <OpenDialog component={AcceptTermsDialog} doClose={doNothing} />;
  
  if (isLoaded(storageRq) && storageRq.data && bootstrap.WZUW)
    return <WZUWHello rq={helloRq} />;
  
  return null;
}

function TermsCheckOff() {
  return null;
}

function AcceptTermsDialog({ dialog }) {
  const accept = useBind(acceptTerms).then(dialog.close);
  return <BrandedDialog dialog={dialog} buttons={null}>
    <TermsOfService onSubmit={accept} noEscape />
  </BrandedDialog>
}

async function acceptTerms() {
  const newDate = capiDateToString();
  const folks = sessionClientByPath(store, "folks");
  const [cmd] = await folks.execute(new Folks.StoragePut("tos", newDate));
  cmd.ensure(200, 204);
  signalMarkers(`storage:tos`);
  return newDate;
}

function WZUWHello({ rq }) {
  if (isLoaded(rq) && !rq.data)
    return <OpenDialog component={WZUWHelloDialog} doClose={doNothing} />;
  
  return null;
}

function WZUWHelloDialog({ dialog }) {
  const accept = useBind(acceptHello).then(dialog.close);
  
  return <BrandedDialog dialog={dialog} buttons={<Button preset="close" onClick={accept} content="Rozumiem" />}>
    <div className="alignLeft">
      <p>Zbiory biblioteki udostępniane w bazie <b>SDE</b> chronione są prawem autorskim.</p>
      <p>Większość ze digitalizowanych książek możemy udostępniać jedynie w zakresie określonym przepisami o dozwolonym użytku.</p>
      <p>Zgodnie z art. 28 Ustawy o prawach autorskich i prawach pokrewnych z dnia 4 lutego 1994 r. (t.j. Dz.U. 2021 r., poz. 1062 ze zm.)
        biblioteki mogą udostępniać swoje zdigitalizowane zbiory dla celów badawczych lub poznawczych jedynie za pośrednictwem końcówek systemu
        informatycznego (terminali) znajdujących się na ich terenie.</p>
      <p>W związku z powyższym osoby, które chcą skorzystać w całości ze zdigitalizowanych zbiorów, zapraszamy do <b>Biblioteki WZ UW</b>.</p>
      <p>W pewnych określonych sytuacjach możliwy jest również dostęp zdalny do dokumentów.
        Szczegółowe zasady udostępniania znajdziesz na
        <a href="http://www.wz.uw.edu.pl/portale/biblioteka/dzial/e-czytelnia" target="_blank" rel="noopener noreferrer"> stronie Biblioteki WZ UW</a>.</p>
    </div>
  </BrandedDialog>;
}

async function acceptHello() {
  const newDate = capiDateToString();
  const folks = sessionClientByPath(store, "folks");
  const [cmd] = await folks.execute(new Folks.StoragePut("hello", newDate));
  cmd.ensure(200, 204);
  signalMarkers(`storage:hello`);
  return newDate;
}

// Ukryj jeśli nie podano dokumentu z regulaminem i nie jest to instalacja WZUW.
const hasTermsFile = !!(bootstrap.files && bootstrap.files.terms);
export const TermsCheck = (!hasTermsFile && !bootstrap.WZUW) ? TermsCheckOff : TermsCheckOn;
