import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { Icon, Button, Dropdown } from "components/shared";
import { tran } from "utils/language";

// TODO: podgląd hasła powinien być zewnętrznym stanem (value+setValue) żeby dwa pola mogły go współdzielić

let id = 0;

class Input extends Component {
  state = {
    active: false,
    blank: true,
    displayPasswordValue: false
  };
  thisId = id++;
  errorMsgId = `errormsg_${this.thisId}`;
  inputId = `input_${this.thisId}`;
  
  constructor(props) {
    super(props);
    this.state.blank = !props.value;
  }
  
  componentDidMount() {
    if (this.props.selectContent) {
      this.textInput.select();
    }

    if (this.props.autoFocus) {
      // Focus jest często łapany przez inne komponenty (np. Shortcuts), przez co nie input go nie dostawał
      // Zamiast automatycznego dodaania atrybutu autofocus do html-owego inputa odczekujemy chwilę i ustwiamy go funkcją
      this.mountTimeoutId = setTimeout(this.setFocus, 100);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.autoFocus) {
      if(this.props.autoFocus !== prevProps.autoFocus) {
        this.updateTimeoutId = setTimeout(this.setFocus, 100);
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.mountTimeoutId);
    clearTimeout(this.updateTimeoutId);
  }

  handleChange = e => {
    const val = e.target.value;
    const blank = !val;
    if (blank && !this.state.blank)
      this.setState({ blank });
    else if (!blank && this.state.blank)
      this.setState({ blank });
    
    if (this.props.onChange) {
      this.props.onChange(val, e);
    }
  };

  handleBlur = e => {
    this.setState({ active: false });
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  handleFocus = e => {
    this.setState({ active: true });
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  /** Ustawia focus na inpucie */
  setFocus = () => {
    if (this.textInput) {
      this.textInput.focus();
    }
  };

  togglePasswordVisibility = () => {
    this.setState(state => ({
      displayPasswordValue: !state.displayPasswordValue
    }));
  };

  clearContent = () => {
    this.textInput.value = "";
    this.setFocus();
    if (this.props.onChange) {
      this.props.onChange("", {});
    }

    this.props.clearButton && this.props.onClear && this.props.onClear();
  };

  /**
   * Sprawdzenie, czy w propsach podany jest komunikat do wyświetlenia
   * @returns {Boolean}
   */
  checkIfHasErrorMessage() {
    const { error } = this.props;
    return !!error && error !== true;
  };
  
  /** Zwraca odpowiedni typ do głównej ikony
   * @returns {String}
   */
  getIconType = () => {
    if (this.props.error) {
      return "error";
    }
    if (this.props.iconColored) {
      return "primary";
    }
    return "default";
  };

  getAria = () => {
    const aria = {
      "aria-required": this.props.required,
      "aria-invalid": Boolean(this.props.error),
      "aria-disabled": this.props.disabled
    };

    if (this.checkIfHasErrorMessage()) {
      aria["aria-errormessage"] = this.errorMsgId;
    }

    return aria;
  };

  /** Zwraca część propsów, które mają być przekazane do elementu input
   * @returns {Object}
   */
  getInputProps() {
    const props = this.props;
    const result = {};
    for (const k in props) {
      const v = props[k];
      if (!propsToOmit.has(k)) {
        result[k] = v;
      }
    }
    if (result.value === null) result.value = "";
    return result;
  }
  
  handleRef = (element) => {
    this.textInput = element;
    const upstream = this.props.inputRef;
    if (typeof upstream === "function")
      upstream(element);
    else if (typeof upstream === "object")
      upstream.current = element;
  }


  render() {
    const inputId = this.props.id || this.inputId;

    const hasLabel = !!(this.props.label ?? false);

    const actionIconClass = "custom-input__icon custom-input__icon--action";

    const icon = this.props.icon ? (
      <Icon
        name={this.props.icon}
        type={this.getIconType()}
        className="custom-input__icon custom-input__icon--main"
      />
    ) : null;

    const errorMsg = this.checkIfHasErrorMessage() ? (
      <div id={this.errorMsgId} className="custom-input__error-msg">
        {this.props.error}
      </div>
    ) : null;

    const buttonOnClick = () => {
      if (this.props.button.onClick) this.props.button.onClick();
    };

    const button = this.props.button ? (
      <Button
        size="small"
        variant="icon"
        className={actionIconClass}
        {...this.props.button}
        onClick={buttonOnClick}
      />
    ) : null;

    const clearButton = this.props.clearButton && (this.props.onChange ? !!this.props.value : true) &&
      <Button 
        variant="icon" 
        size="small" 
        type="button"
        className={cx(actionIconClass)}
        preset="clear"
        onClick={this.clearContent} />

    const passwordVisibilityButton =
      this.props.enablePasswordPreview && this.props.type === "password" ? (
        <Button
          icon={this.state.displayPasswordValue ? "eye-slash" : "eye"}
          onClick={this.togglePasswordVisibility}
          className={actionIconClass}
          size="small"
          title={tran(this.state.displayPasswordValue ? 'password.hide' : 'password.show')}
        />
      ) : null;

    const label = hasLabel ? (
      <label
        htmlFor={inputId}
        onClick={this.setFocus}
        className={`custom-input__label 
          ${ this.props.labelCls }
          ${ this.props.required ? "required" : "" }`
        }
      >
        {this.props.label}
      </label>
    ) : null;

    const dropdown = this.props.dropdown ? (
      <InputDropdown 
        props={this.props.dropdown}
        textInput={this.textInput}
        active={this.state.active}
      />
    ) : null;
    
    const numberOfActionIcons = [
      clearButton,
      passwordVisibilityButton,
      button
    ].filter(item => !!item).length;

    const rightColumn =
      numberOfActionIcons > 0 ? (
        <div className={`custom-input__right-column ${this.props.usersListClass ? "custom-input__right-column--users-list" : ""}`}>
          {clearButton}
          {passwordVisibilityButton}
          {button}
        </div>
      ) : null;

    const wrapperClasses = cx(
      "custom-input",
      this.props.wrapperCls,
      `right-icons-${numberOfActionIcons}`,
      `variant__${this.props.variant}`,
      {
        "with-icon": this.props.icon,
        "with-right-column": numberOfActionIcons > 0,
        "with-dropdown": Boolean(this.props.dropdown),
        fluid: this.props.fluid,
        inline: this.props.inline,
        borderless: this.props.borderless,
        error: this.props.error,
        compact: this.props.compact,
        filled: !this.state.blank || !!this.props.value || !!this.props.placeholder,
        active: this.state.active,
        labeled: hasLabel,
        disabled: this.props.disabled,
      }
    );

    return (
      <div className={wrapperClasses} style={this.props.style} onClick={this.props.onClick}>
        {label}
        {icon}
        <div className="custom-input__inner">
          {dropdown}
          <input
            disabled={this.props.disabled}
            {...this.getAria()}
            {...this.getInputProps()}
            id={inputId}
            name={this.props.name}
            type={
              this.props.enablePasswordPreview && this.props.type === "password"
              ? this.state.displayPasswordValue
              ? "text"
              : "password"
              : this.props.type || "text"
            }
            className={`custom-input__input ${this.props.className || ""} ${this.props.disabled ? "custom-input__input--disabled" : "" }`}
            onBlur={this.handleBlur}
            onFocus={this.handleFocus}
            onChange={this.handleChange}
            ref={this.handleRef}
          />
          {rightColumn}
        </div>
        {errorMsg}
      </div>
    );
  }
}

const propsToOmit = new Set([
  "label",
  "inline",
  "iconColored",
  "borderless",
  "fluid",
  "className",
  "autoFocus",
  "compact",
  "error",
  "clearButton",
  "onClear",
  "button",
  "selectContent",
  "enablePasswordPreview",
  "dropdown",
  'wrapperCls',
  'labelCls',
  'variant',
  "inputRef",
]);



const ARROW_UP = "ArrowUp";
const ARROW_DOWN = "ArrowDown";

// TODO: pozbyć się sandryzmu "usersListClass"
// FIXME: potworek, nie powinniśmy przekazywać referencji DOM do innego komponentu
function InputDropdown({ props, active, textInput }) {
  const { value, options, onChange } = props;
  const _value = props.value;
  const _onChange = props.onChange;


  function handleKey(e) {
    if (e.key === ARROW_DOWN) {
      const currentValueIndex = options.findIndex(
        option => option.value === _value
      );

      if (currentValueIndex === options.length - 1) {
        return _onChange(options[0].value);
      }

      const nextValueIndex = currentValueIndex + 1;
      onChange(options[nextValueIndex].value);
    } else if (e.key === ARROW_UP) {
      const currentValueIndex = options.findIndex(
        option => option.value === _value
      );

      if (currentValueIndex === 0) {
        return _onChange(options[options.length - 1].value);
      }

      const nextValueIndex = currentValueIndex - 1;
      onChange(options[nextValueIndex].value);
    }
  }

  useEffect(() => {
    if (active) {
      textInput.addEventListener("keydown", handleKey);
      return () => {
        textInput.removeEventListener("keydown", handleKey);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, value])

  return (
    <Dropdown className={props.usersListClass ? "dropdown--sm" : ""}
      headerClass
      inline
      {...props}
    />
  )
}

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,

  disabled: PropTypes.bool,
  required: PropTypes.bool,

  onChange: PropTypes.func, // funkcja do wyciągania wartości
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,

  icon: PropTypes.string, // nazwa ikony
  iconColored: PropTypes.bool,

  fluid: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  borderless: PropTypes.bool,
  compact: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.element]),

  clearButton: PropTypes.bool,
  onClear: PropTypes.func,
  selectContent: PropTypes.bool, // czy zaznaczyć zawartość inputa na starcie
  button: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  enablePasswordPreview: PropTypes.bool, // wyświetla przycisk umożliwiający podgląd hasła

  dropdown: PropTypes.object,
  wrapperCls: PropTypes.string,
  labelCls: PropTypes.string,

  variant: PropTypes.oneOf(["default", "search", "no-border"])
};

Input.defaultProps = {
  variant: "default"
}

export default Input;
