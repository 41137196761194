import React from "react";
import PropTypes from "prop-types";
import { Icon } from "components/shared";

const PageHeader = props => {
  const icon =
    props.icon && props.icon.length > 0 ? (
      <Icon className="page-header__icon" name={props.icon} />
    ) : null;
  
  const title = props.title ? props.title : props.children;
  const subheader = props.description || props.subheader;
  
  return (
    <header className="page-header__wrapper">
      <div className="page-header__container">
        <div className="page-header__headers">
          {title && <h1 className="page-header__title">{title}</h1>}
          {subheader && <div className="page-header__subtitle">{subheader}</div>}
        </div>
        {props.rightColumnContent && <div className="page-header__right-column-content">
          {props.rightColumnContent}
        </div>}
        {icon}
      </div>
    </header>
  );
};

PageHeader.propTypes = {
  icon: PropTypes.string,
  // nazwa ikony
  title: PropTypes.any,
  description: PropTypes.node,
  rightColumnContent: PropTypes.node,

  // ! Deprecated
  subheader: PropTypes.node
};

export default PageHeader;
