import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { pages } from "utils/pages";

const ProfileLink = props => {
  const { label } = props;

  if (!label) {
    return null;
  }

  return (
    <Link
      to={pages.myProfile.fullPath}
      className="hide-on-med-and-down top-bar__profile-link"
    >
      {label}
    </Link>
  );
};

const READ = state => ({
  label: state.currentUser.folks.label
});

export default connect(READ)(ProfileLink);
