import bootstrap from "bootstrap";
import store from "store";

import { PassportCreate, SessionCreate, UserInfo, UserPassSet } from "capi/folks";
import { savePassportInfo, saveSessionInfo } from "utils/localStorageSession";
import { emptyArray, isTrue } from "utils/constants";
import { FETCH_CURRENT_USER, SESSION_SUCCESS, UPDATE_SHORTCUTS } from "sagas/types";
import { Toast } from "../../shared";
import { Dictionary } from "../../../utils/types";
import { useEffect, useState } from "react";
import { scheduleReactUpdate } from "../../../utils/hooks";

// Można wywołać z auth, albo z identifier+password, ale tylko ta druga forma sprawdza ważność hasła
export async function performLogin({ identifier, password, auth, remember, newPassword }:
                                     { identifier?: string, password?: string, auth?: readonly unknown[], remember?: boolean, newPassword?: string }): 
  Promise<{ expiry?: Date, expired?: boolean, role?: string }>
{
  const sessionCmd  = new SessionCreate(bootstrap.domain)
  let   passportCmd = null;
  let   passwordCmd = null;
  let   result      = {} as { expiry?: Date };
  
  if (!auth) {
    auth = [100, identifier, bootstrap.domain, password];
  }
  
  if (password !== undefined) {
    const [userInfoCmd] = await bootstrap.folks.client
      .withAuth(...auth)
      .execute(new UserInfo());
    
    userInfoCmd.ensure(200);
    const userInfo = userInfoCmd.result.data;
  
    if (!newPassword) {
      if (userInfo.kind !== "registrant" && userInfo.expiry && userInfo.expiry <= new Date()) {
        return { expired: true, role: userInfo.role };
      }
    }
    else {
      passwordCmd = new UserPassSet(userInfo.user_id, newPassword);
    }
  }
  
  if (remember)
    passportCmd = new PassportCreate();
  
  await bootstrap.folks.client
    .withAuth(...auth)
    // @ts-ignore // TS nie rozumie, że .filter(isTrue) usunie nulle
    .execute(...[passwordCmd, sessionCmd, passportCmd].filter(isTrue));
  
  if (passwordCmd) {
    passwordCmd.ensure(200);
    result.expiry = passwordCmd.result!.data.expiry;
  }
  
  sessionCmd.ensure(200);
  
  if (passportCmd && passportCmd.status === 200) {
    const { passport_id, passport_key } = passportCmd.result!.data;
    savePassportInfo({ passport_id, passport_key });
  }
  
  const { expires_at, session_id, session_key, timeout } = sessionCmd.result!.data;
  
  saveSessionInfo({
    session_id,
    session_key,
    session_expires_at: expires_at.getTime(),
    timeout
  });
  
  store.dispatch({
    type: SESSION_SUCCESS,
    payload: {
      session_id,
      session_key,
      expires_at,
      timeout
    }
  });
  
  store.dispatch({
    type: UPDATE_SHORTCUTS,
    payload: []
  });
  
  store.dispatch({
    type: FETCH_CURRENT_USER,
    payload: { folks: true, teka: true, sowa: true, settings: true }
  });
  
  return result;
}

//
//
//

export type MakeJwtFn = () => Promise<string>;

function handleJwtLoginFailure(e: any) {
  Toast.emit("Logowanie kluczem nie powiodło się.", { type: "error" });
  e.dontReport = true;
  console.error(e);
}

async function handleJwtHandshake(callback: (claims: Dictionary) => Promise<string>): Promise<void> {
  try {
    function makeJwt() {
      const dom = bootstrap.domain;
      const exp = Date.now() / 1000 + 300;
      return callback({ dom, exp });
    }
    
    for (const cb of JWT_STACK) {
      cb(makeJwt);
      return;
    }
    
    await performLogin({ auth: [300, await makeJwt()], remember: false });
  }
  catch (e: any) {
    handleJwtLoginFailure(e);
  }
}

// @ts-ignore
window.jwtLogin = handleJwtHandshake;

window.addEventListener('message', function (event) {
  if (event.source !== window) return;
  const message = event.data;

  if (typeof message === "object" && message.type === "jwt-login") {
    // rozszerzenie powinno wysłać nam port do prywatnej komunikacji
    const port = event.ports && event.ports[0];
    if (!port) {
      console.error("JWT login message contained no port");
      return;
    }
    void handleJwtHandshake(claims => new Promise((resolve, reject) => {
      // wchodzimy tutaj kiedy websowa zdecyduje się kontynuować tworzenie JWT
      // podczas logowania jest to zaraz po otrzymaniu wiadomości
      // a podczas wypełniania pola w formularzu jest to przy submicie formularza
      
      // na wypadek jakby rozszerzenie nie odpowiedziało
      const timer = setTimeout(() => {
        port.close()
        reject(new Error("Timeout waiting for reply on return port"))
      }, 3000);
      
      // 2. czekamy na odpowiedź z tokenem
      port.onmessage = (event: MessageEvent) => {
        const token = event.data;
        clearTimeout(timer);
        if (typeof token === "string")
          resolve(token);
        else
          reject(new Error("Invalid message received on return port: " + typeof token));
        port.close();
      }
      port.onmessageerror = () => {
        clearTimeout(timer);
        reject(new Error("Invalid message received on return port - can't deserialize"));
        port.close();
      }
      
      // 1. wysyłamy claimsy --> 2.
      port.postMessage(claims);
    }))
  }
});


// poniższy hook jest mechanizmem pozwalającym przekierować obsługę JWT z: logowania do aplikacji
// do: innego komponentu, np. do operacji niedostępnych z poziomu sesji, które normalnie proszą o hasło;
// dostajemy `undefined` (przed uwierzyt.) albo asynchroniczną funkcję, której wywołanie zwróci token JWT,
// należy ją wywołać dopiero podczas submitowania formularza, żeby token był aktualny
const JWT_STACK = [] as ((makeJwt: MakeJwtFn) => void)[];
export function useJwtLogin() {
  const [jwtLogin, setJwtLogin] = useState<undefined | MakeJwtFn>(undefined)
  useEffect(() => {
    const fn = (makeJwt: MakeJwtFn) => {
      async function wrapper() {
        try {
          return await makeJwt()
        }
        finally {
          // czyścimy po wywołaniu, bo makeJwt wolno wywołać tylko raz,
          // więc jak submit formularza nie przejdzie, to trzeba dać znać,
          // że należy kolejny raz utworzyć JWT ręcznie
          // TODO: jak zaktualizujemy do nowszego Reacta to tutaj chcemy użyć startTransition,
          //       żeby odwlec renderowanie tej aktualizacji, bo siłą rzeczy wykonuje się przed
          //       skończeniem submitowania formularza i inaczej z tym nic nie zrobimy
          scheduleReactUpdate(setJwtLogin, undefined);
        }
      }
      // `() =>` bo useState funkcje traktuje specjalnie
      setJwtLogin(() => wrapper);
    }
    JWT_STACK.push(fn);
    return () => { JWT_STACK.splice(JWT_STACK.indexOf(fn), 1); }
  }, emptyArray);
  return jwtLogin;
}
