import React from "react";
import { ensure, isError, isLoading, useSowa } from "../../../../../capi/hooks";
import { RecordList } from "../../../../../capi/sowa";
import Loader from "../../../../shared/Loader";
import { Marq } from "../../../../../utils/smarc/marq";
import { tran } from "../../../../../utils/language";
import { issueNrAndDate } from "utils/tekaUtils";
import { ServerTree } from "../usePaginatedTree";

export function getSlideoutForDocument(document?: ServerTree['document']): JSX.Element | null {
  if (document) {
    if (document!.sowa_id)
      return <DocTreeSlideoutSowa catId={document!.catalogue_id!} sowaId={document!.sowa_id}/>
    else if (document!.metadata && document!.metadata.t)
      return <DocTreeSlideoutTeka {...document!.metadata} />
  }
  
  return null;
}

export const DocTreeSlideoutSowa = React.memo(function DocTreeSlideoutSowa({ catId, sowaId }: { catId: string, sowaId: string }): JSX.Element {
  const rq = ensure(useSowa(catId, GetRecord, sowaId));
  
  if (isLoading(rq))
    return <Loader />
  
  if (isError(rq) || rq.data.length != 1)
    return <p style={{ color: "var(--cancel)" }}>{rq.message || tran("teka.explorer.slideout.404")}</p>
  
  const isN = (rq.data[0].rec_no || "").startsWith("N");
  const record = isN ? rq.data[0].parents.find((rec: any) => rec.rec_no.startsWith("U")).view : rq.data[0].view;
  const marc = Marq.fromNestedLists(record.data);
  const titleZone = marc.first("245").subfields().join(),
    publisherZone = marc.first("260").subfields().join() || marc.first("773").subfields("d").smartJoin(),
    physicalZone  = marc.first("300").subfields().join();
  
  const [issueNr, issueDate] = issueNrAndDate(rq.data[0]);
  
  return <div>
    {issueNr && <p>Nr: {issueNr}{issueDate ? <>, z dnia: {issueDate}</> : null}.</p>}
    <p>{titleZone || <span style={{ color: "var(--cancel)" }}>{tran("teka.explorer.slideout.245")}</span>}</p>
    {publisherZone && <p>{publisherZone}</p>}
    {physicalZone && <p>{physicalZone}</p>}
  </div>
});

class GetRecord extends RecordList {
  constructor(sowaId: string) {
    super([sowaId]);
  }
}

type DocTreeSlideoutTekaProps = {
  t: string
  c?: readonly string[],
  y?: number
}

export const DocTreeSlideoutTeka = React.memo(function DocTreeSlideoutTeka({ t, c, y }: DocTreeSlideoutTekaProps): JSX.Element {
 return <div>
    <p>{t}</p>
    {c ? <p>{c.join(", ")}</p> : null}
    {y ? <p>{y}</p> : null}
  </div>
});
