import React from "react";
import { Icon } from "components/shared";

// Tak, w tym pliku jest bałagan, ale jest próba jego względnego ogarnięcia w pull requestcie. Tylko trzeba by potem uwzględnić zmiany dot. propsa ```required``` przy rozwiązywaniu konfliktów.
const Item = props => {
  const { name, value, bold, required, align, className, ...rest } = props;
  
  const alignClass = ["top", "bottom", "center"].indexOf(align) > -1 ? `align-${align}` : "";
  const boldClass = bold ? "bold" : "";

  const requiredIcon = required && (
    <Icon
      size="tiny"
      name="asterisk"
      type="error"
      style={{ marginLeft: 8 }}
    />
  );

  return (
    <div className={`column-view__item ${boldClass} ${alignClass} ${className}`} {...rest}>
      <div className={`column-view__item-name`}>
        {name}
        {requiredIcon}
      </div>
      <div className={`column-view__item-value`}>{value}</div>
    </div>
  );
};

Item.defaultProps = {
  className: "",
  align: ""
};

export default Item;
