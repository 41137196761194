import React, { useEffect, useState } from "react";
import { emptyArray } from "../../../utils/constants";

import { toastSignals, toastModule } from "./Toast";

export function ToastContainer() {
  const [toast, setToast] = useState(toastModule);
  
  useEffect(() => {
    toastSignals.add(setToast);
    return () => toastSignals.delete(setToast);
  }, emptyArray);
  
  if (!toast)
    return null;
  
  const transition = toast.cssTransition({
    enter: 'toastIn',
    exit: 'toastOut',
    duration: 150,
    appendPosition: false
  }); 
  
  const Toastify = toast.ToastContainer;
  
  return (
    <Toastify
      closeButton={false}
      position="bottom-left"
      draggable={false}
      transition={transition}
      pauseOnHover={true}
      pauseOnFocussLoss={true}
      hideProgressBar={true}
    />
  );
}
