import React from "react";
import PropTypes from "prop-types";

import { Popup, Button } from "components/shared";

const MenuItem = props => {
  const { children, buttonProps } = props;

  return (
    <Popup
      on="click"
      closeOnScroll={false}
      position="bottom right"
      trigger={open => (
        <Button color={open ? "primary" : undefined} {...buttonProps} />
      )}
      content={children}
    />
  );
};

MenuItem.propTypes = {
  buttonProps: PropTypes.object.isRequired
};

export default MenuItem;
