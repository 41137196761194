export const APP_LOAD = "APP_LOAD";
export const APP_SHOWN = "APP_SHOWN";
export const APP_HIDDEN = "APP_HIDDEN";
export const ACTIVITY_CHANGE = "ACTIVITY_CHANGE";

export const SESSION_SUCCESS = "SESSION_SUCCESS";
export const SESSION_ERROR = "SESSION_ERROR";
export const CHECK_SESSION = "CHECK_SESSION";
export const UPDATE_SESSION_INFO = "UPDATE_SESSION_INFO";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

export const CLEAR_USER = "CLEAR_USER";
export const FETCH_CURRENT_USER = "FETCH_CURRENT_USER";
export const CURRENT_USER_SUCCESS = "CURRENT_USER_SUCCESS";
export const CURRENT_USER_ERROR = "CURRENT_USER_ERROR";
export const CURRENT_USER_LOADING = "CURRENT_USER_LOADING";

export const CURRENT_USER_TEKA_SUCCESS = "CURRENT_USER_TEKA_SUCCESS";
export const CURRENT_USER_TEKA_ERROR = "CURRENT_USER_TEKA_ERROR";

export const CURRENT_USER_SOWA_SUCCESS = "CURRENT_USER_SOWA_SUCCESS";
export const CURRENT_USER_SOWA_ERROR = "CURRENT_USER_SOWA_ERROR";

export const SESSION_EXPIRATION_UPDATED = "SESSION_EXPIRATION_UPDATED";
export const RESET_SESSION_CLOCK = "RESET_SESSION_CLOCK";

export const UPDATE_SHORTCUTS = "UPDATE_SHORTCUTS";
export const SHORTCUTS_SUCCESS = "SHORTCUTS_SUCCESS";

export const UPDATE_SETTINGS = "UPDATE_SETTINGS";
export const SETTINGS_SUCCESS = "SETTINGS_SUCCESS";
export const FETCH_SETTINGS = "FETCH_SETTINGS";

export const FETCH_DICTIONARIES = "FETCH_DICTIONARIES";
export const DICTIONARIES_SUCCESS = "DICTIONARIES_SUCCESS";
export const DICTIONARIES_ERROR = "DICTIONARIES_ERROR";

export const DISPLAY_JOURNAL = "DISPLAY_JOURNAL";
export const HIDE_JOURNAL = "HIDE_JOURNAL";

export const FETCH_APPLICATION_QUEUE = "FETCH_APPLICATION_QUEUE";
export const APPLICATION_QUEUE_SUCCESS = "APPLICATION_QUEUE_SUCCESS";
export const APPLICATION_QUEUE_ERROR = "APPLICATION_QUEUE_ERROR";
export const APPLICATION_QUEUE_LOADING = "APPLICATION_QUEUE_LOADING";

export const FETCH_APPLICATION_INFO = "FETCH_APPLICATION_INFO";
export const APPLICATION_INFO_SUCCESS = "APPLICATION_INFO_SUCCESS";
export const APPLICATION_INFO_ERROR = "APPLICATION_INFO_ERROR";
export const APPLICATION_INFO_LOADING = "APPLICATION_INFO_LOADING";
export const EDIT_APPLICATION = "EDIT_APPLICATION";

export const FETCH_DOCUMENT_DATA = "FETCH_DOCUMENT_DATA";
export const DOCUMENT_LOADING = "DOCUMENT_LOADING";
export const DOCUMENT_SUCCESS = "DOCUMENT_SUCCESS";
export const DOCUMENT_ERROR = "DOCUMENT_ERROR";

export const TEKA_CATALOGUES_SUCCESS = "TEKA_CATALOGUES_SUCCESS";
export const TEKA_CATALOGUES_ERROR = "TEKA_CATALOGUES_ERROR";
export const TEKA_CATALOGUES_LOADING = "TEKA_CATALOGUES_LOADING";

export const FETCH_DOCUMENT_STATS = "FETCH_DOCUMENT_STATS";
export const DOCUMENT_STATS_SUCCESS = "DOCUMENT_STATS_SUCCESS";
export const DOCUMENT_STATS_ERROR = "DOCUMENT_STATS_ERROR";
export const DOCUMENT_STATS_LOADING = "DOCUMENT_STATS_LOADING";

export const FETCH_LABELS_LIST = "FETCH_LABELS_LIST";
export const LABELS_LIST_SUCCESS = "LABELS_LIST_SUCCESS";
export const LABELS_LIST_ERROR = "LABELS_LIST_ERROR";
export const LABELS_LIST_LOADING = "LABELS_LIST_LOADING";
export const FETCH_LABEL = "FETCH_LABEL";
export const LABEL_SUCCESS = "LABEL_SUCCESS";
export const LABEL_ERROR = "LABEL_ERROR";
export const LABEL_LOADING = "LABEL_LOADING";
export const ADD_LABEL = "ADD_LABEL";
export const EDIT_LABEL = "EDIT_LABEL";
export const REMOVE_LABEL = "REMOVE_LABEL";
export const ADD_DOCUMENTS_TO_LABEL = "ADD_DOCUMENTS_TO_LABEL";
export const REMOVE_DOCUMENTS_FROM_LABEL = "REMOVE_DOCUEMENTS_FROM_LABEL";

export const FETCH_USERS_LIST = "FETCH_USERS_LIST";
export const USERS_LIST_SUCCESS = "USERS_LIST_SUCCESS";
export const USERS_LIST_ERROR = "USERS_LIST_ERROR";
export const USERS_LIST_LOADING = "USERS_LIST_LOADING";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const EDIT_PASSWORD = "EDIT_PASSWORD";

export const FETCH_USER = "FETCH_USER";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_ERROR = "USER_ERROR";
export const USER_LOADING = "USER_LOADING";
export const EDIT_FOLKS_USER = "EDIT_FOLKS_USER";

export const USER_TEKA_SUCCESS = "USER_TEKA_SUCCESS";
export const USER_TEKA_ERROR = "USER_TEKA_ERROR";
export const EDIT_TEKA_USER = "EDIT_TEKA_USER";

export const USER_SOWA_SUCCESS = "USER_SOWA_SUCCESS";
export const USER_SOWA_ERROR = "USER_SOWA_ERROR";
export const EDIT_SOWA_USER = "EDIT_SOWA_USER";

export const CREATE_ADDRESS = "CREATE_ADDRESS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const VERIFY_ADDRESS = "VERIFY_ADDRESS";
export const CANCEL_ADDRESS = "CANCEL_ADDRESS";
export const RESEND_ADDRESS = "RESEND_ADDRESS";

export const RESET_EMAIL = "RESET_EMAIL";

export const FETCH_PREVIEW_USERS = "FETCH_PREVIEW_USERS";
export const PREVIEW_USERS_LOADING = "PREVIEW_USERS_LOADING";
export const PREVIEW_USERS_SUCCESS = "PREVIEW_USERS_SUCCESS";

export const DISPLAY_FOOTER = "DISPLAY_FOOTER";
export const HIDE_FOOTER = "HIDE_FOOTER";

export const REQUEST_LEASE = "REQUEST_LEASE";
export const LEASE_LOADING = "LEASE_LOADING";
export const LEASE_SUCCESS = "LEASE_SUCCESS";
export const LEASE_ERROR = "LEASE_ERROR";
