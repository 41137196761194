import { select, take } from "redux-saga/effects";

/**
 * Funkcja wstrzymująca działanie sagi, dopóki określony klucz w storze nie będzie miał określonej wartości
 * @param {Function} selector callback przekazywany funkcji `select`
 * @param {Function} compare callback otrzymujący jako parametr aktualną wartość elementu w storze - jeśli zwróci `true`, to funkcja się zakończy
 */
export function* waitFor(selector, compare) {
  let stateValue = yield select(selector);
  while (!compare(stateValue)) {
    yield take();
    stateValue = yield select(selector);
  }
}
