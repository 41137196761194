import React from "react";
import bootstrap from "bootstrap";
import { Segment, Divider } from "components/shared";
import { translate } from "utils/language";
import SowaGDPR from "./SowaGDPR";
import {ExternalDocument} from "../ExternalDocument/ExternalDocument";

const FILES = bootstrap.files || {};

const PrivacyPolicyPage = () => {
  return (
    <div className="plain-page__container">
      <Segment className="plain-page__content">
        {FILES.privacy
          ? <ExternalDocument resource="privacy" locationScrolling/>
          : null}
        
        {FILES.gdpr
          ? <ExternalDocument resource="gdpr"/>
          : <SowaGDPR />}
        
      </Segment>
    </div>
  );
};

export default PrivacyPolicyPage;
