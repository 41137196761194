export function getMinutesAndSecondsString(time) {
  const timeInSecs = time / 1000.0;
  
  let seconds = Math.floor(timeInSecs % 60);
  seconds = seconds >= 10 ? String(seconds) : "0" + String(seconds);

  let minutes = Math.floor(timeInSecs / 60);

  let hours = Math.floor(minutes / 60);

  // Jeśli aktualny czas jest już liczony w godzinach, to liczba minut do wyświetlenia jest znów liczona od zera
  if(minutes > 59)
    minutes = minutes - (hours * 60);

  minutes = minutes >= 10 ? String(minutes) : "0" + String(minutes);

  return `${hours === 0 ? "" : hours + ":"}${minutes}:${seconds}`;
}
