import React, { useEffect, useRef } from "react";
import { Form, FormInputProps, FormIsRequiredProps, useNewField } from "utils/forms";
import { useFieldMonitor } from "utils/forms/formField";

type BirthDateInputProps = {
  invalidmessage?: React.ReactNode
};

function isNumInRange(num: number, min: number, max: number) {
  return num >= min && num <= max;
}

/** Wypełnianie pola daty urodzenia użytkownika na podstawie wartości pola z numerem PESEL (jego pierwszych 6 cyfr)
 *  https://pl.wikipedia.org/wiki/PESEL
*/
export function BirthDateInput(props: BirthDateInputProps & FormIsRequiredProps & FormInputProps) {
  const dateField = useNewField<string>(props.name || "", props.form, props.initial || "", undefined, props.extra);
  const peselField = useFieldMonitor<string>("pesel", props.form);

  const prevVal = useRef(props.initial || "");
  const setPrevVal = useRef(false);

  useEffect(() => {
    if(props.disabled || !peselField || !peselField.value)
      return;

    if(peselField.error) {
      if(setPrevVal.current) {
        dateField.set(prevVal.current);
        setPrevVal.current = false;
      }
      return;
    }

    // wyciągamy po dwie cyfry oznaczające rok, miesiąc i dzień z pierwszych 6 cyfr numeru PESEL
    let year = peselField.value.substring(0, 2);
    let month = peselField.value.substring(2, 4);
    const day = peselField.value.substring(4, 6);

    // dekodujemy miesiąc i rok urodzenia wg zasad z linka powyżej
    if(isNumInRange(+month, 81, 92)) {
      year = `18${year}`;
      month = (+month - 80).toString().padStart(2, "0");
    } else if(isNumInRange(+month, 1, 12)) {
      year = `19${year}`;
      month = month.padStart(2, "0");
    } else if(isNumInRange(+month, 21, 32)) {
      year = `20${year}`;
      month = (+month - 20).toString().padStart(2, "0");
    } else if(isNumInRange(+month, 41, 52)) {
      year = `21${year}`;
      month = (+month - 40).toString().padStart(2, "0");
    } else if(isNumInRange(+month, 61, 72)) {
      year = `22${year}`;
      month = (+month - 60).toString().padStart(2, "0");
    }

    const birthDate = `${year}-${month}-${day}`;

    prevVal.current = dateField.value;
    setPrevVal.current = true;

    dateField.set(birthDate);
  }, [props.disabled, peselField?.value, peselField?.error]);

  return React.createElement(Form.Input, { ...props, field: dateField });
};