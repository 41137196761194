import React, {useContext, useRef, useEffect} from "react";

import {doNothing, emptyArray, emptyObject} from "../../../utils/constants";
import {OverlayServiceContext, OverlayState, OverlayType} from "./OverlayService";

const OverlayApiProto = {
  _onClose: undefined,
  _doClose: undefined,
  
  get isOpen() {
    return this._state && this._state.isOpen;
  },
  
  onClose(callback?: (...args: any[]) => void) {
    this._onClose = callback;
    if (this._state)
      this._state.onClose = callback;
    return this;
  },
  
  doClose(callback?: (...args: any[]) => void) {
    this._doClose = callback || undefined;
    if (this._state)
      this._state.doClose = callback;
    return this;
  }
} as any as IOverlay;

/** Interfejs do interakcji z nakładką z wewnątrz */
export interface IOverlay {
  /** Czy nakładka powinna się zamknąć jeśli wywołujący komponent zostanie odmontowany? Domyślnie tak. */
  autoClose: boolean
  
  /** Czy okno dialogowe jest aktualnie otwarte? */
  readonly isOpen: boolean
  
  open(type: OverlayType, content: JSX.Element): void
  
  /** Zamknięcie nakładki */
  readonly close: () => void
  readonly closeWith: (...args: any[]) => void
  
  readonly forceClose: (...args: any[]) => void 
  
  /** Ustawienie callbacka wywoływanego asynchronicznie po zamknięciu nakładki */
  onClose(callback?: (...args: any[]) => void): this
  
  /** Ustawienie callbacka wywoływanego synchronicznie zamiast wbudowanej logiki close() */
  doClose(callback?: (...args: any[]) => void): this
}

export default function useOverlay(): IOverlay {
  const service = useContext(OverlayServiceContext);
  
  type State = IOverlay & {
    _state: OverlayState | null
    _onClose?: (...args: any[]) => void
    _doClose?: (...args: any[]) => void
    _mount: () => () => void
    _mounted: boolean
  }
  
  const ref = useRef(null as any as State);
  
  if (ref.current === null) {
    const overlay = ref.current = {
      __proto__: OverlayApiProto,
      
      _state: null,
      _onClose: undefined,
      _doClose: undefined,
      autoClose: true,
      _mounted: true,
      
      close: () => overlay._state && overlay._state.close(),
      closeWith: function() { overlay._state && overlay._state.close.apply(this, arguments as any) },
      forceClose: function() { overlay._state && overlay._state.forceClose.apply(this, arguments as any) },
      
      open(type: OverlayType, content: JSX.Element) {
        if (this._state && this._state.isOpen)
          this._state.update(content);
        else if (this._mounted) {
          const llOverlay = this._state = service.createOverlay(type, content);
          llOverlay.onClose = overlay._onClose;
          llOverlay.doClose = overlay._doClose;
        }
      },
      
      _mount: () => () => {
        // unmount
        overlay._mounted = false;
        if (overlay.autoClose) {
          overlay._state && overlay._state.forceClose();
        }
      }
    } as any as State /* TS nie wspiera __proto__ */;
  }
  
  // nie wiem czemu mu to tutaj nie pasuje, nie wychodzimy wcześnie
  // eslint-disable-next-line
  useEffect(ref.current._mount, emptyArray);
  
  return ref.current;
};
