import React from "react";
import PropTypes from "prop-types";
import {useId} from "../../../utils/hooks";

const Switch = ({ isOn, handleToggle }) => {
  const id = useId();
  
  return (
    <div className="switch" 
      tabIndex={0}
      role="checkbox"
      aria-checked={!!isOn}   
      onClick={handleToggle} 
    >
      <input
        checked={!!isOn}
        className="switch__checkbox"
        id={id}
        type="checkbox"
        tabIndex={-1}
        onChange={() => {}}
      />
      <label
        className="switch__label"
        htmlFor={id}
      >
        <span className="switch__circle" />
      </label>
  </div>
  );
}

Switch.propTypes = {
  isOn: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired
};

export default Switch;
