import React, { useState } from "react";
import { Icon, Button } from "components/shared";
import { translate } from "utils/language";

const CookiesDeclaration = () => {
  const [shown, setShown] = useState(localStorage.getItem("cookiesok") !== "1");

  if (!shown) return null;

  const handleClick = () => {
    localStorage.setItem("cookiesok", 1);
    setShown(false);
  };

  return (
    <div className="cookies-declaration">
      <Icon name="cookie-bite" className="cookies-declaration-icon" />
      <div className="cookies-declaration-text">
        <p>
          {translate("information.cookies.main")}
          <br />
          {translate("information.cookies.more")}
        </p>
      </div>
      <div>
        <Button variant="text" to="/privacy-policy">
          {translate("information.cookies.learnMore")}
        </Button>
        <Button pretext="consent" icon="check" onClick={handleClick} />
      </div>
    </div>
  );
};

export default CookiesDeclaration;
