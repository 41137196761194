import {useRef, useState} from "react";

import {increment} from "../constants";

const slice = Array.prototype.slice;

type FunctionReturning<R> = ((...args: any[]) => R) | ((...args: any[]) => Promise<R>)

type Getter<R> = () => Promise<R>

type Setter<R> = {
  (nothing: null): void
  (constant: R): void
  <T extends any[]>(sync: (...args: T) => R, ...args: T): void
  <T extends any[]>(async: (...args: T) => Promise<R>, ...args: T): void
}

/** Zmienna stanu do przechowywania funkcji lub nulla.
 *  
 *  Używane w formularzach ze skomplikowanymi polami, które mają własną logikę podczas submitowania.
 *  
 *      const [getter, setter] = useStateFn<string>(null);
 *      
 *  Setter przyjmuje następujące formy:
 *  
 *      setter(null) // czyści wartość
 *      
 *      setter("foo") // ustawia wartość bez dodatkowej logiki
 *      
 *      setter(funkcja, ...argumenty) // powoduje, że podczas wywoływania gettera wywoła się podana funkcja
 *      
 *  Podczas wysyłania formularza sprawdzamy czy `getter !== null`, co oznacza że wartość jest ustawiona,
 *  a następnie robimy `await getter()` by dostać efektywną wartość.
 *  
 *  Jak najbardziej oczekujemy, że funkcja podana do settera i wywoływana przez getter będzie miała
 *  skutki uboczne (np. utworzenie czegoś na serwerze i zwrócenie idka), więc gettera poza submitem nie wywołujemy.
 *  
 **/
export function useStateFn<R>(init: R | FunctionReturning<R> | null): [Getter<R> | null, Setter<R>, unknown, unknown] {
  type State = [
    Getter<R> | null,
    Setter<R>,
    any, // R | FunctionReturning<R> | null
    any  // argumenty dla FunctionReturning<R>
  ]
  
  const ref = useRef(null as any as State);
  const [_, setSignal] = useState(0);
  if (ref.current === null) {
    // getter gdy ustawiona jest wartość a nie funkcja
    const cb1 = () => Promise.resolve(ref.current[2]);
    
    // getter gdy ustawiona jest funkcja z argumentami
    const cb2 = function(this: any) {
      const current = ref.current;
      if (arguments.length > 0)
        return Promise.resolve(current[2].call(this, ...current[3], ...arguments));
      else
        return Promise.resolve(current[2].apply(this, current[3]));
    };
    
    const setter = function(fn: R | FunctionReturning<R> | null) {
      const current = ref.current;
      let changed = false;
      
      if (typeof fn === "function") {
        if (current[0] !== cb2) {
          changed = true;
          current[0] = cb2;
        }
        current[2] = fn;
        const args = current[3];
        if (args && args.length === arguments.length - 1) {
          for (let i = 1; i < arguments.length; i++)
            args[i - 1] = arguments[i];
        }
        else {
          current[3] = slice.call(arguments, 1);
        }
      }
      else if (fn !== null) {
        if (current[0] !== cb1) {
          changed = true;
          current[0] = cb1;
        }
        current[2] = fn;
        current[3] = null;
      }
      else {
        if (current[0] !== null) {
          changed = true;
          current[0] = null;
        }
        current[2] = null;
        current[3] = null;
      }
      
      if (changed)
        setSignal(increment);
    };
    
    //             value, setValue, fn, args
    ref.current = [null, setter, null, null];
    
    setter.apply(init, arguments as any);
  }
  
  return ref.current;
}
