import React, { Component } from "react";
import { Responsive, Icon } from "components/shared";
import PropTypes from "prop-types";

class FloatingActionButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    document
      .querySelector("body")
      .addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    document
      .querySelector("body")
      .removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = event => {
    var scrollPosition = document.querySelector("body").scrollTop;

    if (scrollPosition > window.innerHeight / 4)
      this.setState({ visible: true });
    else this.setState({ visible: false });
  };

  toggleComponent = toggle => {
    this.setState({ visible: toggle });
  };

  scrollTop = () => {
    document.querySelector("body").scrollTop = 0;
  };

  render() {
    var style = {};
    switch (this.props.floating) {
      case "left": {
        style = { bottom: "20px", left: "15px", position: "fixed" };
        break;
      }
      case "right":
      case true: {
        style = { bottom: "40px", right: "40px", position: "fixed" };
        break;
      }
      default: {
        style = { position: "relative" };
      }
    }

    const large = this.props.large ? "large" : "";
    const visible = this.state.visible ? "" : "hidden";

    let fab = (
      <div
        className={`floating-action-button ${large} ${visible}`}
        style={style}
        onClick={() => this.scrollTop()}
      >
        {this.props.icon ? <Icon size="tiny" name={this.props.icon} /> : ""}
      </div>
    );

    if (this.props.hideOnMobile)
      fab = <Responsive minWidth={767}>{fab}</Responsive>;

    return fab;
  }
}

export default FloatingActionButton;

FloatingActionButton.defaultProps = {
  large: false,
  visible: true,

  icon: "plus"
};

FloatingActionButton.propTypes = {
  large: PropTypes.bool,
  visible: PropTypes.bool,

  icon: PropTypes.string
};
