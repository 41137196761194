import { sessionClientByPath } from "capi/appclient";
import { isLoaded } from "capi/hooks";
import * as Teka from "capi/teka";
import { Message, Toast } from "components/shared";
import React from 'react';
import store from "store";
import { useBind, useDerivedState } from "utils/hooks";
import { useTekaParams } from "utils/params/hooks";
import { ExpoTile } from "./ExpoTile";
import { emptyArray } from "../../../../utils/constants";

export function Expo({ isEditor, document }: { isEditor: boolean, document?: { document_id: number, state: string } }) {
  const tekaParamsRq = useTekaParams(TEKA_EXPOSITION_PARAM);
  const expoContents = useDerivedState(getExpoContents, tekaParamsRq);

  const updateTiles = useBind(updateSingleTile, expoContents);
  
  let newDocumentId = document?.document_id;
  let message1;
  let message2;
  
  if (document && expoContents.includes(document.document_id))
    message1 = <Message type="info" content="Ekspozycja posiada już wybrany dokument"/>;
  
  if (document && document.state !== "published") {
    newDocumentId = undefined;
    message2 = <Message type="warning" content="Dokumentu nie można umieścić na ekspozycji, ponieważ nie jest widoczny dla czytelników"/>;
  }
  
  return (
    <>
      {message1}
      {message2}
      <div className="segment teka-expo" >
        {expoContents && expoContents.map(( documentId, index) => {
          return (
            <ExpoTile
              index={index}
              isEditor={isEditor}
              updateTiles={updateTiles}
              documentId={documentId}
              newDocumentId={newDocumentId}
              big={index === 7 || index === 0}
            />
          );
        })}
      </div>
    </>
  );
}


const TEKA_EXPOSITION_PARAM = "branding.exposition.contents";
const DOCUMENT_NONE = 0
const EMPTY_EXPO = Object.freeze(Array(12).fill(DOCUMENT_NONE));

function getExpoContents(paramRequest: any): readonly number[] {
  if (!isLoaded(paramRequest))
    return emptyArray;

  const params = paramRequest.data;  
  const expositionList = params[TEKA_EXPOSITION_PARAM] as readonly number[];
  
  return (expositionList || EMPTY_EXPO).concat(EMPTY_EXPO).slice(0, 12);
}

async function updateExposition(expo: readonly number[]) {
  const teka = sessionClientByPath(store, "teka");

  const paramToSet = {
    [TEKA_EXPOSITION_PARAM]: expo
  };

  const results = await teka.execute(new Teka.ParamsSet(paramToSet));
  const [result] = results

  result.ensure(200, 204);

  Toast.emit("Zmieniono ekspozycję", { timeout: 5000 })

  return true;
}


async function updateSingleTile(expo: readonly number[], index: number, documentId: number) {
  const newExpo = [...expo];
  newExpo[index] = documentId;

  return await updateExposition(newExpo);
}
