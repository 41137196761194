import React from "react";
import PropTypes from "prop-types";

import { Icon } from "components/shared";

import Item from "./Item";
import Hidden from "./Hidden";

// TODO: pewnie można by zrefaktorować bo na kolanie dodawałem responsywność

const Breadcrumb = props => {
  const className = `${props.className} breadcrumb__container flex`;
  const Tag = props.asNav ? "nav" : "div";
  const separator =
    <span tabIndex={-1} className="breadcrumb__separator">
      {props.separator || <Icon name="angle-right" size="small" />}
    </span>;
  
  if (props.maxItems > 3 && props.items.length > 3) {
    return (
      <React.Fragment>
        <Tag className={`${className} show-on-medium-and-up hide-on-small-only`} style={props.style}>
          {renderContent(props.items, props.maxItems, separator)}
        </Tag>
        <Tag className={`${className} show-on-small hide-on-med-and-up`} style={props.style}>
          {renderContent(props.items, 3, separator)}
        </Tag>
      </React.Fragment>
    );
  }
  
  return (
    <Tag className={className} style={props.style}>
      {renderContent(props.items, props.maxItems, separator)}
    </Tag>
  );
};

Breadcrumb.defaultProps = {
  maxItems: 90,
  asNav: true,
  className: ""
};

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.node,
      link: PropTypes.string,
      className: PropTypes.string,
      onClick: PropTypes.func
    })
  ).isRequired,
  style: PropTypes.object,
  separator: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  maxItems: PropTypes.number,
  asNav: PropTypes.bool,
  className: PropTypes.string
};

export default Breadcrumb;

const renderItems = (items, separator) => {
  return items.map((item, id) => {
    const isActive = id === items.length - 1;
    return (
      <React.Fragment key={id}>
        <Item {...item} active={isActive} key={id} />
        {id === items.length - 1 ? null : separator}
      </React.Fragment>
    );
  });
};

const renderContent = (items, maxItems, separator) => {
  const haveToSplit = maxItems < items.length;
  
  if (maxItems === 1) {
    if (items.length === 1) {
      return <Item {...items[0]} />;
    } else {
      return <Hidden items={items} />;
    }
  }
  
  if (haveToSplit) {
    if (maxItems === 2) {
      return (
        <React.Fragment>
          <Hidden items={items.slice(0, items.length - 1)} />
          {separator}
          <Item {...items[items.length - 1]} />
        </React.Fragment>
      );
    }
    
    const lastSplittedIndex = items.length - maxItems + 1; // indeks ostatniego przyciętego elementu
    
    return (
      <React.Fragment>
        <Item {...items[0]} />
        {separator}
        <Hidden items={items.slice(1, lastSplittedIndex + 1)} />
        {separator}
        {renderItems(items.slice(lastSplittedIndex + 1), separator)}
      </React.Fragment>
    );
  } else {
    return renderItems(items, separator);
  }
};
