import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import ConditionalWrap from "conditional-wrap";
import { Link } from "react-router-dom";

const AttachmentTile = ({ title, description, thumbnail, link, compact, active, ...props }) => {
  const classes = cx(
    "attachment-tiles__item",
    "attachment-tile",
    {
      "attachment-tile--active": active,
    },
    { compact: compact },
    { "no-thumbnail": !thumbnail }
  );

  return (
    <ConditionalWrap condition={link} wrap={children => <Link to={link}>{children}</Link>}>
      <div className={classes} {...props}>
        {thumbnail && <div className="attachment-tile__thumbnail">{thumbnail}</div>}
        <div className="attachment-tile__info">
          <p className="attachment-tile__name" title={title}>
            {title}
          </p>
          {description && (
            <p className="attachment-tile__description" title={description}>
              {description}
            </p>
          )}
        </div>
      </div>
    </ConditionalWrap>
  );
};

AttachmentTile.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  thumbnail: PropTypes.node,
  link: PropTypes.string,
  active: PropTypes.bool,
  compact: PropTypes.bool,
};

AttachmentTile.defaultProps = {
  compact: false,
};

export default AttachmentTile;
