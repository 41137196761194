import React, {useContext, useRef} from "react";

import {reportError} from "../../utils/errorReporting";
import {translate} from "../../utils/language";

import {Blankholder} from ".";

import {doNothing, emptyObject} from "../../utils/constants";
import useDialog, {IDialog} from "./Dialog/useDialog";
import {Button} from "./index";
import {ErrorDialog} from "./Dialog/comps";

export type ErrorExtraInfo = {
  title?: string
} 

export type ErrorBoundaryApi = {
  installed: boolean
  reportError(error: any, extra?: ErrorExtraInfo): void
  softReportError(error: any, extra?: ErrorExtraInfo): void | never
  clearError(): void
}

const ErrorBoundaryContext = React.createContext({ installed: false, reportError: doNothing, softReportError: doNothing, clearError: doNothing } as ErrorBoundaryApi);

const DEFAULT_MESSAGE = translate("error.generic") as string;

type Props = {
  fallback?: React.ReactNode | ((message: string) => React.ReactNode)
  resetKey?: any
}

type State = {
  thrownError: string
}

export default class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    thrownError: "",
  };
  
  static getDerivedStateFromError(error: any) {
    // Twarde błędy, to takie które React złapie jako wyjątki podczas renderowania
    // obsługa ich polega na odmontowaniu całego poddrzewa komponentów i zastąpieniu go komunikatem
    //
    if (error.enduserMessage)
      return { thrownError: error.enduserMessage };
    else
      return { thrownError: DEFAULT_MESSAGE };
  }
  
  componentDidUpdate(prevProps: Props) {
    const props = this.props;
    if (prevProps.resetKey !== props.resetKey)
      this.setState({ thrownError: "" });
  }
  
  clearError = () => this.setState({ thrownError: "" });
  
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // TODO: errorInfo.componentStack
    void reportError(error)
  }
  
  render() {
    let content;
    
    if (this.state.thrownError) {
      if (typeof this.props.fallback === "function")
        content = this.props.fallback(this.state.thrownError);
      else if (this.props.fallback)
        content = this.props.fallback;
      else
        content = <Blankholder icon="exclamation-circle" description={this.state.thrownError}/>
    }
    else
      content = this.props.children;

    return <ErrorBoundary.Inner clearError={this.clearError}>
        {content}
    </ErrorBoundary.Inner>
  }
  
  static useApi(): ErrorBoundaryApi {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useContext(ErrorBoundaryContext);
  }
  
  /** Komponent wewnętrznej granicy błędów, który udostępnia API do wyświetlania błędów przez okna dialogowe. */
  static Inner({ clearError, children }: { clearError: () => void, children: any }) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const errorDialog = useDialog(ErrorBoundary._ErrorDialog);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const api = useRef(null as any as ErrorBoundaryApi);
    if (api.current === null) {
      api.current = {
        installed: true,
        reportError(error: any, extra: ErrorExtraInfo = emptyObject) {
          const message = getErrorMessage(error);
          message && errorDialog.openWith({ message, ...extra });
          if (error instanceof Error) {
            void reportError(error);
          }
        },
        softReportError(error: any, extra: ErrorExtraInfo = emptyObject) {
          if (!error || !error._softReported) {
            const message = getErrorMessage(error);
            message && errorDialog.openWith({message, ...extra});
          }
          
          if (error instanceof Error) {
            // @ts-ignore
            error._softReported = true;
            throw error;
          }
        },
        clearError
      }
    }
    return <ErrorBoundaryContext.Provider value={api.current}>
      {children}
    </ErrorBoundaryContext.Provider>
  }
  
  /** Komponent okna dialogowego z błędem dla Inner */
  static _ErrorDialog({ message, title, dialog }: { message: string, title?: string, dialog: IDialog }) {
    const buttons = <>
      <Button preset="close" onClick={dialog.close}/>
    </>;
    
    return <ErrorDialog
      title={title || translate("error.dialogTitle.operation")}
      icon="exclamation triangle"
      buttons={buttons}
      message={message}
      dialog={dialog}
    />;
  }
}

function getErrorMessage(error: any) {
  // Miękkie błędy, to takie które my łapiemy i zgłaszamy sami
  // Powodują wyświetlenie komunikatu jako toast albo inny komponent, który zarejestruje się
  // z chęcia do wyświetlania błędów
  
  // soft=true znaczy, że nie łapiemy i nie logujemy błędu, tylko zapisujemy w stanie
  // i rzucamy ponownie
  
  if (!error)
    return "";
  
  if (typeof error === "string")
    return error;
  
  if (error instanceof Error) {
    const message = (error as any).enduserMessage;
    if (message) {
      return message;
    }
  }
  
  return DEFAULT_MESSAGE;
}
