import React from "react";
import Dialog, { DialogProps } from "../Dialog";
import { Button } from "../..";
import { IDialog } from "../useDialog";

type IProps = {
  onPrimary?: () => void
  title?: string
  description?: string
  type?: DialogProps["type"]
  dialog: IDialog
};

/**
 * Dialog containing information without any call to action.
 */
const InfoDialog = ({ title, description, onPrimary, ...props }: IProps) => {
  return (
    <Dialog title={title} buttons={[<Button key="onPrimary-close-button" preset="close" onClick={onPrimary} />]} {...props}>
      {description}
    </Dialog>
  );
};

export default InfoDialog;
