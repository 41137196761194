import React from 'react';
import cx from 'classnames';
import { ProgressProps, DEFAULT_MAX_VALUE } from './index';

/**
 * Gruby pasek progresu ze wskaźnikiem procentów w środku paska.
 */
export const ProgressThick = (props: ProgressProps) => {
  const classes = cx(props.className, "variant-thick");

  const labelDynamicStyle = {
    width: `${props.value}%`,
    marginLeft: props.value === 0 ? "10px" : "42px"
  };

  return (
    <div className={classes} style={props.style}>
      {!props.hidePercentage &&
        <span style={labelDynamicStyle}>{props.value}%</span>}
      <progress
        value={props.value.toString()}
        max={props.max?.toString() ?? DEFAULT_MAX_VALUE} />
    </div>
  );
};
