import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import StepGroup from "./Group";
import { Icon } from "..";

class Step extends PureComponent {
  /**
   * @description Exclude props which have been already used.
   * @returns {object}
   */
  sortProps() {
    const propTypes = Object.keys(Step.propTypes),
      props = { ...this.props };

    if (props.onClick && props.disabled) delete props.onClick;

    // Delete all props applied in classes etc..
    for (var i = 0; i < propTypes.length; i++) delete props[propTypes[i]];

    return props;
  }

  renderContent = () => {
    const { icon, completed, number } = this.props;

    const addition = !completed
      ? icon
        ? this.renderIcon(icon)
        : this.renderNumber(number)
      : this.renderIcon("check");

    return (
      <div className="content">
        {addition}
        {this.renderInfo()}
      </div>
    );
  };

  renderIcon = icon => {
    if (!icon) return <React.Fragment />;

    return (
      <div className="icon">
        <Icon name={icon} />
      </div>
    );
  };

  renderNumber = number => {
    if (!number) return <React.Fragment />;

    return <div className="number">{number}</div>;
  };

  renderInfo = () => {
    const { title, description } = this.props;

    return (
      <div className="info">
        <h5 className="title">{title}</h5>
        {description ? <p className="description">{description}</p> : null}
      </div>
    );
  };

  render() {
    const { link, active, completed, disabled, fluid } = this.props;

    const classes = cx(
      "sowa",
      "step",
      "item",
      link && "link",
      active && "active",
      completed && "completed",
      disabled && "disabled",
      fluid && "fluid"
    );

    return (
      <div className={classes} {...this.sortProps()}>
        {this.renderContent()}
      </div>
    );
  }
}

Step.Group = StepGroup;

Step.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string,
  link: PropTypes.bool,
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  active: PropTypes.bool,
  completed: PropTypes.bool,
  disabled: PropTypes.bool,
  fluid: PropTypes.bool
};

Step.defaultProps = {
  link: false,
  active: false,
  completed: false,
  disabled: false,
  fluid: false
};

export default Step;
