import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "../Button";
import cx from "classnames";

import Indicator from "./Indicator";

// TODO: Implement the key method.
class Paging extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      totalPages: 0,
      numMethod: props.p8n === "num"
    };
  }

  componentDidMount() {
    const { at, volume, total } = this.props;

    if (typeof at === "number" && typeof total === "number" && volume) {
      this.calculatePages(at, volume, total);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.at !== this.props.at ||
      prevProps.volume !== this.props.volume ||
      prevProps.total !== this.props.total
    ) {
      this.calculatePages();
    }

    if (prevProps.p8n !== this.props.p8n)
      this.setState({ numMethod: this.props.p8n === "num" });
  }

  /**
   * @description Calculates pages and sets the state.
   * @param {number} at
   * @param {number} volume
   * @param {total} total
   */
  calculatePages = (
    at = this.props.at,
    volume = this.props.volume,
    total = this.props.total
  ) => {
    this.setState({
      page: calcPage(at, volume),
      totalPages: Math.ceil(total / volume)
    });
  };

  /**
   * @description Handles switch to first page.
   */
  handleFirst = () => {
    const { onChange } = this.props;

    if (onChange) onChange(this.state.numMethod ? { at: undefined } : { after: undefined, before: undefined });
  };

  /**
   * @description Handles switch to last page.
   */
  handleLast = () => {
    const { onChange, volume } = this.props;
    const { totalPages } = this.state;

    const pages = this.state.numMethod
      ? { at: totalPages * volume - volume }
      : { after: undefined, before: null };

    if (onChange) onChange(pages);
  };

  /**
   * @description Handles switch to next page.
   */
  handleNext = () => {
    const { onChange, next } = this.props;

    if (onChange)
      onChange(this.state.numMethod ? { at: next } : { after: next, before: undefined });
  };

  /**
   * @description Handles switch to previous page.
   */
  handlePrev = () => {
    const { onChange, prev } = this.props;

    if (onChange)
      onChange(this.state.numMethod ? { at: prev || undefined } : { after: undefined, before: prev });
  };

  /**
   * @description Handles switch to a specific page.
   * @param {number} page
   */
  handleChange = page => {
    const { onChange, volume } = this.props;

    const at = this.state.numMethod
      ? { at: (page - 1) * volume || undefined }
      : { after: undefined, before: null }; // ! Reason about !numMethod

    if (onChange) onChange(at);
  };

  /**
   * @description Changes volume
   * @param {number} volume
   */
  changeVolume = volume => {
    const { onVolumeChange } = this.props;

    if (onVolumeChange) onVolumeChange(volume);
  };

  /**
   * @description Returns nummered buttons.
   * @returns {node}
   */
  renderNumbers = () => {
    if (this.props.hidePages) return <React.Fragment />;

    const { page, totalPages } = this.state;

    let numbers = doPaging(page, {
      range: 3,
      pages: totalPages
    });

    numbers = numbers.filter(v => Number(v) > 0);

    return (
      <div className="numbers">
        {numbers.map(e => (
          <Indicator
            number={+e}
            key={""+e}
            compact={this.props.compact}
            active={page === +e}
            loading={this.props.loading}
            onClick={() => {
              if (!isNaN(Number(e))) this.handleChange(e);
            }}
          />
        ))}
      </div>
    );
  };

  /**
   * @description Returns single and double chevrons.
   * @param {string} type [single or double]
   * @param {string} side [left or right]
   * @param {object} props custom button props
   * @returns {node}
   */
  renderChevron = (type = "single", side, props) => {
    if (type === "double" && this.props.hideDoubleArrows)
      return <React.Fragment />;

    props = {
      size: this.props.compact ? "small" : "normal",
      variant: "icon",
      preset: getChevronPreset(type, side),
      ...props
    };

    return <Button {...props} />;
  };

  renderPagination = () => {
    const { prev, next, at, fluid } = this.props;
    if (this.props.hidePagination || (prev === null && next === null))
      return <React.Fragment />;

    const classes = cx("pagination", fluid && "fluid");

    return (
      <div className={classes}>
        <div>
          {this.renderChevron("double", "left", {
            disabled: this.state.numMethod ? at === 0 : !prev,
            onClick: this.handleFirst
          })}
          {this.renderChevron("single", "left", {
            disabled: prev === null,
            onClick: this.handlePrev
          })}
        </div>
        {this.state.numMethod ? this.renderNumbers() : <React.Fragment />}
        <div>
          {this.renderChevron("single", "right", {
            disabled: next === null,
            onClick: this.handleNext
          })}
          {this.renderChevron("double", "right", {
            disabled: this.state.numMethod ? at >= next : !next,
            onClick: this.handleLast
          })}
        </div>
      </div>
    );
  };

  render() {
    const classes = cx("sowa", "paging");

    return (
      <div className={classes} style={this.props.style}>
        {this.renderPagination()}
      </div>
    );
  }
}

/**
 * @description Calculates pages from at and volume values.
 * @param {number} at
 * @param {number} volume
 * @returns {number} page
 */
function calcPage(at, volume) {
  return Math.floor(at / volume) + 1;
}

/**
 * @description Calculates the page nummering range.
 * @param {number} current
 * @param {number} range
 * @param {number} pages
 * @param {number} start
 * @returns {array} range
 */
function doPaging(current, { range = 5, pages, start = 1 }) {
  if (!pages) return [];
  if (range === 1) return [current];

  const pagin = [];
  var i = Math.min(
    pages + start - range,
    Math.max(start, current - ((range / 2) | 0))
  );
  const end = i + range;
  while (i < end) {
    pagin.push(`${i++}`);
  }
  return pagin;
}

export default Paging;

Paging.propTypes = {
  hideDoubleArrows: PropTypes.bool,
  hideSettings: PropTypes.bool,
  hidePages: PropTypes.bool,

  fluid: PropTypes.bool,
  compact: PropTypes.bool,
  loading: PropTypes.bool,

  at: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  p8n: PropTypes.string,
  prev: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  next: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
  volume: PropTypes.number,
  total: PropTypes.number,

  onChange: PropTypes.func.isRequired,
  onVolumeChange: PropTypes.func
};

Paging.defaultProps = {
  hideDoubleArrows: false,
  hideSettings: false,
  hidePages: false,

  fluid: false,
  compact: true,
  loading: false
};

function getChevronPreset(type, side){
  if (type === 'single') {
    if (side === "left") {
      return "paginationPrev"
    } else return "paginationNext"
  } else {
    if (side === "left") {
      return "paginationFirst"
    } else return "paginationLast"
  }
}