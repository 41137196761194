import React, { createElement, useCallback } from "react";
import { useId } from "./hooks";

type AriaLabelParentProps = {
  noTitle?: boolean
  
  /** jeśli chcemy dodać label do dalszego potomka, to podajemy selektor by go znaleźć */
  selector?: string
  
  /** jeśli chcemy wyrenderować widoczny element, podajemy jego taga */
  as?: string
  
  className?: string
  
  /** tytuł tego elementu (przydatne jeśli używamy `as`) */
  title?: string
  
  children?: React.ReactNode
}

/** Komponent wyrenderuje potomków ukrytych i ustawi rodzicowi atrybuty `aria-labelledby` i `title`,
 *  Chyba że podamy `as`, wtedy wyrenderuje element `as` bez ukrywania go. */
export function AriaLabelParent({ noTitle, selector, as, className, children, title }: AriaLabelParentProps) {
  const id = useId();

  if (!as) {
    as = "span";
    className = className ? `${className} invisible-label` : "invisible-label";
  }
  
  return createElement(as, { id, className, children, title, ref: onRender, "data-title": +!noTitle });
  
  // TODO: dla higieny warto pewnie użyć useLayoutEffect, bo pozwoli nam posprzątać m.in. aria-labelledby
  //       gdyby parent/selector się zmienił
  
  function onRender(el: null | HTMLSpanElement) {
    if (el) {
      let parent = el.parentElement;
      if (selector && parent) {
        parent = parent.closest(selector);
      }
      
      if (parent) {
        // moglibyśmy ustawiać aria-label na innerText, ale innerText jest potencjalnie kosztowne
        // i nie chcemy odpalać tego dla każdego komponentu podczas renderowania,
        // bo takich komponentów, w stylu przycisków ikonowych, może być wiele na stronie
        parent.setAttribute("aria-labelledby", el.id);
        if (el.getAttribute("data-title") === "1") {
          if (el.title)
            parent.title = el.title;
          else {
            parent.removeEventListener("mouseenter", onHover);
            parent.addEventListener("mouseenter", onHover, { once: true });
          }
        }
      }
    }
  }
}

function onHover(ev: MouseEvent) {
  const target = ev.target as HTMLElement;
  const labelId = target.getAttribute("aria-labelledby");
  const label = labelId && document.getElementById(labelId);
  if (!label) return;
  target.title = label.innerText;
}
