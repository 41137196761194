import React, { Component } from "react";
import PropTypes from "prop-types";

/**
 * Component that alerts if you click outside of it
 */
class OutsideClickDetector extends Component {
  constructor(props) {
    super(props);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (!this.props.listening) return;

    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.onOutsideClick(event);
    }
  }

  /**
   * @description Exclude props which have been already used.
   * @returns {object}
   */
  sortProps() {
    const propTypes = Object.keys(OutsideClickDetector.propTypes),
      props = { ...this.props };

    // Delete all props applied in classes etc..
    for (var i = 0; i < propTypes.length; i++) delete props[propTypes[i]];

    return props;
  }

  render() {
    return (
      <div {...this.sortProps()} ref={this.setWrapperRef}>
        {this.props.children}
      </div>
    );
  }
}

OutsideClickDetector.propTypes = {
  children: PropTypes.element.isRequired,
  onOutsideClick: PropTypes.func.isRequired,
  listening: PropTypes.bool
};

OutsideClickDetector.defaultProps = {
  listening: true
};

export default OutsideClickDetector;
