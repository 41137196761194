import {useRef} from "react";

import {scheduleReactUpdate} from "./batch";

import {RefSetter} from "./util";
import {doNothing} from "../constants";

/**
 * Hook monitorujący poziom widoczności danego elementu na stronie.
 * @param active   czy monitoring jest włączony?
 * @param callback callback wywoływany gdy poziom widoczności się zmieni
 * @return         funkcja do przekazania jako ref do JSX by złapać monitorowany element
 */
export function useIntersectionObserver(active: boolean, callback: (intersection: IntersectionObserverEntry) => any): RefSetter {
  const ref = useRef(null as any as IOState);
  
  if (WINDOW_OBSERVER) {
    if (ref.current === null) {
      const state = ref.current = {
        active,
        element: null,
        callback,
        onRef: (ref: Element | null) => {
          if (state.element === ref)
            return;
          
          if (state.element !== null) {
            WINDOW_OBSERVER.unobserve(state.element);
            WINDOW_OBSERVER_CLIENTS.delete(state.element);
          }
          
          state.element = ref;
        
          if (ref !== null && state.active) {
            WINDOW_OBSERVER_CLIENTS.set(ref, state);
            WINDOW_OBSERVER.observe(ref);
          }
        },
        onMount: () => () => {
          if (state.element !== null) {
            WINDOW_OBSERVER.unobserve(state.element);
            WINDOW_OBSERVER_CLIENTS.delete(state.element);
          }
          
          state.element = null;
        },
      } as IOState;
    }
    else {
      const state = ref.current;
  
      state.callback = callback;
  
      if (active && !state.active) {
        state.active = active;
        if (state.element) {
          WINDOW_OBSERVER_CLIENTS.set(state.element, state);
          WINDOW_OBSERVER.observe(state.element);
        }
      }
      else if (!active && state.active) {
        state.active = active;
        if (state.element) {
          WINDOW_OBSERVER.unobserve(state.element);
          WINDOW_OBSERVER_CLIENTS.delete(state.element);
        }
      }
    }
  
    return ref.current.onRef;
  }
  else {
    return doNothing;
  }
}

type IOState = {
  active: boolean
  element: Element | null
  callback: (intersection: IntersectionObserverEntry) => any
  onMount: () => () => void
  onRef: RefSetter
}

const WINDOW_OBSERVER = window.IntersectionObserver ? new IntersectionObserver(onWindowIntersection, { threshold: [0, 0.1, 0.2, 0.5, 0.8, 0.9, 1] }) : null;

const WINDOW_OBSERVER_CLIENTS = new Map<Element, IOState>();

function onWindowIntersection(intersections: IntersectionObserverEntry[]) {
  for (let i = 0; i < intersections.length; i++) {
    const e = intersections[i];
    const state = WINDOW_OBSERVER_CLIENTS.get(e.target);
    if (state && state.element === e.target) {
      scheduleReactUpdate(state.callback, e);
    }
  }
}
