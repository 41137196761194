import React, { Component } from "react";
import PropTypes from "prop-types";

class Card extends Component {
  handleClasses = () => {
    let { fluid, elevation, hoverable, link, href } = this.props;
    const className = this.props.className
      ? this.props.className.split(" ")
      : [];
    let classes = ["card__container", ...className];

    if (fluid) classes.push("fluid");
    if (link || href) classes.push("link");
    if (hoverable || link) {
      classes.push("hoverable");
      elevation = 1;
    }
    if (elevation > 0) classes.push(`z-depth-${elevation}`);

    return classes.join(" ");
  };

  normalizeProps = () => {
    const props = { ...this.props };

    delete props.fluid;
    delete props.elevation;
    delete props.hoverable;
    delete props.href;
    delete props.link;
    delete props.className;

    return props;
  };

  render() {
    const content = this.props.href ? (
      <a href={this.props.href}>{this.props.children}</a>
    ) : (
      this.props.children
    );

    return (
      <div {...this.normalizeProps()} className={this.handleClasses()}>
        {content}
      </div>
    );
  }
}

Card.propTypes = {
  fluid: PropTypes.bool,
  elevation: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  hoverable: PropTypes.bool,
  href: PropTypes.string,
  link: PropTypes.bool
};

Card.defaultProps = {
  fluid: false,
  elevation: 1,
  hoverable: false,
  link: false
};

class CardContent extends Component {
  handleClasses = () => {
    const { extra } = this.props;
    const className = this.props.className
      ? this.props.className.split(" ")
      : [];
    let classes = ["card__content", ...className];

    if (extra) classes.push("extra");

    return classes.join(" ");
  };

  normalizeProps = () => {
    const props = { ...this.props };

    delete props.extra;
    delete props.className;

    return props;
  };

  render() {
    return (
      <div {...this.normalizeProps()} className={this.handleClasses()}>
        {this.props.children}
      </div>
    );
  }
}

CardContent.propTypes = {
  extra: PropTypes.bool
};

CardContent.defaultProps = {
  extra: false
};

class CardHeader extends Component {
  handleClasses = () => {
    const className = this.props.className
      ? this.props.className.split(" ")
      : [];
    let classes = ["card__content--header", ...className];

    const { divider } = this.props;

    if (divider) classes.push("divide--header");

    return classes.join(" ");
  };

  render() {
    return (
      <div {...this.props} className={this.handleClasses()}>
        {this.props.children}
      </div>
    );
  }
}

CardHeader.propTypes = {
  divider: PropTypes.bool
};

CardHeader.defaultProps = {
  divider: false
};

class CardDescription extends Component {
  render() {
    return <p {...this.props}>{this.props.children}</p>;
  }
}

class CardMeta extends Component {
  render() {
    return (
      <p {...this.props} className="card__content--meta">
        {this.props.children}
      </p>
    );
  }
}

/** Exports */
Card.Content = CardContent;
Card.Header = CardHeader;
Card.Description = CardDescription;
Card.Meta = CardMeta;

export default Card;
