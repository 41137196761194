import React from "react";
import { FormDialog } from "../../shared/Dialog/comps";
import { tran, useLanguage } from "../../../utils/language";
import { Form } from "utils/forms";
import { IDialog } from "../../shared/Dialog/useDialog";
import { CapiClient, CapiCommand } from "../../../capi/client";
import { Dictionary } from "../../../utils/types";
import { FormState } from "../../../utils/forms/formState";
import { EMAIL_PATTERN } from "../../../utils/validate";
import { useBind } from "../../../utils/hooks";

// TODO: i to duże - logowanie na istniejące konto z tego miejsca, wymaga wsparcia serwera, który musi połączyć dwa konta,
//       uwierzytelniając oba i kopiując dane osobowe z konta samozapisu do pełnoprawnego konta z hasłem;
//       co będzie skomplikowane od strony klienta, jeśli chcemy re-użyć istniejącego formularza logowania,
//       który sprawdza ważność hasła itp.
//       Hmm... może nie będzie takie trudne jako dwa kroki... logowanie hasłem, a potem wywołanie komendy asymilacji
//       nowego konta przez stare.
//       W każdym razie w tym miejscu powinien być przycisk oferujący logowanie na istniejące konto jeśli client.auth != 100

// ten komponent jest "stroną" w CompleteSignupDialog

export function EmailSetDialogPage({ client, onSubmit, setUnwantedEmail, dialog }: { client: CapiClient, onSubmit?: () => void, setUnwantedEmail?: React.Dispatch<React.SetStateAction<string>>, dialog: IDialog }) {
  // jeśli jakiś email był i dostajemy tego propsa, to anulujemy chęć zmiany maila
  // w przeciwnym wypadku zamykamy dialog
  const onCancel = setUnwantedEmail
    ? () => setUnwantedEmail("")
    : undefined;
  
  const submit = useBind(submitEmail)
    .then(onSubmit);
  
  const lang = useLanguage();
  
  return <FormDialog title={tran("signup.header.email")} name="reg-email-form" size="small" onSubmit={submit} submitData={client} onCancel={onCancel} dialog={dialog}>
    <div className="mb mt ml mr">
      <Form.PatternInput
        name="email"
        pattern={EMAIL_PATTERN}
        aria-label={lang.translate("signup.fld.email")}
        required
        invalidmessage={tran("error.email.invalid")}
        fluid
      />
    </div>
  </FormDialog>;
}

export class AddressRegister extends CapiCommand {
  constructor(address: string) {
    super(["folksAddressRegister", ["umail", address], { client: "web" }]);
  }
}

async function submitEmail(this: FormState, values: Dictionary<unknown>, client: CapiClient) {
  const [command] = await client.execute(new AddressRegister(values.email as string));
  command.ensure(200, 204, "duplicate_email");
  const result = command.result!;
  if (result.reason === "duplicate_email") {
    this.fields.get("email")?.setError(result.message);
  }
  // nie zawracamy true, żeby dialog się nie zamknął, CompleteSignupDialog wyświetli coś innego w ostateczności
}
