import React from "react";
import { ColumnView } from "components/shared";
import { tran } from "utils/language";
import moment from "moment";
import { DialogPresets } from "components/shared/Dialog";
import { DateTime } from "../../shared/dateUtils";

export function AppInfoDialog(props) {
  const { WS_VER: version, WS_BLD: release } = process.env;
  const when = moment(release, "YYYYMMDD.HHmm");

  return (
    <DialogPresets.BrandedDialog {...props}>
      <h3 style={{ textAlign: "left" }}>{tran("footer.appInfo.about")}</h3>
      <br />
      <div style={{ textAlign: "left", width: "100%" }}>
        <ColumnView>
          <ColumnView.Item name={tran("footer.appInfo.version")} value={version} />
          <ColumnView.Item
            name={tran("footer.appInfo.releaseDate")}
            value={<DateTime at={when}/>}
          />
        </ColumnView>
      </div>
    </DialogPresets.BrandedDialog>
  );
}
