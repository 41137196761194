import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Button from "./Button";

class Group extends Component {
  /**
   * @description Exclude props which have been already used.
   * @returns {object}
   */
  sortProps() {
    const propTypes = Object.keys(Group.propTypes),
      props = { ...this.props };

    // Delete all props applied in classes etc..
    for (var i = 0; i < propTypes.length; i++) delete props[propTypes[i]];

    return props;
  }

  /**
   * @description Renders buttons from shorthand.
   * @prop {array} buttons
   * @returns {node}
   */
  renderButtons = buttons => {
    if (!buttons || buttons.length <= 0) return <React.Fragment />;

    return buttons.map((e, i) => {
      return <Button key={""+i} {...e} />;
    });
  };

  render() {
    const { align, buttons, children, className } = this.props;

    const classes = cx("sowa", "button-group", align, className);
    const content = children || this.renderButtons(buttons);

    return (
      <div {...this.sortProps()} className={classes}>
        {content}
      </div>
    );
  }
}

export default Group;

Group.propTypes = {
  align: PropTypes.oneOf(["left", "center", "right"]),
  buttons: PropTypes.arrayOf(PropTypes.object)
};

Group.defaultProps = {
  align: "left",
  className: ""
};
