import {
  UPDATE_SHORTCUTS,
  SHORTCUTS_SUCCESS,
  UPDATE_SETTINGS,
  FETCH_SETTINGS,
  SETTINGS_SUCCESS,
  FETCH_DICTIONARIES,
  DICTIONARIES_SUCCESS,
  DICTIONARIES_ERROR
} from "../sagas/types";
import isEqual from "lodash.isequal";

import {
  put,
  takeLatest,
  select,
  all,
  takeEvery,
  call
} from "redux-saga/effects";
import { waitFor } from "./utils";

import * as FolksApi from "../api/folks";

import { translate } from "utils/language";

export default function* pageWatcher() {
  yield all([
    yield takeLatest(UPDATE_SHORTCUTS, updateShortcuts),
    yield takeLatest(FETCH_SETTINGS, fetchSettings),
    yield takeEvery(UPDATE_SETTINGS, updateSettings),
    yield takeLatest(FETCH_DICTIONARIES, fetchDictonaries)
  ]);
}

function* updateShortcuts(action) {
  try {
    const shortcuts = [...action.payload];
    const isUserLoggedIn = yield select(state => state.session.isUserLoggedIn);
    if (isUserLoggedIn) {
      shortcuts.push({
        key: "ctrl + shift + l",
        description: translate("unique.logout")
      });
    } else {
      shortcuts.push({
        key: "ctrl + shift + l",
        description: translate("unique.login")
      });
    }

    yield put({
      type: SHORTCUTS_SUCCESS,
      payload: shortcuts
    });
  } catch (error) {
    console.log(error);
  }
}

/** Aktualizacja ustawień użytkownika */
function* updateSettings({ payload }) {
  try {
    const settings = yield select(state => state.utils.appSettings);
    const newSettings = {
      ...settings,
      ...payload
    };
    yield put({
      type: SETTINGS_SUCCESS,
      payload: newSettings
    });

    if (!isEqual(settings, newSettings)) {
      const isUserLoggedIn = yield select(
        state => state.session.isUserLoggedIn
      );
      if (isUserLoggedIn) {
        // sprawdzamy czy użytkownik jest jeszcze zalogowany, bo ustawienia często są zapisywane przez metodę componentWillUnmount
        yield call(FolksApi.saveSettingsToStorage, JSON.stringify(newSettings));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

/** Pobranie ustawień użytkownika */
function* fetchSettings() {
  try {
    const result = yield call(FolksApi.getUserSettings);
    if (result.status === 200) {
      const settings = JSON.parse(result.data || '{}');
      if (sessionStorage.getItem("isContrastMode") === "true") {
        settings.contrast = true;
        yield call(FolksApi.saveSettingsToStorage, JSON.stringify(settings));
        sessionStorage.removeItem("isContrastMode");
      }
      yield put({
        type: SETTINGS_SUCCESS,
        payload: settings
      });
    }
  } catch (err) {
    console.log(err);
  }
}

/**
 * Pobranie słowników używanych w komendach API folks
 */
function* fetchDictonaries() {
  try {
    // Wstrzymanie funkcji, aż w storze pojawi się session_id
    yield call(
      waitFor,
      state => state.session.session_id,
      session_id => Boolean(session_id)
    );

    const result = yield FolksApi.enumDictionary("all");
    if (result.status === 200) {
      yield put({
        type: DICTIONARIES_SUCCESS,
        payload: result.data
      });
    } else {
      yield put({
        type: DICTIONARIES_ERROR,
        payload: result.status
      });
    }
  } catch (error) {
    yield put({
      type: DICTIONARIES_ERROR,
      payload: 500
    });
  }
}
