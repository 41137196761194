import React, {useContext, useEffect, useRef, useState, Dispatch, SetStateAction} from "react";

import { doNothing, emptyArray, emptySet, identity, increment } from "../constants";

import {FormContext} from "./context";
import {findForm, FormState, FormStatePriv, useFormState} from "./formState";
import {callElementWithIncrement} from "./util";

import isEqual from 'lodash.isequal';
import {
  finishImmediateBatchUpdates,
  scheduleImmediateReactUpdate, scheduleReactUpdate,
  startImmediateBatchUpdates
} from "../hooks/batch";
import { useId, useVersionedSignal } from "../hooks/other";

const freeze = Object.freeze;

export interface FormField<T = string> {
  parent: FormField<any>
  
  name: string
  form: string
  formState: FormState | undefined
  
  value: T
  initial: T
  readonly error: React.ReactNode
  initialError: React.ReactNode
  
  isValid: boolean
  isDirty: boolean
  
  reset(): void
  save(): void
  wake(): void
  wakeAsync(): void
  
  set(value: T, error?: React.ReactNode): void
  setError(error: React.ReactNode): void
  setter: (value: T, error?: React.ReactNode) => void
  
  readonly extra: any
}

export interface FormFieldPriv<T = string> extends FormField<T> {
  _error: React.ReactNode
  
  _signals: Set<Dispatch<SetStateAction<number>>>
  
  _setter: (value: T, error?: string) => void
  
  _self: FormFieldPriv<T>
  
  _version: number
  
  _onMount: () => () => void
  
  _recLock: boolean
  
  _extra: any
  
  setQuietly(value: T, error?: React.ReactNode): void
}

function compare(a: any, b: any): boolean {
  if(Array.isArray(a) && Array.isArray(b)) {
    return isEqual(a, b);
  }
  
  return a === b;
}

const FormFieldProto = freeze({
  parent: null as any,
  name: "",
  form: "",
  formState: undefined,
  value: undefined as any,
  initial: undefined as any,
  initialError: undefined,
  
  _error: "",
  _signals: emptySet,
  _setter: doNothing,
  _version: -1,
  _recLock: false,
  _extra: undefined,
  
  get error(): React.ReactNode {
    return this._error || (this.parent && this.parent.error) || ""; 
  },
  
  get isValid(): boolean {
    return !this.error;
  },
  
  get isDirty(): boolean {
    return !compare(this.value, this.initial);
  },
  
  reset() {
    const self = this._self; // potrzebne przez wrapper
    
    self.value = self.initial;
    self._error = self.initialError;
    
    this.wakeAsync();
  },
  
  save() {
    const self = this._self; // potrzebne przez wrapper
    
    self.initial = self.value;
    self.initialError = self._error;
    
    this.wakeAsync();
  },
  
  wake() {
    const self = this._self;
    
    if (self._recLock)
      return;
  
    startImmediateBatchUpdates();
    try {
      self._recLock = true;
      this._signals.forEach(callElementWithIncrement);
    }
    finally {
      self._recLock = false;
      finishImmediateBatchUpdates();
    }
  },

  wakeAsync() {
    this._signals.forEach(signal => scheduleReactUpdate(signal, increment));
  },

  toString() {
    return `FormField(name=${this.name}, value=${JSON.stringify(this.value)})`;
  },
  
  set(value, error) {
    const self = this._self; // potrzebne przez wrapper
    
    const isValid = self.isValid;
    const isDirty = self.isDirty;

    if (!error && compare(value, self.initial))
      error = self.initialError;
    else
      error = error || "";
    
    const wake = !compare(self.value, value) || self.error !== error;
    
    self.value = value;
    self._error = error;
    
    if (wake)
      self.wake();
    
    if (self.formState && (isValid !== self.isValid || isDirty !== self.isDirty)) {
      // trzeba poinformować formularz o zmianie stanu gotowości
      (self.formState as FormStatePriv).wake();
    }
  },
  
  setQuietly(value, error) {
    const self = this._self; // potrzebne przez wrapper
    
    const isValid = self.isValid;
    const isDirty = self.isDirty;

    if (!error && compare(value, self.initial))
      error = self.initialError;
    else
      error = error || "";
    
    self.value = value;
    self._error = error;
    
    if (self.formState && (isValid !== self.isValid || isDirty !== self.isDirty)) {
      // trzeba poinformować formularz o zmianie stanu gotowości
      (self.formState as FormStatePriv).wakeAsync();
    }
  },
  
  setError(error) {
    const self = this._self; // potrzebne przez wrapper
    
    const isValid = self.isValid;
    
    error = error || "";
    const wake = self.error !== error;
    
    self._error = error;
    
    if (wake)
      self.wake();
    
    if (self.formState && isValid !== self.isValid) {
      // trzeba poinformować formularz o zmianie stanu gotowości
      (self.formState as FormStatePriv).wake();
    }
  },
  
  get setter() {
    const self = this._self;
    let setter = self._setter;
    if (!setter) {
      setter = self._setter = self.set.bind(self);
    }
    return setter;
  },
  
  get extra() {
    return this._self._extra;
  },
  
  // setExtra(value: any) {
  //   this._self._extra = value;
  // },
  
  /** Ponieważ używamy łańcucha prototypów, gdzie pierwszy i ostatni będzie niezmienny, to śledzimy ten obiekt, który zmieniamy */
  get _self() {
    return this;
  },
  
  _onMount: () => () => {},
} as FormFieldPriv);

export function useNewField<T>(name: string, form: string | null | undefined, initial: T, initialError?: React.ReactNode, extra?: any): FormField<T> {
  const context = useContext(FormContext);
  const ref = useRef(null as any as FormFieldPriv<T>);
  const [version, signal] = useState(0);

  let formState: FormStatePriv | undefined;
  if (form === null) {
    // wolny input bez formularza
  }
  else {
    formState = findForm(context, form) as FormStatePriv;
  }

  if (typeof name !== "string") {
    throw new Error("useNewField() called with name: " + typeof name);
  }

  if (ref.current === null) {
    const fieldState = Object.seal({
      __proto__: FormFieldProto,
      parent: null,
      name,
      form,
      formState,
      initial,
      value: initial,
      _error: initialError || FormFieldProto.error,
      initialError: initialError || "",
      
      _signals: new Set([(x: any) => scheduleImmediateReactUpdate(signal, x)]),
      _setter: null,
      _recLock: false,
      _extra: extra,
      _onMount: FormFieldProto._onMount,
    } as any as FormFieldPriv<T>);
    
    if (formState) {
      const fs = formState;
      fieldState._onMount = function FormField_onMount() {
        if (fs.fields.get(name))
          throw new Error(`Duplicate form field: ${fs.name}.${name}`);
        
        fs.fields.set(name, fieldState);
        fs.wake(); // budzimy formularz z uwagi na dodanie pola
        
        return function FormField_unmount() {
          fieldState._signals.clear();
          fs.fields.delete(name);
          fs.wake(); // budzimy formularz z uwagi na usunięcie pola
        };
      };
    }
    else {
      fieldState._onMount = function FormField_onMount_free() {
        return function FormField_unmount_free() {
          // nie chcemy warningów, że budzimy odmontowany komponent
          fieldState._signals.clear();
        };
      };
    }
    
    // używamy niezmiennego wrappera, który tworzymy na nowo przy każdej zmianie
    // żeby zapobiec błędom gdzie jako zależności używamy `field` zamiast `field.value` czy `field.error`
    ref.current = freeze({ __proto__: fieldState, _self: fieldState, _version: version } as any as FormFieldPriv<T>);
  }
  else {
    const cur = ref.current;
    const self = cur._self;
    self._extra = extra;
    if (cur._version !== version) {
      // tworzymy nowy wrapper po zmianach
      ref.current = freeze({ __proto__: self, _self: self, _version: version } as any as FormFieldPriv<T>);
    }
  }
  
  const fieldState = ref.current;
  
  useEffect(fieldState._onMount, emptyArray);
  
  if (initial !== fieldState.initial || (initialError || "") !== fieldState.initialError) {
    // FIXME: chcemy to pewnie zaimplementować bez re-renderowania
    const oldInitial = fieldState.initial;
    fieldState._self.initial = initial;
    fieldState._self.initialError = initialError || "";
    if (fieldState.value === oldInitial)
      fieldState.reset();
    if (formState)
      formState.wakeAsync();
  }

  return fieldState;
}

// te overloady nam niestety średnio pomagają, bo propsy z typem sumowym nie dopasowują się do nich
//export function useField<T = string>(field: FormField<T> | undefined, name: string, form: string | null | undefined, initial: T): FormField<T>
// @ts-ignore
//export function useField<T = string>(field: FormField<T>, name: string | undefined, form: string | null | undefined, initial?: T): FormField<T>
export function useField<T = string>(field: FormField<T> | undefined, name: string, form: string | null | undefined, initial?: T, initialError?: React.ReactNode, extra?: any): FormField<T> {
  const mode = useRef(0);

  // świadomie obchodzimy reguły hooków, więc sprawdzamy czy nasze założenie
  // nie jest gwałcone, pole może mieć podane `field` albo nie, ale dynamicznie
  // nie może się ten props zmieniać

  if (!field) {
    if (mode.current >= 0)
      mode.current = 1;
    else
      throw new Error("Hook rule violation, field property cannot change.");
    
    if (arguments.length < 4)
      throw new Error("useField requires either `field` or `initial` to be given.");
    
    /* eslint-disable react-hooks/rules-of-hooks */
    // @ts-ignore
    field = useNewField<T>(name, form, initial, initialError, extra);
    /* eslint-enable react-hooks/rules-of-hooks */
  }
  else {
    if (mode.current <= 0)
      mode.current = -1;
    else
      throw new Error("Hook rule violation, field property cannot change.");
  }
  
  return field as any;
}

export function useFieldMonitor<T>(name?: string, form?: string): FormField<T> | null {
  const [, signal] = useState(0);
  const [fieldState, setFieldState] = useState(null as FormField<any> | null);
  const formState = useFormState(form);
  const ref = useRef(null as any as () => () => void);
  
  if (ref.current === null) {
    ref.current = function FieldValue_onMount() {
      const fieldState = name && formState.fields.get(name) as FormFieldPriv<any> | undefined;
      
      if (fieldState) {
        const scheduledSignal = (x: any) => scheduleImmediateReactUpdate(signal, x);
        fieldState._signals.add(scheduledSignal); // rejestrujemy sygnał by budzić się gdy zmienia się wartość pola
        setFieldState(fieldState);
        return function FieldValue_unmount() {
          fieldState._signals.delete(scheduledSignal);
          setFieldState(null);
        };
      }
      
      setFieldState(null);
      return doNothing;
    };
  }
  
  useEffect(ref.current, [name && formState.fields.get(name)]);
  
  return fieldState;
}
