import React from "react";
import { useDispatch } from "react-redux";
import { tran } from "utils/language";
import { LOGOUT_SUCCESS } from "../../sagas/types";
import { removePassportInfo, removeSessionInfo } from "../../utils/localStorageSession";
import { Blankholder, Button } from "components/shared";
import { useDialog } from "components/shared/Dialog";
import { LoginDialog } from "components/special-pages/Login/LoginDialog";

export function SessionExpired() {
  const loginDialog = useDialog(LoginDialog);

  const dispatch = useDispatch();
  
  function continueAnon() {
    removeSessionInfo();
    removePassportInfo();
    dispatch({ type: LOGOUT_SUCCESS });
  }

  return (
    <Blankholder
      title={tran("sessionExpired.a")}
      description={tran("sessionExpired.b")}
      button={<>
        <Button
          icon="sign-in-alt"
          onClick={() => loginDialog.open()}
        >
          {tran("unique.loginAgain")}
        </Button>

        <Button
          icon="angle-right"
          onClick={continueAnon}
        >
          {tran("unique.continueAnon")}
        </Button>
      </>}
      icon="clock"
    />
  );
}