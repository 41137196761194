import {emptyArray, emptyObject} from "../utils/constants";
import {CapiCache} from "./hooks";
import {EnumDictionary} from "./folks";

const freeze = Object.freeze || (v => v);

const placeholderEnumValue = freeze({ key: "", desc: "<brak tłumaczenia>", order: -1 });
const placeholderEnumValueProxy = obj => freeze(new Proxy(obj, {
  get: function(target, name) {
    return target[name] || placeholderEnumValue;
  }
}));
const placeholderEnumValueProxyEmpty = placeholderEnumValueProxy(emptyObject)

const placeholderEnum = freeze({ byKey: placeholderEnumValueProxyEmpty, byDesc: placeholderEnumValueProxyEmpty, ordered: emptyArray, });
const placeholderEnumProxy = obj => freeze(new Proxy(obj, {
  get: function(target, name) {
    return target[name] || placeholderEnum;
  }
}));

const placeholder = freeze({ enums: placeholderEnumProxy(emptyObject) });

export class EnumDictionaryCache extends CapiCache {
  constructor(client) {
    super(client, 2)
  }
  
  get(id) {
    return super.get("");
  }
  
  use(id) {
    return super.use("");
  }
  
  makeCommand = id => new EnumDictionary();
  
  objectIsLoading = id => placeholder;
  
  objectFromError = (id, err) => placeholder;
  
  objectFromResult(id, result) {
    switch (result.status) {
      case 200:
        const obj = {};
        for (let k in result.data) {
          const values = result.data[k];
          const byKey = {};
          const byDesc = {};
          
          for (let i = 0; i < values.length; i++) {
            const x = values[i];
            x.order = i;
            byKey[x.key] = x;
            byDesc[x.desc] = x;
          }
          
          obj[k] = {
            byKey: placeholderEnumValueProxy(byKey),
            byDesc: placeholderEnumValueProxy(byDesc),
            ordered: values
          }
        }
        return { enums: placeholderEnumProxy(obj) };
      
      default:
        return this.objectFromError(id);
    }
  }
}
