import React, { Component } from "react";
import cx from "classnames";

import PropTypes from "prop-types";

class Textarea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      filled: false
    };

    this.textInput = React.createRef();
  }

  componentDidMount() {
    this.checkIfFilled();

    // In case of incompatibility...
    setTimeout(() => (this.textInput.current && this.props.autoFocus) && this.textInput.current.focus(), 1)
  }

  handleChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e.target.value);
    }
  }
  checkIfFilled() {
    let filled =
      (this.props.placeholder && this.props.placeholder.length > 0) ||
      (this.props.value && this.props.value.length > 0);
    this.setState({ filled });
  }

  handleBlur(e) {
    this.setState({ active: false });
    this.checkIfFilled();
    if (this.props.onBlur) {
      this.props.onBlur(e.target.value);
    }
  }

  handleFocus(e) {
    this.setState({ active: true });
    if(this.props.selectOnFocus && e.target.select) 
      e.target.select();
    
    if (this.props.onFocus) {
      this.props.onFocus(e.target.value);
    }
  }

  render() {
    const {
      className,
      resize,
      error,
      fluid,
      label,
      disabled,
      autoFocus,
      placeholder,
      value,
      wrapperStyle,
      style,
      minHeight,
      selectOnFocus: _selectOnFocus,
      ...rest
    } = this.props;

    const resizeClass =
      resize[0] && resize[1]
        ? "resize-both"
        : resize[0]
        ? "resize-x"
        : resize[1]
        ? "resize-y"
        : "";

    const hasError = !!error;

    const errorMsg =
      hasError && error !== true ? (
        <p className="custom-input__error-msg">{error}</p>
      ) : null;

    const _label =
      label.length > 0 ? (
        <label className="custom-input__label">{label}</label>
      ) : null;

    const wrapperClasses = cx(
      "custom-input",
      "custom-textarea",
      { fluid: fluid },
      { error: hasError },
      { labeled: label && label.length > 0 },
      { disabled: disabled },
      { filled: this.state.filled },
      { active: this.state.active }
    );
    
    const mh = typeof minHeight === "number"
      ? `${minHeight}px`
      : minHeight;
    
    return (
      <div style={wrapperStyle} className={wrapperClasses}>
        {_label}
        <textarea
          {...rest}
          style={{ minHeight: mh, ...style }}
          autoFocus={autoFocus}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          className={`custom-textarea__textarea  ${resizeClass} ${className ||
            ""} `}
          onChange={e => this.handleChange(e)}
          onBlur={e => this.handleBlur(e)}
          onFocus={e => this.handleFocus(e)}
          ref={this.textInput}
        />
        {errorMsg}
      </div>
    );
  }
}

Textarea.defaultProps = {
  resize: [false, false],
  error: false,
  placeholder: "",
  label: "",
  value: "",
  wrapperStyle: {},
  minHeight: 40,
  style: {},
  autoFocus: false,
  selectOnFocus: false
};

Textarea.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.element]),
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  fluid: PropTypes.bool,
  resize: PropTypes.arrayOf(PropTypes.bool), // [x,y]
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  wrapperStyle: PropTypes.object, // style do wrappera
  autoFocus: PropTypes.bool, // ustawienie focusa na starcie
  selectOnFocus: PropTypes.bool // zaznacza cały tekst przy fokusie
};

export default Textarea;
