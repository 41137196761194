import {
  LOGOUT_SUCCESS,
  CURRENT_USER_ERROR,
  CURRENT_USER_SUCCESS,
  CURRENT_USER_LOADING,
  CURRENT_USER_TEKA_SUCCESS,
  CURRENT_USER_TEKA_ERROR,
  CURRENT_USER_SOWA_ERROR,
  CURRENT_USER_SOWA_SUCCESS, CLEAR_USER
} from "../sagas/types";

const initialState = {
  folks: {},
  sowa: {},
  teka: {},
  error: null,
  loading: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGOUT_SUCCESS:
    case CLEAR_USER: {
      return {
        ...state,
        folks: {},
        sowa: {},
        teka: {}
      };
    }

    case CURRENT_USER_SUCCESS: {
      return {
        ...state,
        folks: {
          ...action.payload
        },
        error: null
      };
    }

    case CURRENT_USER_ERROR: {
      return {
        ...state,
        folks: {},
        error: action.payload
      };
    }

    case CURRENT_USER_LOADING: {
      return {
        ...state,
        loading: action.hasOwnProperty("payload") ? action.payload : true
      };
    }

    case CURRENT_USER_TEKA_SUCCESS: {
      return {
        ...state,
        teka: action.payload
      };
    }

    case CURRENT_USER_TEKA_ERROR: {
      return {
        ...state,
        teka: { error: action.payload }
      };
    }

    case CURRENT_USER_SOWA_SUCCESS: {
      return {
        ...state,
        sowa: {
          ...state.sowa,
          [action.payload.cat_id]: action.payload.data
        }
      };
    }

    case CURRENT_USER_SOWA_ERROR: {
      return {
        ...state,
        sowa: {
          ...state.sowa,
          [action.payload.cat_id]: { error: action.payload.error }
        }
      };
    }

    default: {
      return state;
    }
  }
}
