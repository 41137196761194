import React from "react";

const Heading = props => {
  const { children, className, ...rest } = props;

  return (
    <h3 className={`column-view__heading ${className}`} {...rest}>
      {children}
    </h3>
  );
};

Heading.defaultProps = {
  className: ""
};

export default Heading;
