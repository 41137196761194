import {useEffect} from 'react';
import {useSignal} from "./other";
import {scheduleReactUpdate} from "./batch";
import {emptyArray} from "../constants";

// Na postawie oryginalnej implementacji Sandry
// ale nasza tworzy jednego listenera na wyrażenie

// FIXME: część naszego kodu używa 599/600 a część 600/601

export const SMALL_BREAKPOINT = 600;
export const MEDIUM_BREAKPOINT = 992;

const smallScreenQuery = `only screen and (max-width: ${SMALL_BREAKPOINT}px)`;
export const useSmallScreen = () => useMediaQuery(smallScreenQuery);

// ...część 992/993, część 900

const mediumScreenQuery = `only screen and (max-width: ${MEDIUM_BREAKPOINT}px)`;
export const useSmallOrMediumScreen = () => useMediaQuery(mediumScreenQuery);

/** Hook pozwalający używać media queries w inlinowych stylach */
export function useMediaQuery(query: string): boolean {
  const state = getOrCreateQuery(query);
  const signal = useSignal();
  
  if (!(signal as any).onMount) {
    (signal as any).onMount = function useMediaQuery_onMount() {
      state.signals.add(signal);
      return function useMediaQuery_onUnmount() {
        state.signals.delete(signal);
      }
    }
  }
  
  useEffect((signal as any).onMount, emptyArray);
  
  return state.matches;
}

interface QueryState {
  query: MediaQueryList
  matches: boolean
  signals: Set<() => void>
}

const QUERIES: Map<string, QueryState> = new Map();

function getOrCreateQuery(queryString: string): QueryState {
  let state = QUERIES.get(queryString);
  if (!state) {
    const query = matchMedia(queryString);
    
    state = {
      query: query,
      matches: query.matches,
      signals: new Set()
    };
    
    if (state.query.addEventListener)
      state.query.addEventListener("change", handleChange);
    else if (state.query.addListener)
      state.query.addListener(handleChange);
    
    QUERIES.set(queryString, state);
  }
  return state;
}

function handleChange(this: MediaQueryList, event: MediaQueryListEvent) {
  const state = QUERIES.get(event.media);
  if (state) {
    state.matches = event.matches;
    for (let signal of state.signals) {
      scheduleReactUpdate(signal);
    }
  }
}

/* Wszystkie wspierane przeglądarki to mają, poza FF<64 gdzie nawet na telefonie będzie false i nam to nie przeszkadza */
export function useTouchDevice(): boolean {
  return useMediaQuery("(hover: none)")
}
