import ReactDOM from "react-dom";
import {reportError} from "../errorReporting";

// UWAGA, w wersji 16 React nie grupuje przetwarzania aktualizacji stanu komponentów
// inicjowanych spoza Reacta, czyli np. z asynchronicznych timerów i zdarzeń sieciowych
// dlatego ręczne grupowanie poniżej stanowczo poprawia wydajność

let BATCH_TIMER = null as any;
let BATCH_COUNT = 0;
const BATCH_ARRAY = [null, null, null, null, null, null, null, null] as any[];

// @ts-ignore
export function scheduleReactUpdate(fn: () => any): void;
export function scheduleReactUpdate<T>(fn: (arg: T) => any, arg: T): void;
export function scheduleReactUpdate<T>(fn: (arg: T) => any, arg: T): void {
  let i = BATCH_COUNT;
  BATCH_ARRAY[i++] = fn;
  BATCH_ARRAY[i++] = arg;
  BATCH_COUNT = i;

  if (BATCH_TIMER === null)
    BATCH_TIMER = setTimeout(ReactDOM.unstable_batchedUpdates, 0, runBatchUpdates);
}

function runBatchUpdates() {
  let errorCount = 0;
  
  try {
    for (let i = 0; i < BATCH_COUNT; i += 2) {
      const fn = BATCH_ARRAY[i];
      const arg = BATCH_ARRAY[i + 1];
      BATCH_ARRAY[i + 1] = BATCH_ARRAY[i] = null;
    
      try {
        fn(arg);
      } catch (e) {
        if (++errorCount <= 3)
          reportError(e);
      }
    }
  }
  finally {
    // resetujemy dopiero na końcu, bo podczas wykonania `fn` mogą być dodawane nowe wpisy w BATCH_ARRAY
    BATCH_TIMER = null;
    BATCH_COUNT = 0;
  }
}

// Altenatywna implementacja grupująca w ramach tego samego "ticka"

let IMM_BATCH_TIMER = null as any;
let IMM_BATCH_COUNT = 0;
let IMM_BATCH_DEPTH = 0;
let IMM_BATCH_EXECUTING = false;
const IMM_BATCH_ARRAY = [null, null, null, null, null, null, null, null] as any[];

// @ts-ignore
export function scheduleImmediateReactUpdate(fn: () => any): void;
export function scheduleImmediateReactUpdate<T>(fn: (arg: T) => any, arg: T): void;
export function scheduleImmediateReactUpdate<T>(fn: (arg: T) => any, arg: T): void {
  let i = IMM_BATCH_COUNT;
  IMM_BATCH_ARRAY[i++] = fn;
  IMM_BATCH_ARRAY[i++] = arg;
  IMM_BATCH_COUNT = i;
  
  if (IMM_BATCH_TIMER === null)
    IMM_BATCH_TIMER = setTimeout(runImmediateBatchUpdates, 0);
}

export function startImmediateBatchUpdates() {
  IMM_BATCH_DEPTH += 1;
}

export function finishImmediateBatchUpdates() {
  IMM_BATCH_DEPTH = Math.max(0, IMM_BATCH_DEPTH - 1);
  if (IMM_BATCH_DEPTH === 0 && !IMM_BATCH_EXECUTING)
    runImmediateBatchUpdates();
}

function runImmediateBatchUpdates() {
  if (IMM_BATCH_DEPTH !== 0)
    console.warn("runImmediateBatchUpdates run from emergency timer");
  
  IMM_BATCH_EXECUTING = true;
  
  ReactDOM.unstable_batchedUpdates(() => {
    try {
      let errorCount = 0;
      
      for (let i = 0; i < IMM_BATCH_COUNT; i += 2) {
        const fn = IMM_BATCH_ARRAY[i];
        const arg = IMM_BATCH_ARRAY[i + 1];
        IMM_BATCH_ARRAY[i + 1] = IMM_BATCH_ARRAY[i] = null;
    
        try {
          fn(arg);
        } catch (e) {
          if (++errorCount <= 3)
            reportError(e);
        }
      }
    }
    finally {
      IMM_BATCH_EXECUTING = false;
      IMM_BATCH_COUNT = 0;
      IMM_BATCH_DEPTH = 0;
      if (IMM_BATCH_TIMER !== null) {
        clearTimeout(IMM_BATCH_TIMER);
        IMM_BATCH_TIMER = null;
      }
    }
  });
}
