import React from "react";
import PropTypes from "prop-types";
import { FlexWrapper } from "..";

import Button from ".";

const Pair = ({ primary = {}, secondary = {}, flex = {}, ...props }) => {
  const flexProps = { ...Pair.defaultProps.flex, ...flex };
  const primaryProps = { ...Pair.defaultProps.primary, ...primary };
  const secondaryProps = { ...Pair.defaultProps.secondary, ...secondary };

  return (
    <FlexWrapper {...flexProps} {...props}>
      <Button {...primaryProps} />
      <Button {...secondaryProps} />
    </FlexWrapper>
  );
};

export default Pair;

Pair.propTypes = {
  primary: PropTypes.object, // Primary action button
  secondary: PropTypes.object, // Secondary action button
  flex: PropTypes.object, // Flexbox settings
};

Pair.defaultProps = {
  primary: {
    preset: "confirm",
  },
  secondary: {
    preset: "cancel",
  },
  flex: {
    justifyContent: "flex-start",
    flexDirection: "row-reverse",
  },
};