import React from "react";

import MenuItem from "../MenuItem";

import {
  tran,
  useLanguage,
  setLanguage,
  LANGUAGE_LIST
} from "utils/language";

const LanguageMenu = () => {
  const { translate, id: active } = useLanguage();

  const languageClicked = lang => {
    if (lang.id !== active) {
      setLanguage(lang.id);
    }
  };

  return (
    <MenuItem buttonProps={{ icon: "globe-europe", title: translate("pages.settings.content.lang.title") }}>
      <div className="top-bar__languages">
        {LANGUAGE_LIST.map(lang => (
          <div
            key={lang.id}
            onClick={() => languageClicked(lang)}
            className={`top-bar__languages-item ${
              active === lang.id ? "active" : ""
            }`}
          >
            <div className="top-bar__languages-item-flag">
              <img src={lang.flagUrl} alt="" />
            </div>
            <div className="top-bar__dropdown-item">
              <p className="top-bar__dropdown-item-name">{lang.name}</p>
              <span className="top-bar__dropdown-item-info">
                {tran("languages." + lang.id)}
              </span>
            </div>
          </div>
        ))}
      </div>
    </MenuItem>
  );
};

export default LanguageMenu;
