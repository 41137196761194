import React from "react";
import PropTypes from "prop-types";

const Cell = props => {
  const {
    style,
    children,
    content,
    align,
    highlighted,
    ...rest
  } = props;

  const _style = {
    position: "relative",
    cursor:
      props.hasOwnProperty("onClick") || props.hasOwnProperty("onDoubleClick")
        ? "pointer"
        : "inherit",
    textAlign: align || "initial",
    background: highlighted ? "var(--bgHighlighted)" : "transparent",
    ...style
  };

  const _content = children === undefined ? content : children;

  return (
    <td
      onClick={e => handleCellClick(e, props)}
      onDoubleClick={e => handleCellDoubleClick(e, props)}
      style={_style}
      align={align}
      {...rest}
    >
      {_content}
    </td>
  );
};

const handleCellClick = (e, cell) => {
  if (
    cell.onClick &&
    e.target.tagName !== "LABEL" &&
    e.target.tagName !== "A" &&
    e.target.tagName !== "BUTTON" &&
    e.target.tagName !== "CANVAS"
  ) {
    cell.onClick(e);
  }
};

const handleCellDoubleClick = (e, cell) => {
  if (
    cell.onDoubleClick &&
    e.target.tagName !== "LABEL" &&
    e.target.tagName !== "A" &&
    e.target.tagName !== "BUTTON" &&
    e.target.tagName !== "CANVAS"
  ) {
    cell.onDoubleClick(e);
  }
};

Cell.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
  align: PropTypes.oneOf(["left", "center", "right"]),
  content: PropTypes.any,
  highlighted: PropTypes.bool
};

Cell.defaultProps = {
  align: "center"
};

export default Cell;
