import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import { Header, Cell, HeaderCell, Row, Body } from "./parts/";

const Table = props => {
  const classes = {
    container: cx("table-container", props.containerClass),
    main: cx(
      "table-main",
      props.compressed && "compressed",
      props.celled && "celled",
      !props.disableHover && "hover-colored",
      props.className
    )
  };

  const inlineStyles = {
    container: {...props.containerStyle},
    main: {...props.style}
  }

  const inner = props.children || (
    <ShorthandTable
      head={props.head}
      body={props.body}
      focusIndex={props.focusIndex}
    />
  );

  return (
    <div className={classes.container} style={inlineStyles.container}>
      <table className={classes.main} style={inlineStyles.main}>{inner}</table>
    </div>
  );
};

const ShorthandTable = ({ head, body, focusIndex }) => {
  // * Map head and compose the headers cells.
  const headerCells = head.map((row, i) => {
    let inner;

    if (row && row.content) {
      if (React.isValidElement(row.content)) {
        inner = row.content;
      } else {
        inner = row.content.map(cell => cell);
      }
    } else {
      inner = row;
    }

    return (
      <HeaderCell key={"th_"+i} onClick={e => row.onClick && row.onClick(e)}>
        {inner}
      </HeaderCell>
    );
  });

  // * Map body, compose the rows and map the rows content composing the rows cells.
  const bodyRows = body.map((row, index) => {
    const content = Array.isArray(row) ? row : row.content;
    const cells = content.map((cell, i) => {
      const cellContent = cell
        ? cell.hasOwnProperty("content")
          ? { ...cell }
          : { content: cell }
        : cell;
      return <Cell key={"td_"+i} {...cellContent} />;
    });

    const rest = row.constructor === Object ? { ...row } : {};
    delete rest.content;
    
    return (
      <Row key={`tr_${index}`} focus={Boolean(focusIndex === index)} {...rest}>
        {cells}
      </Row>
    );
  });

  return (
    <React.Fragment>
      <Header>
        <Row>{headerCells}</Row>
      </Header>
      <Body>{bodyRows}</Body>
    </React.Fragment>
  );
};

Table.Header = Header;
Table.Body = Body;
Table.Row = Row;
Table.HeaderCell = HeaderCell;
Table.Cell = Cell;

Table.defaultProps = {
  compressed: false,
  focusable: false,
  celled: false,
  focusIndex: -1,
  className: "",
  head: [],
  body: [],
  containerClass: ""
};

Table.propTypes = {
  children: PropTypes.node,
  head: PropTypes.array,
  body: PropTypes.array,
  disableHover: PropTypes.bool,
  compressed: PropTypes.bool,
  focusable: PropTypes.bool,
  focusIndex: PropTypes.number,
  celled: PropTypes.bool,
  containerClass: PropTypes.string,
  containerStyle: PropTypes.object,
  style: PropTypes.object
};

export default Table;
