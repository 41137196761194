import React, {FunctionComponent, useEffect, useContext, useRef, useState} from "react";
import ReactDOM from "react-dom";
import {OverlayServiceContext, OverlayState, OverlayType} from "./OverlayService";
import {doNothing, emptyArray} from "../../../utils/constants";

type Props = {
  type?: OverlayType
  
  doClose?: (...args: any[]) => void
  
  children?: any
}

const EMPTY_ELEMENT = <></>;

/** Ten komponent implementuje portal w oparciu o mechanizm nakładek
 *  
 *  Domyślne funkcje zamykania OverlayService są przejęte przez ten komponent
 *  i powodują wywołanie onClose, ale nie automatyczne zamknięcie.
 *  Nie podanie onClose efektywnie wyłącza zamykanie Escapem i kliknięciem na zewnątrz.
 * */
export default function Overlay({ type = "modal", doClose, children }: Props) {
  type Overlay = OverlayState;
  
  const service = useContext(OverlayServiceContext);
  const overRef = useRef<Overlay | null>(null);
  
  const [portal, setPortal]  = useState<HTMLElement | null>(null);
  
  if (overRef.current) {
    // specjalnie ustawiamy doClose zamiast onClose, bo w tym wypadku ma powodować
    // odmontowanie komponentu
    overRef.current.doClose = doClose;
  }
  
  useEffect(() => {
    overRef.current = service.createOverlay(type, EMPTY_ELEMENT, { onRef: setPortal }) as Overlay;
    overRef.current.doClose = doClose;
    
    return () => {
      if (overRef.current)
        overRef.current.forceClose();
    }
  }, emptyArray);
  
  if (!portal)
    return null;
  
  return ReactDOM.createPortal(children, portal);
}
