import React from "react";
import { IDialog } from "./Dialog/useDialog";
import { tran, translate } from "../../utils/language";
import { Request, isInstalled, isReloading, isError, isLoaded, isLoading } from "../../capi/hooks";
import Dialog from "./Dialog/Dialog";
import { TryAgainDialog } from "./Dialog/comps";
import Loader from "./Loader";
import Button from "./Button";
import Blankholder from "./Blankholder";
import { useTimeoutLatch } from "utils/hooks/timing";

type RequestDialogProps = {
  request: Request
  title?: React.ReactNode
  dialog: IDialog
  messages?: { [key: string]: React.ReactNode }
};

export function RequestDialog({ request, title = tran("error.fetch"), messages, dialog }: RequestDialogProps) {
  const slow = useTimeoutLatch(200);
  
  if (isReloading(request)) {
    return slow ? <Dialog size="small" dialog={dialog}>
      <Loader inline />
    </Dialog> : null;
  }
  
  let message = overrideMessage(messages, request) || request.message || tran("error.generic");
  if (!isInstalled(request)) {
    message = <>
      {message}
      (NOT_INSTALLED)
    </>;
  }
  
  return <TryAgainDialog
    title={title}
    message={message}
    onRetry={request._refresh}
    dialog={dialog}
  />
};

type RequestStateProps = {
  request: Request
  title?: React.ReactNode
  inline?: boolean
  messages?: { [key: string]: React.ReactNode }
};

export function RequestState({ request, inline, title = tran("error.fetch"), messages }: RequestStateProps) {
  const slow = useTimeoutLatch(200);
  
  if (isReloading(request)) {
    return slow ? <Loader inline={inline} /> : null;
  }
  
  let message = overrideMessage(messages, request) || request.message || tran("error.generic");
  if (!isInstalled(request)) {
    message = <>
      {message}
      (NOT_INSTALLED)
    </>;
  }
  
  return <Blankholder
    title={title}
    description={message}
    icon="exclamation circle"
    button={<Button preset="tryAgain" onClick={request._refresh}/>}
  />
};

function overrideMessage(messages: { [key: string]: React.ReactNode } | undefined, request: Request): React.ReactNode | undefined {
  if (messages) {
    if (request.status) {
      let x = messages[request.status];
      if (x) return x;
      if (request.reason) {
        x = messages[request.reason];
        if (x) return x;
      }
    }
  }
}

type RequestWrapperProps = {
  request: Request | readonly Request[]
  
  title?: React.ReactNode
  inline?: boolean
  messages?: { [key: string]: React.ReactNode }
  
  children?: React.ReactNode
};

export function RequestWrapper(props: RequestWrapperProps) {
  let reloading = false;
  
  let rqs = props.request as readonly Request[];
  if (!Array.isArray(rqs)) rqs = [rqs as any];
  
  for (const rq of rqs) {
    if (isError(rq) || isLoading(rq))
      return <RequestState request={rq} title={props.title} inline={props.inline} messages={props.messages}/>;
  
    else if (isReloading(rq))
      reloading = true;
  }
  
  return <div className={reloading ? "rq-wrapper rq-wrapper--reloading" : "rq-wrapper"} children={props.children} />;
}
