import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { LOGOUT } from "sagas/types";
import { tran } from "utils/language";
import { Button, Checkbox, Divider, FlexWrapper } from "components/shared/index";
import { ExternalDocument } from "components/special-pages/ExternalDocument/ExternalDocument";

type TermsOfServiceProps = {
  onCancel: () => void,
  onSubmit: () => void,
  submitButtonProps?: { preset?: string, content?: React.ReactNode },
  cancelButtonProps?: { preset?: string },
  noEscape?: boolean,
  simple?: boolean
};

export function TermsOfService({
  onCancel,
  onSubmit,
  submitButtonProps = { preset: "continue" },
  cancelButtonProps = { preset: "back" },
  noEscape = false,
  simple = false,
}: TermsOfServiceProps) {
  const dispatch = useDispatch();
  const signOut = () => dispatch({ type: LOGOUT });

  const [checked, setChecked] = useState(false);

  const submit = () => onSubmit && onSubmit();

  return (
    <div>
      <ExternalDocument resource="terms" locationScrolling />
      
      {!simple && (
        <>
          <Divider />
          
          <Checkbox
            required
            checked={checked}
            onClick={() => setChecked(prevVal => !prevVal)}
            label={<span style={{ fontWeight: "bold" }}>{tran("terms.acceptTerms")}</span>}
          />

          <br />
          <br />

          {!simple &&
            <FlexWrapper>
              {noEscape 
                ? <Button preset="logout" onClick={signOut} />
                : <Button style={{ alignSelf: "flex-end" }} onClick={onCancel} {...cancelButtonProps} />
              }

              <Button
                style={{ alignSelf: "flex-end" }}
                onClick={submit}
                disabled={!checked}
                {...submitButtonProps}
              />
            </FlexWrapper>
          }
        </>
      )}
    </div>
  );
};
