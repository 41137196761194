import React, { Component } from "react";
import PropTypes from "prop-types";

import { Popup, Icon } from "components/shared";

const COLORS = ["main", "primary", "secondary", "bgSub"]

class ColorPalette extends Component {
  constructPalette() {
    const colors = [];
    for (let i of COLORS) {
      if (this.props.colors[i]) {
        let color = (
          <div
            key={i}
            title={this.translateColorName(i)}
            className={`color ${this.props.animated ? "animated" : ""}`}
            style={{ background: this.props.colors[i] }}
          />
        );

        if (this.props.tooltip)
          colors.push(
            <Popup
              position="top center"
              trigger={color}
              content={this.translateColorName(i)}
            />
          );
        else
          colors.push(color);
      }
    }
    return colors;
  }

  translateColorName = name => {
    switch (name) {
      case "main":
        return "Kolor Główny";
      case "primary":
        return "Kolor podrzędny";
      case "secondary":
        return "Drugi kolor podrzędny";
      case "confirm":
        return "Kolor potwierdzenia";
      case "cancel":
        return "Kolor anulacji";
      case "text":
        return "Kolor tekstu";
      case "textLight":
        return "Jasny kolor tekstu";
      case "bgSub":
        return "Kolor tła";
      case "bgMain":
        return "Drugi kolor tła";
      default:
        return "Kolor wyspecjalizowany";
    }
  };

  render() {
    return (
      <button
        type="button"
        onClick={() => this.props.onClick(this.props.value)}
        className={`color-palette__container ${
          this.props.active ? "active" : ""
        }`}
      >
        <div className={`color-palette`}>
          {/* {this.props.active ? <Label corner="right" icon="check" color="green" /> : ""} */}
          {this.constructPalette()}
        </div>
        <p>
          {this.props.name}
          {this.props.active ? (
            <Icon
              name="check circle"
              style={{ fontSize: ".9rem", marginLeft: "5px" }}
            />
          ) : null}
        </p>
      </button>
    );
  }
}

ColorPalette.defaultProps = {
  tooltip: true,
  animated: true
};

ColorPalette.propTypes = {
  value: PropTypes.string, // Identyfikator palety
  name: PropTypes.string, // Wyświetlana nazwa palety
  colors: PropTypes.object, // Paleta kolorów
  active: PropTypes.bool, // Aktywna paleta
  tooltip: PropTypes.bool, // Popup z opisem koloru
  shadow: PropTypes.bool, // Cień pod paletą
  animated: PropTypes.bool // Animowana paleta
};

export default ColorPalette;
