import React from "react";
import PropTypes from "prop-types";

import { Button, Popup } from "components/shared";
import Item from "../Item";

const BreadcrumbHidden = props => {
  if(props.items.length <= 0) return null; // Hide breadcrumbs button on home page.

  return (
    <Popup
      trigger={
        <Button variant="icon" preset="showMore" />
      }
      on="click"
      position="bottom left"
      content={
        <div className="breadcrumb__popup-container">
          {props.items.map((item, id) => {
            return (
              <div className="breadcrumb__popup-item" key={id}>
                <Item {...item} active={false} />
              </div>
            );
          })}
        </div>
      }
    />
  );
};

BreadcrumbHidden.defaultProps = {
  items: []
};

BreadcrumbHidden.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object)
};

export default BreadcrumbHidden;
