import React, { useState, useEffect } from "react";
import bootstrap from "bootstrap";
import { Tran, tran } from "utils/language";
import { Button, Checkbox, Input, Message } from "components/shared";
import { ForgotPasswordDialog } from "../ForgotPasswordDialog";
import { DialogPresets } from "components/shared/Dialog";
import { BrandedDialog } from "components/shared/Dialog/comps";
import useDialog, { IDialog } from "components/shared/Dialog/useDialog";
import { SignupDialog } from "../Signup/SignupDialog";
import { useBind, useId } from "../../../utils/hooks";
import { performLogin } from "./loginLogic";
import { usePasswordValidation } from "../../common/PasswordForm/usePasswordValidation";
import { isLoaded, notYet } from "../../../capi/hooks";
import { Requirements } from "../../common/PasswordForm/Requirements";
import { RequestDialog } from "../../shared/requestUtils";
import { FlexWrapper, Toast } from "../../shared";
import { DateTime } from "../../shared/dateUtils";
import { CompleteSignupDialog } from "../Signup/CompleteSignupDialog";
import { getRuleIdf } from "utils/roles";

type LoginLocalDialogProps = {
  dialog: IDialog,
  offerSignup: boolean,
};

// notYet to funkcja, a setState nie interpretuje ich jako wartości
const notYetCons = () => notYet;

export function LoginLocalDialog({ dialog, offerSignup }: LoginLocalDialogProps) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [newPasswordFor, setNewPasswordFor] = useState<typeof notYet | string>(notYetCons);
  
  const signupDialog = useDialog(SignupDialog);
  const forgotPasswordDialog = useDialog(ForgotPasswordDialog);

  const [error, setError] = useState<React.ReactNode>("");
  const formId = useId();
  
  const passwordExpired = typeof newPasswordFor === "string";
  const passwordValidationState = usePasswordValidation(newPasswordFor);
  
  const onSubmit = useBind(handleLogin).preventDefault();
  
  const completeDialog = useDialog(CompleteSignupDialog);
  
  useEffect(() => {
    // reset błędów po zmianie loginu
    setNewPasswordFor(notYetCons);
    setError("");
  }, [login]);
  
  if (passwordExpired && !isLoaded(passwordValidationState.paramsRq))
    return <RequestDialog request={passwordValidationState.paramsRq} dialog={dialog} />;
  
  const buttons = [
    <Button preset="cancel" onClick={dialog.close} />,
    <Button preset="login" onClick={onSubmit} form={formId} />
  ];
  
  let newPasswordPart = null;
  if (passwordExpired) {
    newPasswordPart = <>
      <Input
        labelCls="custom-input__label--login"
        {...passwordValidationState.newPasswordProps}
        autoFocus={passwordExpired}
        fluid
        required
        icon={undefined}
        enablePasswordPreview
      />
  
      <Input
        labelCls="custom-input__label--login"
        {...passwordValidationState.confirmPasswordProps}
        fluid
        required
        icon={undefined}
        enablePasswordPreview
      />
      
      <Requirements {...passwordValidationState.requirementsProps} cls="login-form__pass-requirements" />
    </>;
  }

  return (
    <DialogPresets.BrandedDialog size="large" dialog={dialog} buttons={buttons}>
      <form className="login-form" id={formId}>
        <BrandedDialog.Heading>
          {tran("login.heading")}
        </BrandedDialog.Heading>

        {/* Nazwa biblioteki */}
        {!bootstrap.WZUW && 
          <h3 className="login-form__subheading">{bootstrap.title}</h3>
        }
        
        {passwordExpired && passwordValidationState.errors.length > 0
          ? <Message type="error" content={<div>{passwordValidationState.errors.map(el => <div>{el}</div>)}</div>} />
          : error && <Message type="error" content={error} />}

        <Input
          wrapperCls="custom-input--login"
          labelCls="custom-input__label--login"
          fluid
          required
          autoFocus={!passwordExpired}
          type="text"
          value={login}
          onChange={setLogin}
          label={tran("login.loginOrEmail")}
        />

        <Input
          wrapperCls="custom-input--login"
          labelCls="custom-input__label--login"
          fluid
          required
          type="password"
          value={password}
          onChange={setPassword}
          label={tran("login.password")}
        />
        
        {newPasswordPart}
        
        <FlexWrapper alignItems="baseline" columnOnMobile style={{ marginTop: 16 }}>
          <div>
            <div className="login-form__checkbox-wrapper">
              <Checkbox
                label={tran("login.btn.remember")}
                checked={remember}
                onClick={() => setRemember(prevVal => !prevVal)}
              />
            </div>
          </div>
          
          <FlexWrapper alignItems="start" flexDirection="column" style={{ marginLeft: -8 /* minus padding buttona */ }}>
            {offerSignup && !passwordExpired &&
              <Button
                className="m0"
                variant="text"
                type="button"
                icon="user-plus"
                onClick={signupDialog.open}
                content={tran("login.btn.noAccount")}
              />}
            {!passwordExpired &&
              <Button
                className="m0"
                variant="text"
                type="button"
                icon="lock"
                onClick={forgotPasswordDialog.open}
                children={tran("login.btn.forgot")}
              />}
          </FlexWrapper>
        </FlexWrapper>
      </form>
    </DialogPresets.BrandedDialog>
  );
  
  async function handleLogin() {
    if (!login || !password) {
      setError(tran("error.fillAllFields"));
      return;
    }
    
    if (passwordExpired && !passwordValidationState.validate())
      return;
    
    let result;
    try {
      result = await performLogin({ identifier: login, password, remember, newPassword: passwordExpired ? passwordValidationState.values.newPassword : undefined });
    }
    catch(err: any) {
      if (err.capiStatus && err.capiStatus < 500) {
        if (err.capiReason === "registration_incomplete") {
          completeDialog.openWith({ client: bootstrap.folks.client.withAuth(100, err.capiData.user_id, bootstrap.domain, password), skipTerms: true });
          return;
        }
        setError(err.enduserMessage);
        return;
      }
      else {
        throw err;
      }
    }
    
    if (result.expired) {
      setNewPasswordFor(getRuleIdf(result.role!));
      setError(tran("login.err.expired"))
    }
    else if (result.expiry !== undefined) {
      Toast.emit(<Tran id="login.toast.password" search="<<1>>" replace={<DateTime at={result.expiry}/>} /> as any)
    }
  }
}
