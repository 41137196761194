import React, { useState, useRef } from "react";

import isEqualReact from "react-fast-compare";

import { Link, LinkProps } from "react-router-dom";
import { useTouchDevice } from "../../utils/hooks";

/** Ten komponent implementuje link, który zachowuje się inaczej na komputerze i telefonie.
 *  Na komputerze zachowuje się identycznie jak Link.
 *  Na telefonie pierwsze dotknięcie daje focus ale nie aktywuje linku, a drugie aktywuje link. */
export const TouchLink = React.memo(function TouchLink(props: LinkProps) {
  type State = {
    props: typeof props
    timer: number | undefined
    onFocus: (event: React.FocusEvent<HTMLAnchorElement>) => void,
    onBlur: (event: React.FocusEvent<HTMLAnchorElement>) => void,
  }
  
  const stateRef = useRef<State>(null as any as State);
  
  if (stateRef.current === null) {
    stateRef.current = {
      timer: undefined,
      props: props,
      onFocus: (event: React.FocusEvent<HTMLAnchorElement>) => {
        const state = stateRef.current;
        const props = state.props;
        if (event.currentTarget.matches(":focus-within")) {
          clearTimeout(state.timer)
          state.timer = setTimeout(setOnClick, 100, ENABLED_CLICK);
        }
        props.onFocus && props.onFocus(event);
      },
      onBlur: (event: React.FocusEvent<HTMLAnchorElement>) => {
        const state = stateRef.current;
        const props = state.props;
        if (!event.currentTarget.matches(":focus-within")) {
          clearTimeout(state.timer)
          state.timer = setTimeout(setOnClick, 100, DISABLED_CLICK);
        }
        props.onBlur && props.onBlur(event);
      }
    }
  }
  else {
    stateRef.current.props = props;
  }
  
  const state = stateRef.current;
  
  const [onClick, setOnClick] = useState<undefined | ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void)>(DISABLED_CLICK);
  
  const isTouchDevice = useTouchDevice();
  
  let newProps;
  if (isTouchDevice) {
    newProps = {
      ...props,
      onClick,
      onFocus: state.onFocus,
      onBlur: state.onBlur,
    }    
  }
  else {
    newProps = props;
  }
  
  return <Link {...newProps}/>
}, isEqualReact);

const ENABLED_CLICK = undefined;
const DISABLED_CLICK = () => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => { event.preventDefault(); event.stopPropagation(); };
