import React from "react";
import useTheme from "utils/useTheme";
import Dialog from "../Dialog";
import { IDialog } from "../useDialog";
import { FlexWrapper } from "components/shared";
import bootstrap from "bootstrap";

// @ts-ignore: krzyczy, że moduł nie został znaleziony.
import sowa from "../../../../images/sowa-napis.png";
// @ts-ignore
import sowaWhite from "../../../../images/sowa-napis-white.png";

type BrandedDialogProps = {
  children?: React.ReactNode
  buttons?: readonly React.ReactElement[] | React.ReactFragment | null,
  size?: "small" | "medium" | "large",
  dialog: IDialog
};

type HeadingProps = {
  children?: React.ReactNode
};

const BrandedDialog = ({ children, size = "medium", buttons, dialog }: BrandedDialogProps) => {
  const { isDark } = useTheme();
  
  let header;
  if (bootstrap.WZUW)
    header = <FlexWrapper alignItems="center" className="head">
      <p className="head__text head__text--wzuw">
        {bootstrap.title2}
      </p>
    </FlexWrapper>;
  
  else
    header = <FlexWrapper alignItems="center" className="head">
      <div className="head__logo-wrapper">
        <img className="head__logo" alt="Logo SOWA" src={isDark ? sowaWhite : sowa} />
      </div>
  
      <p className="head__text">
        {bootstrap.title2}
      </p>
    </FlexWrapper>;
  
  return (
    <Dialog type="branded" size={size} buttons={buttons} dialog={dialog}>
      {header}
      {children}
    </Dialog>
  );
};

const Heading = ({ children }: HeadingProps) => (
  <h2 className="heading">
    {children}
  </h2>
);

BrandedDialog.Heading = Heading;

export default BrandedDialog;