import { Breadcrumb } from 'components/shared';
import React, { useEffect } from 'react';
import { useDisplayBreadcrumbs } from 'utils/hooks/breadcrumbs';
import { emptyArray } from "../../../../utils/constants";
import bootstrap from "../../../../bootstrap";

// TODO: może dodać śledzenie poprzednich okruszków, żeby można było nawigować w obie strony

const Navigation = () => {
  const breadcrumbs = useDisplayBreadcrumbs();
  const [maxCrumbs, setMaxCrumbs] = React.useState(getNumberOfCrumbs);

  useEffect(() => {
    const setMax = () => 
      void setMaxCrumbs(getNumberOfCrumbs())
    
    window.addEventListener('resize', setMax);

    return () => 
      window.removeEventListener('resize', setMax);
  }, emptyArray);

  const mappedCrumbs = breadcrumbs.map(crumb => {
    return { content: crumb.label, link: crumb.url };
  }).filter(crumb => crumb.content);
  
  useEffect(() => {
    const last = breadcrumbs[breadcrumbs.length - 1];
    last && !last.bcExtra.titleLoading && setDocumentTitle(last.title || "");
  }, [breadcrumbs]);
  
  // TODO: lepsze miejsce na to? jest tutaj, bo tutaj mamy świeży tytuł strony
  useEffect(() => {
    // @ts-ignore
    const gtag = window.gtag;
    if (gtag) {
      const location = window.location;
      const path = location.pathname;
      console.log("gtag", path);
    
      if (path === "/" || path.startsWith("/teka"))
        gtag('event', 'page_view', {
          page_title: window.document.title,
          page_location: location.origin + path + (location.search || ""),
          page_path: path
        });
    }
  }, [breadcrumbs]);
  
  return <Breadcrumb
            maxItems={maxCrumbs}
            items={mappedCrumbs} />
}

/** Ustawienie liczby wyświetlanych elementów w
  * Breadcrumb w zależności od szerokości okna */
const getNumberOfCrumbs = () => {
      if (window.innerWidth < 600) {
        return 1;
      } else if (window.innerWidth >= 600 && window.innerWidth < 1000) {
        return 3;
      } else {
        return 5;
      }
}

/** Ustawia tytuł zakładki w przeglądarce na połączenie podanej nazwy oraz nazwy aplikacji. */
const setDocumentTitle = (pageName: string) => {
  window.document.title = `${pageName || bootstrap.title2} • ${bootstrap.name}`;
};

export default Navigation;