import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { AriaLabelParent } from "utils/aria";
import { tran } from "utils/language";

const BreadcrumbItem = props => {
  const { active, className, link, content, ...rest } = props;

  const _content = link ? 
  <Link tabIndex={0} to={link}>
    {content}
    {content?.props?.name === "home" && <AriaLabelParent>{tran("app.home")}</AriaLabelParent>}
  </Link> : content;

  const _className = `${className} breadcrumb__item`;
  
  const role = link ? undefined : "link";

  if (active) {
    return (
      <b tabIndex={link ? -1 : 0} className={_className} role={role} {...rest}>
        {_content}
      </b>
    );
  } else {
    return (
      <span tabIndex={link ? -1 : 0} className={_className} role={role} {...rest}>
        {_content}
      </span>
    );
  }
};

BreadcrumbItem.defaultProps = {
  className: "",
  active: false,
  link: ""
};

BreadcrumbItem.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  link: PropTypes.string,
  content: PropTypes.node
};

export default BreadcrumbItem;
