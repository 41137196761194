import { useRef } from "react";
import { emptyArray } from "../constants";

/** Referencja funkcyjna w starym reactowym stylu.
 *  Tzn. funkcja, którą należy przekazać jako `ref` do JSX,
 *  albo ręcznie wywołać podając element docelowy jako argument. */
export type RefSetter<T = Element> = {
  (ref: T | null): void
}

const is = Object.is;

// Copied from React sources and rewritten
export function areHookInputsEqual(nextDeps: readonly any[], prevDeps: readonly any[]): boolean {
  if (prevDeps.length !== nextDeps.length)
    return false;
  
  for (let i = 0; i < prevDeps.length; i++) {
    if (is(nextDeps[i], prevDeps[i])) {
      continue;
    }
    return false;
  }
  
  return true;
}


/** Dla obiektów dowolnego typu tworzy słownik pozwalający zmapować je do stringów,
 *  do użycia jako klucz komponentu w React.
 *  UWAGA: zakładamy, że prop jest stały, jeśli potrzebny jest zmienny,
 *         to trzeba poprosić Marcina o refaktoring.
 */
export function useKeyMapping<T>(inputs: readonly T[]): (obj: T) => string;
export function useKeyMapping<T>(inputs: readonly T[], prop: keyof T): (obj: T[typeof prop]) => string;
export function useKeyMapping<T>(inputs: readonly T[], prop?: keyof T): Function {
  type State = {
    map: Map<any, string>
    getter: Function
    prev: readonly T[]
    ctr: number
  }
  
  const stateRef = useRef<State>(null as any);
  if (stateRef.current === null) {
    const m = new Map<any, string>();
    stateRef.current = {
      map: m,
      getter: m.get.bind(m),
      prev: emptyArray,
      ctr: 0
    }
  }
  
  const state = stateRef.current;
  let { map, prev } = state;
  if (!areHookInputsEqual(inputs, prev)) {
    const newMap = new Map();
    for (let input of inputs) {
      const key = prop ? input[prop] : input;
      newMap.set(key, map.get(key) || `kmid-${state.ctr++}`);
    }
    map.clear();
    state.map = newMap;
    state.getter = newMap.get.bind(newMap);
  }
  
  return state.getter;
}
