import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Popup, Icon } from "components/shared";
import { tran } from "utils/language";
import Ink from "react-ink";
import cx from "classnames";

import Group from "./Group";
import Pair from "./Pair";

import {isRunning} from "../../../utils/hooks/bind";
import { AriaLabelParent } from "utils/aria";
import { iconPresets } from "./iconPresets";

class Button extends Component {
  state = {
    renderIcon: true,
    popupOpen: false
  };

  componentDidMount() {
    if (this.props.keybind)
      window.addEventListener("keydown", this.handleKeyDown);
    
    if (this.props.focus) this.refer.focus();
  }
  
  componentWillUnmount() {
    if (this.props.keybind)
      window.removeEventListener("keydown", this.handleKeyDown);
  }
  
  handleKeyDown = (ev) => {
    // TODO: lepszy mechanizm połączony z warstwami w OverlayService
    const key = `${ev.ctrlKey ? "C-" : ""}${ev.altKey ? "A-" : ""}${ev.shiftKey ? "S-" : ""}${ev.key}`;
    if (this.props.keybind === key) {
      ev.preventDefault();
      ev.stopPropagation();
      this.refer && this.refer.click();
    }
  }

  /**
   * @description In case there is still a component having old props and was overlooked by us.
   * @param {object} variables
   * @returns {object}
   */
  backwardsCompatibillity(variables) {
    // Due to color name change.
    if (variables.color)
      switch (variables.color) {
        case "confirm":
          variables.color = "success";
          break;
        case "cancel":
          variables.color = "error";
          break;
        case "warn":
          variables.color = "warning";
          break;
        default:
          break;
      }

    // ! Deprecated - floated backwards compatibility.
    if (!variables.align && variables.floated)
      variables.align = variables.floated;

    return variables;
  }

  /**
   * @description Process all variables and commit changes if needed.
   * @param {object} variables
   * @returns {object} Processed variables.
   */
  controlVariables(variables) {
    variables = this.backwardsCompatibillity(variables);

    // Set variable to icon if it's icon only
    if (
      variables.variant !== "icon" &&
      variables.variant !== "fab" &&
      this.isIconOnly()
    )
      variables.variant = "icon";
    // Set color to disabled if component is disabled
    if (variables.disabled && variables.color !== "disabled")
      variables.color = "disabled";
    // Make sure inverted is applied only if the variant equals contained.
    if (
      (variables.variant !== "contained" &&
        variables.variant !== "fab" &&
        variables.inverted) ||
      variables.disabled
    )
      variables.inverted = false;
    // Convert compact to small size
    if (variables.compact && !variables.size) variables.size = "small";
    // Make component fluid if it's not an icon
    if (variables.fluid && this.isIconOnly()) variables.fluid = false;
    // Set elevation only if variant is contained and component is not disabled
    if (
      variables.variant === "contained" &&
      !variables.unelevated &&
      !variables.disabled
    )
      variables.elevated = true;
    // Make FAB extended if it has text to show.
    if (
      variables.variant === "fab" &&
      (this.props.content || this.props.children)
    )
      variables.extended = true;

    // Override certain changes if preset is set.
    if(variables.preset) {
      if (variables.variant === "icon" || variables.variant === "fab") {
        const preset = iconPresets[variables.preset];
        if (preset) {
          variables.icon ||= preset.icon;
          variables.color ||= preset.color;
          variables.title ||= (preset.title ?? tran(`unique.${variables.preset}`)) || undefined;
        }
        else
          variables.title ||= tran(`unique.${variables.preset}`);
      } else {
        variables.variant = "contained";
        if(!variables.pretext) variables.pretext = variables.preset; // Set text by preset key.

        if(["back", "cancel", "close"].indexOf(variables.preset) > -1) 
          variables.inverted = true;
        
        if (! variables.color) {
          if (["remove", "delete", "logout", "reset"].indexOf(variables.preset) > -1)
            variables.color = "error";
  
          if (["save", "confirm", "continue", "send", "edit", "login", "select", "leave"].indexOf(variables.preset) > -1)
            variables.color = "primary";
  
          if (['special'].indexOf(variables.preset) > -1)
            variables.color = "success";
        }
      }
      
      if(variables.disabled) variables.color = "disabled";
    }
    
    // Set color if it wasn't set before.
    if (!variables.color) variables.color = "primary";

    return variables;
  }

  /**
   * @description Handle styles not included in CSS.
   * @prop {object} style
   * @prop {string} floated
   * @returns {object} Inline-style
   */
  handleDynamicStyles = (style = {}, align, cornered) => {
    const styles = { ...style };

    // Set floated
    if (align) styles.float = align;

    // Set element's corner offset.
    if (cornered) {
      switch (cornered) {
        case "top left":
          styles.top = "16px";
          styles.left = "16px";
          break;
        case "top right":
          styles.top = "16px";
          styles.right = "24px";
          break;
        case "bottom left":
          styles.bottom = "16px";
          styles.left = "16px";
          break;

        default:
        case "bottom right":
          styles.bottom = "16px";
          styles.right = "24px";
          break;
      }
    }

    return styles;
  };

  /**
   * @description Renders an icon.
   * @param {string|node} icon
   * @returns {node} Icon component.
   */
  renderIcon(icon, position) {
    const loading = this.props.loading || isRunning(this.props.onClick) /* Automatyczny stan ładowania z useBind */
    
    if (loading) icon = "hourglass";
    
    if (!icon || !this.state.renderIcon) return <React.Fragment />;

    if (this.isIconOnly()) {
      const color = this.props.color;
      const iconType =
        ["primary", "secondary", "warning", "success", "error"].indexOf(color) >
        -1
          ? color
          : "default";
      return (
        <Icon name={icon} type={iconType} spin={loading} />
      );
    }

    // Check if icon is an Element and clone it with new ref.
    icon = React.isValidElement(icon) ? (
      icon
    ) : (
      <Icon name={icon} />
    );

    const iconCls = cx(
      "btn-icon",
      position,
      loading && "rotate"
    );
    return <span className={iconCls}>{icon}</span>;
  }

  /**
   * @description Render a label
   * @param {object} label
   * @returns {node} Label components.
   */
  renderLabel(label) {
    if (!label) return <React.Fragment />;

    const composeFloat = f => {
      if (!f) f = "top left";
      f = f.split(" ");
      if (!f[1]) f = ["top", ...f];
      return `label-${f.join("-")}`;
    };

    const float = composeFloat(label.float);
    const typeClass = label.hasOwnProperty("type") ? label.type : "";

    return (
      <div
        tabIndex="-1"
        className={`btn-label ${typeClass} ${float}`}
        style={{ ...label.style }}
      >
        {label.content}
      </div>
    );
  }

  /**
   * @description Render a popup.
   * @param {object} popup
   * @returns {node}
   */
  renderPopup = (popup, trigger) => {
    if (typeof popup === "string") popup = { content: popup };

    return (
      <Popup
        content={popup.content}
        style={{ ...popup.style, float: this.props.align }}
        {...popup.props}
        trigger={trigger}
        onOpen={() => this.setState({ popupOpen: true })}
        onClose={() => this.setState({ popupOpen: false })}
      />
    );
  };

  /**
   * @description Renders the content and an icon on a specific position.
   * @prop {element} content
   * @prop {element} icon
   * @prop {string} iconPosition
   * @returns {node}
   */
  renderIconWithContent = (content, icon, iconPosition) => {
    return icon ? (
      iconPosition === "right" ? (
        <React.Fragment>
          {content} {icon}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {icon} {content}
        </React.Fragment>
      )
    ) : (
      content
    );
  };

  /**
   * @description Check if button is icon-only.
   * @returns {bool}
   */
  isIconOnly() {
    return (
      (this.props.icon || this.props.variant === "icon") &&
      (!this.props.children && !this.props.content && !this.props.pretext)
    );
  }

  /**
   * @description Handle onClick.
   */
  handleClick = e => {
    const props = this.props;
    const pd = !props.to && props.preventDefault !== false;
    const click = props.onClick;
    
    if (pd && click && e.preventDefault) e.preventDefault();
    if (props.disabled) return;
    // if (pd && e.stopPropagation) e.stopPropagation();
    click && click(e);
  };
  
  /**
   * @description Handles ref.
   */
  handleRef = c => {
    this.refer = c;
    if (this.props.handleRef) this.props.handleRef(c);
  };

  render() {
    const props = this.props;
    const {
      children,
      content,
      to,
      style,
      cornered,
      hideOn,
      tabIndex,
      className
    } = props;

    const { popupOpen } = this.state;

    const variables = this.controlVariables({ ...props });

    const classes = cx(
      "sowa",
      "button",
      variables.disabled && "disabled",
      `variant__${variables.variant}`,
      `color__${variables.color}`,
      `size__${variables.size}`,
      variables.fluid && "fluid",
      variables.elevated && "elevated",
      variables.cornered && "cornered",
      !variables.toggled && "toggled",
      variables.extended && "extended",
      variables.inverted && "inverted",
      popupOpen && "focused",
      setVisibility(hideOn),
      className
    );

    const styles = this.handleDynamicStyles(style, variables.align, cornered);
    const _content = children || content || translatePretext(variables.pretext);
    const _icon = this.renderIcon(variables.icon, variables.iconPosition);

    const inner = this.renderIconWithContent(
      _content,
      _icon,
      variables.iconPosition
    );
    
    const disabled = !!variables.disabled;
    const buttonProps = {
      disabled: disabled,
      tabIndex: disabled ? -1 : tabIndex,
      to: disabled ? undefined : to,
      ref: this.handleRef,
      style: styles,
      className: classes,
      onClick: this.handleClick,
      title: variables.title,
      children: [
        // span centruje całość w środku dla kompat. wstecz po tym jak ustawiliśmy display: inline-flex
        <span key="content">
          {inner}
          {this.renderLabel(variables.label)}
        </span>,
        disabled ? null : INK
      ],
    };
    
    const propTypes = Button.propTypes;
    for (const prop in props) {
      // kopiujemy nieużyte inaczej propsy
      if (!(prop in propTypes) && !(prop in buttonProps))
        buttonProps[prop] = props[prop];
    }

    // Usage of AriaLabelParent if title is not a string
    if (typeof buttonProps.title === "object") {
      buttonProps.children = (
        <>
          {buttonProps.children}
          <AriaLabelParent>{buttonProps.title}</AriaLabelParent>
        </>
      )
    } else if (buttonProps.title && !buttonProps["aria-label"]) {
      buttonProps["aria-label"] = buttonProps.title;
    }

    let result = (to && !disabled)
      ? React.createElement(Link, buttonProps)
      : React.createElement("button", buttonProps);
    
    if (variables.popup) result = this.renderPopup(variables.popup, result);

    return result;
  }
}

const INK = <Ink key="ink"/>;

Button.Group = Group;
Button.Pair = Pair;

export default Button;

/**
 * @description Sets responsive visibility.
 * @param {string|array} value
 * @returns {array}
 */
const setVisibility = value => {
  if (!value) return "";

  if (typeof value === "string") value = value.split(" ");

  return value.map((e, i) => {
    return `hide ${e}`;
  });
};

/**
 * @description Returns translated text.
 * @param {string} pretext translation key
 * @returns {string}
 */
const translatePretext = pretext => {
  if (!pretext) return undefined;

  if(pretext.indexOf(".") <= -1) return tran(`unique.${pretext}`);
  return tran(pretext);
};

Button.propTypes = {
  variant: PropTypes.oneOf(["contained", "outlined", "text", "icon", "fab"]),
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "success",
    "error",
    "warning",
    "disabled",
    "default"
  ]),
  size: PropTypes.oneOf(["xsmall", "small", "medium", "large"]),
  disabled: PropTypes.bool,
  inverted: PropTypes.bool,
  content: PropTypes.node,
  icon: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  unelevated: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.shape({ // emblemat
    content: PropTypes.any.isRequired,
    style: PropTypes.object, // Regular inline-styles
    type: PropTypes.oneOf(["error", "success", "primary", "secondary"]),
    float: PropTypes.oneOf([
      "left",
      "right",
      "top left",
      "top right",
      "bottom left",
      "bottom right"
    ])
  }),
  fluid: PropTypes.bool,
  handleRef: PropTypes.func,
  align: PropTypes.oneOf(["left", "right", "center"]),
  focus: PropTypes.bool,
  hideOn: PropTypes.oneOfType([
    PropTypes.oneOf(["mobile", "tablet", "desktop"]),
    PropTypes.array
  ]),
  popup: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      content: PropTypes.any.isRequired,
      style: PropTypes.object, // Regular inline-styles
      props: PropTypes.object // See Popup component
    })
  ]),
  style: PropTypes.object, // Regular inline-styles
  pretext: PropTypes.oneOfType([PropTypes.oneOf([
    "save",
    "cancel",
    "remove",
    "delete",
    "back",
    "tryAgain",
    "clear",
    "close",
    "confirm",
    "continue",
    "download",
    "edit",
    "leave",
    "reset",
    "send",
    "search",
    "upload",
    "add",
    "yes",
    "no",
    "fill",
    "create",
    "consider",
    "example",
    "consent",
    "select",
    "refresh",
  ]), PropTypes.string]),
  preset: PropTypes.oneOf([
    'save', 'cancel', 'remove', "delete", 'back', 'continue', 'edit', 'send', 'close', 'confirm', 'login', 'logout', "select", "special", "reset", "leave", "refresh", "tryAgain",,
    "paginationFirst",
    "paginationPrev",
    "paginationNext",
    "paginationLast",
  ]),
  loading: PropTypes.bool,
  to: PropTypes.string, // Wraps the component with a Link component.
  
  /** FAB-only props */
  cornered: PropTypes.oneOf([
    "top left",
    "top right",
    "bottom left",
    "bottom right"
  ]),
  toggled: PropTypes.bool,

  /** Deprecated */
  compact: PropTypes.bool,
  floated: PropTypes.oneOf(["left", "right"]),

  /** Rest */
  tabIndex: PropTypes.number,
  preventDefault: PropTypes.bool,
  
  keybind: PropTypes.string,
};

Button.defaultProps = {
  variant: "contained",
  disabled: false,
  inverted: false,
  unelevated: false,
  size: "medium",
  fluid: false,
  iconPosition: "left",
  loading: false,

  /** FAB-only props */
  toggled: true,

  /** Deprecated */
  compact: false,

  /** Rest */
  tabIndex: 0,
};
