import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

const RangeSlider = ({
  value,
  min = 0, // Has to be 0 if step prop is applied, otherwise it will result in values like 51, 81, 101 etc.
  max = 100,
  step = 1,
  onChange,
  displayValue = true,
  ...props
}) => {
  const classes = cx("sowa", "range-slider");

  const handleChange = element =>
    onChange && onChange(Number(element.target.value));

  return (
    <div style={{ textAlign: "center" }}>
      {displayValue ? <p>{value}</p> : null}
      <input
        step={step}
        type="range"
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
        className={classes}
        {...props}
      />
    </div>
  );
};

RangeSlider.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,

  displayValue: PropTypes.bool,
  onChange: PropTypes.func
};

export default RangeSlider;
